import React, { Component } from "react";
import { Link } from "react-router-dom";
import { api } from "../common/api";
import $ from "jquery";
//Jaam new imports
import joinPage from "../assets/svg/joinPage.svg";
import signIn from "../assets/svg/signIn.svg";
import JoinWithoutPasscode from "../assets/svg/JoinWithoutPasscode.svg";
import inviteViaEmail from "../assets/svg/inviteViaEmail.svg";
import hostAcceptLobby from "../assets/svg/hostAcceptLobby.svg";
import enableLobby from "../assets/svg/enableLobby.svg";
import waitingRoom from "../assets/svg/waitingRoom.svg";
import payment from "../assets/svg/payment.svg";
import paymentCard from "../assets/svg/paymentCard.svg";
import paymentCancel from "../assets/svg/paymentCancel.svg";
//Jaam New Imports
import invitePage3 from "../assets/svg/invitePage3.JPG";
import previewVideoOn from "../assets/svg/previewVideoOn.svg";
import av2 from "../assets/av2.svg";
import ss1 from "../assets/ss1.svg";
import host from "../assets/svg/host.svg";
import coHost from "../assets/svg/coHost.svg";
import participant1 from "../assets/svg/participant1.svg";
import participant2 from "../assets/svg/participant2.svg";
import chat1 from "../assets/svg/chat1.svg";
import leaveMeeting from "../assets/svg/leaveMeeting.svg";
import Themes from "../assets/svg/Themes.jpg";
//Jaam New Imports
import leaveMeeting1 from "../assets/svg/leaveMeeting1.JPG";
import moreAction1 from "../assets/svg/moreAction1.svg";
import moreAction2 from "../assets/svg/moreAction2.svg";
//Jaam new imports end

import join from "../assets/videos/join.mp4";
import invite from "../assets/videos/invite.mp4";
import screenshare from "../assets/videos/screenshare.mp4";
import hostcontrols from "../assets/videos/hostcontrols.mp4";
import moreoptions from "../assets/videos/moreoptions.mp4";
import chat from "../assets/videos/chat.mp4";
import leavemeeting from "../assets/videos/leavemeeting.mp4";



class Supportpage extends Component {

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    window.scrollTo(0, 0);
  }

  state = {};

  handleClick = () =>{
    $(".dropdown-menu li a").click(function() {
      var selText = $(this).text();
      $(this).parents('.btn-group').find('.dropdown-toggle').html(selText + ' <span class="caret"></span>');
    });
  }

  render() {
    return (
      <section className="background">
        <div className="container container-decoration">
          <div className="row">
            <div className="col-lg-12 text-center anytime">
              Hi, Get support  at anytime
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-2 col-12 col-md-2 col-lg-2 ml-lg-n3 col-xl-2">
              <div className="col-md-12 showmd">
                <nav className="nav text-center nav-pills flex-column">
                  <a className="nav-link text-blackk ml-n2 mr-n3 text-left mb-2 active" href="#ninja" data-toggle="tab">Join a Meeting</a>
                  <a className="nav-link text-blackk ml-n2 mr-n3 text-left mb-2" href="#wizard" data-toggle="tab">Invite a Meeting</a>
                  <a className="nav-link text-blackk ml-n2 mr-n3 text-left mb-2" href="#captain" data-toggle="tab">Audio &amp; Video</a>
                  <a className="nav-link text-blackk ml-n2 mr-n3 text-left mb-2" href="#avenger" data-toggle="tab">Screen Sharing</a>
                  <a className="nav-link text-blackk ml-n2 mr-n3 text-left mb-2" href="#host" data-toggle="tab">Host control</a>
                  <a className="nav-link text-blackk ml-n2 mr-n3 text-left mb-2" href="#Participant" data-toggle="tab">Participant</a>
                  {/* <a className="nav-link text-blackk ml-n2 mr-n3 text-left mb-2" href="#more" data-toggle="tab">More actions</a> */}
                  <a className="nav-link text-blackk ml-n2 mr-n3 text-left mb-2" href="#security" data-toggle="tab">Security</a>
                  <a className="nav-link text-blackk ml-n2 mr-n3 text-left mb-2" href="#chat" data-toggle="tab">Chat</a>
                  <a className="nav-link text-blackk ml-n2 mr-n3 text-left mb-2" href="#Themes" data-toggle="tab">Themes</a>
                  <a className="nav-link text-blackk ml-n2 mr-n3 text-left mb-2" href="#payment" data-toggle="tab">Payment</a>
                   {/* Jaam code text changed */}
                  <a className="nav-link text-blackk ml-n2 mr-n3 text-left mb-2" href="#exit" data-toggle="tab">Leave Meeting</a>
                </nav>
              </div>
              <div class="col-12 input-prepend input-append showxs support-navigation">
                <div class="btn-group landing-mobile-nav support-navigation">
                  <button class="btn dropdown-toggle select-main-bg ml-n2 support-navigation roboto-large-white" onClick={this.handleClick} name="recordinput" data-toggle="dropdown">
                    Join a Meeting
                    <span class="caret support-navigation"></span>
                  </button>
                  <ul class="dropdown-menu nav flex-column support-navigation">
                    <li><a className="nav-link mb-2 ml-3 text-purple active" href="#ninja" data-toggle="tab">Join a Meeting</a></li>
                    <li><a className="nav-link mb-2 ml-3 text-purple" href="#wizard" data-toggle="tab">Invite a Meeting</a></li>
                    <li><a className="nav-link mb-2 ml-3 text-purple" href="#captain" data-toggle="tab">Audio &amp; Video</a></li>
                    <li><a className="nav-link mb-2 ml-3 text-purple" href="#avenger" data-toggle="tab">Screen Sharing</a></li>
                    <li><a className="nav-link mb-2 ml-3 text-purple" href="#host" data-toggle="tab">Host control</a></li>
                    <li><a className="nav-link mb-2 ml-3 text-purple" href="#Participant" data-toggle="tab">Participant</a></li>
                {/* <li><a className="nav-link mb-2 ml-3 text-purple" href="#more" data-toggle="tab">More actions</a></li> */}
                    <li><a className="nav-link mb-2 ml-3 text-purple" href="#security" data-toggle="tab">Security</a></li>
                    <li><a className="nav-link mb-2 ml-3 text-purple" href="#chat" data-toggle="tab">Chat</a></li>
                    <li><a className="nav-link mb-2 ml-3 text-purple" href="#Themes" data-toggle="tab">Themes</a></li>
                    <li><a className="nav-link mb-2 ml-3 text-purple" href="#payment" data-toggle="tab">Payment</a></li>
                    {/* Jaam code text changed */}
                    <li><a className="nav-link mb-2 ml-3 text-purple" href="#exit" data-toggle="tab">Leave Meeting</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row"><div className="verticalll ml-lg-1" id="vertdis"></div></div>
            <div className="tab-content col-12 col-md-9">
              <div className="tab-pane active" id="ninja">
                <div className="row">

                  <div className="col-md-12 col-12 text-left text-black">
                    <div className="row">
                      <div className="col-md-12 mb-2" id="giveleft">
                        <span className="joinameet">How to join a meeting?</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <video width="100%" height="80%" controls >
                        <source src="https://jaampromo.s3.us-east-2.amazonaws.com/support/JOIN.mp4" type="video/mp4" />
                        </video>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">&nbsp;</div>
                      <div className="col-md-12 horizlinee"></div>
                      <div className="col-md-12">&nbsp;</div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">&nbsp;</div>


                      <div className="col-md-12 mb-4">
                        <div className="row">
                          <div className="col-md-6">
                            {/* Jaam code start */}
                            <div className="mt-5">
                              <img src={JoinWithoutPasscode}
                                height="100%"
                                width="100%"
                                alt=""
                                className="w-100"
                                data-toggle="modal"
                                data-target="#JoinWithoutPasscodeModal"
                              />
                            </div>
                          </div>
                          <div className="col-md-12 showxs">&nbsp;</div>
                          <br/>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-12 instructions"><b>Method 1:</b></div>
                              <br/>
                              <br/>
                              {/* Jaam code text changed*/}
                              <ul className="Joinameetunorderlist joinameetspacing ml-5 text-justify">
                                <li className="ml-n4 mb-2 instructions">
                                  Enter into jaam website
                                  <Link
                                    to="/home"
                                    id="on"
                                    className="text-purple fontbold"
                                  >
                                    &nbsp;{`${api.WEB_URL}`}
                                  </Link>.</li>
                                <li className="ml-n4 mb-2 instructions">Click on the 'Join' button on the home page
                                  <Link
                                    to="/home"
                                    id="on"
                                    className="text-purple fontbold"
                                  >
                                    &nbsp;{`${api.WEB_URL}`}&nbsp;
                                  </Link>
                                  {/* Jaam code text changed */}
                                  if you want to join as a non-signin user.</li>
                                <li className="ml-n4 mb-2 instructions">Enter the valid meeting id and name.click on join, then enter the passcode if required(This is the
                                  name that will appear in the participant's panel for your meeting).</li>
                                <li className="ml-n4 mb-2 instructions">Click on the join button.</li>
                                <li className="ml-n4 mb-2 instructions">It redirects to the jaam preview screen.Enable/Disable audio and video and click on 'Join meeting' button.</li>
                                <li className="ml-n4 mb-2 instructions">It redirects to the jaam conference page.you have successfully join a meeting.</li>
                              </ul>
                              <br/>
                            </div>
                          </div>
                          <div class="modal fade" id="JoinWithoutPasscodeModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                            aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <button type="button" class="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div class="modal-body">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <img
                                        src={JoinWithoutPasscode}
                                        className="w-100" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Jaam code end */}
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            {/* Jaam code start */}
                            <div className="mt-5">
                              <img src={signIn}
                                height="100%"
                                width="100%"
                                className="mt-n1 w-100"
                                data-toggle="modal"
                                data-target="#signInModal"
                                alt=""/>
                            </div>
                          </div>
                          <div className="col-md-12 showxs">&nbsp;</div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-12 instructions"><b>Method 2:</b></div>
                              <br/>
                              <br/>
                              <ul className="Joinameetunorderlist joinameetspacing text-justify">
                                <li className="ml-3 mb-2 instructions">Enter into Jaam website "(
                                  <Link
                                    to="/home"
                                    id="on"
                                    className="text-purple fontbold"
                                  >
                                    {`${api.WEB_URL}`}
                                  </Link>
                                  )"</li>
                                  {/* Jaam code text changed */}
                                <li className="ml-3 mb-2 instructions">Click on the sign in button to join the meeting as a sign in user.</li>
                                <li className="ml-3 mb-2 instructions">Select the account type (Google/Facebook).</li>
                                <li className="ml-3 mb-2 instructions">Enter the valid account details and click on sign in.It redirects to the account page.</li>
                                <li className="ml-3 mb-2 instructions">Click on my account.It redirects to landing page. </li>
                                <li className="ml-3 mb-2 instructions">Now click on the "Join now" link to join the meeting .</li>
                                <li className="ml-3 mb-2 instructions">Enter the valid meeting ID and name, then enter the passcode if required and click on 'Join'.</li>
                                <li className="ml-3 mb-2 instructions">It redirects to the jaam preview screen. Enable or Disable audio and video and join meeting.</li>
                                <li className="ml-3 mb-2 instructions">You have successfully join the meeting. </li>
                                <br/>
                                <br/>
                              </ul>
                            </div>
                          </div>
                          <div class="modal fade" id="signInModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                            aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <button type="button" class="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div class="modal-body">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <img
                                        src={signIn}
                                        className="w-100" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Jaam code end */}
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                          <div className="col-md-12 showxs">&nbsp;</div>
                          </div>
                          <div className="col-md-12 showxs">&nbsp;</div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-12 instructions"><b>Method 3:</b></div>
                              <br/>
                              <br/>
                              {/* Jaam code text changed */}
                              <ul className="Joinameetunorderlist joinameetspacing text-justify">
                                <li className="ml-3 mb-2 instructions">You can also join the meeting with the meeting invitation sent to you in email or message.</li>
                                <li className="ml-3 mb-2 instructions">Just click on the URL ({`${api.WEB_URL}/join/********`}). It redirects to the join page.</li>
                                <li className="ml-3 mb-2 instructions">Enter name then enter the passcode if required and click on the join button.</li>
                                <li className="ml-3 mb-2 instructions">It redirects to the jaam preview screen. Enable or Disable audio and video and click on the 'Join meeting'.</li>
                                <li className="ml-3 mb-2 instructions">You have successfully join the meeting.</li>
                              </ul>
                            </div>
                          </div>
                        
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="wizard">
                <div className="row">
                  <div className="col-md-12 col-12 text-left text-black">
                    <div className="row">
                      <div className="col-md-12 mb-2" id="giveleft">
                        <span className="joinameet">How to invite a meeting?</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <video width="100%" height="80%" controls >
                          <source src="https://jaampromo.s3.us-east-2.amazonaws.com/support/INVITE.mp4" type="video/mp4" />
                        </video>
                      </div>
                      <div className="col-md-12">&nbsp;</div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">&nbsp;</div>
                      <div className="col-md-12 horizlinee"></div>
                      <div className="col-md-12">&nbsp;</div>
                    </div>
                    <div className="row">

                      <div className="col-md-12">&nbsp;</div>
                      <div className="col-md-12 mb-4">
                        <div className="row ">
                        <div className="col-md-6">&nbsp;</div>
                        {/* Jaam code text changed */}
                        <div className="col-md-6 my-auto">
                        <div className="row">
                          <div className="col-md-12 instructions">
                          <div className="col-md-12 text-justify"><b>Inviting others to join a meeting - Meeting in progress</b></div>
                           <ul className="mb-4 ">
                              <li className="mb-2">Visit www.jaam.co in your browser.</li>
                              <li className="mb-2">Join or start a meeting.</li>
                              <li className="mb-2">Click on the invite link on the top corner.</li>
                              <li className="mb-2">Or click on the participant's icon on the menu.</li>
                              <li className="mb-2">Click on the invite button on the bottom of the participant's panel.</li>
                              <li className="mb-2">Click on the list of invite options.</li>
                            </ul>
                          </div>
                          </div>
                        </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            {/* Jaam code start */}
                            <img src={inviteViaEmail}
                              height="100%"
                              width="100%"
                              alt=""
                              className="w-100"
                              data-toggle="modal"
                              data-target="#inviteViaEmailModal" />
                          </div>
                          <div className="col-md-6 my-auto">
                            <div className="row">
                              <div className="col-md-12 instructions">
                                <br/>
                                <br/>
                                {/* Jaam code text changed */}
                                <div className="col-md-12 text-justify"><b>Step1: Invite using Email:</b></div>
                                <ul className="mb-4 text-justify">
                                  <li className="mb-2">Default Email: Invite others by using your default email application from your computer.</li>
                                  <li className="mb-2">Gmail or Yahoo Mail : Invite others by using the gmail or yahoo website.you will be prompted to sign in to your account.</li>
                                  <li className="mb-2">OutLook Email: Invite others by using your default outlook email from your computer.</li>
                                  <li className="mb-2">Add recipients to the email and send it.</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                       
                        <div class="modal fade" id="inviteViaEmailModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                          aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                              </div>
                              <div class="modal-body">
                                <div class="row">
                                  <div class="col-md-12">
                                    <img
                                      src={inviteViaEmail}
                                      className="w-100" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Jaam code end*/}
                        </div>
                      <div className="col-md-12">&nbsp;</div>
                      </div>
                    <div className="row">
                      <div className="col-md-6">
                        {/* Jaam code start */}
                        <img src={invitePage3}
                          height="90%"
                          width="100%"
                          alt=""
                          className="w-100"
                          data-toggle="modal"
                          data-target="#invitePage3Modal" />
                      </div>
                      <div className="col-md-12 showxs">&nbsp;</div>
                      <div className="col-md-12 showxs">&nbsp;</div>
                      <div className="col-md-6 instructions">
                        {/* Jaam code text changed */}
                        <div className="col-md-12 text-justify"><b>Step2:Invite using copy invite link and URL</b></div>
                        <ul className="mb-4 text-justify">
                          <li className="mb-2">Click on the copy invite link and paste into participants' message or send it via email.</li>
                          <li className="mb-2">Click on the "copy Invitation" details to copy the full invitation with a passcode (If enable) and send it to the participants.</li>
                        </ul>
                      </div>
                    <div className="col-md-12">&nbsp;</div>
                  </div>
                  </div>
                  </div>
                <div class="modal fade" id="invitePage3Modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                  aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="row">
                          <div class="col-md-12">
                            <img
                              src={invitePage3}
                              className="w-100"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
               
               <div className="row">
               <div className="col-md-6">&nbsp;</div>
                        <div className="col-md-6 my-auto">
                            <div className="row">
                  <div className="col-md-12 instructions">
                    <div className="col-md-12 text-justify"><b>Inviting others to join a meeting - Before meeting starts</b></div>
                    <ul className="mb-4 text-justify">
                      <li className="mb-2">Visit jaam page (www.jaam.co) and sign into your jaam account.</li>
                      <li className="mb-2">On the landing page click the Copy URL/Copy invite URL.</li>
                      <li className="mb-2">The meeting details are copied to the clipboard.</li>
                      <li className="mb-2">Share it with users you want to invite.</li>
                    </ul>
                    {/* Jaam code end */}
                    </div>
                </div> 
                  </div>
                </div> 
              </div>

              <div className="tab-pane fade" id="captain">
                <div className="row">
                  <div className="col-md-12 col-12 text-left text-black">
                    <div className="row">
                      <div className="col-md-12 mb-2" id="giveleft">
                        <span className="joinameet">How to adjust video and audio settings?</span>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="col-md-6">
                      <video width="100%" height="80%" controls >
                      <source src={invite} type="video/mp4"/>
                      </video>
                      </div>
                      <div className="col-md-12">&nbsp;</div>
                      </div> 
                      <div className="row">
                      <div className="col-md-12">&nbsp;</div>
                      <div className="col-md-12 horizlinee"></div>
                      <div className="col-md-12">&nbsp;</div>
                      </div>*/}
                    <div className="row">
                      <div className="col-md-12">&nbsp;</div>
                      <div className="col-md-12 mb-4">
                        <div className="row">
                          <div className="col-md-6">
                         {/* Jaam code start */}
                            <img src={previewVideoOn}
                              height="100%"
                              width="100%"
                              alt=""
                              className="w-100"
                              data-toggle="modal"
                              data-target="#previewVideoOnModal" />
                          </div>
                          <div className="col-md-12 showxs">&nbsp;</div>
                          <div className="col-md-6 instructions my-auto">
                            <div className="row">
                              {/* Jaam code text changed */}
                              <div className="col-12"><b>How to adjust video and audio settings?</b></div>
                              <ul className="mb-4">
                                <li className="mb-2">Open the jaam.co.</li>
                                <li className="mb-2">Start/Join the meeting.</li>
                                <li className="mb-2">Enable the audio/video and click on start meeting.</li>
                                <li className="mb-2">If you're using a jaam meeting for the first time on the device, you will be asked to allow permission to access the camera and microphone.</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="modal fade" id="previewVideoOnModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                          aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                              </div>
                              <div class="modal-body">
                                <div class="row">
                                  <div class="col-md-12">
                                    <img
                                      src={previewVideoOn}
                                      className="w-100" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Jaam code end */}
                      </div>
                      <div className="col-md-12">&nbsp;</div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 align-self-start">
                        {/* Jaam code start */}
                        <img src={av2}
                          height="100%"
                          width="100%"
                          alt=""
                          className="w-100"
                          data-toggle="modal"
                          data-target="#av2Modal" />
                      </div>
                      <div className="col-md-12 showxs">&nbsp;</div>
                      <div className="col-md-6 instructions">
                        <div className="col-12"><b>Here are the steps on how to allow audio on a chrome computer:</b></div>
                        <ul className="mb-4">
                          {/* Jaam text changed*/}
                          <li className="mb-2">Launch the google chrome browser on computer.</li>
                          <li className="mb-2">Click on the entire menu for options.</li>
                          <li className="mb-2">Select settings from the list.</li>
                          <li className="mb-2">Scroll down to the privacy and security section, and select the site settings from the menu.</li>
                          <li className="mb-2">Choose the sounds option within site settings.</li>
                          <li className="mb-2">Toggle the button to turn ON and allow the sound permissions.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal fade" id="av2Modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                  aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="row">
                          <div class="col-md-12 mt-9">
                            <img
                              src={av2}
                              className="w-100" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Jaam code end*/}
              </div>

              <div className="tab-pane fade" id="avenger">
                <div className="row">
                  <div className="col-md-12 col-12 text-left text-black">
                    <div className="row">
                      <div className="col-md-12 mb-2" id="giveleft">
                        <span className="joinameet">How to screen sharing?</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <video width="100%" height="80%" controls >
                          <source src="https://jaampromo.s3.us-east-2.amazonaws.com/support/SCREENSHARE.mp4" type="video/mp4" />
                        </video>
                      </div>
                      <div className="col-md-12">&nbsp;</div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">&nbsp;</div>
                      <div className="col-md-12 horizlinee"></div>
                      <div className="col-md-12">&nbsp;</div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">&nbsp;</div>
                      <div className="col-md-12">
                        <div className="row">
                          {/* Jaam code start */}
                          <div className="col-md-6  align-self-start">
                            <img src={ss1}
                              height="100%"
                              width="100%"
                              alt=""
                              className="w-100"
                              data-toggle="modal"
                              data-target="#ss1Modal" />
                          </div>
                          <div className="col-md-12 showxs">&nbsp;</div>
                          <div className="col-md-6 my-auto instructions  text-justify">
                            <div className="row">
                              <div className="col-md-12"><b>Step 1:</b></div>
                              <div className="col-md-12">Join the meeting with valid meeting ID.</div>
                              <div className="col-md-12 mb-3">Click on the “Share Screen” button on the menu provided on your screen.</div>
                              <br/>
                              <div className="col-md-12"><b>Step 2:</b></div>
                              <div className="col-md-12 mb-3">A popup window will be displayed with three screen sharing features..</div>
                              <b/>
                              <div className="col-md-12"><b>Step 3:</b></div>
                              <div className="col-md-12 mb-3">Click on the “Your Entire Screen” tab, to share the entire screen of your PC or Laptop and hit “Share audio” to screen your screen along with audio and press the “Share” button to start the screen share.</div>
                              <br/>
                              <div className="col-md-12"><b>Step 4:</b></div>
                              <div className="col-md-12 mb-3">Click the &quot;Application Window&quot; tab, the list of applications opened in
                                your device is shown. You can select from the preview lists on the screen and
                                hit &quot;Share audio&quot; to screen your screen along with audio and press the &quot;Share&quot;
                                button to start the screen share.</div>
                              <br/>
                            </div>
                          </div>
                        </div>
                        <div class="modal fade" id="ss1Modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                          aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                              </div>
                              <div class="modal-body">
                                <div class="row">
                                  <div class="col-md-12">
                                    <img
                                      src={ss1}
                                      className="w-100" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Jaam code end */}
                      </div>
                      <div className="col-md-12">&nbsp;</div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                      <div className="col-md-12 showxs">&nbsp;</div>
                      </div>
                      <div className="col-md-12 showxs">&nbsp;</div>
                      <div className="col-md-6">
                      <div className="row">
                      <div className="col-md-12 instructions ml-n2">
                        <div className="col-md-12"><b>Step 5:</b></div>
                        <div className="col-md-12 mb-3">Click the &quot;Browser Tab&quot; button, this feature will list out all the tabs
                          opened on your browser and you can select among them and hit &quot;Share audio&quot;
                          to screen your screen along with audio and press the &quot;Share&quot; button to start
                          the screen share.</div>
                        <br/>
                        <div className="col-md-12"><b>Step 6:</b></div>
                        
                        <div className="col-md-12 mb-3">Now the participants can see your screen.To stop sharing your screen click the &quot;Stop&quot; button provided on the
                          notification popup or click on the screen share icon on the menu list.</div>
                        <div className="col-md-12"><b>Step 7:</b></div>
                        {/* Jaam code text changed */}
                        <div className="col-md-12 mb-3">Happy sharing and jamming! keep inspiring with your presentations!</div>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
               </div>

              {/* <div className="tab-pane fade" id="record">
                      <div className="row">
                      <div className="col-md-12 col-12 text-left text-black">
                      <div className="row">
                      <div className="col-md-12 mb-2" id="giveleft">
                      <span className="joinameet">How to record a meeting?</span>
                      </div>
                      </div> */}
              {/* <div className="row">
                      <div className="col-md-6">
                      <video width="100%" height="80%" controls >
                      <source src={invite} type="video/mp4"/>
                      </video>
                      </div>
                      <div className="col-md-12">&nbsp;</div>
                      </div> 
                      <div className="row">
                      <div className="col-md-12">&nbsp;</div>
                      <div className="col-md-12 horizlinee"></div>
                      <div className="col-md-12">&nbsp;</div>
                      </div>*/}
              {/* <div className="row">
                      <div className="col-md-12">&nbsp;</div>
                      <div className="col-md-12 mb-4">
                      <div className="row">
                      <div className="col-md-6">
                      <img src={record1}
                      height="100%"
                      width="100%"
                      alt=""/>
                      </div>
                      <div className="col-md-6 my-auto">
                      <div className="row">
                      <div className="col-md-12 instructions">
                      Press  record icon to start the conference recording,
                      again to stop recording and  confirm.
                     </div>
                     </div>  
                     </div>
                      </div>
                      </div>
                      <div className="col-md-12">&nbsp;</div>
                      </div>
                      <div className="row">
                      <div className="col-md-6">
                      <img src={record2}
                      height="100%"
                      width="100%"
                      alt=""/>
                      </div>
                      </div>
                      </div>
                    </div>
                    </div> */}

              <div className="tab-pane fade" id="host">
                <div className="row">
                  <div className="col-md-12 col-12 text-left text-black">
                    <div className="row">
                      <div className="col-md-12 mb-2" id="giveleft">
                        <span className="joinameet">How to host control?</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <video width="100%" height="80%" controls >
                          <source src="https://jaampromo.s3.us-east-2.amazonaws.com/hostcontrols.mp4" type="video/mp4" />
                        </video>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">&nbsp;</div>
                      <div className="col-md-12 horizlinee"></div>
                      <div className="col-md-12">&nbsp;</div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">&nbsp;</div>
                      <div className="col-md-12 mb-4">
                        {/* Jaam code start */}
                        <div className="row ">
                          <div className="col-md-6 align-self-start">
                            <img src={host}
                              height="100%"
                              width="100%"
                              alt=""
                              className="w-100"
                              data-toggle="modal"
                              data-target="#hostModal" />
                          </div>
                          <div className="col-md-12 showxs">&nbsp;</div>
                          <div className="col-md-6">
                            <div className="row">
                              {/* Jaam code text changed */}
                              <div className="col-md-12 instructions   text-justify">
                                <b>Host control in a meeting</b>
                                <br/>Host control allow you as the host to control various aspects of a meeting, such as managing the participant.
                                <ul className="mb-4">
                                <li className="mb-2">Start a meeting.</li>
                                <li className="mb-2">End meeting for all participants.</li>
                                <li className="mb-2">Make another participant a co-host.</li>
                                <li className="mb-2">Start waiting room(Hosts can place participants in waiting room).</li>
                                <li className="mb-2">Host can also enable the security option(can admit/remove participants from the lobby room).</li>
                                <li className="mb-2">Can mute the participant.</li>
                                <li className="mb-2">The host can disable the camera option for the participants.</li>
                                <li className="mb-2">The host can also remove the participants from the meeting.</li>
                              </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 showxs">&nbsp;</div>
                        <div class="modal fade" id="hostModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                          aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                              </div>
                              <div class="modal-body">
                                <div class="row">
                                  <div class="col-md-12">
                                    <img
                                      src={host}
                                      className="w-100" />
                                  </div>
                                </div>
                                {/* Jaam code end */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">&nbsp;</div>
                    </div>
                    <div className="row">
                          <div className="col-md-6">
                            {/* Jaam code start */}
                            <img src={coHost}
                              height="100%"
                              width="100%"
                              alt=""
                              className="w-100"
                              data-toggle="modal"
                              data-target="#coHostModal"/>
                          </div>
                          <div className="col-md-12 showxs">&nbsp;</div>
                          <div className="col-md-6">
                            <div className="row">
                              {/* Jaam code text changed */}
                               <div className="col-md-12 instructions text-justify">
                               <ul className="mb-4">
                                <li className="mb-2">Co-hosts also cannot start a meeting.</li>
                                <li className="mb-2">If a host needs someone else to be able to start the meeting, they can assign an alternative host.</li>
                                <li className="mb-2">Participants do not have access to the following controls as they are only available for only host and co-host in a meeting.</li>
                                </ul>
                              </div> 
                            </div>
                          </div>
                        </div>
                        <div class="modal fade" id="coHostModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                          aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                              </div>
                              <div class="modal-body">
                                <div class="row">
                                  <div class="col-md-12">
                                    <img
                                      src={coHost}
                                      className="w-100" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 showxs">&nbsp;</div>
                        {/* Jaam code end */}
                        </div>
                      </div>
                </div>
              </div>

              <div className="tab-pane fade" id="Participant">
                <div className="row">
                  <div className="col-md-12 col-12 text-left text-black">
                    <div className="row">
                      <div className="col-md-12 mb-2" id="giveleft">
                        <span className="joinameet">How to view participants in a meeting?</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <video width="100%" height="80%" controls >
                          <source src={invite} type="video/mp4" />
                        </video>
                      </div>
                      <div className="col-md-12">&nbsp;</div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">&nbsp;</div>
                      <div className="col-md-12 horizlinee"></div>
                      <div className="col-md-12">&nbsp;</div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">&nbsp;</div>
                      <div className="col-md-12 mb-4">
                        <div className="row">
                          <div className="col-md-6">
                            {/* Jaam code start */}
                            <img src={participant1}
                              height="100%"
                              width="100%"
                              alt=""
                              className="w-100"
                              data-toggle="modal"
                              data-target="#participant1Modal" />
                          </div>
                          <div className="col-md-12 showxs">&nbsp;</div>
                          <div className="col-md-6 my-auto">
                            <div className="row instructions  text-justify">
                              <br/>
                              {/* Jaam code text changed */}
                              <ul className="mb-4">
                                <li className="mb-2">Start/Join the meeting.</li>
                                <li className="mb-2">Click the “open or close participants” button on the menu provided on your left side of the screen.</li>
                                <li className="mb-2">A window will pop up on your screen with the list of participants in the meeting.</li>
                                <li className="mb-2">You can view the list of participants in the gallery view on the top right corner of your screen.</li>
                                <li className="mb-2">All participants will be displayed on a single page.</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="modal fade" id="participant1Modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                          aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                              </div>
                              <div class="modal-body">
                                <div class="row">
                                  <div class="col-md-12">
                                    <img
                                      src={participant1}
                                      className="w-100" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Jaam code end */}
                      </div>
                      <div className="col-md-12">&nbsp;</div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        {/* Jaam code start */}
                        <img src={participant2}
                          height="100%"
                          width="100%"
                          alt=""
                          className="w-100"
                          data-toggle="modal"
                          data-target="#participant2Modal" />
                      </div>
                      <div className="col-md-12 showxs">&nbsp;</div>
                    </div>
                  </div>
                </div>
                <div class="modal fade" id="participant2Modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                  aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="row">
                          <div class="col-md-12">
                            <img
                              src={participant2}
                              className="w-100" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Jaam code end */}
              </div>

              {/* <div className="tab-pane fade" id="more">
                <div className="row">
                  <div className="col-md-12 col-12 text-left text-black">
                    <div className="row">
                      <div className="col-md-12 mb-2" id="giveleft">
                        <span className="joinameet">How to use more actions settings?</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <video width="100%" height="80%" controls >
                          <source src="https://jaampromo.s3.us-east-2.amazonaws.com/moreoptions.mp4" type="video/mp4" />
                        </video>
                      </div>
                      <div className="col-md-12">&nbsp;</div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">&nbsp;</div>
                      <div className="col-md-12 horizlinee"></div>
                      <div className="col-md-12">&nbsp;</div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">&nbsp;</div>
                      <div className="col-md-12 mb-4">
                        <div className="row">
                          <div className="col-md-6">
                            <img src={moreAction1}
                              height="100%"
                              width="100%"
                              alt=""
                              className="w-100"
                              data-toggle="modal"
                              data-target="#moreAction1Modal" />
                          </div>
                          <div className="col-md-12 showxs">&nbsp;</div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-12 instructions mt-4 text-justify">
                                <ul className="mb-4">
                                  <li className="mb-2">In more settings, you can manage video quality.</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="modal fade" id="moreAction1Modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                          aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                              </div>
                              <div class="modal-body">
                                <div class="row">
                                  <div class="col-md-12">
                                    <img
                                      src={moreAction1}
                                      className="w-100" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">&nbsp;</div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <img src={moreAction2}
                          height="100%"
                          width="100%"
                          alt=""
                          className="w-100"
                          data-toggle="modal"
                          data-target="#moreAction2Modal" />
                      </div>
                      <div className="col-md-12 showxs">&nbsp;</div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-12 instructions mt-3  text-justify">
                            <ul className="mb-4">
                              <li className="mb-2"> We can view speaker stats.</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal fade" id="moreAction2Modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                  aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="row">
                          <div class="col-md-12">
                            <img
                              src={moreAction2}
                              className="w-100" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
 */}
             {/* Jaam code start */}
              <div className="tab-pane fade" id="security">
                <div className="row">
                  <div className="col-md-12 col-12 text-left text-black">
                    <div className="row">
                      <div className="col-md-12 mb-2" id="security">
                        <span className="joinameet"> Lobby during a Meeting</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                       {/*  <video width="100%" height="80%" controls >
                          <source src={invite} type="video/mp4" />
                        </video> */}
                      </div>
                      <div className="col-md-12">&nbsp;</div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">&nbsp;</div>
                      <div className="col-md-12 horizlinee"></div>
                      <div className="col-md-12">&nbsp;</div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">&nbsp;</div>
                      <div className="col-md-12 mb-4">
                        <div className="row">
                          <div className="col-md-6 align-self-start">
                            <img src={waitingRoom}
                              height="100%"
                              width="100%"
                              alt=""
                              className="w-100"
                              data-toggle="modal"
                              data-target="#lobbyModal" />
                          </div>
                          <div className="col-md-12 showxs">&nbsp;</div>
                          <div className="col-md-6">
                            <div className="row instructions  text-justify">
                              <div className="col-md-12 text-justify"><b>Enabling or disabling Lobby during a Meeting</b></div>
                              <br/>
                         {/* Jaam code text changed */}
                              <ul className="mb-4">
                                <li className="mb-2">As the meeting host,can enable the participant in waiting room</li>
                                <li className="mb-2">Once the host start the meeting,it directs to Conference page,then.</li>
                                <li className="mb-2">Click more action in tool bar and tap to security options.</li>
                                <li className="mb-2">Enable lobby ON/OFF to enable the feature</li>
                              </ul>
                            </div>
                            <div class="modal fade" id="lobbyModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                              aria-hidden="true">
                              <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                                  </div>
                                  <div class="modal-body">
                                    <div class="row">
                                      <div class="col-md-12">
                                        <img
                                          src={waitingRoom}
                                          className="w-100" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <img src={enableLobby}
                                height="95%"
                                width="100%"
                                alt=""
                                className="w-100"
                                data-toggle="modal"
                                data-target="#lobby1Modal" />
                            </div>
                            <div className="col-md-12 showxs">&nbsp;</div>
                            <div className="col-md-6">
                              <div className="row instructions  text-justify">
                                <div className="col-md-12 text-justify"><b>Host can Admit/Reject Participants During a Meeting</b></div>
                                <br />
                                <ul className="mb-4">
                                  <li className="mb-2">Tap allow to have the participant join the meeting.</li>
                                  <li className="mb-2">Tap reject to have participant stop joining the meeting.</li>
                                </ul>
                              </div>
                            </div>

                          </div>
                        </div>
                        <div class="modal fade" id="lobby1Modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                          aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                              </div>
                              <div class="modal-body">
                                <div class="row">
                                  <div class="col-md-12">
                                    <img
                                      src={enableLobby}
                                      className="w-100" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">&nbsp;</div>
                    </div>
                    <div className="row">
                   
                      <div className="col-md-12 showxs">&nbsp;</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <img src={hostAcceptLobby}
                      height="100%"
                      width="100%"
                      alt=""
                      className="w-100"
                      data-toggle="modal"
                      data-target="#lobby2Modal"/>
                  </div>
                  <div className="col-md-12 showxs">&nbsp;</div>
                  <div className="col-md-6">
                    <div className="row instructions  text-justify">
                      <div className="col-md-12 text-justify"><b>Participant waiting in Lobby Room:</b></div>
                      <br />
                      <ul className="mb-4">
                        <li className="mb-2">Participant can Mute/Unmute themselves before joining the meeting.</li>
                        <li className="mb-2">Participant can video On/Off themselves before joining the meeting.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              
              <div class="modal fade" id="lobby2Modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                  aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="row">
                          <div class="col-md-12">
                            <img
                              src={hostAcceptLobby}
                              className="w-100"/>
                              {/* Jaam code end */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab-pane fade" id="chat">
                <div className="row">
                  <div className="col-md-12 col-12 text-left text-black">
                    <div className="row">
                      <div className="col-md-12 mb-2" id="giveleft">
                        <span className="joinameet">How to chat in meeting?</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <video width="100%" height="80%" controls >
                          <source src="https://jaampromo.s3.us-east-2.amazonaws.com/support/CHAT.mp4" type="video/mp4" />
                        </video>
                      </div>
                      <div className="col-md-12">&nbsp;</div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">&nbsp;</div>
                      <div className="col-md-12 horizlinee"></div>
                      <div className="col-md-12">&nbsp;</div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">&nbsp;</div>
                      <div className="col-md-12 mb-4">
                        <div className="row">
                          <div className="col-md-6">
                            {/* Jaam code start */}
                            <img src={chat1}
                              height="100%"
                              width="100%"
                              alt=""
                              className="w-100"
                              data-toggle="modal"
                              data-target="#chat1Modal" />
                          </div>
                          <div className="col-md-12 showxs">&nbsp;</div>
                          <div className="col-md-6">
                            {/* Jaam code text changed*/}
                            <div className="row instructions  text-justify ">
                              <ul className="mb-4">
                                <li className="mb-2">Click on the chat icon in the menu..</li>
                                <li className="mb-2">Chat allows you to message all participants in the meeting (Chat with all).</li>
                                <li className="mb-2">Or you can chat privately by clicking on a specific participants name.</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="modal fade" id="chat1Modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                          aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                              </div>
                              <div class="modal-body">
                                <div class="row">
                                  <div class="col-md-12">
                                    <img
                                      src={chat1}
                                      className="w-100" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Jaam code end */}
                      </div>
                      <div className="col-md-12">&nbsp;</div>
                    </div>
                  </div>
                </div>
               
                {/* Jaam code end*/}
              </div>


              <div className="tab-pane fade" id="Themes">
                <div className="row">
                  <div className="col-md-12 col-12 text-left text-black">
                    <div className="row">
                      <div className="col-md-12 mb-2" id="giveleft">
                        <span className="joinameet">How to use themes ?</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <video width="100%" height="80%" controls >
                          <source src="https://jaampromo.s3.us-east-2.amazonaws.com/support/PARTICIPANT.mp4" type="video/mp4" />
                        </video>
                      </div>
                      <div className="col-md-12">&nbsp;</div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">&nbsp;</div>
                      <div className="col-md-12 horizlinee"></div>
                      <div className="col-md-12">&nbsp;</div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">&nbsp;</div>
                      <div className="col-md-12">
                        <div className="row">
                          {/* Jaam code start */}
                          <div className="col-md-6 mb-5">
                            <img src={Themes}
                              height="90%"
                              width="90%"
                              alt=""
                              className="w-100"
                              data-toggle="modal"
                              data-target="#ThemesModal"/>
                          </div>
                          <div className="col-md-12 showxs">&nbsp;</div>
                          <div className="col-md-6">
                            {/* Jaam code text changed */}
                            <div className="row instructions  text-justify">
                              <ul className="mb-4">
                                <li className="mb-2">Enter into jaam website (
                                  <Link
                                    to="/home"
                                    id="on"
                                    className="text-purple fontbold"
                                  >
                                    {`${api.WEB_URL}`}
                                  </Link>
                                  )</li>
                                <li className="mb-2">Click the my account and it redirects to the landing page.</li>
                                <li className="mb-2">On the landing page click the check out now! link. A popup will be displayed.</li>
                                <li className="mb-2">Select the theme you like and click the apply button and close the window.</li>
                                <li className="mb-2">The Selected theme is displayed.</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="modal fade" id="ThemesModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                          aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                              </div>
                              <div class="modal-body">
                                <div class="row">
                                  <div class="col-md-12">
                                    <img
                                      src={Themes}
                                      className="w-100"/>
                                  </div>
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">&nbsp;</div>
                    </div> 
                  </div>
                </div>
              </div>

              {/* Payment */}
              <div className="tab-pane fade" id="payment">
                <div className="row">
                  <div className="col-md-12 col-12 text-left text-black">
                    <div className="row">
                      <div className="col-md-12 mb-2" id="giveleft">
                        <span className="joinameet">How to make payment?</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <video width="100%" height="80%" controls >
                          <source src="https://jaampromo.s3.us-east-2.amazonaws.com/leavemeeting.mp4" type="video/mp4" />
                        </video>
                      </div>
                      <div className="col-md-12">&nbsp;</div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">&nbsp;</div>
                      <div className="col-md-12 horizlinee"></div>
                      <div className="col-md-12">&nbsp;</div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">&nbsp;</div>
                      <div className="col-md-12">
                        <div className="row">
                          {/* Jaam code start*/}
                          <div className="col-md-6 align-self-start">
                            <img src={payment}
                              height="100%"
                              width="100%"
                              alt=""
                              className="w-100"
                              data-toggle="modal"
                              data-target="#paymentModal" />
                          </div>
                          <div className="col-md-12 showxs">&nbsp;</div>
                          <div className="col-md-6">
                            <div className="row">
                              {/* Jaam code text changed */}
                              <div className="col-md-12 instructions text-justify">
                                <span className="ml-3"><b>Make Payment-</b></span>
                                <ul className="text-justify">
                                  <li>Sign in to your Jaam ‘Account‘</li>
                                  <li>On your account page, click on the ‘billing’ tab or click on the ‘Upgrade Now’ button, it will redirect to the billing page.</li>
                                  <li>Or on your jaam home page, click on the ‘Pricing’ tab, it redirects to the pricing page.</li>
                                  <li>Choose the currency and the plan and click on the ‘Buy Now’ button, it redirects to the billing page.</li>
                                  <li>On the billing page, check your currency and plan, and then click on ‘Pay Now ‘ button.</li>
                                  <li>It redirects to the payment page.</li>
                                  <li>On the payment page, enter your valid account information, billing address.</li>
                                  <li>Click on subscribe button. now your payment is completed and you will receive a confirmation that your payment is successful and you will redirect to the billing page.</li>
                                  <li>You will receive your invoice/receipt to your registered Email.</li>
                                  <li>You can also download the receipt/invoice for your future reference in the ‘invoice history’ on the billing tab.</li>
                                  <li>Enjoy the unlimited fun-filled jaam experience!</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="modal fade" id="paymentModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                          aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                              </div>
                              <div class="modal-body">
                                <div class="row">
                                  <div class="col-md-12">
                                    <img
                                      src={payment}
                                      className="w-100" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Jaam code end */}
                      </div>
                    </div>
                    <div className="row">
                      
                      <div className="col-md-12 mb-4">
                        <div className="row">
                          <div className="col-md-6  align-self-start">
                            {/* Jaam code start*/}
                            <img src={paymentCard}
                              height="100%"
                              width="100%"
                              alt=""
                              className="w-100"
                              data-toggle="modal"
                              data-target="#paymentCardModal" />
                          </div>
                          <div className="col-md-12 showxs">&nbsp;</div>
                          <div className="col-md-6 my-auto">
                            <div className="row">
                              {/* Jaam code text changed */}
                              <div className="col-md-12 instructions  text-justify">
                                <span className="ml-3"><b>Update Payment Details –</b></span>
                                <ul className="text-justify">
                                  <li>Sign in to your jaam account.</li>
                                  <li>On the account page, click on the billing tab.</li>
                                  <li>If you want to change your payment details, click on the cancel subscription, its redirects to the payment page.</li>
                                  <li>In add payment method, enter the card details and select add.</li>
                                  <li>Then set your default payment method.</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="modal fade" id="paymentCardModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                          aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                              </div>
                              <div class="modal-body">
                                <div class="row">
                                  <div class="col-md-12">
                                    <img
                                      src={paymentCard}
                                      className="w-100"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Jaam code end */}
                      </div>
                    </div>
                    <div className="row">
                     
                      <div className="col-md-12 mb-4">
                        <div className="row">
                          <div className="col-md-6 mb-5 align-self-start">
                            {/* Jaam code start */}
                            <img src={paymentCancel}
                              height="100%"
                              width="100%"
                              alt=""
                              className="w-100"
                              data-toggle="modal"
                              data-target="#paymentCancelModal" />
                          </div>
                          <div className="col-md-12 showxs">&nbsp;</div>
                          <div className="col-md-6 my-auto">
                            <div className="row">
                              {/* Jaam code text changed */}
                              <div className="col-md-12 instructions  text-justify">
                                <span className="ml-3"><b>Cancel Payment –</b></span>
                                <ul className="text-justify">
                                  <li>Sign in to your jaam account.</li>
                                  <li>In the account page, click on the billing tab.</li>
                                  <li>In the billing page,click on the cancel subscription.</li>
                                  <li>It will redirect to the payment page.</li>
                                  <li>Under payment click on the cancel plan to stop your subscription to auto renewal.</li>
                                  <li>If you still like to continue to have the same plan, you can reactivate the plan by selecting reactive option on billing page.</li>
                                  <li>After canceling your subscription, your plan remains active until the end of your billing period.</li>
                                  <li>To avoid getting charged for another billing cycle, you need to cancel your plan at least one day before your next scheduled billing date.</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">&nbsp;</div>
                    </div>
                  </div>
                </div>
                <div class="modal fade" id="paymentCancelModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                  aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="row">
                          <div class="col-md-12">
                            <img
                              src={paymentCancel}
                              className="w-100" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Jaam code end */}
              </div>

              <div className="tab-pane fade" id="exit">
                <div className="row">
                  <div className="col-md-12 col-12 text-left text-black">
                    <div className="row">
                      <div className="col-md-12 mb-2" id="giveleft">
                     {/* Jaam code text changed */}
                        <span className="joinameet">How to leave the meeting?</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <video width="100%" height="80%" controls >
                          <source src="https://jaampromo.s3.us-east-2.amazonaws.com/leavemeeting.mp4" type="video/mp4" />
                        </video>
                      </div>
                      <div className="col-md-12">&nbsp;</div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">&nbsp;</div>
                      <div className="col-md-12 horizlinee"></div>
                      <div className="col-md-12">&nbsp;</div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">&nbsp;</div>
                      <div className="col-md-12 mb-4">
                        <div className="row">
                          <div className="col-md-6 align-self-start">
                            {/* Jaam code start*/}
                            <img src={leaveMeeting}
                              height="100%"
                              width="100%"
                              alt=""
                              className="w-100"
                              data-toggle="modal"
                              data-target="#leaveMeetingModal" />
                          </div>
                          <div className="col-md-12 showxs">&nbsp;</div>
                          <div className="col-md-6 my-auto">
                            <div className="row">
                              <div className="col-md-12 instructions  text-justify">
                                <span className="ml-3"><b>How to leave the meeting?</b></span>
                                <ul>
                                  <li>Sign in to jaam.</li>
                                  <li>Start a meeting as the host.</li>
                                  <li>Click on the leave icon on the menu.</li>
                                  <li>A popup will be shown with end meeting for all and leave meeting.</li>
                                  <li>Click leave meeting.</li>
                                  <li>Assign someone to be the new host, then click "Assign and Leave"to leave the meeting successfully without ending it.</li>
                                  <li>If you want to end the meeting click "End Meeting For All".</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="modal fade" id="leaveMeetingModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                          aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                              </div>
                              <div class="modal-body">
                                <div class="row">
                                  <div class="col-md-12">
                                    <img
                                      src={leaveMeeting}
                                      className="w-100" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Jaam code end */}
                      </div>
                      <div className="col-md-12">&nbsp;</div>
                    </div>
                    <div className="row">
                    <div className="col-md-12 showxs">&nbsp;</div>
                    <div className="col-md-12 mb-4">
                        <div className="row">
                          <div className="col-md-6 mb-5">
                            {/* Jaam code start*/}
                            <img src={leaveMeeting1}
                              height="100%"
                              width="100%"
                              alt=""
                              className="w-100"
                              data-toggle="modal"
                              data-target="#leaveMeeting1Modal" />
                          </div>
                          <div className="col-md-12 showxs">&nbsp;</div>
                          </div>
                          </div>
                    </div>
                    <div class="modal fade" id="leaveMeeting1Modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                          aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                              </div>
                              <div class="modal-body">
                                <div class="row">
                                  <div class="col-md-12">
                                    <img
                                      src={leaveMeeting1}
                                      className="w-100" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>
                </div>
              </div>
              
          </div>
          </div>
        </div>
        <div className="col-md-12">&nbsp;</div>
        <div className="col-md-12">&nbsp;</div>
        <div className="container-fluid bg-white">
          <div className="row bg-white">&nbsp;</div>
          <div className="container bg-white">
            <div className="row">&nbsp;</div>
            <div className="row">
              <div className="col-lg-12">
                <div className="row no-gutters">
                  <div className="col-sm-12 text-left">
                    <span className="common">Frequently Asked <span id="content2">Questions (FAQ)</span></span>
                  </div></div>

                <div className="row">&nbsp;</div>

                <div className="row no-gutters mt-1">
                  <div className="col-lg-12">
                    <div className="accordion" id="accordionExample">
                      <div className="fullaccordion">
                        <div
                          role="button"
                          className="collapse-header mb-n2"
                          data-toggle="collapse"
                          data-target="#collapse1"
                          aria-expanded="true"
                          aria-controls="collapse1"
                        >
                          <a> What does the Jaam meet <span id="new-line-mobile"></span> account include?</a>
                        </div>
                        <div
                          id="collapse1"
                          className="collapse show accordionanswer"
                          aria-labelledby="heading1"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 col-lg-10 col-md-10 pt-3 mt-0" id="opaci">
                            <div>
                              <ul>
                                <li>FREE unlimited high-definition video and audio conferencing for up to 50 participants for 30 days.</li>
                                <li>Online meetings with screen sharing.</li>
                                <li>Top-notch security, and more…</li>
                              </ul>
                            </div>
                            <br/>
                          </div>
                        </div>
                      </div>

                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse2"
                          aria-expanded="true"
                          aria-controls="collapse2"
                        >
                          <a>How do I sign up for Jaam?</a>
                        </div>
                        <div
                          id="collapse2"
                          className="collapse accordionanswer pb-3"
                          aria-labelledby="heading2"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 col-md-10 pt-3 pb-1 mt-0" id="opaci">
                            <div>
                              To sign up for your free JAAM account, visit
                              <Link
                                to="/signup"
                                id="on"
                                className="text-purple fontbold"
                              >
                                &nbsp;{`${api.WEB_URL}/signup`}
                              </Link> and enter your
                              valid Google/Facebook email address and password. You will receive an email notification.
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse3"
                          aria-expanded="true"
                          aria-controls="collapse3"
                        >
                          <a>Why can't I sign up for a Jaam account?</a>
                        </div>
                        <div
                          id="collapse3"
                          className="collapse accordionanswer"
                          aria-labelledby="heading3"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 col-md-10 pt-3 mt-0" id="opaci">
                            <div>
                              <ul>
                                <li>If you already have a Gmail/Facebook account with the email address, then you can’t sign up with the same Email. Try with an alternative email id.</li>
                                <li>Even If your alternative Email ID doesn’t work try incognito mode (or) another browser (or) contact our Jaam support team at <a href="mailto: support@jaam.co">support@jaam.co</a></li>
                              </ul>
                            </div>
                            <div>&nbsp;</div>
                          </div>
                        </div>
                      </div>

                      <div className="fullaccordion">
                        {/* Jaam code text changed */}
                        <div className="row horizontal-accordion mt-2 col-lg-12 text-justify" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse4"
                          aria-expanded="true"
                          aria-controls="collapse4"
                        >
                          <a> How do I access my account information?</a>
                        </div>
                        <div
                          id="collapse4"
                          className="collapse accordionanswer"
                          aria-labelledby="heading4"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 col-md-10 pt-3 mt-0" id="opaci">
                            <div>
                              To access your account information, click on ‘My Account’ on the Jaam home page.
                            </div>
                            <div>&nbsp;</div>
                          </div>
                        </div>
                      </div>

                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse5"
                          aria-expanded="true"
                          aria-controls="collapse5"
                        >
                          <a>How do I join a Jaam meeting?</a>
                        </div>
                        <div
                          id="collapse5"
                          className="collapse accordionanswer"
                          aria-labelledby="heading5"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 col-md-10 pt-3 mt-0" id="opaci">
                            <div>
                              You can join a meeting by clicking the meeting URL  or go to
                              <Link
                                to="/join"
                                id="on"
                                className="text-purple fontbold"
                              >
                                &nbsp;{`${api.WEB_URL}/join`}
                              </Link> and enter the meeting ID and your
                              name and click on ‘Join’, you may be asked to enter a
                              passcode only if required.
                            </div>
                            <div>&nbsp;</div>
                          </div>
                        </div>
                      </div>

                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse6"
                          aria-expanded="true"
                          aria-controls="collapse6"
                        >
                          <a> Is the Jaam account needed to <span id="new-line-mobile"></span>Join a meeting?</a>
                        </div>
                        <div
                          id="collapse6"
                          className="collapse accordionanswer"
                          aria-labelledby="heading6"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 col-md-10 pt-3" id="opaci">
                            <div>
                              <ul>
                                <li>No, just to join a meeting as a participant you don’t need to create an account.</li>
                                <li>A Jaam account is only required if you host your own meeting and send invitations to participants.
                                  Having a Jaam account allows you to start your own meetings.</li>
                              </ul>
                            </div>
                            <div>&nbsp;</div>
                          </div>
                        </div>
                      </div>

                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse7"
                          aria-expanded="true"
                          aria-controls="collapse7"
                        >
                          <a> Do I have to download the Jaam <span id="new-line-mobile"></span>App to join the meeting?</a>
                        </div>
                        <div
                          id="collapse7"
                          className="collapse accordionanswer"
                          aria-labelledby="heading7"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 pt-3 col-md-10" id="opaci">
                            <div>
                              No downloads are required, Jaam allows hosts or participants to join online meetings
                              with their web browsers instead of downloading a meeting app.
                            </div>
                            <div>&nbsp;</div>
                          </div>
                        </div>
                      </div>

                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse8"
                          aria-expanded="true"
                          aria-controls="collapse8"
                        >
                          <a>How many participants can join <span id="new-line-mobile"></span>the online meeting?</a>
                        </div>
                        <div
                          id="collapse8"
                          className="collapse accordionanswer"
                          aria-labelledby="heading8"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 pt-3 col-md-10" id="opaci">
                            <div>
                              A maximum of 50 participants can join a meeting at a time.
                            </div>
                            <div>&nbsp;</div>
                          </div>
                        </div>
                      </div>

                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse9"
                          aria-expanded="true"
                          aria-controls="collapse9"
                        >
                          <a>How do I invite others to join my meeting?</a>
                        </div>
                        <div
                          id="collapse9"
                          className="collapse accordionanswer"
                          aria-labelledby="heading9"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 pt-3 col-md-10" id="opaci">
                            <div>
                              There are several ways to invite participants to the meeting.
                              <br />
                              <br />
                              1. Landing Page -
                              <ul>
                                <li>Click on ‘Copy Invite’ Link or Copy the Invitation if you want to send your instant meeting information to the participants.</li>
                                <li>Copy Invite link: Copy the meeting join link.</li>
                                <li>Copy Invitation link: Copy the invitation info along with a join link.</li>
                                <li>Paste the URL or invitation and send it to the participants via email or chat.</li>
                              </ul>
                              2. Email -
                              <ul>
                                <li>Sign in to the JAAM Account.</li>
                                <li>Start or join a meeting.</li>
                              </ul>
                              Find the invite icon on the top left corner to copy (or) open a participants panel from the menu and find the invite icon at the bottom of the panel.
                            </div>
                            <div>&nbsp;</div>
                          </div>
                        </div>
                      </div>

                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse10"
                          aria-expanded="true"
                          aria-controls="collapse10"
                        >
                          <a>Is there a limit to using the same account?</a>
                        </div>
                        <div
                          id="collapse10"
                          className="collapse accordionanswer"
                          aria-labelledby="heading10"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 col-md-10 pt-3" id="opaci">
                            <div>
                              You can use the same account as many times as you like to Join the Jaam meeting. You have only 30 days of free trial to host a meeting using your trial account.
                            </div>
                            <div>&nbsp;</div>
                          </div>
                        </div>
                      </div>

                      <div className="fullaccordion">
                        <div className="row horizontal-accordion col-lg-12 mt-2" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse11"
                          aria-expanded="true"
                          aria-controls="collapse11"
                        >
                          <a>How do I eliminate static or echo <span id="new-line-mobile"></span>at a meeting?</a>
                        </div>
                        <div
                          id="collapse11"
                          className="collapse accordionanswer"
                          aria-labelledby="heading11"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 pt-3 col-md-10" id="opaci">
                            <ul>
                              <li>Echo can be caused by many things, such as a participant
                                connected to the meeting audio on multiple devices or two
                                participants joined in from the same local</li>
                              <li>A participant has both the computer and telephone audio
                                active, Participants with computer or telephones speakers that
                                are too close to each other,</li>
                              <li>Echo cancellation has failed (device or performance issue)</li>
                              <li>A bad microphone.</li>
                            </ul>
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>

                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse12"
                          aria-expanded="true"
                          aria-controls="collapse12"
                        >
                          <a>Why my audio isn’t working?</a>
                        </div>
                        <div
                          id="collapse12"
                          className="collapse accordionanswer"
                          aria-labelledby="heading12"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 pt-3 col-md-10" id="opaci">
                            <div>
                              <ul>
                                <li>Ensure your audio is turned on,</li>
                                <li>Check the speaker icon from your computer is turned off, tap it to turn on.</li>
                                <li>Try using headphones or speaker,</li>
                                <li>Visit your browser settings and check the audio permission is enableb,</li>
                                <li>Visit your device's Manager and update/enable the audio driver.</li>
                              </ul>
                            </div>
                            <div>&nbsp;</div>
                          </div>
                        </div>
                      </div>

                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse13"
                          aria-expanded="true"
                          aria-controls="collapse13"
                        >
                          <a>Why my camera isn't working?</a>
                        </div>
                        <div
                          id="collapse13"
                          className="collapse accordionanswer"
                          aria-labelledby="heading13"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 col-md-10 pt-3" id="opaci">
                            <div>
                              <ul>
                                <li>Ensure your camera is turned on</li>
                                <li>Visit your device's manager and update/enable the camera driver</li>
                                <li> If your camera is not showing up within the Jaam application, make sure that any other application that utilizes your camera or not.</li>
                                <li>Visit your browser settings and check for camera permission.</li>
                              </ul>
                            </div>
                            <div>&nbsp;</div>
                          </div>
                        </div>
                      </div>

                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse14"
                          aria-expanded="true"
                          aria-controls="collapse14"
                        >
                          <a>How can I tell who is at the <span id="new-line-mobile"></span>meeting / How can I view the Participant list in the meeting?</a>
                        </div>
                        <div
                          id="collapse14"
                          className="collapse accordionanswer"
                          aria-labelledby="heading14"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 pt-3 col-md-10" id="opaci">
                            <div>
                              Gallery View will list all the participants, else click on the participant’s icon
                              on the menu to know the list of participants in the meeting.
                            </div>
                            <div>&nbsp;</div>
                          </div>
                        </div>
                      </div>

                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse15"
                          aria-expanded="true"
                          aria-controls="collapse15"
                        >
                          <a>How can I change my passcode?</a>
                        </div>
                        <div
                          id="collapse15"
                          className="collapse accordionanswer"
                          aria-labelledby="heading15"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 col-md-10 pt-3" id="opaci">
                            <div>
                              You can change the passcode by clicking on edit in ‘My Account’. You can have numeric, alpha or any combination of alpha-numeric.
                            </div>
                            <div>&nbsp;</div>
                          </div>
                        </div>
                      </div>

                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse16"
                          aria-expanded="true"
                          aria-controls="collapse16"
                        >
                          <a> What are the roles in the JAAM account?</a>
                        </div>
                        <div
                          id="collapse16"
                          className="collapse accordionanswer"
                          aria-labelledby="heading16"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 pt-3 col-md-10" id="opaci">
                            <div>
                              There are 3 roles in the Jaam accounts:
                              <ul>
                                <li>Host: Has all the rights to start a meeting, add/remove participants, including role management.</li>
                                <li>Co-Host: -Can add, remove, or edit users in the meetings.</li>
                                <li>Participants: Have no administrative rights.</li>
                              </ul>
                            </div>
                            <div>&nbsp;</div>
                          </div>
                        </div>
                      </div>

                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse17"
                          aria-expanded="true"
                          aria-controls="collapse17"
                        >
                          <a>How can the host assign a <span id="new-line-mobile"></span>co-host?</a>
                        </div>
                        <div
                          id="collapse17"
                          className="collapse accordionanswer"
                          aria-labelledby="heading17"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 col-md-10 pt-3" id="opaci">
                            To make a participant as a host, click on the “participants’” icon on the
                            conference page. In the participants’ window, hover over the name of the
                            participant you want to make co-host and click ‘More’. Click on the ‘Assign
                            Co-host’ option, Click ‘Yes’ to confirm, and make the participant co-host.
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>

                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse18"
                          aria-expanded="true"
                          aria-controls="collapse18"
                        >
                          <a>Can someone else start my <span id="new-line-mobile"></span>meeting?</a>
                        </div>
                        <div
                          id="collapse18"
                          className="collapse accordionanswer"
                          aria-labelledby="heading18"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 pt-3 col-md-10" id="opaci">
                            <div>
                              On the landing page, if you enable the “Allow Participants to join
                              the meeting before the Host” option then the participants can join
                              the meeting before the host start the meeting or without the host.
                              <br />
                              Note: When the user disables the “Allow Participants to join the
                              meeting before the Host”  in the landing page, the participants
                              have to wait until the host starts the meeting.
                            </div>
                            <div>&nbsp;</div>
                          </div>
                        </div>
                      </div>

                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse19"
                          aria-expanded="true"
                          aria-controls="collapse19"
                        >
                          <a> How do I share my screen? </a>
                        </div>
                        <div
                          id="collapse19"
                          className="collapse accordionanswer"
                          aria-labelledby="heading19"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 pt-3 col-md-10" id="opaci">
                            <div>
                              To share your screen with the participants:
                              Click the ‘Share Screen’ icon on the menu. A popup window
                              will be shown. Select any one of these screen share options:
                              Share your entire desktop, specific application windows, or
                              share your browser tab and then click on the  ‘Share audio’
                              to screen your screen along with audio and press the “Share”
                              button to start the screen share.
                            </div>
                            <div>&nbsp;</div>
                          </div>
                        </div>
                      </div>

                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse20"
                          aria-expanded="true"
                          aria-controls="collapse20"
                        >
                          <a>How do I change my theme?</a>
                        </div>
                        <div
                          id="collapse20"
                          className="collapse accordionanswer"
                          aria-labelledby="heading20"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 col-md-10 pt-3" id="opaci">
                            <div>
                              You can change the theme by clicking on ‘My Account’. You can select the themes from the new popup window.
                            </div>
                            <div>&nbsp;</div>
                          </div>
                        </div>
                      </div>

                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse21"
                          aria-expanded="true"
                          aria-controls="collapse21"
                        >
                          <a> How to enable a Lobby room?</a>
                        </div>
                        <div
                          id="collapse21"
                          className="collapse accordionanswer"
                          aria-labelledby="heading21"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 col-lg-10 col-md-10 pt-3" id="opaci">
                            On the conference page, click on ‘more’ options, from the list,
                            select the ‘security option’, a popup will be displayed. After
                            you enable a meeting lobby, participants who have not yet joined
                            the meeting must ask to wait until the host accepts the request.
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse22"
                          aria-expanded="true"
                          aria-controls="collapse22"
                        >
                          <a>Is there a limit on the length of a Meeting?</a>
                        </div>
                        <div
                          id="collapse22"
                          className="collapse accordionanswer"
                          aria-labelledby="heading22"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 col-md-10 pt-3" id="opaci">
                            <ul>
                              <li>There is no time limit in the length of the meeting for the paid Jaam users.</li>
                              <li>In the free trial, the user has a time limit of 40-mins.</li>
                            </ul>
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse23"
                          aria-expanded="true"
                          aria-controls="collapse23"
                        >
                          <a>Are my meetings kept private?</a>
                        </div>
                        <div
                          id="collapse23"
                          className="collapse accordionanswer"
                          aria-labelledby="heading23"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 pt-3" id="opaci">
                            Yes. We don’t listen or record any meetings.
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse24"
                          aria-expanded="true"
                          aria-controls="collapse24"
                        >
                          <a>How to secure your meetings with <span id="new-line-mobile"></span>the lobby room?</a>
                        </div>
                        <div
                          id="collapse24"
                          className="collapse accordionanswer"
                          aria-labelledby="heading24"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 col-md-10 pt-3" id="opaci">
                            When someone enters the meeting, they will see a screen
                            indicating they are in the waiting room and the host
                            will see a message indicating someone is in the waiting
                            room. Click ‘Admit’ to allow the participant to join
                            immediately or click on ‘Remove’.
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse25"
                          aria-expanded="true"
                          aria-controls="collapse25"
                        >
                          <a>Can the co-host admit from the waiting room?</a>
                        </div>
                        <div
                          id="collapse25"
                          className="collapse accordionanswer"
                          aria-labelledby="heading25"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 col-md-10 pt-3" id="opaci">
                            When participants are in the Waiting Room, hosts and co-hosts <b>can admit</b> or <b>remove</b> them on an individual basis.
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse26"
                          aria-expanded="true"
                          aria-controls="collapse26"
                        >
                          <a>Can a host leave a meeting <span id="new-line-mobile"></span>without ending it?</a>
                        </div>
                        <div
                          id="collapse26"
                          className="collapse accordionanswer"
                          aria-labelledby="heading26"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 col-md-10 pt-3" id="opaci">
                            Yes! Before leaving the meeting, if you want you can assign
                            another host to take over the meeting. When you click on the
                            ‘leave meeting’, another pop-up window will appear to assign
                            a host to continue the meeting. You can assign another host
                            from the list of participants and then click on the ‘Assign
                            and Leave’ button to leave the meeting successfully without
                            ending it.
                            <br />
                            Note: If you want to end the meeting for all click on the
                            ‘End Meeting’ button
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse27"
                          aria-expanded="true"
                          aria-controls="collapse27"
                        >
                          <a> What devices are compatible <span id="new-line-mobile"></span>with online meetings?</a>
                        </div>
                        <div
                          id="collapse27"
                          className="collapse accordionanswer"
                          aria-labelledby="heading27"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 col-md-10 pt-3" id="opaci">
                            Online meetings work on PCs, Macs®, Chromebooks™, iOS, and Android™ phones and tablets with Chrome and Safari browsers. No downloads are required.
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse28"
                          aria-expanded="true"
                          aria-controls="collapse28"
                        >
                          <a>Which operating systems are supported with Jaam?</a>
                        </div>
                        <div
                          id="collapse28"
                          className="collapse accordionanswer"
                          aria-labelledby="heading28"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 col-md-10 pt-3" id="opaci">
                            <ul>
                              <li>Windows® 7</li>
                              <li>Mac® OS X 10.9</li>
                              <li>iOS 9.0 (screen sharing requires v11.0)</li>
                              <li>Android 4.0.1 (screen sharing requires v5.0)</li>
                              <li>Ubuntu® 14.04 64-bit, other distributives may be also supported but the result is not guaranteed
                                <ul>
                                  <li>Preferred display server: X.Org</li>
                                  <li>Desktop environment: Unity, Gnome</li>
                                </ul>
                              </li>
                              <li>
                                Bandwidth: 100Kb/s (HD Audio), 400Kb/s (screen
                                sharing), 1.1Mb/s (video)
                              </li>
                              <li>
                                Desktop: Chrome™ 57, Firefox® 60, Safari® 10.1 (Mac only), Internet Explorer® 11(Windows only), Edge 15 (Windows only)
                              </li>
                              <li>
                                Mobile: Chrome™ 57, Firefox® Android 60 and iOS 20, Safari® iOS 10
                              </li>
                            </ul>
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse29"
                          aria-expanded="true"
                          aria-controls="collapse29"
                        >
                          <a>How much does JAAM cost?</a>
                        </div>
                        <div
                          id="collapse29"
                          className="collapse accordionanswer"
                          aria-labelledby="heading29"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 col-md-10 pt-3" id="opaci">
                            A basic trial Jaam license is free. Please check out the
                            available Jaam plans on the pricing page
                            (<Link
                              to="/pricing"
                              id="on"
                              className="text-purple fontbold"
                            >
                              &nbsp;{`${api.WEB_URL}/pricing`}
                            </Link>).
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse30"
                          aria-expanded="true"
                          aria-controls="collapse30"
                        >
                          <a>How do I make a payment?</a>
                        </div>
                        <div
                          id="collapse30"
                          className="collapse accordionanswer"
                          aria-labelledby="heading30"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 col-md-10 pt-3" id="opaci">
                            <ol>
                              <li>
                                On the landing page, click on Upgrade Now or on the Pricing page, choose the currency and plan and click ‘Buy Now’.
                                <br/>
                                Note: When you select currency (INR/Dollar) in your first payment, you cannot change the currency for future payment.
                              </li>
                              <li>
                                On the Billing page, Select your currency and plan and then click ‘Pay Now ‘.
                              </li>
                              <li>
                                On the payment page, Enter your Account information, Billing address.
                              </li>
                              <li>
                                Click on the ‘Subscribe’ button. Your payment is successful.
                              </li>
                              <li>
                                Enjoy the Unlimited fun-filled Jaam experience!.
                              </li>
                            </ol>
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse31"
                          aria-expanded="true"
                          aria-controls="collapse31"
                        >
                          <a>Where do I find my payment<span id="new-line-mobile"></span>/invoice history?</a>
                        </div>
                        <div
                          id="collapse31"
                          className="collapse accordionanswer"
                          aria-labelledby="heading31"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 col-md-10 pt-3" id="opaci">
                            You can find the invoice history for your JAAM account on
                            the Billing page. On the billing page Click on the ‘Invoice
                            history’ tab, you can view your paid invoices. You can also
                            download the receipt/invoice for your future reference.
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse32"
                          aria-expanded="true"
                          aria-controls="collapse32"
                        >
                          <a>How do I change from ‘Monthly to Annual’ or ‘Annual to Monthly’ Payments?</a>
                        </div>
                        <div
                          id="collapse32"
                          className="collapse accordionanswer"
                          aria-labelledby="heading32"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 col-md-10 pt-3" id="opaci">
                            If the user wants to change the plan, he/she needs to cancel the plan and wait until the end of the next billing cycle to update the new plan.
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse33"
                          aria-expanded="true"
                          aria-controls="collapse33"
                        >
                          <a>How do I cancel my Jaam account?</a>
                        </div>
                        <div
                          id="collapse33"
                          className="collapse accordionanswer"
                          aria-labelledby="heading33"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 col-md-10 pt-3" id="opaci">
                            You can cancel your subscription through the Billing Page at any time before the next billing cycle. If you do not cancel your subscription, it will automatically be renewed. You can make other changes to your subscriptions, including changing your billing period and adjusting the number of licenses.
                            <br />
                            Note: After canceling your subscription, your plan remains active until the end of your billing period.
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse34"
                          aria-expanded="true"
                          aria-controls="collapse34"
                        >
                          <a>What is the Refund process for <span id="new-line-mobile"></span>a paid plan?</a>
                        </div>
                        <div
                          id="collapse34"
                          className="collapse accordionanswer"
                          aria-labelledby="heading34"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 col-md-10 pt-3" id="opaci">
                            No refund for the monthly plans. Annual plan subscribers are allowed to cancel the plan and can request a refund via our support team at <a href="mailto: support@jaam.co">support@jaam.co</a> .
                            <br />
                            Note: To be eligible for a refund, an account must not have any usage since the last time the account was billed.
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse35"
                          aria-expanded="true"
                          aria-controls="collapse35"
                        >
                          <a>What payment methods can I use?</a>
                        </div>
                        <div
                          id="collapse35"
                          className="collapse accordionanswer"
                          aria-labelledby="heading35"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 col-md-10 pt-3" id="opaci">
                            Jaam accepts all credit cards, debit card payments. Jaam saves the default payment for your upcoming payments. We make sure that your payment information will be kept safe and secure.
                            <br />
                            Note: If you are a user from India, Jaam will not accept the international card (AMEX) payment.
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse36"
                          aria-expanded="true"
                          aria-controls="collapse36"
                        >
                          <a>How can I change the payment <span id="new-line-mobile"></span>details in Jaam?</a>
                        </div>
                        <div
                          id="collapse36"
                          className="collapse accordionanswer"
                          aria-labelledby="heading36"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 col-md-10 pt-3" id="opaci">
                            On the payment page, you can add or change your default payment.
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse37"
                          aria-expanded="true"
                          aria-controls="collapse37"
                        >
                          <a>What happens when a payment is declined?</a>
                        </div>
                        <div
                          id="collapse37"
                          className="collapse accordionanswer"
                          aria-labelledby="heading37"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 col-md-10 pt-3" id="opaci">
                            When the payment fails but the amount gets deducted from the
                            bank account, it usually takes up to 5 - 7  business days to
                            add money back to your account. Please wait for your bank to
                            complete the reversal. Refer to the bank account statement to
                            verify if your transaction has been reversed.
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                      <div className="fullaccordion">
                        <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                        <div
                          role="button"
                          className="collapse-header mb-n2 collapsed"
                          data-toggle="collapse"
                          data-target="#collapse38"
                          aria-expanded="true"
                          aria-controls="collapse38"
                        >
                          <a>Why did my payment fail?</a>
                        </div>
                        <div
                          id="collapse38"
                          className="collapse accordionanswer"
                          aria-labelledby="heading38"
                          data-parent="#accordionExample"
                        >
                          <div className="text-525260 col-md-10 pt-3" id="opaci">
                            There are several reasons why a payment can be denied.
                            <ul>
                              <li>The debit card has Insufficient funds or has reached its limit.</li>
                              <li>The credit or debit card information is incorrect.</li>
                              <li>CVV mismatch.</li>
                              <li>The credit or debit card  is expired.</li>
                              <li>Your card has been previously reported as stolen.</li>
                              <li>Your purchase triggered fraud protection.</li>
                              <li>Your country is not supported by Rebtel payment providers.</li>
                              <li>Your payment attempts have been refused by our payment provider.</li>
                            </ul>
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                      <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                      <div className="row">&nbsp;</div>
                      <div className="row">&nbsp;</div>
                      <div className="row">&nbsp;</div>
                      <div className="row">&nbsp;</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Supportpage;