import React, { Component } from "react";
import { api } from "../common/api";
import _ from "lodash";
import modalwrap from "../assets/modalwrap.svg";

class ExistingEmailModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
          modalState: true,
        };
      }

      handleShow() {
    //     const { history } = this.props;
        this.setState({ modalState: !this.state.modalState,
         });
    //     history.push('./signin');
      }
    
      render() {
        return (
            <div className="container">
              <div className="row content-center">
                  <div className={"modal fade" + (this.state.modalState ? " show d-block" : " d-none")} tabIndex="-1" role="dialog">
                      <div className="modal-dialog modal-dialog-centered" role="document">
                          <div className="modal-content">
                            <div className="modal-body">
                            <div className="text-center">
                            <img src={modalwrap} alt="modal image" />
                            </div>  
                            <div className="text-center mt-n3 modal-welcome-text">Welcome Back</div>
                              <div className="text-center mt-3">You already have an account with us.<br/> Do you want to sign in?</div>
                                  <div className ="text-center mt-3 mt-md-4">
                                    <button type="button" className="mb-4 btn btn-cancel text-center existing-email-btn">
                                    <a href={`${api.WEB_URL}/signin`} className="text-decoration-none">
                                    <span className="text-white text-decoration-none">Sign in</span>
                                    </a>  
                                    </button>
                                    <br/>
                                    <a href={`${api.WEB_URL}`} className="text-decoration-none">
                                    <span className="existing-email-home-btn text-purple text-center">Go to Home Page</span>
                                    </a>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>      
        );
      }
    }

export default ExistingEmailModal;