import { push } from 'react-router-redux';
import qs from 'qs';
import axios, { post } from 'axios';
import * as types from './sessionActionTypes'
import {  api } from '../api'
import { setStorage, clearStorage } from '../localStorage'
import { isEmpty } from 'lodash';

let API_URL = api.API_URL;
let APP_URL = api.APP_URL;

// export const getLinkedInData = data => dispatch => {
//     var encodedURI = window.encodeURI(`${API_URL}/getLinkedInData`);
//     dispatch({ type: types.LINKEDIN_DATA.REQUEST })
//     axios.post($encodedURI, qs.stringify(data)).then(res => {
//         dispatch({ type: types.LINKEDIN_DATA.SUCCESS, payload: res.data })
//     }).catch(err => {
//         dispatch({ type: types.LINKEDIN_DATA.FAILURE, payload: err })
//     })
// }

export const login = (formProps) => (dispatch) => {
  var encodedURI = window.encodeURI(`${API_URL}/login`);
  dispatch({ type: types.LOGIN.REQUEST });
  axios
    .post(encodedURI, formProps)
    .then((response) => {
      setStorage("token", response.data.data.users.token);
      setStorage("email", response.data.data.users.email);
      dispatch({ type: types.LOGIN.SUCCESS, payload: response.data });
      // dispatch(push('/home'));
    })
    .catch((error) => {
      dispatch({ type: types.LOGIN.FAILURE, payload: "" });
      // dispatch(push('/signin'))
    });
};

export const getUser = (formProps) => (dispatch) => {
  var encodedURI = window.encodeURI(`${API_URL}/getUser`);
  dispatch({ type: types.GETUSER.REQUEST });
  axios
    .post(encodedURI, formProps)
    .then((response) => {
      dispatch({ type: types.GETUSER.SUCCESS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: types.GETUSER.FAILURE, payload: "" });
    });
};

export const contactus = (formProps) => dispatch => {
    var encodedURI = window.encodeURI(`${API_URL}/contactus`);
    dispatch({ type: types.CONTACT.REQUEST })
    axios.post(encodedURI, formProps)
        .then(response => {
            dispatch({ type: types.CONTACT.SUCCESS, payload: response.data })
        })
        .catch(error => {
            dispatch({ type: types.CONTACT.FAILURE, payload: '' })
        })
}

export const feedbackus = (formProps) => dispatch => {
    var encodedURI = window.encodeURI(`${API_URL}/feedback`);
    dispatch({ type: types.FEEDBACK.REQUEST })
    axios.post(encodedURI, formProps)
        .then(response => {
            dispatch({ type: types.FEEDBACK.SUCCESS, payload: response.data })
        })
        .catch(error => {
            dispatch({ type: types.FEEDBACK.FAILURE, payload: '' })
        })
}

export const partnership = (formProps) => (dispatch) => {
  var encodedURI = window.encodeURI(`${API_URL}/partnership`);
  dispatch({ type: types.PARTNERSHIP.REQUEST });
  axios
    .post(encodedURI, formProps)
    .then((response) => {
      dispatch({ type: types.PARTNERSHIP.SUCCESS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: types.PARTNERSHIP.FAILURE, payload: "" });
    });
};

export const signup = (formProps) => (dispatch) => {
  var encodedURI = window.encodeURI(`${API_URL}/signup`);
  dispatch({ type: types.SIGNUP.REQUEST });
  axios
    .post(encodedURI, formProps)
    .then((response) => {
      setStorage("token", response.data.data.users.token);
      setStorage("email", response.data.data.users.email);
      dispatch({ type: types.SIGNUP.SUCCESS, payload: response.data });
      // dispatch(push('/home'));
    })
    .catch((error) => {
      dispatch({ type: types.SIGNUP.FAILURE, payload: {errorMessage: 'Server error. Please try again later'} })
      // dispatch(push('/home'));
    });
};

export const join = (formProps) => (dispatch) => {
  var encodedURI = window.encodeURI(`${API_URL}/join`);
  dispatch({ type: types.JOIN.REQUEST });
  axios
    .post(encodedURI, formProps)
    .then((response) => {
      let PCenable = response.data.enablePC;
      let lockEnable = response.data.hostLock;
      let hostActive = response.data.hostActive;
      if(!PCenable) {
        if(lockEnable === true && hostActive === 1){
          let token = formProps.token ? formProps.token : "";
          window.location.href = `${APP_URL}/?q=` + formProps.displayName + "&m=" + formProps.roomName + "&t=" + token
          dispatch({ type: types.JOIN.SUCCESS, payload: response.data })   
          window.location.replace(`${APP_URL}/?q=` + formProps.displayName + "&m=" + formProps.roomName + "&t=" + token);
        } 
        else if(lockEnable === false){
          let token = formProps.token ? formProps.token : "";
          window.location.href = `${APP_URL}/?q=` + formProps.displayName + "&m=" + formProps.roomName + "&t=" + token
          dispatch({ type: types.JOIN.SUCCESS, payload: response.data })   
          window.location.replace(`${APP_URL}/?q=` + formProps.displayName + "&m=" + formProps.roomName + "&t=" + token);
        } 
        else{
          dispatch({ type: types.JOIN.SUCCESS, payload: response.data })   
        }      
      }
      else{
        dispatch({ type: types.JOIN.SUCCESS, payload: response.data })   
      }      
    })
    .catch((error) => {
	      if(error.response) {
	            dispatch({ type: types.JOIN.FAILURE, payload: error.response.data })
	            dispatch(push('/join'))
	        } else {
	            dispatch({ type: types.JOIN.FAILURE, payload: error})
	        }
    });
};

export const joinPasscode = (formProps) => (dispatch) => {
  var encodedURI = window.encodeURI(`${API_URL}/joinPasscode`);
  dispatch({ type: types.JOINPASSCODE.REQUEST });
  axios
    .post(encodedURI, formProps)
    .then((response) => {
      let token = formProps.token ? formProps.token : "";
      window.location.href = `${APP_URL}/?q=` + formProps.displayName + "&m=" + formProps.roomName + "&t=" + token
      dispatch({ type: types.JOINPASSCODE.SUCCESS, payload: response.data })      
      window.location.replace(`${APP_URL}/?q=` + formProps.displayName + "&m=" + formProps.roomName + "&t=" + token);
    })
    .catch((error) => {
	      if(error.response) {
	            dispatch({ type: types.JOINPASSCODE.FAILURE, payload: error.response.data })
	            // dispatch(push('/'))
	        } else {
	            dispatch({ type: types.JOINPASSCODE.FAILURE, payload: {errorMessage: 'Server error. Please try again later'} })
	        }
    });
};


export const updateMeeting = (formProps) => (dispatch) => {
  var encodedURI = window.encodeURI(`${API_URL}/updateMeet`);
  dispatch({ type: types.UPDATEMEET.REQUEST });
  axios
    .post(encodedURI, (formProps))
    .then((response) => {
      dispatch({ type: types.UPDATEMEET.SUCCESS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: types.UPDATEMEET.FAILURE, payload: "" });
      dispatch(push("/"));
    });
};

export const signOut = () => (dispatch) => {
  clearStorage("token");
  clearStorage("email");
  dispatch({ type: types.LOGOUT });
};

export const updateTheme = (theme) => (dispatch) => {
  dispatch({ type: "UPDATE_THEME", payload: theme });
};

// Stripe

// Create Checkout API
export const stripeCreateCheckout = (formProps) => (dispatch) => {
  var encodedURI = window.encodeURI(`${API_URL}/stripeCreateCheckout`);
  dispatch({ type: types.CREATECHECKOUT.REQUEST });
  axios
    .post(encodedURI, (formProps))
    .then((response) => {
      dispatch({ type: types.CREATECHECKOUT.SUCCESS, payload: response.data });
    })
    .catch((error) => {
      // dispatch({ type: types.CREATECHECKOUT.FAILURE, payload: error.response.data.error });
      // dispatch(push("/"));
      if(error.response) {
        dispatch({ type: types.CREATECHECKOUT.FAILURE, payload: error.response.data.error });
        dispatch(push("/"));
      } else {
        dispatch({ type: types.CREATECHECKOUT.FAILURE, payload: {errorMessage: 'Server error. Please try again later'} })
      }
    });
};

// Customer Portal
export const stripeCustomerPortal = (formProps) => (dispatch) => {
  var encodedURI = window.encodeURI(`${API_URL}/stripeCustomerPortal`);
  dispatch({ type: types.GETCUSTOMERPORTAL.REQUEST });
  axios
    .post(encodedURI, (formProps))
    .then((response) => {
      dispatch({ type: types.GETCUSTOMERPORTAL.SUCCESS, payload: response.data });
    })
    .catch((error) => {
      // dispatch({ type: types.GETCUSTOMERPORTAL.FAILURE, payload: error.response.data.error });
      // dispatch(push("/"));
      if(error.response) {
        dispatch({ type: types.GETCUSTOMERPORTAL.FAILURE, payload: error.response.data.error });
        dispatch(push("/"))
      } else {
        dispatch({ type: types.GETCUSTOMERPORTAL.FAILURE, payload: {errorMessage: 'Server error. Please try again later'} })
      }
    });
};

// Get Invoice
export const stripeGetInvoice = (formProps) => (dispatch) => {
  var encodedURI = window.encodeURI(`${API_URL}/stripeGetInvoice`);
  dispatch({ type: types.GETINVOICE.REQUEST });
  axios
    .post(encodedURI, (formProps))
    .then((response) => {
      dispatch({ type: types.GETINVOICE.SUCCESS, payload: response.data });
    })
    .catch((error) => {
      // dispatch({ type: types.GETINVOICE.FAILURE, payload: error.response.data.error });
      // dispatch(push("/"));
      if(error.response) {
        dispatch({ type: types.GETINVOICE.FAILURE, payload: error.response.data.error });
        dispatch(push("/"))
      } else {
        dispatch({ type: types.GETINVOICE.FAILURE, payload: {errorMessage: 'Server error. Please try again later'} })
      }
    });
};

//Get Subscriptions
export const stripeGetSubscriptions = (formProps) => (dispatch) => {
  var encodedURI = window.encodeURI(`${API_URL}/stripeGetSubscriptions`);
  axios
    .post(encodedURI, (formProps))
    .then((response) => { 
      dispatch({ type: types.GETSUBSCRIPTIONS.SUCCESS, payload: response.data });
    })
    .catch((error) => {          
      // dispatch({ type: types.GETSUBSCRIPTIONS.FAILURE, payload: error.response.data.error });
      // dispatch(push("/"));
      if(error.response) {
        dispatch({ type: types.GETSUBSCRIPTIONS.FAILURE, payload: error.response.data.error });
        dispatch(push("/"))
      } else {
        dispatch({ type: types.GETSUBSCRIPTIONS.FAILURE, payload: {errorMessage: 'Server error. Please try again later'} })
      }
    });
};


// Get All Prices from DB
export const stripeGetallPrices = (formProps) => (dispatch) => {  
  var encodedURI = window.encodeURI(`${API_URL}/stripeGetallPrices`);
  dispatch({ type: types.GETALLPRICES.REQUEST });
  axios
    .post(encodedURI, (formProps))
    .then((response) => {
      dispatch({ type: types.GETALLPRICES.SUCCESS, payload: response.data });
    })
    .catch((error) => {
      // dispatch({ type: types.GETALLPRICES.FAILURE, payload: error.response.data.error });
      // dispatch(push("/"));
      if(error.response) {
        dispatch({ type: types.GETALLPRICES.FAILURE, payload: error.response.data.error });
        dispatch(push("/"))
      } else {
        dispatch({ type: types.GETALLPRICES.FAILURE, payload: {errorMessage: 'Server error. Please try again later'} })
      }
    });
};

export const payErrorClear = () => (dispatch) => {
  dispatch({ type: types.PAYMENTERROR });
};

export const setShowProfile = (showprofile) => (dispatch) => {
  dispatch({ type: "SHOWPROFILE", payload: showprofile});
};