import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import ReCaptchaV2 from 'react-google-recaptcha';
import StatusHelper from "../common/helpers/statusHelper";
import CheckBox from "../common/CheckBox.js";
import {savePartnership} from "../services/partnershipService";
class Partnership extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // isValid: false,
      data: {
        txtCompanyName: "",
        txtWebsite: "",
        txtAddress: "",
        txtCity: "",
        txtState: "",
        txtPostalCode: "",
        txtCountry: "",
        txtCompanyRevenue: "",
        txtNumberofEmployees: "",
        txtContactFirstName: "",
        txtContactLastName: "",
        txtContactRole: "",
        txtContactEmail:"",
        txtContactPhone: "",
        ddlGeography: "",
        vendorOptions: "",
        allowPrivacy: false,
      },
      recaptchaRef: React.createRef(),
      errors: {},
      statusData: {},
      vendors: [
        { id: 1, value: "Cisco", isChecked: false },
        { id: 2, value: "Microsoft", isChecked: false },
        { id: 3, value: "Zoom", isChecked: false },
        { id: 4, value: "GoTo Meeting", isChecked: false },
        { id: 5, value: "Other", isChecked: false },
        { id: 6, value: "Unknown", isChecked: false },
      ],
    };
    window.scrollTo(0, 0);
  }
  statusHelper = new StatusHelper(this);

  handleCheckPrivacy = (event) => {
    let chk = event.target.checked;
    this.setState((prevState) => {
      let data = Object.assign({}, prevState.data);
      data.allowPrivacy = chk;
      return { data };
    });
  };

  handleCheckChieldElement = (event) => {
    let vendors = this.state.vendors;
    let listVendors = "";
    vendors.forEach((vendor) => {
      if (vendor.value === event.target.value)
        vendor.isChecked = event.target.checked;

      if (vendor.isChecked) {
        if (listVendors == "") listVendors = vendor.value;
        else listVendors = listVendors + "," + vendor.value;
      }
    });
    this.setState({ vendors: vendors });

    this.setState((prevState) => {
      let data = Object.assign({}, prevState.data); // creating copy of state variable data
      data.vendorOptions = listVendors; // update the name property, assign a new value
      return { data }; // return new object data object
    });
  };

  doSubmit = async (e) => {
    e.preventDefault();
    this.setState({ statusData: {} });
    const valid = this.validateForm();
    if (!!valid) {
      try {
        const { data } = this.state;
        await savePartnership(data);
        const statusData = {
          type: "success",
          message:
            "Partnership form has successfully submitted. Our sales team will contact you soon.",
        };
        this.setState({ statusData });
        this.setState({
          data: {
            txtCompanyName: "",
            txtWebsite: "",
            txtAddress: "",
            txtCity: "",
            txtState: "",
            txtPostalCode: "",
            txtCountry: "",
            txtCompanyRevenue: "",
            txtNumberofEmployees: "",
            txtContactFirstName: "",
            txtContactLastName: "",
            txtContactRole: "",
            txtContactEmail:"",
            txtContactPhone: "",
            ddlGeography: "",
            vendorOptions: "",
            allowPrivacy: false,
          },
        });
        document.getElementById("partnershipform").reset();
        this.state.recaptchaRef.current.reset();
      } catch (ex) {
        const statusData = {
          type: "error",
          message: "Something went wrong. Please try after some time.",
        };
        this.setState({ statusData });
        this.state.recaptchaRef.current.reset();
      }
    }
  };

  validateForm = () => {
    
    let valid = true;
    // let data = this.state.data;
    const errors = {};

    if(this.state.data["allowPrivacy"]===false){
      valid = false;
      errors["allowPrivacy"] = "*Allow Privacy must be selected.";
    }

    if (!this.state.recaptchaRef.current.getValue()) {
      valid = false;
      errors["txtCaptcha"] = "*Captcha selection is required.";
    }
    
    if (this.state.data["txtContactEmail"].length === 0) {
      valid = false;
      errors["txtContactEmail"] = "*Please enter your email.";
    }

    if (typeof this.state.data["txtContactEmail"] !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(this.state.data["txtContactEmail"])) {
        valid = false;
        errors["txtContactEmail"] = "*Valid Email-ID is required!";      }
    }

    if (this.state.data["txtCompanyName"].length === 0) {
      valid = false;
      errors["txtCompanyName"] = "*Company Name is required!.";
     }

    if (typeof this.state.data["txtCompanyName"] !== "undefined") {
      if (!this.state.data["txtCompanyName"].match(/^[a-zA-Z ]*$/)) {
        valid = false;
        errors["txtCompanyName"] = "*Please enter alphabet characters only.";
      }
    }
    if (this.state.data["txtCity"].length === 0) {
      valid = false;
      errors["txtCity"] = "*City is required!.";
     }

    if (typeof this.state.data["txtCity"] !== "undefined") {
      if (!this.state.data["txtCity"].match(/^[a-zA-Z ]*$/)) {
        valid = false;
        errors["txtCity"] = "*Please enter alphabet characters only.";
      }
    }
    if (this.state.data["txtState"].length === 0) {
      valid = false;
      errors["txtState"] = "*State is required!.";
     }

    if (typeof this.state.data["txtState"] !== "undefined") {
      if (!this.state.data["txtState"].match(/^[a-zA-Z ]*$/)) {
        valid = false;
        errors["txtState"] = "*Please enter alphabet characters only.";
      }
    }

    if (this.state.data["txtCountry"].length === 0) {
      valid = false;
      errors["txtCountry"] = "*Country is required!.";
     }

    if (typeof this.state.data["txtCountry"] !== "undefined") {
      if (!this.state.data["txtCountry"].match(/^[a-zA-Z ]*$/)) {
        valid = false;
        errors["txtCountry"] = "*Please enter alphabet characters only.";
      }
    }
    
    if (this.state.data["txtContactFirstName"].length === 0) {
      valid = false;
      errors["txtContactFirstName"] = "*First Name is required!.";
     }

    if (typeof this.state.data["txtContactFirstName"] !== "undefined") {
      if (!this.state.data["txtContactFirstName"].match(/^[a-zA-Z ]*$/)) {
        valid = false;
        errors["txtContactFirstName"] = "*Please enter alphabet characters only.";
      }
    }

    if (this.state.data["txtContactLastName"].length === 0) {
      valid = false;
      errors["txtContactLastName"] = "*Last Name is required!.";
     }

    if (typeof this.state.data["txtContactLastName"] !== "undefined") {
      if (!this.state.data["txtContactLastName"].match(/^[a-zA-Z]*$/)) {
        valid = false;
        errors["txtContactLastName"] = "*Please enter alphabet characters only.";
      }
    }

    if (this.state.data["txtContactPhone"].length === 0) {
      valid = false;
      errors["txtContactPhone"] = "*Contact Phone is required!.";
     }

    if (typeof this.state.data["txtContactPhone"] !== "undefined") {
      if (!this.state.data["txtContactPhone"].match(/^[0-9]*$/)) {
        valid = false;
        errors["txtContactPhone"] = "*Please enter numbers only.";
      }
    }

    if (this.state.data["txtPostalCode"].length === 0) {
      valid = false;
      errors["txtPostalCode"] = "*Postal code is required!.";
     }

    if (typeof this.state.data["txtPostalCode"] !== "undefined") {
      if (!this.state.data["txtPostalCode"].match(/^[0-9]*$/)) {
        valid = false;
        errors["txtPostalCode"] = "*Please enter numbers only.";
      }
    }

    if (this.state.data["txtNumberofEmployees"].length === 0) {
      valid = false;
      errors["txtNumberofEmployees"] = "*Number of Employees is required!.";
     }

    if (typeof this.state.data["txtNumberofEmployees"] !== "undefined") {
      if (!this.state.data["txtNumberofEmployees"].match(/^[0-9]*$/)) {
        valid = false;
        errors["txtNumberofEmployees"] = "*Please enter numbers only.";
      }
    }

    if (this.state.data["txtCompanyRevenue"].length === 0) {
      valid = false;
      errors["txtCompanyRevenue"] = "*Company Revenue is required!.";
     }

    if (typeof this.state.data["txtCompanyRevenue"] !== "undefined") {
      if (!this.state.data["txtCompanyRevenue"].match(/[a-zA-Z0-9,\.\_]/)) {
        valid = false;
        errors["txtCompanyRevenue"] = "*Please enter valid revenue only.";
      }
    }
    
    if (this.state.data["txtContactRole"].length === 0) {
      valid = false;
      errors["txtContactRole"] = "*Contact Role is required!.";
     }

    if (typeof this.state.data["txtContactRole"] !== "undefined") {
      if (!this.state.data["txtContactRole"].match(/^[a-zA-Z]*$/)) {
        valid = false;
        errors["txtContactRole"] = "*Please enter alphabet characters only.";
      }
    }

    if (this.state.data["txtAddress"].length === 0) {
      valid = false;
      errors["txtAddress"] = "*Address is required!.";
     }

    if (typeof this.state.data["txtAddress"] !== "undefined") {
      if (!this.state.data["txtAddress"].match(/[a-zA-Z0-9,\.\_]/)) {
        valid = false;
        errors["txtAddress"] = "*Please enter valid address only.";
      }
    }
    
    if (this.state.data["ddlGeography"].length === 0) {
      valid = false;
      errors["ddlGeography"] = "*Geography is required!.";
    }

    if (this.state.data["txtWebsite"].length === 0) {
      valid = false;
      errors["txtWebsite"] = "*Website is required!.";
     }

    if (typeof this.state.data["txtWebsite"] !== "undefined") {
      if (!this.state.data["txtWebsite"].match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/)) {
        valid = false;
        errors["txtWebsite"] = "*Please enter valid website only.";
      }
    }
    this.setState({
      errors: errors,
    });
    return valid;
  };

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };
  onChange = (value) => {
    console.log("Captcha value:", value);
    
  }
  onSubmit = (event) => {
    event.preventDefault();
    const recaptchaValue = this.recaptchaRef.current.getValue();
    this.props.onSubmit(recaptchaValue);
  };

  render() {
    const { data } = this.state;
    return (
      <div>
        <section className="PartnershipBanner">
          <div className="container container-decoration">
            <div className="row movedown ml-md-n5">
              <div className="col-lg-6 col-12">
                <p className="text-white font-bolder GilroyExtrabold">Become a</p>
                <p className="text-white font-bolder GilroyExtrabold">Jaam Partner</p>
              </div>
            </div>
          </div>
        </section>
        <section className="mt-4">
          <div className="">
          <div className="container py-4 py-md-0 p py-lg-4">
            <div className="row content-center">
              <div className="col-lg-12">
              <p className="text-justify">
                    Introduce Jaam to your  customers and open new revenue streams
                    with our collaboration platform that offers fun
                    filled, simple and secured audio, video conferencing so
                    any one can connect from any devices across the world.
                </p>
                <p className="text-justify mb-5 ">
                  Great revenue opportunity while giving your customers a
                  simple, fun filled, and secured meeting solution that is
                  scalable and affordable.
                </p>
                
                  <h5 className="GilroyExtrabold mt-n2">Why become Jaam Partner?</h5>
                
                </div>
                  <div className="ml-n4 col-lg-6">
                  
                  <ul>
                    <li className="mb-3">
                      Pay for performance model
                    </li>
                    <li className="mb-3">
                      Deal registration payments
                    </li>
                    <li className="mb-3">
                      Predictable model through wholesale prices
                    </li>
                    <li className="mb-3">
                      Growth-related rebates
                    </li>
                    </ul>
                    
                    </div>
                    <div className="ml-n4 col-lg-6">
                    
                    <ul>
                    <li className="mb-3">
                      Recurring annual bookings on renewals
                    </li>
                    <li className="mb-3">
                      Training and enablement
                    </li>
                    <li className="mb-3">
                      Ongoing sales, technical and informational support
                    </li>
                  </ul>
                  
                  </div>
              <div className="col-lg-12 horizontal"></div>
              <div className="col-xl-9 col-12 ml-lg-n5">
              <div className="col-lg-12">
                <div className="container">
                <form id="partnershipform" onSubmit={this.doSubmit}>
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="txtCompanyName"
                        name="txtCompanyName"
                        placeholder="Company Name"
                        value={data["txtCompanyName"]}
                        onChange={this.handleChange}
                        noValidate
                      ></input>
                        <span className="errorMsg errormessage">
                          {this.state.errors.txtCompanyName}
                        </span>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-12">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="txtWebsite"
                        name="txtWebsite"
                        value={data["txtWebsite"]}
                        placeholder="Website"
                        onChange={this.handleChange}
                      ></input>
                      <span className="errorMsg errormessage">
                          {this.state.errors.txtWebsite}
                        </span>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-12">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="txtAddress"
                        name="txtAddress"
                        value={data["txtAddress"]}
                        placeholder="Address"
                        onChange={this.handleChange}
                      ></input>
                        <span className="errorMsg errormessage">
                          {this.state.errors.txtAddress}
                        </span>
                    </div>
                  </div>
                  
                  <div className="row mt-4">
                    <div className="col-md-6 col-12 col-sm-6 ">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="txtCountry"
                        name="txtCountry"
                        value={data["txtCountry"]}
                        placeholder="Country"
                        onChange={this.handleChange}
                      ></input>
                        <span className="errorMsg errormessage">
                          {this.state.errors.txtCountry}
                        </span>
                    </div>
                    <div className="showxs col-12">&nbsp;</div>
                  <div className="col-md-6 col-12 col-sm-6 ">
                  <input
                        type="text"
                        className="form-control form-control-lg"
                        id="txtState"
                        name="txtState"
                        value={data["txtState"]}
                        placeholder="State/Province"
                        onChange={this.handleChange}
                  ></input>
                        <span className="errorMsg errormessage">
                          {this.state.errors.txtState}
                        </span>
                  </div>
                  </div>
                  
                  <div className="row mt-4">
                    <div className="col-md-6 col-12 col-sm-6 ">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="txtCity"
                        name="txtCity"
                        value={data["txtCity"]}
                        placeholder="City"
                        onChange={this.handleChange}
                      ></input>
                        <span className="errorMsg errormessage">
                          {this.state.errors.txtCity}
                        </span>
                    </div>
                    <div className="showxs col-12">&nbsp;</div>
                    <div className="col-md-6 col-12 col-sm-6 ">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="txtPostalCode"
                        name="txtPostalCode"
                        value={data["txtPostalCode"]}
                        placeholder="Postal Code"
                        onChange={this.handleChange}
                      ></input>
                        <span className="errorMsg errormessage">
                          {this.state.errors.txtPostalCode}
                        </span>
                    </div>
                  </div>

                  
                  <div className="row mt-4">
                    <div className="col-md-6 col-12 col-sm-6 ">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="txtCompanyRevenue"
                        name="txtCompanyRevenue"
                        value={data["txtCompanyRevenue"]}
                        placeholder="Company Revenue"
                        onChange={this.handleChange}
                      ></input>
                        <span className="errorMsg errormessage">
                          {this.state.errors.txtCompanyRevenue}
                        </span>
                    </div>
                    <div className="showxs col-12">&nbsp;</div>
                    <div className="col-md-6 col-sm-6 col-12">
                      <input
                        type="number"
                        className="form-control form-control-lg"
                        id="txtNumberofEmployees"
                        min="1"
                        name="txtNumberofEmployees"
                        value={data["txtNumberofEmployees"]}
                        placeholder="Number of Employees"
                        onChange={this.handleChange}
                      ></input>
                        <span className="errorMsg errormessage">
                          {this.state.errors.txtNumberofEmployees}
                        </span>
                    </div>
                  </div>
                  
                  <div className="row mt-4">
                    <div className="col-md-6 col-12 col-sm-6">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="txtContactFirstName"
                        name="txtContactFirstName"
                        value={data["txtContactFirstName"]}
                        placeholder="Contact First Name"
                        onChange={this.handleChange}
                      ></input>
                        <span className="errorMsg errormessage">
                          {this.state.errors.txtContactFirstName}
                        </span>
                    </div>
                    <div className="showxs col-12">&nbsp;</div>
                    <div className="col-md-6 col-sm-6 col-12">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="txtContactLastName"
                        name="txtContactLastName"
                        value={data["txtContactLastName"]}
                        placeholder="Contact Last Name"
                        onChange={this.handleChange}
                      ></input>
                        <span className="errorMsg errormessage">
                          {this.state.errors.txtContactLastName}
                        </span>                    
                  </div>
                  </div>
                
                  <div className="row mt-4">
                    <div className="col-md-6 col-sm-6 col-12">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="txtContactRole"
                        name="txtContactRole"
                        value={data["txtContactRole"]}
                        placeholder="Contact Role"
                        onChange={this.handleChange}
                      ></input>
                        <span className="errorMsg errormessage">
                          {this.state.errors.txtContactRole}
                        </span>
                    </div>
                    <div className="showxs col-12">&nbsp;</div>
                    <div className="col-md-6 col-sm-6 col-12">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="txtContactPhone"
                        name="txtContactPhone"
                        value={data["txtContactPhone"]}
                        placeholder="Contact Phone"
                        onChange={this.handleChange}
                      ></input>
                        <span className="errorMsg errormessage">
                          {this.state.errors.txtContactPhone}
                        </span>
                    </div>
                  </div>
                  
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        id="txtContactEmail"
                        name="txtContactEmail"
                        value={data["txtContactEmail"]}
                        placeholder="Contact Email"
                        onChange={this.handleChange}
                      ></input>
                        <span className="errorMsg errormessage">
                          {this.state.errors.txtContactEmail}
                        </span>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-6">
                      <select
                        className="form-control form-control-lg select-purple"
                        name="ddlGeography"
                        value={data["ddlGeography"]}
                        defaultValue="0"
                        onChange={this.handleChange}
                      >
                        <option value="">Geography</option>
                        <option value="Americas">Americas</option>
                        <option value="Asia Pacific">Asia Pacific</option>
                        <option value="Europe">Europe</option>
                        <option value="Middle East">Middle East</option>
                      </select>
                        <span className="errorMsg errormessage">
                          {this.state.errors.ddlGeography}
                        </span>
                    </div>
                  </div>
                  
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <p>Do you currently sell any of the following vendors? (Optional)</p>
                      <ul className="list-unstyled ml-n3">
                        {this.state.vendors.map((vendor) => {
                          return (
                            <CheckBox
                              handleCheckChieldElement={
                                this.handleCheckChieldElement
                              }
                              {...vendor}
                            />
                          );
                        })}
                      </ul>
                    </div>
                  </div>

                  <div className="row mt-n1 mb-1">
                    <div className="col-md-12 ml-1">
                      <input
                        name="allow"
                        value="allowPrivacy"
                        id="allowPrivacy"
                        className="larger-checkbox"
                        //checked={data["allowPrivacy"]}
                        //onChange={this.handleCheckChange}
                        type="checkbox"
                        onClick={this.handleCheckPrivacy}
                        defaultChecked={false}
                      ></input>{" "}
                      <label className="mt-n4 mt-md-n4 ml-4" htmlFor="allowPrivacy">
                      Allow Jaam to contact me regarding events and other
                      promotions as described in the Privacy Policy
                      </label>
                    </div>
                    <span className="errorMsg ml-3 errormessage">
                          {this.state.errors.allowPrivacy}
                        </span>
                  </div>

                  <div className="row">
                    <div className="col-md-12 ml-3 mb-3">
                    &nbsp;&nbsp;By continuing, I confirm that I have read and understand
                      the{" "}
                      <Link
                        to="/privacypolicy"
                        target="_blank"
                        className="text-purple"
                      >
                        Privacy Policy
                      </Link>{" "}
                      and{" "}
                      <Link
                        to="/termsofservice"
                        target="_blank"
                        className="text-purple"
                      >
                        Terms of Service
                      </Link>
                      .
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-12 mb-3">
                    <form onSubmit={this.onSubmit}>
                    <ReCaptchaV2
                        ref={this.state.recaptchaRef}
                        sitekey="6Lejk8spAAAAACTPC8CTLK5TrT3oia6nvV1aGXPB"
                        onChange={this.onChange}
                      />
                      <span className="errorMsg errormessage">
                        {this.state.errors.txtCaptcha}
                      </span>
                      </form>
                    </div>
                  </div>

                  <div className="row mt-4 mb-4">
                    <div className="col-md-12">
                    <button className="col-lg-12 btn btn-purple btn-lg">
                    Submit
                   </button>
                    </div>
                  {Object.keys(this.state.statusData).length > 0 &&
                  this.statusHelper.renderStatus(this.state.statusData)}
                   </div>
                </form>
                </div>
              </div>
            </div>
            </div>
          </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(Partnership);