import _ from 'lodash';
import queryString from 'query-string';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import StatusHelper from '../common/helpers/statusHelper';
import LoadingComp from '../common/LoadingComp';
import { joinPasscode } from '../common/session/sessionAction';
import SnackBar from '../common/SnackBar';
import { Link } from "react-router-dom";
import $ from "jquery";

class JoinPasscode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomName: "",
      // displayName: props.user.firstname,
      displayName: "",
      passcode: "",
      showSnack: false,
      statusData: {},
    };
  }
  statusHelper = new StatusHelper(this);

  joinMeeting = (e) => {
    e.preventDefault();
    var token = this.props.token;
    const formIsValid = this.validateForm();
    if(formIsValid){
      this.props.dispatch(
        joinPasscode({
          ...this.state,
          token: token,
        })
      );
    }
  };

  updateState(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.joinError &&
      nextProps.joinError !== "" &&
      this.props.joinError !== nextProps.joinError
    ) {
      nextProps.joinErrorCode === "ERR_01" ? this.setState({ showSnack: true }) : this.setState({statusData: {
        type: 'error',
        message: nextProps.joinError
      }});
    }
  }
  componentDidMount() {
    const { user } = this.props;
    let queryStr = queryString.parse(this.props.location.search);
    this.setState({
      roomName: queryStr.meet ? queryStr.meet : "",
    });

    const { meetingId } = this.props
    if(meetingId) {
      this.setState({
        roomName: meetingId ? meetingId : "",
      });
    }

    const userName = this.props.user.firstname
    const AnonName = this.props.location.state.name;
    this.setState({
      displayName: AnonName ? AnonName : "",
    });

    // var code = event.keyCode || event.charCode;

    $("#joinbutton").click(function(){
      console.log("enter clicked");
    })

    $(document).keypress(function(e){
      if(e.charCode == 13)
        $("#joinbutton").trigger("click")
    })

    $("#backbutton").click(function(){
      console.log("back clicked");
    })
    
    $(document).keypress(function(e){
      if(e.charCode == 13)
        e.preventDefault();
    })
  }

validateForm(){
  let formIsValid = true;
  return formIsValid;
}

home() {
  this.props.history.push("/home");
}

join(str) {
  this.props.history.push(str);
}

  render() {
    const { t } = this.props;
    const { roomName, displayName } = this.state;
    const { loading, userLoading, joinError, joinErrorCode, meetingId } = this.props;
    
    let loadingStyle = {
      minHeight: "50vh",
      textAlign: "center",
    };
    return loading || userLoading ? (
      <div style={loadingStyle} className="background">
        <LoadingComp />
      </div>
    ) : (
      <section className="background">
        <div className="container container-decoration">
          <div className="row content-center">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="text-center">{t("join.titlePasscode")}</h2>
                </div>
              </div>
              <form onSubmit={this.joinMeeting}>
                <div className="row mt-4">
                  <div className="col-md-3"></div>
                  <div className="col-md-6">
                  <input
                      type="text"
                      className="form-control form-control-lg"
                      id="txtPasscode"
                      placeholder="Enter the Passcode"
                      name="passcode"
                      onChange={(e) => this.updateState(e)}
                    ></input>
                    {["ERR_02", "ERR_03"].includes(joinErrorCode) && (
                      <span className="text-danger error-text">
                        {t("join.validation.passcode")}
                      </span>
                    )}{" "}
                  </div>
                  <div className="col-md-3"></div>
                </div>               
                <div className="row mt-4 mb-4">
                <div className="col-12 col-md-2 col-lg-3"></div>
                  <div className="col-12 col-md-3 col-lg-2">
                    <button
                      className="col-12 btn btn-back btn-lg ml-lg-n3" 
                      onClick={() => this.join(`/join/${this.props.meetingId}`)}
                      id="backbutton"   
                    >                  
                    ❮ Back                                  
                    </button>
                  </div>
                  {/* <div className="col-md-1"></div> */}
                  <div className="showxs">&nbsp;</div>
                  <div className="col-12 col-md-2 col-lg-2">
                    <button
                      className="col-12 btn btn-cancel btn-lg" 
                      onClick={() => this.home()}            
                    >
                      Cancel                
                    </button>
                  </div>
                  {/* <div className="col-md-1"></div> */}
                  <div className="showxs">&nbsp;</div>
                  <div className="col-12 col-md-2 col-lg-2">
                    <button
                      className="col-12 btn btn-purple btn-lg"
                      disabled={
                        this.state.passcode === undefined ||
                        this.state.passcode === ""
                      }
                      id="joinbutton"
                    >
                      {t("join.joinPasscodebutton")}                    
                    </button>
                  </div>
                  <div className="col-12 col-md-2 col-lg-2"></div>
                </div>
              </form>
              {Object.keys(this.state.statusData).length > 0 &&
                this.statusHelper.renderStatus(this.state.statusData)}
            </div>
          </div>
        </div>
        {this.state.showSnack ? (
          <SnackBar
            message={joinError}
            snackShow={true}
            minwidth="600px"
          ></SnackBar>
        ) : (
          ""
        )}{" "}
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.session.user,
  token: state.session.token,
  joinLoading: state.session.joinLoading,
  meetingId: state.session.meetingId,
  loading: state.session.loading,
  joinError: state.session.joinError,
  joinErrorCode: state.session.joinErrorCode,
  selectedTheme: state.session.selectedTheme
});

export default withTranslation()(connect(mapStateToProps)(JoinPasscode));
