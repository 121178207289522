import React, { Component } from "react";
import image1 from "../assets/girl.png";
import jaamlogo from "../assets/jaamlogo.svg";
import { Link } from "react-router-dom";

class About extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
  }
  state = {};
  render() {
    return (
      <div>
        <div className="row no-gutters">&nbsp;</div>
        <div className="row no-gutters">&nbsp;</div>

        <section
          className="banner banner_about_bg"
          style={{ backgroundColor: "#5D3EA8" }}
        >
          <div className="row no-gutters" style={{ height: 20 + "vh" }}></div>
          <div className="container">
            <div className="row">
              <div className="col-md-9">
                <h3
                  className="font-weight-normal"
                  style={{
                    fontSize: "40px",
                    fontFamily: "Gilroy Extrabold",
                    color: "#E5CBFF"
                  }}
                >
                  About Jaam
                </h3>
                <br></br>
                <br></br>
                <div
                  style={{
                    fontSize: "24px",
                    color: "#E5CBFF"
                  }}
                >
                  <span>
                    Jaam’s core value is to transform your remote meeting
                  </span>
                  <br></br>
                  <span>
                    experience to become simpler yet engaged, more fun-filled
                    and
                  </span>
                  <br></br>
                  <span>securely protected.</span>
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
          <div className="row no-gutters" style={{ height: 20 + "vh" }}></div>
        </section>

        <section style={{ backgroundColor: "#FCBF49" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <img
                  className="mb-2"
                  src={jaamlogo}
                  height="75"
                  width="100"
                  alt=""
                ></img>
                &nbsp;&nbsp;
                <span className="verticalBlack">
                  &nbsp;&nbsp;unlimited collaboration
                </span>
                <div
                  style={{
                    fontSize: "24px",
                  }}
                >
                  <br></br>
                  <p>
                    Jaam will be your integral focal point for all your video,
                    audio and web conferencing needs.
                  </p>
                  <p>
                    We focus engage technologies like AR, VR and Machine
                    learning platform elevates YOUR creativity to enrich your
                    virtual meetings to be more entertained, flexible,
                    easy-to-use and improve productivity.
                  </p>
                  <p>
                    We meticulously bundled our package to be custom-focused,
                    firmly secured and cost-effective.
                  </p>
                </div>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-6">
                <img src={image1} height="100%" width="100%" alt=""></img>
              </div>
            </div>
          </div>
        </section>

        <div className="row no-gutters bg-orange">
          <div className="container">
            <div className="col-md-12 mt-5 mb-5">
              <div className="row">
                <div className="col-md-6 text-md-left text-center">
                  <h4 className="text-white">
                    Looking for even greater flexibility?
                  </h4>
                </div>
                <div className="col-md-1">&nbsp;</div>
                <div className="col-md-5 text-md-right text-center">
                  <Link
                    to="/contact"
                    className="btn btn-light btn-sm col-8 col-md-8"
                  >
                    <span className="text-orange large font-weight-bolder">
                      Contact Sales
                    </span>
                  </Link>
                </div>
                <div className="col-md-1">&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;

/*<div className="row no-gutters look">
          <div className="container">
            <div className="col-md-12 mt-4 mb-4">
              <div className="row no-gutters">
                <div className="col-md-6 mt-3 text-md-right text-center">
                  <span className="looking">
                    Looking for even greater flexibility?
                  </span>
                </div>
                <div className="col-md-2">&nbsp;</div>
                <div className="col-md-4 mt-2 text-md-left text-center">
                  <Link
                    to="/contact"
                    className="btn btn-purple btn-sm col-8 col-md-8"
                  >
                    <span className="contact">
                      Contact Sales
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
*/