import { createRequestTypes } from "../api";

export const LOGOUT = "LOGOUT";
export const LOGIN = createRequestTypes("LOGIN");
export const SIGNUP = createRequestTypes("SIGNUP");
export const JOIN = createRequestTypes("JOIN");
export const JOINPASSCODE = createRequestTypes("JOINPASSCODE");
export const GETUSER = createRequestTypes("GETUSER");
export const UPDATEMEET = createRequestTypes("UPDATEMEET");
export const LINKEDIN_DATA = createRequestTypes("LINKEDIN_DATA");
export const CONTACT = createRequestTypes("CONTACT");
export const PARTNERSHIP = createRequestTypes("PARTNERSHIP");
export const UPDATE_THEME = "UPDATE_THEME";
export const FEEDBACK = createRequestTypes('FEEDBACK');

// Stripe
export const CREATECHECKOUT = createRequestTypes('CREATECHECKOUT');
export const GETCUSTOMERPORTAL = createRequestTypes('GETCUSTOMERPORTAL');
export const GETINVOICE = createRequestTypes('GETINVOICE');
export const GETSUBSCRIPTIONS = createRequestTypes('GETSUBSCRIPTIONS');
export const GETALLPRICES = createRequestTypes('GETALLPRICES');
export const PAYMENTERROR = "PAYMENTERROR";

//Common Local States
export const SHOWPROFILE = "SHOWPROFILE";