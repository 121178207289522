import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import ReCaptchaV2 from 'react-google-recaptcha';
import Input from "react-phone-number-input/input";
import StatusHelper from "../common/helpers/statusHelper";
import { saveContactus } from "../services/contactService";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        email: "",
        companyName: "",
        employeeCount: "",
        phone: "",
        country: "",
        state: "",
        zipCode: "",
        additionalInfo: "",
      },
      recaptchaRef: React.createRef(),
      errors: {},
      showSnack: false,
      shown: false,
      statusData: {},
    };
    window.scrollTo(0, 0);
  }
  statusHelper = new StatusHelper(this);

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };

  selectCountry(val) {
    const { data } = this.state;
    data["country"] = val;
    this.setState({ data });
  }

  selectRegion(val) {
    const { data } = this.state;
    data["state"] = val;
    this.setState({ data });
  }

  selectPhone(val) {
    const { data } = this.state;
    data["phone"] = val;
    this.setState({ data });
  }

  doSubmit = async (e) => {
    e.preventDefault();
    this.setState({ statusData: {} });
    const isFormValid = this.validateForm();
    if (!!isFormValid) {
      try {
        const { data } = this.state;
        await saveContactus(data);
        const statusData = {
          type: "success",
          message:
            "Contact us has successfully submitted. Our sales team will contact you soon.",
        };
        this.setState({ statusData });
        this.setState({
          data: {
            email: "",
            companyName: "",
            employeeCount: "",
            phone: "",
            country: "",
            state: "",
            zipCode: "",
            additionalInfo: "",
          },
        });
        this.state.recaptchaRef.current.reset();
      } catch (ex) {
        const statusData = {
          type: "error",
          message: "Something went wrong. Please try after some time.",
        };
        this.setState({ statusData });
        this.state.recaptchaRef.current.reset();
      }
    }
  };

  validateForm() {
    let errors = {};
    let formIsValid = true;

    if (this.state.data["email"].length === 0) {
      formIsValid = false;
      errors["email"] = "*Please enter your email.";
    }

    if (typeof this.state.data["email"] !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(this.state.data["email"])) {
        formIsValid = false;
        errors["email"] = "*Valid Email-ID is required!";      }
    }
    if (this.state.data["companyName"].length === 0) {
      formIsValid = false;
      errors["companyName"] = "*Company Name is required!.";    }

    if (typeof this.state.data["companyName"] !== "undefined") {
      if (!this.state.data["companyName"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["companyName"] = "*Please enter alphabet characters only.";
      }
    }
    if (this.state.data["employeeCount"].length === 0) {
      formIsValid = false;
      errors["employeeCount"] = "*Employee Count is required!";
    }

    if (typeof this.state.data["employeeCount"] !== "undefined") {
      if (!this.state.data["employeeCount"].match(/^[0-9]*$/)) {
        formIsValid = false;
        errors["employeeCount"] = "*Please enter valid count only.";
      }
    }
    if (this.state.data["zipCode"].length === 0) {
      formIsValid = false;
      errors["zipCode"] = "*Zipcode is required!.";
    }

    if (typeof this.state.data["zipCode"] !== "undefined") {
      if (!this.state.data["zipCode"].match(/^[0-9]*$/)) {
        formIsValid = false;
        errors["zipCode"] = "*Please enter numbers only.";
      }
    }
    if (
      this.state.data["phone"].length === 0 ||
      this.state.data["phone"].value === "+919"
    ) {
      formIsValid = false;
      errors["phone"] = "*Phone Number is required!.";
    }
    if (this.state.data["country"].length === 0) {
      formIsValid = false;
      errors["country"] = "*Country selection is required!.";
    }
    if (this.state.data["state"].length === 0) {
      formIsValid = false;
      errors["state"] = "*Region selection is required!.";
    }
    if (!this.state.recaptchaRef.current.getValue()) {
      formIsValid = false;
      errors["txtCaptcha"] = "*Captcha selection is required!.";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  onChange = (value) => {
    console.log("Captcha value:", value);
    
  }
  onSubmit = (event) => {
    event.preventDefault();
    const recaptchaValue = this.recaptchaRef.current.getValue();
    this.props.onSubmit(recaptchaValue);
  };

  render() {
    const { data } = this.state;

    return (
      <section className="background">
        <div className="container container-decoration">
          <div className="row content-center">
            <div className="col-xl-9">
              <div className="row">
                <div className="col-lg-12">
                  <h2 className="text-center">Contact Sales</h2>
                </div>
              </div>
              <form onSubmit={this.doSubmit}>
                <div className="row mt-4">
                  <div className="col-lg-12">
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      id="email"
                      name="email"
                      placeholder="Email"
                      value={data["email"]}
                      onChange={this.handleChange}
                    ></input>
                    <span className="errorMsg errormessage">
                      {this.state.errors.email}
                    </span>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="companyName"
                      name="companyName"
                      placeholder="Company Name"
                      value={data["companyName"]}
                      onChange={this.handleChange}
                    ></input>
                    <span className="errorMsg errormessage">
                      {this.state.errors.companyName}
                    </span>
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                    <input
                      type="number"
                      className="form-control form-control-lg"
                      id="employeeCount"
                      min="1"
                      placeholder="Employee Count"
                      name="employeeCount"
                      value={data["employeeCount"]}
                      onChange={this.handleChange}
                    ></input>
                    <span className="errorMsg errormessage">
                      {this.state.errors.employeeCount}
                    </span>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <CountryDropdown
                      className="form-control form-control-lg select-purple"
                      value={data["country"]}
                      onChange={(val) => this.selectCountry(val)}
                    />
                    <span className="errorMsg errormessage">
                      {this.state.errors.country}
                    </span>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                    <RegionDropdown
                      className="form-control form-control-lg select-purple"
                      country={data["country"]}
                      value={data["state"]}
                      onChange={(val) => this.selectRegion(val)}
                    />
                    <span className="errorMsg errormessage">
                      {this.state.errors.state}
                    </span>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <Input
                      className="form-control form-control-lg"
                      id="phone"
                      name="phone"
                      international
                      value={data["phone"]}
                      placeholder="Phone (include country code)"
                      onChange={(val) => this.selectPhone(val)}
                    />
                    <span className="errorMsg errormessage">
                      {this.state.errors.phone}
                    </span>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="zipCode"
                      pattern="[0-9]*"
                      placeholder="Zip Code"
                      name="zipCode"
                      value={data["zipCode"]}
                      onChange={this.handleChange}
                    ></input>
                    <span className="errorMsg errormessage">
                      {this.state.errors.zipCode}
                    </span>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg-12">
                    <textarea
                      rows="4"
                      className="form-control form-control-lg"
                      id="additionalInfo"
                      placeholder="Additional Info (Optional)"
                      name="additionalInfo"
                      onChange={this.handleChange}
                      value={data["additionalInfo"]}
                    ></textarea>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg-12">
                    <form onSubmit={this.onSubmit}>
                    <ReCaptchaV2
                        ref={this.state.recaptchaRef}
                        sitekey="6Lejk8spAAAAACTPC8CTLK5TrT3oia6nvV1aGXPB"
                        onChange={this.onChange}
                      />
                      <span className="errorMsg errormessage">
                        {this.state.errors.txtCaptcha}
                      </span>
                    </form>
                  </div>
                </div>
                <div className="row mt-4 mb-3 mx-auto">
                  <button className="col-lg-12 btn btn-purple btn-lg">
                    Contact Us
                  </button>
                </div>
                {Object.keys(this.state.statusData).length > 0 &&
                  this.statusHelper.renderStatus(this.state.statusData)}
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(Contact);
