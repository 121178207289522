import axios from "axios";

//axios.defaults.baseURL = process.env.REACT_APP_API_URL;
/*
axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    logger.log(error);
  }

  return Promise.reject(error);
});
*/
/*
function setJwt(jwt) {
  axios.defaults.headers.common["x-auth-token"] = jwt;
}
*/
/*
function customError(error) {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;
  if (expectedError) {
    const errors = {};
    errors.message = error.response.data;
    return errors;
  } else {
    const errors = {};
    errors.message = "Service is unavailable. Please try after some time.";
    return errors;
  }
}
*/

export default {
  get: axios.get,
  post: axios.post,
  delete: axios.delete,
  put: axios.put,
  all: axios.all,
  // customError,
};
