import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'lodash';
import LoadingComp from '../../../../common/LoadingComp';
import { stripeCreateCheckout, stripeGetSubscriptions } from '../../../../common/session/sessionAction';
import RedirectCheckout from './redirectCheckout';
import { month_Interval, year_Interval } from '../../../../common/constants';
import getSymbolFromCurrency from 'currency-symbol-map';
import BoxPlans from './boxPlans'
import PaymentStaticContent from './paymentStaticContent';

var cc = require('currency-codes');
let currValidate;
export class SubscribeCheckout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerToken: props.user.token,  // signed in user
      customerEmail: props.user.email,  // signed in user
      payDisabled: false,
      monthlyactive:false,
      annualactive:false,
      selectedPriceId: '',   
      stripeRedirect: false,   
      statusData: {}
     };

    this.imgClick = this.imgClick.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    window.scrollTo(0, 0);
  }  
  
  componentWillReceiveProps(nextProps) {
    if (!_.isEmpty(nextProps.sessionId) && this.props.sessionId !== nextProps.sessionId ) {
      this.setState({ stripeRedirect: true });
    } 
  
    if (
      nextProps.user.token &&
      !_.isEmpty(nextProps.user.token) &&
      !_.isEqual(this.props.user.token, nextProps.user.token)
    ) {
      this.setState({
        customerToken: nextProps.user.token ? nextProps.user.token : "",
      });
    }

    if (
      nextProps.user &&
      !_.isEmpty(nextProps.user) &&
      !_.isEqual(this.props.user, nextProps.user)
    ) {
      this.setState({
        customerEmail: nextProps.user.email ? nextProps.user.email : "",
      });
    } 

    if (!_.isEmpty(nextProps.allprices) && this.props.allprices !== nextProps.allprices ) {       
      this.setState({ allprices: nextProps.allprices });
    }
  }

  // boxflow
  imgClick = (pid,pinterval) => {
    this.setState ({ 
      selectedPriceId: pid,
      payDisabled: true
    })
    switch (pinterval) {
      case month_Interval:        
        this.setState({monthlyactive:true});  
        this.setState({annualactive:false});      
        break;
      case year_Interval:
        this.setState({monthlyactive:false});
        this.setState({annualactive:true});          
        break;                 
      default:
        break;     
    }
  }

  handleChange =  (event) => {  
    this.setState({ selectedCurrency: event.target.value
     });
  };

  handleClick = async (price) => {    
        const priceId = this.state.selectedPriceId && this.state.selectedPriceId? this.state.selectedPriceId : ''; 
        if (priceId !== null || priceId !== "undefined") {              
              this.props.dispatch( stripeCreateCheckout({ ...this.state,currValidate  }))               
          }   
  };

  render() {
  console.log('selectedCurrency',this.state.selectedCurrency);
    const { user, allprices, initCurrency, cusCurrency, getallpricesLoading} = this.props;   
    const { selectedCurrency } = this.state;   

    let myData;
    // const {month} = this.state.monthlyactive;
    // const {year} = this.state.annualactive;
    const boxes = [
      this.state.annualactive,this.state.monthlyactive
    ];
   
    let { stripeRedirect } = this.state
    let loadingStyle = {
      minHeight: "50vh",
      textAlign: "center",
    };

    const uniqueTags = [];
    if (allprices) {     
      allprices.map(item => {                  
        var findItem = uniqueTags.find(x => x.priceCurrency === item.priceCurrency);
        if (!findItem)
         uniqueTags.push(item);
        });     
    }  

  
    let selectoption = false;   

    if(user.token) {  
      if(cusCurrency) {
        currValidate = cusCurrency;
      } else {    
        selectoption = true;  
        if(selectedCurrency){
          currValidate = selectedCurrency;
        }else {
          currValidate = initCurrency;
        }      
      }
    }

    return getallpricesLoading ? (
      <div style={loadingStyle} >
        <LoadingComp />
      </div>
    ) : (
      stripeRedirect ?  (      
        <div>
          <RedirectCheckout currency={currValidate} />
        </div>
      ) : (
      <div className="container billing current-plan ml-n3 ml-md-0 ml-lg-0">
        <div className="row ml-3">
        <div className="col-12">
        <div className="row mt-4">
          <div className="col-12">
          {
          (!selectoption)?
          <div className="billing-plan-expired-text ml-n3">Your subscription has expired, Please choose your plan to upgrade. </div>
          :
            <div className="col-12 ml-n3">
            <form id="formcurrency">           
            <div className="row">              
              <div className="column">
              <select
                name="currency"
                value={currValidate}
                label="Currency" 
                onChange={this.handleChange}   
                > 
                {
                  uniqueTags && uniqueTags.map((allprices, i) =>                          
                  <option key={allprices.priceId} value={allprices.priceCurrency}>
                    {cc.code(allprices.priceCurrency).currency} {getSymbolFromCurrency(allprices.priceCurrency)}
                  </option>
                )
                }
              </select>        
              </div>
            </div>
            </form>
            </div>
          }
          </div>
        </div>
        <div className="row mt-3 ml-n4">           
          {           
          myData = [].concat(allprices && allprices)
          .filter(allprices => allprices.priceCurrency.includes(currValidate))
          .sort((a,b) => a.priceUnitAmount < b.priceUnitAmount ? 1 : -1)
          .map((price, i) =>       
            <div className={"mr-3 mb-3 ml-2 mb-md-0 mb-lg-0 billing-card sub-check box-plan boxLayout" + (boxes[i++] ? 'active' : 'inactive')}            
             onClick = {()=> this.imgClick(price.priceId, price.priceInterval)} key = {i} >
               {/* Boxplan Component */}
               <BoxPlans boxArray = {i} boxPrice = {price} /> 
            </div>
           )}         
         </div>
         {/* PAY NOW BUTTON      */}
          <div className="row mt-3 mb-4 ml-n4">
           <div className="col-12">
           { 
             this.state.payDisabled
             ?
             <Link
             id="on"
             className="btn btn-purple buy btn-lg ml-n1 col-12 col-md-4 col-lg-3"
             onClick ={() => this.handleClick(this.state.selectedPriceId)}
             >
              Pay now 
             </Link>            
             : 
             <Link id="on" className="btn btn-purple buy btn-lg disabled ml-n1 col-12 col-lg-3 col-md-4">
              Pay now 
             </Link>
            }
          </div>
         <div className="billing-auto-renew-text col-12 mt-2">Your subscription will auto renew upon expiration.</div>
         </div>
         <div className="ml-n4">
         <PaymentStaticContent />
         </div>
        </div>        
       </div>
      </div>
      )
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.session.user,
  token: state.session.token, 
  loading: state.session.loading,
  sessionId: state.session.sessionId,
  priceId: state.session.priceId,
  allprices: state.session.allpricesList,
  initCurrency: state.session.initCurrency,
  cusCurrency: state.session.customerCurrency,
  getallpricesLoading: state.session.getallpricesLoading,
});

export default withTranslation()(connect(mapStateToProps)(SubscribeCheckout));