import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _  from "lodash";
import LoadingComp from "../../../common/LoadingComp";
import Sidebar from '../Sidebar';
import Meetings from "./Components/meetings";
import TrialCounter from "../trialCounter";
import { getStorage } from "../../../common/localStorage";

class meetings extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      selImg:  props.meetingData.themeImage,
      Enable: false
    };
    window.scrollTo(0, 0);
  }

  componentWillMount( ){
    this.setState({
      Enable : true,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (_.isEmpty(nextProps.user)) {
      this.props.history.push("/signin");
    }
    this.setState({
        selImg: nextProps.meetingData.themeImage,
    });
  }
  componentDidMount () {
    const Token = getStorage("token");
    if ( !Token) {
      this.props.history.push("/signin");
    }
    this.setState({
        selImg: this.props.meetingData.themeImage,
    });
    setTimeout(() => {
      this.setState({ Enable: false});
    }, 1500);
  }
  render() {
    let backGroundImage =
      this.state.selImg !== ""
        ? `${window.location.origin}${this.state.selImg}`
        : "";
    let loadingStyle =  { minHeight: "50vh", textAlign: "center" };

    return this.state.Enable ? (
      <div style={loadingStyle} className="background">
        <LoadingComp />
      </div> ) : ( 
      <div
        className="background"
        style={
          this.state.selImg !== ""
            ? { backgroundImage: `url(${backGroundImage})` }
            : {}
        }
      >
        <div className="container container-decoration">
          <TrialCounter/>
          <div className="row mt-5">
            <div className="container">
              <div className="row">
                <div className="col-md-7 offset-lg-1 col-lg-6 offset-xl-2 col-xl-5">
                </div>
                <div className="row"></div>
              </div>
              <div className="row ml-md-n5">
                <div className="col-sm-2 col-12 col-md-2 col-lg-2 col-xl-2">
                  <Sidebar visible={this.state.selectedTabValue}/>
                </div>
                <div className="tab-content col-12 col-md-10 col-lg-10 col-xl-10">
                      <Meetings />
                </div>
              </div>
            </div>
          </div>
          <div className="row"></div>
          <div className="row">&nbsp;</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.session.user,
  token: state.session.token,
  meetingData: state.session.meetingData,
  isSignedUp: state.session.isSignedUp,
  loading: state.session.loading,
  selectedTheme: state.session.selectedTheme,
});

export default withRouter(connect(mapStateToProps)(meetings));
