import React, { Component } from "react";
import Stockimage from "../assets/stockimage.png";
import Chevronright from "../assets/chevronright.svg";
import Aws from "../assets/aws.svg";
import Oracle from "../assets/oracle.svg";
import Mulesoft from "../assets/mulesoft.svg";
import Pega from "../assets/pega.svg"; 
import Co from "../assets/co.svg";
import Different from "../assets/different.svg";
import Enable from "../assets/enable.svg";
import Agility from "../assets/agility.svg";
import Innovation from "../assets/innovation.svg";
import Engineering from "../assets/engineering.svg";
import Portfolio from "../assets/portfolio.svg";
import Offerings from "../assets/offerings.svg";
import Qualityassurance from "../assets/qualityassurance.png";
import Delivery from "../assets/delivery.svg";
import C4E from "../assets/c4e.svg";
import Benefits from "../assets/benefits.svg";
import Banking from "../assets/banking.svg";
import Proposition from "../assets/proposition.svg";
import Reduction from "../assets/reduction.svg";
import Reusable from "../assets/reusable.svg";
import Highvalue from "../assets/highvalue.svg";
import Transition from "../assets/transition.svg";
import Tunedge from "../assets/tunedge.svg";
import Cox from "../assets/cox.svg";
import UIUX from "../assets/uiux.svg";
import Legacy from "../assets/legacy.svg";
import Finplex from "../assets/finplex.svg";
import Fis from "../assets/fis.svg";
import Sagitec from "../assets/sagitec.svg";

class Services extends Component {
  constructor(props) {
    super(props);  
    window.scrollTo(0, 0);
  }
  render() {
    return (
        <div className="services-page">
        <div className="row">&nbsp;</div>
        <div className="row">&nbsp;</div>
        <div className="row">&nbsp;</div>
        <div className="row">&nbsp;</div>        
        
        <section className="services-banner mt-n3">
         {/* IMAGE PORTION */}
        </section>

        <section className="sticky-top services-navigation display-only-on-pc-tablet display-only-on-landscape services-buttons services-navigation-colour" >
         <div className="container">
          <div className="row content-center">
           <div className="col-8 col-md-8 col-lg-10 ml-4 ml-md-3 my-lg-2">
           <div className="row minimum-height-normal" ></div>
            <div className="row my-2">
             <div className="col-12 col-md-6 col-lg-3 my-2 my-md-2 my-lg-0">
              <a className="text-purple services-decoration" href="#CIWEB"><button className="services-btn-text services-btn">Devops</button></a>
             </div>
             <div className="col-12 col-md-6 col-lg-3 my-2 my-md-2 my-lg-0">
              <a className="text-purple services-decoration" href="#UIWEB"><button className="services-btn-text services-btn">UI/UX</button></a>
             </div>
             <div className="col-12 col-md-6 col-lg-3 my-2 my-md-2 my-lg-0">
              <a className="text-purple services-decoration" href="#MSWEB"><button className="services-btn-text services-btn-edited-mulesoft">MuleSoft API</button></a>
             </div>
             <div className="col-12 col-md-6 col-lg-3 my-2 my-md-2 my-lg-0">
              <a className="text-purple services-decoration" href="#JBWEB"><button className="services-btn-text services-btn-edited-banking">Jaam Banking</button></a>
             </div>
             </div>
             <div className="row minimum-height-normal" ></div>
            </div>
           </div>
          </div>
      </section>

      <section className="display-only-on-mobile display-only-on-mobile-landscape services-buttons services-navigation-colour mt-n5" >
       <div className="container">
        <div className="row content-center">
         <div className="col-12">
          <div className="row minimum-height-normal"></div>
           <div className="row my-2">
            <div className="col-12 my-2 d-flex justify-content-center">
             <a className="text-purple services-decoration" href="#CI"><button className="services-btn-text-mobile services-btn-mobile py-2">Devops</button></a>
            </div>
            <div className="col-12 my-2 d-flex justify-content-center">
             <a className="text-purple services-decoration" href="#UI"><button className="services-btn-text-mobile services-btn-mobile-ui-ux py-2">UI/UX</button></a>
            </div>
            <div className="col-12 my-2 d-flex justify-content-center">
             <a className="text-purple services-decoration" href="#MS"><button className="services-btn-text-mobile services-btn-edited-mulesoft-mobile py-2">MuleSoft API</button></a>
            </div>
            <div className="col-12 my-2 d-flex justify-content-center">
             <a className="text-purple services-decoration" href="#JB"><button className="services-btn-text-mobile services-btn-edited-banking-mobile py-2">Jaam Banking</button></a>
            </div>
           </div>
          <div className="row minimum-height-normal" ></div>
         </div>
        </div>
       </div>
      </section>
      
      <section className="services-white-bg-section">
       <div className="container">
        <div className="row content-center">
         <div className="col-lg-12">
          <div className="row minimum-height-large" ></div>
          <div className="row">
           <div className="col-12 col-md-6 col-lg-6">
            <div className="row">
             <div className="col-12">
              <span className="services-title text-muted">CORPORATE </span> <span className="services-title text-purple">OVERVIEW</span>    
             </div>
             <div className="col-12 services-text my-3" align="justify">
              Jaam helps customers do business better by leveraging our industry
              wide experience, deep technology expertise, comprehensive portfolio
              of services. We are a trusted partner for global businesses looking
              to differentiate through technology interventions.
             </div>
             <div className="col-12 display-only-on-pc-tablet">
              <span className="services-logo-header">Consulting Partners 
              <img src={Chevronright} className="ml-2" alt="" />
              <img src={Aws} alt="" className="ml-4" width="40"/>
              <img src={Pega} alt="" className="ml-4" width="95"/>
              <img src={Oracle} alt="" className="ml-4" width="50"/>
              <img src={Mulesoft} alt="" className="ml-4" width="50"/>
              </span>
             </div>
             <div className="col-12 display-only-on-mobile mb-3">
              <span className="services-logo-header">Consulting Partners 
              <img src={Chevronright} className="ml-2" alt="" />
              </span>
             </div>
             <div className="col-12 display-only-on-mobile">
              <span>
              <img src={Aws} alt="" className="ml-4" width="40"/>
              <img src={Pega} alt="" className="ml-4" width="95"/>
              <img src={Oracle} alt="" className="ml-4" width="50"/>
              <img src={Mulesoft} alt="" className="ml-4" width="50"/>
              </span>
             </div>
            </div>                        
           </div>
           <div className="col-12 col-md-6 col-lg-6 mt-md-5 mt-lg-4">
            <div className="row content-center">
             <div className="col-12 mt-n3 mt-md-5 mt-lg-4 display-only-on-pc-tablet">
              <img src={Co} alt="" className="full-image" width="100%" />
             </div>
             <div className="col-12 mt-5 display-only-on-mobile">
              <img src={Co} alt="" className="full-image" width="100%" />
             </div>
            </div>
           </div>
          </div>
          <div className="row minimum-height-medium"></div>
         </div>
        </div>
       </div>
      </section>
      
      <section className="services-different services-white-bg-section">
       <div className="container">
        <div className="row content-center">
         <div className="col-lg-12">
         <div className="row minimum-height-medium"></div>
          <div className="row">    
           <div className="col-12 col-md-6 col-lg-6">
            <div className="row">
             <div className="col-12 mb-5">
              <span className="services-title text-muted">WHAT MAKES US </span> <span className="services-title text-purple">DIFFERENT</span>
             </div>
             <div className="col-11 col-md-12 col-lg-9 mt-0 mt-md-5 mt-lg-0 mb-5 mb-md-0 mb-lg-0 ml-3 ml-md-0 ml-lg-0">
              <img src={Different} alt="" width="100%" className="full-image"/>
             </div>
            </div>
           </div>
           <div className="col-12 col-md-6 col-lg-6 ml-n4 ml-md-0 ml-lg-0 mt-lg-4">
            <div className="col-12">
             <ul className="services-list">
              <li className="my-4 my-md-0 my-lg-0 mt-lg-5">
               <div className="col-12 mb-1">
                <span className="services-list-title"><img src={Agility} alt="" className="mr-3"/>Agility</span>    
               </div>
               <div className="col-12 mb-3 services-text services-list-margin" align="justify">
                Flexible engagement model around a deep understanding
                of its customers Global Engagement Model perfected for
                projects of all sizes.
               </div>
              </li>
              <li className="my-4">
               <div className="col-12 mb-1">
                <span className="services-list-title"><img src={Innovation} alt="" className="mr-3"/>Innovation</span>
               </div>
               <div className="col-12 mb-3 services-text services-list-margin" align="justify">
                Customer Centric Innovation model revolves
                around the customers and their needs.
               </div>
              </li>
              <li className="my-4">
               <div className="col-12 mb-1">
                <span className="services-list-title"><img src={Engineering} alt="" className="mr-3"/>Engineering Excellence</span>
               </div>
               <div className="col-12 mb-3 services-text services-list-margin" align="justify">
                Our engineering excellence is about delivering services
                with well-articulated business and user needs that our
                clients can be proud of.
                </div>
              </li>
             </ul>    
            </div>    
           </div>
          </div>
          <div className="row minimum-height-medium"></div>
         </div>
        </div>
       </div>
      </section>

      <section className="services-white-bg-section">
       <div className="container">
        <div className="row content-center">
         <div className="col-lg-12">
         <div className="row minimum-height-medium"></div>
         <div className="row">
          <div className="col-12 col-md-6 col-lg-6">
           <div className="col-12 mb-5">
            <span className="services-title text-muted">CLIENT </span> <span className="services-title text-purple">ENABLEMENT</span>
           </div>
           <div className="col-12 services-client-text mb-3">
             Patience &nbsp; | &nbsp; Transparent &nbsp; | &nbsp; Extra-Mile &nbsp;
           </div>
           <div className="col-12 services-text" align="justify">
            Our goal is to help our clients decide which strategic
            capabilities they want to build or strengthen in order
            to achieve sustainable competitive advantage. Also, we
            help our clients improve revenue, streamline business
            processes, reduce Go-To market time and IT operational
            costs by 30-40%.
           </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 mt-md-3 mt-lg-0">
           <div className="row">
           <div className="col-12 col-md-12 col-lg-10 mt-5 mt-md-5 mt-lg-5 ml-0 ml-md-0 ml-lg-5">
            <img src={Enable} alt="" width="100%"/>
           </div>
          </div>
         </div>
        </div>
        <div className="row minimum-height-medium"></div>
       </div>
      </div>
     </div>
    </section>

    <section className="services-purple-bg-section">
     <div className="container">
      <div className="row content-center">
       <div className="col-lg-10">
        <div className="row minimum-height-medium"></div>
         <div className="row">
          <div className="col-12 text-center mb-5">
           <span className="services-title text-service">OUR SERVICE</span> <span className="services-title text-white">PORTFOLIO</span>
          </div>
          <div className="col-12 col-md-12 col-lg-12">
           <img src={Portfolio} alt="" width="100%" />
          </div>
         </div>
         <div className="row minimum-height-medium"></div>
        </div>
       </div>
      </div>
     </section>
     
     <section className="services-white-bg-section">
      <div className="container">
       <div className="row content-center">
        <div className="col-lg-12">
        <div className="row minimum-height-medium"></div>
         <div className="row">
          <div className="col-12 text-center mb-5">
           <span className="services-title text-muted">TECHNOLOGY</span> <span className="services-title text-purple">OFFERINGS</span>
          </div>
          <div className="col-12 col-md-12 col-lg-12">
           <img src={Benefits} alt="" width="100%" />
          </div>
         </div>
         <div className="row minimum-height-large" ></div>
         </div>
        </div>
       </div>
      </section>

      <section id="CIWEB">
       <div className="row" id="CI">
        <img src={Qualityassurance} alt="" width="100%"/>
       </div>
      </section>

      <section className="services-white-bg-section">
       <div className="container">
        <div className="row content-center">
         <div className="col-lg-12">
          <div className="row minimum-height-large" ></div>
           <div className="row">
            <div className="col-12 mb-5">
             <span className="services-title text-muted">CONTINUOUS INTEGRATION </span> <span className="services-title text-purple" id="new-line-mobile">AND DELIVERY</span>    
            </div>
            <div className="col-12 col-md-6 col-lg-6">
             <div className="col-12 services-text ml-n3" align="justify">
              We focus on automating the software delivery process so 
              that teams can easily and confidently deploy their code 
              to production at any time. By ensuring that the codebase 
              is always in a deployable state, releasing software becomes 
              an unremarkable event without complicated ritual.
             </div> 
            </div>
            <div className="col-12 col-md-6 col-lg-6 mt-md-0 mt-lg-0" id="UIWEB">
             <div className="col-12 mt-md-5 mt-lg-0 display-only-on-pc-tablet">
              <img src={Delivery} alt="" width="100%" className="mt-md-2 mt-lg-0"/>
             </div>
             <div className="col-12 mt-5 display-only-on-mobile">
              <img src={Delivery} alt="" width="100%" id="UI"/>
             </div>
            </div>
           </div>
           <div className="row minimum-height-medium"></div>
          </div>
         </div>
        </div>
       </section>

       <section className="services-white-bg-section" >
        <div className="container">
         <div className="row content-center">
          <div className="col-lg-12">
          <div className="row minimum-height-medium"></div>
           <div className="row">
            <div className="col-lg-12">
             <div className="row">
              <div className="col-12 mb-5 text-md-center text-lg-center">
               <span className="services-title text-muted">UI/UX </span> <span className="services-title text-purple">CAPABILITY</span>
              </div>
              <div className="col-12 services-text" align="justify">
               Design thinking is a user-focused iterative process. Designers focus on understanding the user, defining the issues and 
               identifying new creative solutions through a process of creating and testing hypothesis. It’s important to note 
               that this is not a step-by-step process. Each phase can run in parallel to others and be repeated several times 
               throughout the process. Also, by definition, the process requires collaboration with people in other disciplines 
               and specialties who will contribute insights from their unique perspective and experience.
              </div>
              <div className="col-12 col-md-12 col-lg-12" id="MS">
               <div className="col-12 mt-5 mt-md-5 mt-lg-5">
                <img src={UIUX} alt="" width="105%" id="MSWEB"/>
               </div>
              </div>
             </div>
            </div>
           </div>
           <div className="row minimum-height-medium"></div>
          </div>
         </div>
        </div>
       </section>

       <section className="services-mulesoft services-white-bg-section">
        <div className="container">
         <div className="row content-center">
          <div className="col-lg-12">
           <div className="row minimum-height-medium"></div>
            <div className="row">
             <div className="col-12 mb-5">
              <span className="services-title text-purple">MULESOFT </span> <span className="services-title text-muted">SERVICES &amp; SOLUTIONS</span>
             </div>
            </div>
            <div className="row">
             <div className="col-12 col-md-6 col-lg-6">
              <div className="col-12 mb-3">
               <span className="services-client-text text-purple">Legacy Systems</span>
              </div>
              <div className="col-12 mt-md-5 mt-lg-0">
               <img src={Legacy} width="100%" className="mt-md-5 mt-lg-0" />
              </div>
             </div>
             <div className="col-12 col-md-6 col-lg-6 mt-3 mt-md-5 mt-lg-5">
              <div className="col-12 mb-5 services-mulesoft-text" align="justify">
               Jaam provides flexible solutions that will help you 
               simplify your overall architecture by removing point-to-point 
               integrations and application silos to obtain business agility 
               and thereby reduce IT costs.
               </div>
               <div className="col-12">
                <span className="services-client-text">Our Solution and Service Offerings:</span>
               </div>
               <div className="col-12"> 
                <ul className="services-mulesoft-list-text ml-n4">
                 <li className="mb-1"><span className="services-mulesoft-list-text">API Modernization</span></li>
                 <li className="mb-1"><span className="services-mulesoft-list-text">API Management</span></li>
                 <li className="mb-1"><span className="services-mulesoft-list-text">B2B Integration</span></li>
                 <li className="mb-1"><span className="services-mulesoft-list-text">Managed Services &amp; Support</span></li>
                 <li className="mb-1"><span className="services-mulesoft-list-text">Security</span></li>
                </ul>
               </div>
              </div>
             </div>
             <div className="row minimum-height-medium display-only-on-pc-tablet"></div>
            </div>
           </div>
          </div>
         </section>

         <div className="col-lg-12 services-horizontal-line"></div>

         <section className="services-mulesoft services-white-bg-section">
          <div className="container">
           <div className="row content-center">
            <div className="col-lg-12">
             <div className="row minimum-height-medium"></div>
              <div className="row">
               <div className="col-12 col-md-6 col-lg-6">
                <div className="col-12 mb-3">
                 <span className="services-client-text text-purple">Center 4 Enablement</span>
                </div>
                <div className="col-12 services-mulesoft-text mb-5" align="justify">
                 Center for Enablement (C4E) is an IT business model that 
                 enables organizations to create reusable resources, 
                 accumulate APIs, and improve best practices and knowledge 
                 to enable independent service and delivery in organizations 
                 and faster deployment of new solutions.
                </div>
                <div className="col-12 mb-3">
                 <span className="services-client-text text-purple">C4E Operating Model:</span>
                </div>
                <div className="col-12"> 
                 <ul className="services-mulesoft-list-text ml-n4">
                  <li className="mb-1"><span className="services-mulesoft-list-text">Reference Architecture Strategy</span></li>
                  <li className="mb-1"><span className="services-mulesoft-list-text">Certify Team, Training, Manage Product</span></li>
                  <li className="mb-1"><span className="services-mulesoft-list-text">Support &amp; Delivery Reusable API, Template, Patterns</span></li>
                  <li className="mb-1"><span className="services-mulesoft-list-text">Deployment &amp; Management, Migration  tools, Monitoring.</span></li>
                 </ul>
                </div>
               </div>
               <div className="col-12 col-md-6 col-lg-5 mt-md-5 mt-lg-5">
                <div className="col-12 mt-md-3 mt-lg-n3 mb-4 display-only-on-pc-tablet">
                 <img src={C4E} alt="" width="100%" className="mt-md-5 mt-lg-3"/>
                </div>
                <div className="col-12 mt-5 mb-4 display-only-on-mobile">
                 <img src={C4E} alt="" width="100%"/>
                </div>
                <div className="row minimum-height-small"></div>
                </div>

                <div className="col-12 ml-md-0">
                <div className="row minimum-height-small"></div>
                 <div className="row">
                  <div className="col-12 ml-md-1 ml-lg-1" id="JB">
                   <img src={Offerings} alt="" width="100%" className="full-image" id="JBWEB"/>
                  </div>
                 </div>
                </div>
               </div>
               <div className="row minimum-height-medium"></div>
              </div>
             </div>
            </div>
           </section>

          <section className="services-white-bg-section">
           <div className="container">
            <div className="row content-center">
             <div className="col-lg-12">
             <div className="row minimum-height-medium"></div>
              <div className="row">
               <div className="col-12 mb-5">
                <span className="services-title text-muted">OUR FRAMEWORK </span> <span className="services-title text-purple" id="new-line-mobile">- JAAM BANKING</span>
                 </div>
                 <div className="col-12 col-md-6 col-lg-6">
                  <div className="col-12 services-banking-text ml-n2">
                   <ul className="ml-n4">
                    <li className="mb-1"><span className="services-banking-text">Accounts &amp; Balances</span></li>
                    <li className="mb-1"><span className="services-banking-text">Transactions</span></li>
                    <li className="mb-1"><span className="services-banking-text">Money Transfers /BillPay /Zelle</span></li>
                    <li className="mb-1"><span className="services-banking-text">Check Deposit</span></li>
                    <li className="mb-1"><span className="services-banking-text">Location Services for Branch / ATM Locations</span></li>
                    <li className="mb-1"><span className="services-banking-text">Easy View Balance</span></li>
                    <li className="mb-1"><span className="services-banking-text">Secure REST based API integration with the Financial Institution back end systems</span></li>
                    <li className="mb-1"><span className="services-banking-text">Advanced Security Features</span></li>
                   </ul>
                  </div>
                 </div>
                 <div className="col-12 col-md-6 col-lg-5 mt-3 mt-lg-n1">
                  <div className="col-12 mt-md-5 mt-lg-0 display-only-on-pc-tablet">
                   <img src={Banking} alt="" width="100%" className="mt-md-5 mt-lg-0" />
                  </div>
                  <div className="col-12 pt-4 display-only-on-mobile">
                   <img src={Banking} alt="" width="100%" />
                  </div>
                 </div>
                </div>
                <div className="row minimum-height-medium"></div>
               </div>
              </div>  
             </div>
           </section>

           <section className="services-varied-white-bg-section">
            <div className="container">
             <div className="row content-center">
              <div className="col-lg-12">
              <div className="row minimum-height-medium"></div>
               <div className="row">
                <div className="col-12 mb-5">
                 <span className="services-title text-muted">OUR VALUE ADDED </span> <span className="services-title text-purple">PROPOSITION</span>
                </div>
                <div className="col-12 col-md-6 col-lg-6 mt-md-5 mt-lg-0">
                 <div className="col-12 mt-md-5 mt-lg-0 mb-5 mb-md-0 mb-lg-0">
                  <img src={Proposition} alt="" width="100%" />
                 </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 mt-4 mt-md-0 mt-lg-0 ml-3 ml-md-0 ml-lg-0">
                 <div className="col-12 services-banking-text">
                  <ul className="ml-n4 services-list">
                   <li className="mb-4 services-banking-text"><img src={Reduction} alt="" className="mr-4"/>Year Over Year cost reduction</li>
                   <li className="mb-4 services-indent services-banking-text"><img src={Reusable} alt="" className="mr-4"/>Develop and deploy reusable IPs and code base for common frameworks that accelerates development and eliminates repetition</li>
                   <li className="mb-4 services-indent services-banking-text"><img src={Highvalue} alt="" className="mr-4"/>Provide our preferred high value clients volume discounts</li>
                   <li className="mb-4 services-indent services-banking-text"><img src={Transition} alt="" className="mr-4"/>No impact to day to day business during the transition period and deliver a smooth transition from code to client</li>
                  </ul>    
                 </div>
                </div>
               </div>
               <div className="row minimum-height-medium"></div>
              </div>
             </div>  
            </div>
           </section>

           <section className="services-white-bg-section display-only-on-pc-tablet">
            <div className="container">
             <div className="row content-center">
              <div className="col-lg-12">
               <div className="row minimum-height-medium"></div>
                <div className="row">
                 <div className="col-12 text-center mb-5">
                  <span className="services-title text-muted">OUR </span> <span className="services-title text-purple">CLIENTS</span>
                 </div>
                 <div className="col-12 display-only-on-pc">
                  <div className="row justify-content-center">
                   <div className="col- mr-4 mt-2">
                    <img src={Tunedge} alt="" />
                   </div>
                   <div className="col- services-vertical-line mr-4"></div>
                    <div className="col- mr-4">
                     <img src={Cox} alt="" />
                    </div>
                    <div className="col- services-vertical-line mr-4"></div>
                    <div className="col- mr-4">
                     <img src={Sagitec} alt="" width="180px"/>
                    </div>
                    <div className="col- services-vertical-line mr-4"></div>
                    <div className="col- mr-4 mt-2">
                     <img src={Finplex} alt="" width="180px"/>
                    </div>
                    <div className="col- services-vertical-line mr-4"></div>
                    <div className="col- mr-4 mt-n2">
                     <img src={Fis} alt="" width="130px"/>
                    </div>
                   </div>
                  </div>
                  <div className="col-12 display-only-on-tablet display-only-on-landscape">
                  <div className="row justify-content-center">
                   <div className="col- mr-md-2 mt-2">
                    <img src={Tunedge} alt="" />
                   </div>
                   <div className="col- services-vertical-line mr-md-2"></div>
                    <div className="col- mr-md-2">
                     <img src={Cox} alt="" />
                    </div>
                    <div className="col- services-vertical-line mr-md-2"></div>
                    <div className="col- mr-md-2">
                     <img src={Sagitec} alt="" width="180px"/>
                    </div>
                   </div>
                   <div className="row mt-4 justify-content-center">
                    <div className="col- mr-md-2 mt-2">
                     <img src={Finplex} alt="" width="180px"/>
                    </div>
                    <div className="col- services-vertical-line mr-md-2"></div>
                    <div className="col- mr-md-2 mt-n2">
                     <img src={Fis} alt="" width="130px"/>
                    </div>
                   </div>
                  </div>
                 </div>
                <div className="row minimum-height-large" ></div>
               </div>
              </div>  
             </div>
            </section>

            <section className="services-white-bg-section display-only-on-mobile display-only-on-mobile-landscape">
            <div className="container justify-landscape-mobile">
             <div className="row content-center">
              <div className="col-lg-12">
               <div className="row minimum-height-medium"></div>
                <div className="row">
                 <div className="col-12 text-center mb-5">
                  <span className="services-title text-muted">OUR </span> <span className="services-title text-purple">CLIENTS</span>
                 </div>
                 <div className="col-12 my-3">
                  <div className="row d-flex justify-content-center">
                   <div className="col-5 d-flex justify-content-center">
                    <img src={Tunedge} alt="" width="85%"/>
                   </div>
                   <div className="col- services-vertical-line d-flex justify-content-center"></div>
                    <div className="col-6 d-flex justify-content-center">
                     <img src={Cox} alt="" width="80%"/>
                    </div>
                   </div>
                  </div>
                  <div className="col-12 my-3">
                  <div className="row d-flex justify-content-center">
                   <div className="col-5 d-flex justify-content-center">
                    <img src={Sagitec} alt="" width="85%"/>
                   </div>
                   <div className="col- services-vertical-line d-flex justify-content-center"></div>
                    <div className="col-6 d-flex justify-content-center">
                     <img src={Finplex} alt="" width="80%"/>
                    </div>
                   </div>
                  </div>
                  <div className="col-12 ml-n3">
                  <div className="row d-flex justify-content-center">
                    <div className="col-6 d-flex justify-content-center">
                     <img src={Fis} alt="" width="50%"/>
                    </div>
                   </div>
                  </div>
                 </div>
                <div className="row minimum-height-large" ></div>
               </div>
              </div>  
             </div>
            </section>
           </div>
    );
  }
}

export default Services;
