import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'lodash';
import Successtick from "../../../../assets/successtick.svg";
import { withRouter } from "react-router-dom";
import getSymbolFromCurrency from 'currency-symbol-map';

class CheckoutSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalState: true,
    };
  }

  handleShow() {
    const { history } = this.props;
    this.setState({ modalState: !this.state.modalState,
     });
    history.push('./billing');
  }

  render() {
    return (
      (this.props.subscription && this.props.subscription.plan && this.props.cusCurrency)? 
        <div className="container">
          <div className="row content-center">
              <div className={"modal fade" + (this.state.modalState ? " show d-block" : " d-none")} tabIndex="-1" role="dialog">
                  <div className="modal-dialog modal-dialog-centered" role="document">
                      <div className="modal-content">
                        <div className="modal-body payment-success">
                          <div className="text-center pay-cancel"><img src={Successtick}></img></div>
                          <div className="text-center payment-success-message">Thank you for your payment!</div>
                          <div className="text-center payment-success-confirm">we've emailed you a confirmation.</div>
                          <div className="text-center payment-success-plan my-2">Standard plan {getSymbolFromCurrency(this.props.cusCurrency)}&nbsp;{(this.props.subscription.plan.amount) / 100}</div>
                          <div className="text-center payment-success-plan my-2"> 1 host license </div>
                          <div className ="text-center">
                            <button type="button" className="btn btn-cancel text-center payment-success-dismiss-btn" onClick={() => this.handleShow()}>Dismiss</button>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      :""
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.session.user,
  subscription: state.session.subscription,
  cusCurrency: state.session.customerCurrency,
});

export default withTranslation()(withRouter(connect(mapStateToProps)(CheckoutSuccess)));
