import { combineReducers, createStore, applyMiddleware } from 'redux';
import { routerReducer, syncHistoryWithStore, routerMiddleware } from 'react-router-redux';
// import { reducer as formReducer } from 'redux-form';
import { browserHistory } from 'react-router-v3';
import reduxThunk from 'redux-thunk'

import sessionReducer from './common/session/sessionReducer';
import initialState from './common/initialState';

const rootReducer = combineReducers({
    // form: formReducer,
    session: sessionReducer,
    routing: routerReducer,
});

const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(reduxThunk, routerMiddleware(browserHistory))
);
export const history = syncHistoryWithStore(browserHistory, store);

export default store;
