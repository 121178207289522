import React from "react";
import SocialLogin from "react-social-login";

class SocialButton extends React.Component {
  render() {
    return (
      <button
        type="button"
        onClick={this.props.triggerLogin}
        {...this.props}
        className="col-md-8 btn btn-default btn-lg text-center"
      >
        {this.props.children === "Signup with Facebook" ||
        this.props.children === "Signin with Facebook" ? (
          <i className="fa fa-facebook text-primary"></i>
        ) : (
          ""
        )}
        {this.props.children === "Signup with Google" ||
        this.props.children === "Signin with Google" ? (
          <i className="fa fa-google text-danger"></i>
        ) : (
          ""
        )}
        {this.props.children}
      </button>
      // <button onClick={this.props.triggerLogin} {...this.props}>
      //   { this.props.children }
      // </button>
    );
  }
}

export default SocialLogin(SocialButton);
