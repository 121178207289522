import qs from "qs";
import axios, { post } from "axios";

let API_URL = window.location.hostname;

let APP_URL = window.location.hostname;

let WEB_URL = window.location.hostname;

let AWS_KEY,SECRET_KEY,REGION,BUCKET_NAME,S3_URL,STRIPE_PK_KEY,STRIPE_PKIN_KEY;

API_URL = process.env.REACT_APP_API_URL;
APP_URL = process.env.REACT_APP_APP_URL;
WEB_URL = process.env.REACT_APP_WEB_URL;

STRIPE_PK_KEY = process.env.REACT_APP_STRIPE_PK_KEY;
STRIPE_PKIN_KEY = process.env.REACT_APP_STRIPE_PKIN_KEY;

AWS_KEY = process.env.REACT_APP_ACCESS_KEY;
SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
REGION = process.env.REACT_APP_REGION;
BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;
S3_URL = process.env.REACT_APP_S3_URL;



function importAll(r) {
  return r.keys().map(r);
}
export const funImagesOriginal = importAll(
  require.context("../assets/funlovers/Original", false, /\.(png|PNG|jpe?g)$/)
);
export const businessImagesOriginal = importAll(
  require.context(
    "../assets/businessclassic/Original",
    false,
    /\.(png|PNG|jpe?g)$/
  )
);
export const funImages = importAll(
  require.context("../assets/funlovers", false, /\.(png|PNG|jpe?g)$/)
);

export const businessImages = importAll(
  require.context("../assets/businessclassic", false, /\.(png|jpe?g)$/)
);

export const defaultTheme = "fun";

export const defaultThemeImage = funImagesOriginal[8];

export const api = { API_URL: API_URL, APP_URL: APP_URL, WEB_URL: WEB_URL, AWS_KEY: AWS_KEY, SECRET_KEY: SECRET_KEY, REGION: REGION, BUCKET_NAME: BUCKET_NAME, S3_URL: S3_URL, STRIPE_PK_KEY: STRIPE_PK_KEY ,STRIPE_PKIN_KEY: STRIPE_PKIN_KEY};

export const trial_period = 30; //days
// Action types
export const createRequestTypes = (base) => {
  const res = {};
  ["REQUEST", "SUCCESS", "FAILURE"].forEach((type) => {
    res[type] = `${base}_${type}`;
  });
  return res;
};

// Dynamic action creator
export const createPostFunc = (api, actionType, data) => (dispatch) => {
  var encodedURI = window.encodeURI(`${API_URL}/${api}`);
  dispatch({ type: actionType.REQUEST });
  axios
    .post(encodedURI, qs.stringify(data))
    .then((response) => {
      dispatch({ type: actionType.SUCCESS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: actionType.FAILURE, payload: error.response });
    });
};

export const createGetFunc = (api, actionType) => (dispatch) => {
  var encodedURI = window.encodeURI(`${API_URL}/${api}`);
  dispatch({ type: actionType.REQUEST });
  axios
    .get(encodedURI)
    .then((response) => {
      dispatch({ type: actionType.SUCCESS, payload: response.data.data });
    })
    .catch((error) => {
      dispatch({ type: actionType.FAILURE, payload: error.response.data });
    });
};
