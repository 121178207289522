import React, { Component } from "react";
import { Link } from "react-router-dom";
import jaamlogo from "../assets/jaamlogo.svg";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { getStorage, clearStorage } from "../common/localStorage";
import { getUser, signOut,setShowProfile } from "../common/session/sessionAction";
import { push } from "react-router-redux";
import _ from "lodash";
import $ from "jquery";
import { withTranslation } from "react-i18next";

class NavBar extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   showProfile: false,      
    // };
  }
  state = {};

  componentDidMount() {
 
    $(".navbar-nav li").click(function (event) {
      // check if window is small enough so dropdown is created
      var toggle = $(".navbar-toggler").is(":visible");
      if (toggle) {
        $(".navbar-collapse").collapse("hide");
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.user &&
      !_.isEmpty(nextProps.user) &&
      !_.isEqual(this.props.user, nextProps.user)
    ) {
      this.props.dispatch(setShowProfile(true));
      // this.setState({ showProfile: true });
    }
  }

  componentWillMount() {
    if (this.props.token === undefined && getStorage("token")) {
      this.props.dispatch(getUser({ token: getStorage("token") }));
    }
  }

  signOut() {
    // this.setState({ showProfile: false });
    this.props.dispatch(setShowProfile(false));
    this.props.dispatch(signOut());
  }
  
  render() {
    const { t,showprofile } = this.props;
    // console.log(showprofile,"showprofile from navbar");
    // const { showProfile } = this.state;
    
    const fullname=`${this.props.user.firstname} ${this.props.user.lastname ? this.props.user.lastname : '' }`;
    const full_name =fullname.length > 15 ? `${fullname.substring(0, 15)}...` : fullname;
    const account_name=`${full_name} (Account)`;
    const profile_url = `${this.props.user.profileurl ? this.props.user.profileurl : '' }`;
    // console.log('URL Nav',profile_url);
    
    return (
      <nav className="navbar navbar-default bg-white fixed-top shadow-sm">
        <div className="navbar navbar-expand-lg container">
          <a className="navbar-brand text-purple ml-n3 my-n1" href="/home">
            <img src={jaamlogo} width="125" height="50" alt=""></img>
          </a>
          <div className="d-flex order-2 order-lg-3">
            <ul className="nav flex-row">
              {showprofile ? (
                <React.Fragment>
                  <Link to="/join" className="nav-link nav-link-fade-up">
                    <li id="join" className="nav-item mt-n1 text-purple mx-1">{t("navbar.join")}</li>
                  </Link>
                  &nbsp;&nbsp;&nbsp;&nbsp;                                   
                  <div className="dropdown">                   
                    <li id="myaccount" className="nav-link mt-n1 dd_pointer d-none d-sm-block text-purple"  data-toggle="dropdown" aria-expanded="false">
                        My Account                    
                    </li>                                 
                    <div className="dropdown-menu dropdown-menu-right">                  
                      <Link to="/landing"  >
                          <li className="dd-text capitalize" >     
                           {account_name}                                                                 
                          </li>
                      </Link>                    
                      <div className="dropdown-divider"></div>                      
                      <Link to="/home" >
                        <li  className="dd-text" onClick={() => this.signOut()}>
                          {t("navbar.signout")}
                        </li>
                      </Link>                    
                    </div>
                  </div>
                  <Link to="/landing">
                    <li className="mx-2 mx-md-0">
                      <div
                        className="imageDice"
                        style={{
                          height: "40px",
                          width: "40px",
                        }}
                      >
                        <img src={profile_url} alt=""></img>
                      </div>
                    </li>
                  </Link>                                  
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Link to="/join" className="nav-link nav-link-fade-up">
                    <li id="join" className="nav-item text-purple mt-n1 mx-1">{t("navbar.join")}</li>
                  </Link>
                  <Link to="/signin" className="nav-link nav-link-fade-up">
                    <li id="signin" className="nav-item text-purple mt-n1 mx-1"> {t("navbar.signin")}</li>
                  </Link>
                  <Link
                    to="/signup"
                    className="btn btn-purple btn-sm my-2 mt-lg-1 d-none d-sm-block"
                  >
                    <li id="tryfor" className="nav-item mx-2 mx-lg-1">
                      {t("navbar.tryforfree")}
                    </li>
                  </Link>
                </React.Fragment>
              )}
            </ul>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavDropdown"
            >
              <span className="fa fa-bars"></span>
            </button>
          </div>
          <div
            className="collapse navbar-collapse order-3 order-lg-2"
            id="navbarNavDropdown"
          >
            <ul className="navbar-nav mr-auto">                        
              <div className="border-top border-secondary my-3 d-block d-sm-none"></div>
              <Link to="/productfeatures" className="nav-link nav-link-fade-up">
                <li id="profeat" className="nav-item mx-0 mx-lg-1 justify-content-center">
                  {t("navbar.productfeatures")}
                </li>
              </Link>
              <div className="border-top border-secondary my-3 d-block d-sm-none"></div>
              <Link to="/services" className="nav-link nav-link-fade-up">
                <li id="serviceson" className="nav-item mx-0 mx-lg-1 justify-content-center">
                  {t("navbar.services")}
                </li>
              </Link>
              <div className="border-top border-secondary my-3 d-block d-sm-none"></div>
              <Link to="/pricing" className="nav-link nav-link-fade-up">
                <li id="priicing" className="nav-item mx-0 mx-lg-1">{t("navbar.pricing")}</li>
              </Link>
              <div className="border-top border-secondary my-3 d-block d-sm-none"></div>
              <Link
                to="/partnership"
                className="nav-link nav-link-fade-up mb-2">
                <li  id="partner" className="nav-item mx-0 mx-lg-1">
                  {t("navbar.partnership")}
                </li>
              </Link>
              <div className="border-top border-secondary my-3 d-block d-sm-none"></div>
              <Link to="/contact" className="nav-link nav-link-fade-up mb-2">
                <li id="cowntact" className="nav-item mx-0 mx-lg-1">
                  {t("navbar.contactsales")}
                </li>
              </Link>
              <div className="border-top border-secondary my-3 d-block d-sm-none"></div>
              <div className="d-flex justify-content-center">
              {showprofile ? (                
                <Link to="/landing" className=" my-2 d-block d-sm-none capitalize">
                  <li className="nav-item mx-0 mx-lg-1">
                    {account_name}
                  </li>                                                
                </Link>
              ):(
                <Link
                  to="/signup"
                  className="btn btn-purple btn-sm col-6 my-2 d-block d-sm-none"
                >
                  <li className="nav-item ">{t("navbar.tryforfree")}</li>
                </Link>
              )}
              </div>
              {showprofile && <div className="border-top border-secondary my-3 d-block d-sm-none"></div> }
              {showprofile ? (
                <div className="d-flex justify-content-center">             
                  <Link to="/signup" className="btn btn-purple btn-sm col-6 my-2 d-block d-sm-none">
                    <li className="nav-item " onClick={() => this.signOut()}>
                      {t("navbar.signout")}
                    </li>
                  </Link>                            
                </div>
              ):('')
              }
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  token: state.session.token,
  user: state.session.user,
  showprofile: state.session.showprofile
});

export default withTranslation()(withRouter(connect(mapStateToProps)(NavBar)));
