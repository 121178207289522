import AWS from "aws-sdk";
import { api } from "../common/api";

const UploadFileInCloud = (files, mimeType, bucketName) => {
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'us-east-1:f0f11a44-a990-451e-bf0e-b852c068770b',
    });
    AWS.config.update({
        bucketName: api.BUCKET_NAME,
        region: api.REGION,
        accessKeyId: api.AWS_KEY,
        secretAccessKey: api.SECRET_KEY
    });

    var bucket = new AWS.S3({
        apiVersion: '2012-10-17',
        params: {
            Bucket: bucketName
        }
    });
    if (files !== null) {
        const fileName = files.name;
        const blob = new Blob([files], { type: mimeType });
        var filePath = fileName;
        bucket.upload({
            Key: filePath,
            Body: blob,
            ACL: 'public-read'
        }, function (err, data) {
            if (err) {
                console.log(err);
            }

        });
    }
}
export default UploadFileInCloud;