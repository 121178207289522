import React, { Component } from 'react';
import {  trial_period } from "../../common/api";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

const EXPIRED_VALUE = "expired";
const TRIAL_VALUE = "trial";

class trialCounter extends Component {
  render() {
    const { user } = this.props;
    const  createdDate = new Date(user.createdTime);
    const currentDate = new Date();
    const diffTime = Math.abs(currentDate - createdDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    
    let statusData = {}
    let upgradeac =false;
    if(user.userType === TRIAL_VALUE)
    {
    upgradeac=true;
    statusData = {
        type: 'error',
        message: `You have ${trial_period - diffDays} days to enjoy the free trial?`
        }
    } else if(user.userType === EXPIRED_VALUE){
    upgradeac=true;
    statusData = {
        type: 'error',
        message: 'Your free trial/subscription has expired.'
    }
    }
    return (
        <div>
          { upgradeac && 
              <div className="row">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-lg-10 col-xl-8">
                  <div className="row mt_4">
                    <div className="col-1"></div>
                    <div className="col-10 upgradeac form-inline my-2">
                    <span className="statusData mx-auto my-0">{statusData && statusData.message}</span> &nbsp;
                    <div className="showxs">&nbsp;</div>
                    <Link to="/landing/billing">
                      <button className="btn btn-purple btn-lg upgradenow">Upgrade Now</button>
                    </Link>
                    </div>
                    <div className="col-1"></div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        )
    }
}
const mapStateToProps = (state, ownProps) => ({
    user: state.session.user
  });
export default withRouter(connect(mapStateToProps)(trialCounter));
