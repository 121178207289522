import React, { Component } from 'react';
import { render } from "react-dom";
import Userguide from'./userguide';
import Videos from'./videos';
import Faq from'./faq';

class Support extends Component {
  
  constructor(props) {
    super(props) 

    this.state = {
      profile: {},
      statusData: {},
      name: "React",     
      showHideUserguide: true,  
      showHideVideo: false,
      showHideFaq: false    
    };
    this.hideComponent = this.hideComponent.bind(this);
    window.scrollTo(0, 0);
  }
  hideComponent(name) {
    switch (name) {
      case "showHideUserguide":        
        this.setState({showHideUserguide:true});
        this.setState({showHideVideo:false});  
        this.setState({showHideFaq:false});    
        break;
      case "showHideVideo":
        this.setState({showHideUserguide:false});
        this.setState({showHideVideo:true});
        this.setState({showHideFaq:false});     
        break;           
      case "showHideFaq":
          this.setState({showHideUserguide:false});
          this.setState({showHideVideo:false});
          this.setState({showHideFaq:true})      
          break;
      default:
       return null       
    }
  }
  render() {
    const { showHideUserguide, showHideVideo, showHideFaq} = this.state;
    const renderAuthButton = ()=>{
      if(showHideUserguide){    
        return <Userguide><h4>User Guide</h4></Userguide>
      } else if(showHideVideo){      
        return <Videos><h4>Videos</h4></Videos>
      }
      else{
        return <Faq><h4>FAQ</h4></Faq>
      }
    }
    const userguideClassName = showHideUserguide ? 'arrow_box' : 'non_active_support';
    const videosClassName = showHideVideo ? 'arrow_box' : 'non_active_support';
    const faqClassName = showHideFaq ? 'arrow_box' : 'non_active_support';
    return (
      <section className="background">
        <div className="continer support-container-margin">
         
              <div className="row">
                <div className="col-lg col-md col-sm">
                  <div className="_support_banner"><br></br><h2 className="supportjaam">Support Jaam</h2></div>                                  
                </div>
              </div>
        </div>
        <div className="container support-container-decoration">
              <div className="row">
                <div className="col-md col-sm">
                  <br></br>
                 <p className="hello"> Hello, Get Support at anytime</p>                 
                </div>               
              </div>
              <center>
              <div className="row">
                <div className="input-group col-md col-sm">             
                  <input type="text" className="form-control rectangle151" placeholder="How can we help ?"/>
                   <div className="input-group-append">
                     <button className="btn btn-primary rectangle151" type="button">
                        <i className="fa fa-search"></i>
                       </button>
                    </div>                 
                </div>
              </div>
              <br></br><br></br>
              <div className="row content-center">
                <div className="col-sm-4">
                  <div className={userguideClassName} onClick={() => this.hideComponent("showHideUserguide")}><br></br><center>User Guide</center></div>
                </div>
                <div className="col-sm-4">
                  <div className={videosClassName} onClick={() => this.hideComponent("showHideVideo")} ><br></br><center>  Videos</center></div>
                </div>
                <div className="col-sm-4">
                  <div className={faqClassName} onClick={() => this.hideComponent("showHideFaq")}><br></br><center>FAQ</center></div>
                </div>
              </div></center>
              <br></br><br></br>
              <div className="row">
                <br></br>                                
                {renderAuthButton()}
                
                
              </div>
            </div>
         
      </section>
    );
  }
}

export default Support;
