import React, { Component } from "react";
import { Link } from "react-router-dom";
import Boxof404 from '../assets/404box.svg';
class PageNotFound extends Component {
  render() {
    return (
      <section className="background">
        <div className="container container-decoration">
          <div className="row content-center">
            <div className="col-xl-8">
              <div className="row justify-content-md-center pagenotfound">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col">
                      <img src={Boxof404} alt="Box" className="pagenotfound_box" />                     
                    </div>  
                    <div className="col">
                      <div className="pagenotfound_text">404</div>
                    </div>                  
                  </div>
                  <div className="row mt-4">                    
                    <div className="col pagenotfound_head">Oops!</div>               
                  </div>
                  <div className="row">                    
                    <div className="col pagenotfound_body">
                      Looks like something was wrong <br></br>we’re working on it.
                    </div>               
                  </div>
                  <div className="row mt-4 mb-4 mx-auto">
                    <Link to="/home"  >
                      <button
                        className="col-md-6 col-lg-6 btn btn-purple pagenotfound_btn"                                          
                       >
                        Back To Home
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default PageNotFound;
