import http from "./httpService";
import { api } from "../common/api";

let API_URL = api.API_URL;
const apiEndPoint = `${API_URL}/feedback/`;

export const saveFeedback = (formProps) => {
  const body = { ...formProps };
  var encodedURI = window.encodeURI(apiEndPoint);
  return http.post(encodedURI, body);
};
