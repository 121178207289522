import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { month_Interval, year_Interval } from '../../../../common/constants';
import getSymbolFromCurrency from 'currency-symbol-map';

class BoxPlans extends Component {
    constructor(props) {
        super(props);
    }

    render() {         
        const price = this.props.boxPrice ;
        
        return (           
            <div className="row ml-1 my-2">
                <div className="col-12">
                    <div className="row">
                        {price.priceInterval === month_Interval &&  
                        <div className="col-12 mt-2 box-plan-text">Monthly</div>
                        }
                        {price.priceInterval === year_Interval &&  
                        <div className="col-6 mt-2 box-plan-text">Annual</div>
                        }
                        {price.priceInterval === year_Interval &&  
                        <div className="col- mt-2 px-2 percentdiscount">      
                         Save 10%                 
                        </div>
                        } 
                    </div>
                    <div className="row mb-1 mt-2">
                        {price.priceInterval === month_Interval &&
                        <div className="offset-1 col- box-plan-currency-value">{getSymbolFromCurrency(price.priceCurrency)}&nbsp;{price.priceUnitAmount / 100}</div>               
                        }
                        {price.priceInterval === year_Interval &&
                        <div className="offset-1 col- box-plan-currency-value">{getSymbolFromCurrency(price.priceCurrency)}&nbsp;{Math.round((price.priceUnitAmount / 1200) * 100 + Number.EPSILON)/100}</div>               
                        }
                        <div className="col-5 mt-2 box-plan-per-value">
                            <div className="row">
                            <div className="col-12">per host</div>
                            <div className="col-12">per month</div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-1">
                        {price.priceInterval === month_Interval &&  
                        <div className="col-12 box-plan-billed-yearly">&nbsp;</div>
                        }
                        {price.priceInterval === year_Interval &&  
                        <div className="col-12 box-plan-billed-yearly">Billed {getSymbolFromCurrency(price.priceCurrency)} {price.priceUnitAmount / 100} Annually</div>
                        }
                    </div>
                </div>
            </div> 
        )
    };
}

const mapStateToProps = (state, ownProps) => ({
    user: state.session.user,
    allprices: state.session.allpricesList
  });

export default withTranslation()(connect(mapStateToProps)(BoxPlans));