import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import { UploaderComponent } from "@syncfusion/ej2-react-inputs";
import { withRouter } from "react-router-dom";
import drag from "../assets/drag.svg";
import StatusHelper from "../common/helpers/statusHelper";
import { saveFeedback } from "../services/feedbackService";
import SelectElem from "../common/elements/select";
import TextAreaElem from "../common/elements/textArea";
import { isEmpty } from "../util/utilities";    
import Rating from '@material-ui/lab/Rating';
import { api } from "../common/api";
import { updateMeeting } from "../common/session/sessionAction";
import { isNull } from "lodash";

//Jaam code import

import UploadFileInCloud from "./uploadingFilesInCloud";

const feedbackOptions = [
  { _id: "9", name: "Select an option" },
  { _id: "1", name: "I like about" },
  { _id: "2", name: "Need to improve upon" },
  { _id: "3", name: "I have an idea/suggestion" },
  { _id: "4", name: "I have generic comments" },
];
const deviceOptions = [
  { _id: "15", name: "Select an option" },
  { _id: "5", name: "iPad" },
  { _id: "6", name: "iPhone" },
  { _id: "7", name: "Windows" },
  { _id: "8", name: "Mac" },
  { _id: "10", name: "Linux" },
  { _id: "11", name: "Android" },
  { _id: "12", name: "BlackBerry" },
  { _id: "13", name: "Room Systems" },
  { _id: "14", name: "Web" },
];


const config = {
  bucketName: api.BUCKET_NAME,
  region: api.REGION,
  accessKeyId: api.AWS_KEY,
  secretAccessKey: api.SECRET_KEY
}

const S3URL = api.S3_URL

class Feedback extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.state = {
      data: {
        rating:"",
        feedbackAbout: "",
        deviceAbout:"",
        feedback: "",
        uploader:"",
        email: "",
      },
      flag:false,
      errors: {},
      recaptchaRef: React.createRef(),
      statusData: {},
    };
  }
  statusHelper = new StatusHelper(this);
  state = {};

  validateForm = () => {
    let isValid = true;
    const { data } = this.state;
    const errors = {};
    if (
      data["feedbackAbout"] === "" ||
      data["feedbackAbout"] === "Please select an option"
    ) {
      errors["feedbackAbout"] = "Please select and option";
    }
    
    if (
      data["deviceAbout"] === "" ||
      data["deviceAbout"] === "Please select an option"
    ) {
      errors["deviceAbout"] = "Please select and option";
    }

    if (isEmpty(data["feedback"])) {
      errors["feedback"] = "Please add some feedback";
    }


    if (!this.state.recaptchaRef.current.getValue()) {
      errors["captcha"] = "Captcha selection is required";
    }

    this.setState({ errors: errors || {} });

    if (Object.keys(errors).length > 0) {
      isValid = false;
    } else {
      isValid = true;
    }

    return isValid;
  };

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };



//Jaam code start

  changeInput=(e)=>{
    const flag = {...this.state.flag};
   if(e.target.files[0]!=null){
    this.state.data.uploader = S3URL+e.target.files[0].name;
   this.setState({flag:true});  
   }
     else{
    this.setState({flag:false});
      this.state.data.uploader ="";
     }
}
  
  doSubmit = async (e) => {
    e.preventDefault();
    this.setState({ statusData: {} });

    const isFormValid = this.validateForm();

    if (!!isFormValid) {
      try {
        const { data } = this.state;
        await saveFeedback(data);
        const statusData = {
          type: "success",
          message:
            "Thanks for your valuable feedback",
        };
        this.setState({ statusData });
        this.setState({
          data: {
            rating:"",
            feedbackAbout: "",
            deviceAbout:"",
            feedback: "",
            uploader:"",
            email: "",
          },
        });
        document.getElementById("feedbackform").reset();
        this.state.recaptchaRef.current.reset();
      } catch (ex) {
        const statusData = {
          type: "error",
          message: "Something went wrong. Please try after some time.",
        };
        this.setState({ statusData });
        this.state.recaptchaRef.current.reset();
      }
    }
  };

  render() {
    const { data, errors } = this.state;
    const {user} = this.props;
    const isLoggedIn = true;
    console.log(this.state.flag);    
    return (
      <section className="background">
        <div className="container container-decoration">
          <div className="row content-center">
            <div className="col-xl-9 col-12">
              <div className="row">
                <div className="col-lg-12">
                  <h2 className="text-center">We welcome your feedback</h2>
                </div>
              </div>
              <form id="feedbackform" onSubmit={this.doSubmit}>
                <div className="row mb-4">
                  <div className="col-lg-12 text-center">
                    <p className="showmd">
                      Whether it is a compliment, complaint or comment it is
                      important to us, <br/>and can help us to provide a better
                      service for you and for others.
                    </p>
                    <p className="showxs">
                      Whether it is a compliment, complaint or comment it is
                      important to us, and can help us to provide a better
                      service for you and for others.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <p>
                      Your opinion is important to us. How would you rate our app?
                    </p>
                  </div>
                  <div className="col-lg-12 text-center">
                  <Rating
                    name="rating"
                    value={data["rating"]}
                    onChange={this.handleChange}
                  />               
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="row mt-3">
                      <div className="col-sm-6 col-md-6 col-lg-6">
                        <SelectElem
                          name="feedbackAbout"
                          value={data["feedbackAbout"]}
                          label="What would you like to tell us about?"
                          options={feedbackOptions}
                          onChange={this.handleChange}
                          error={errors["feedbackAbout"]}
                          className="form-control form-control-lg select-purple"
                        />
                      </div>
                      <div className="col-12 showxs mt-3"></div>
                      <div className="col-sm-6 col-md-6 col-lg-6">
                        <SelectElem
                          name="deviceAbout"
                          value={data["deviceAbout"]}
                          label="Device Type?"
                          options={deviceOptions}
                          onChange={this.handleChange}
                          error={errors["deviceAbout"]}
                          className="form-control form-control-lg select-purple"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-lg-12">
                    <TextAreaElem
                      name="feedback"
                      autoFocus={false}
                      label="Tell us more. And thank you. "
                      value={data["feedback"]}
                      onChange={this.handleChange}
                      rows="4"
                      placeholder="Please share your feedback here"
                      error={errors["feedback"]}
                      className="form-control form-control-lg"
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-lg-12">
                    <label htmlFor="email">If you want us to get in touch with you, please give your e-mail address.</label>
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      id="email"
                      name="email"
                      placeholder="email.sample@email.com"
                      value={data["email"]}
                      onChange={this.handleChange}
                    ></input>
                    <span className="errorMsg errormessage">
                      {this.state.errors.email}
                    </span>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-lg-12"><label htmlFor="">Upload files (Optional)</label></div>
                  <div className="col-lg-12 text-center">
                    <div className="text-center newupload" id="droparea">
                      <img src={drag} className="mt-4 text-center" alt=""/>
                      <br/>  
                      <span className="drag">
                        Drag and Drop
                        <br/>
                        <div className="fileupload">
                        {/* Jaam code starts*/}
                        <input type="file" className="fileupload_left" accept="image/*" onChange={(e) => UploadFileInCloud(e.target.files[0],"image/jpeg","jaamfeedbackimages")} onInput={this.changeInput}   id="uploader"  />
                        {/* Jaam code ends */}
                        <input type="reset" className={this.state.flag ? "cancel_visible" : "cancel_hidden"} value="Cancel" id="pseudoCancel" onClick={() => this.setState({flag: !this.state.flag})}/>
                        </div>
                      </span>
                      <br/><br/>
                      </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-lg-12">
                    <div className="col-md-12">
                      <ReCAPTCHA
                        ref={this.state.recaptchaRef}
                        sitekey="6LeEw70ZAAAAAOls3_hqqsFwsNXdn46tXQIZgYt1"
                        onChange={this.onReCaptchaSubmit}
                      />
                      <span className="errormessage">
                        {this.state.errors.captcha}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row mt-4 mb-3 mx-auto">
                  <button className="col-lg-12 btn btn-purple btn-lg">
                    Submit
                  </button>
                </div>
                {Object.keys(this.state.statusData).length > 0 &&
                  this.statusHelper.renderStatus(this.state.statusData)}
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.session.user,
  //isLoggedIn: state.session.isLoggedIn,
});

export default (withRouter(connect(mapStateToProps)(Feedback)));
