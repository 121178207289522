import React from "react";

export const CheckBox = (props) => {
  return (
    <li className="list-group-item">
      <input
        id={props.value}
        name="vendor"
        className="larger-checkbox"
        key={props.id}
        onChange={props.handleCheckChieldElement}
        type="checkbox"
        checked={props.isChecked}
        value={props.value}
      />{" "}
      <label htmlFor={props.value}>&nbsp;{props.value}</label>
    </li>
  );
};

export default CheckBox;
