import React, { Component } from "react";
import { Link } from "react-router-dom";
import jaamlogo_reverse from "../assets/jaamlogo_reverse.svg";
import linkedin from "../assets/linkedin.svg";
import facebook from "../assets/facebook.svg";
import twitter from "../assets/twitter.svg";
import instagram from "../assets/instagram.svg";
import youtube from "../assets/youtube.svg";
import "../css/footer.css";

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer-bs">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-3 text-center text-md-left">
                <a className="navbar-brand text-center ml-n2" href="/home">
                  <img
                    src={jaamlogo_reverse}
                    width="125"
                    height="50"
                    alt=""
                  ></img>
                </a>
                <div className="border-top border-secondary my-3 d-block d-md-none"></div>
              </div>
              <div className="col-12 col-md-9 footer-nav">
                <div className="row">
                  <div className="col-sm-1"></div>
                  <div className="col-12 col-sm-5">
                    <ul className="pages text-center text-sm-left">
                      <Link to="/productfeatures" className="nav-link">
                        <li className="list-item">Product Features</li>
                      </Link>
                      <div className="border-top border-secondary my-3 d-block d-sm-none"></div>
                    </ul>
                  </div>
                  <div className="col-12 col-sm-5">
                    <ul className="pages text-center text-sm-left">
                      <Link to="/services" className="nav-link">
                        <li className="list-item">Services</li>
                      </Link>
                      <div className="border-top border-secondary my-3 d-block d-sm-none"></div>
                    </ul>
                  </div>
                </div>
                <div className="row mt-md-n3">
                  <div className="col-sm-1"></div>
                  <div className="col-12 col-sm-5">
                    <ul className="pages text-center text-sm-left ">
                      <Link to="/supportpage" className="nav-link">
                        <li className="list-item">Support</li>
                      </Link>
                      <div className="border-top border-secondary my-3 d-block d-sm-none"></div>
                    </ul>
                  </div>
                  <div className="col-12 col-sm-5">
                    <ul className="pages text-center text-sm-left">
                      <Link to="/pricing" className="nav-link">
                        <li className="list-item">Pricing</li>
                      </Link>
                      <div className="border-top border-secondary my-3 d-block d-sm-none"></div>
                    </ul>
                  </div>
                </div>
                <div className="row mt-md-n3">
                  <div className="col-sm-1"></div>
                  <div className="col-12 col-sm-5">
                    <ul className="pages text-center text-sm-left">
                      <Link to="/contact" className="nav-link">
                        <li className="list-item">Contact Sales</li>
                      </Link>
                      <div className="border-top border-secondary my-3 d-block d-sm-none"></div>
                    </ul>
                  </div>
                  <div className="col-12 col-sm-5">
                    <ul className="pages text-center text-sm-left">
                      <Link to="/about" className="nav-link">
                        <li className="list-item">About Jaam</li>
                      </Link>
                      <div className="border-top border-secondary my-3 d-block d-sm-none"></div>
                    </ul>
                  </div>
                </div>
                <div className="row mt-md-n3">
                  <div className="col-sm-1"></div>
                  <div className="col-12 col-sm-5">
                    <ul className="pages text-center text-sm-left">
                      <Link to="/partnership" className="nav-link">
                        <li className="list-item">Partnership</li>
                      </Link>
                      <div className="border-top border-secondary my-3 d-block d-sm-none"></div>
                    </ul>
                  </div>
                  <div className="col-12 col-sm-5">
                    <ul className="pages text-center text-sm-left">
                      <Link to="/feedback" className="nav-link ">
                        <li className="list-item">Feedback</li>
                      </Link>
                      <div className="border-top border-secondary my-3 d-block d-sm-none"></div>
                    </ul>
                  </div>
                </div>
                <div className="row mt-md-n3">
                  <div className="col-sm-1"></div>     
                <div className="col-12 col-sm-5">
                    <ul className="pages text-center text-sm-left">
                      <a href="https://jaam-careers.webflow.io/" className="nav-link">
                        <li className="list-item">Careers</li>
                      </a>
                      <div className="border-top border-secondary my-3 d-block d-sm-none"></div>
                    </ul>
                  </div>
                  </div>
              </div>
            </div>
            <div className="border-top border-secondary my-3 d-none d-sm-block d-md-none"></div>
            <div className="row">
              <div className="col-12 col-md-3 text-center text-md-right ml-md-n5 my-3 my-sm-0 order-2 order-sm-1">
                <a
                  href="https://www.linkedin.com/company/jaam-ct"
                  target="_blank"
                >
                  <img
                    className="mr-3"
                    src={linkedin}
                    role="button"
                    width="25"
                    height="25"
                    alt=""
                  ></img>
                </a>

                <a href="https://twitter.com/JaamCommunicat1" target="_blank">
                  <img
                    className="mr-3"
                    src={twitter}
                    role="button"
                    width="25"
                    height="25"
                    alt=""
                  ></img>
                </a>

                <a
                  href="https://www.instagram.com/jaamcommunications"
                  target="_blank"
                >
                  <img
                    className="mr-3"
                    src={instagram}
                    role="button"
                    width="25"
                    height="25"
                    alt=""
                  ></img>
                </a>

                <a
                  href="https://www.youtube.com/channel/UCG_rGilDkQ64k1aAauQwMiQ/featured"
                  target="_blank"
                >
                  <img
                    className="mr-3"
                    src={youtube}
                    role="button"
                    width="25"
                    height="25"
                    alt=""
                  ></img>
                </a>

                <a
                  href="https://www.facebook.com/jaamcommunications"
                  target="_blank"
                >
                  <img
                    src={facebook}
                    role="button"
                    width="25"
                    height="25"
                    alt=""
                  ></img>
                </a>

                <div className="border-top border-secondary my-3 d-block d-md-none"></div>
              </div>
              <div className="col-12 col-md-9 ml-md-5 order-1 order-sm-2">
                <div className="row">
                  <div className="col-1"></div>
                  <div className="col-11" style={{ fontSize: "12px" }}>
                    Copyright &copy; 2021 Jaam Communications, LLC.
                    &nbsp;&nbsp;All rights reserved.&nbsp;&nbsp;
                    <Link to="/termsofservice" className="text-white">
                      Terms &amp; conditions
                    </Link>
                    &nbsp;&nbsp;| &nbsp;&nbsp;
                    <Link to="/privacypolicy" className="text-white">
                      Privacy Policy
                    </Link>
                    &nbsp;&nbsp;| &nbsp;&nbsp;
                    <Link to="/cookies" className="text-white">
                      Cookie Preferences
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;
