
export const EXPIRED_VALUE = 'expired';

export const TRIAL_VALUE = 'trial';

// Stripe
export const month_Interval = 'month';

export const year_Interval = 'year'; 

export const invoice_status = 'paid';

export const CURRENCY_USD = 'usd';

export const CURRENCY_INR = 'inr';