//Jaam New File
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import React, { Component } from 'react'
import EdiText from 'react-editext'


class EditName extends Component {
  onSaveCallback = val => {
    sessionStorage.setItem("userName", val);
  }

  isUserNameValid = () => {
    var valueStored = sessionStorage.getItem("userName");
    var isUndefinedNull = (valueStored === "undefined undefined" ? true : (valueStored === null ? true : false));
    console.log(isUndefinedNull);
    return !isUndefinedNull;
  }

  render() {
    const { user } = this.props;
    const fullName = `${user.firstname} ${user.lastname}`
    return (

      <EdiText
        type='text'
        inputProps={{
          className: 'textarea',
          placeholder: 'Type your content here',
          style: {
            width: 150
          },
          rows: 1
        }}
        value={this.isUserNameValid() ? sessionStorage.getItem("userName") : fullName}
        saveButtonContent="Save"
        cancelButtonContent="Cancel"
        editButtonContent="Edit"
        onSave={this.onSaveCallback}
        onEditingStart={sessionStorage.setItem("userName", (this.isUserNameValid() ? sessionStorage.getItem("userName") : fullName))}
        hideIcons={true}
      />
    )
  }
}
const mapStateToProps = (state, ownProps) => ({
  user: state.session.user
});
export default withRouter(connect(mapStateToProps)(EditName));
