//Jaam New File
import React from 'react';

const Preference = () => {
  const [audioCheck, setAudioCheck] = React.useState(false);

  React.useEffect(() => {
    sessionStorage.setItem('isAudioMuted', audioCheck);
  }, [audioCheck]);

  const [videoCheck, setVideoCheck] = React.useState(false);

  React.useEffect(() => {
    sessionStorage.setItem('isVideoMuted', videoCheck);
  }, [videoCheck]);


  const [allowParticipants, setAllowParticipants] = React.useState(false);

  React.useEffect(() => {
    sessionStorage.setItem('isParticipantsAllowed', allowParticipants);
  }, [allowParticipants]);

  const handleCheckAudio = (e) => {
    setAudioCheck(e.target.checked);
  };

  const handleCheckVideo = (e) => {
    setVideoCheck(e.target.checked);
  }

  const handleAllowParticipants = (e) => {
    setAllowParticipants(e.target.checked);
  }

  return (
    <>
      <div className="col-12 col-md-12 col-lg-4 landing-text-muted">Audio Mute:</div>
      <div className="col-12 mb-2 col-md-12 col-lg-8">
        <input type="checkbox" className="larger-checkbox" checked={audioCheck}
          onChange={handleCheckAudio} />&nbsp;
        <label htmlFor="" className="landing-label">Everyone started muted</label>
      </div>
      <div className="col-12 col-md-12 col-lg-4 landing-text-muted">Video Mute:</div>
      <div className="col-12 mb-2 col-md-12 col-lg-8">
        <input type="checkbox" className="larger-checkbox" checked={videoCheck}
          onChange={handleCheckVideo} />&nbsp;
        <label htmlFor="" className="landing-label">Everyone starts video muted</label>
      </div>
      <div className="col-12 col-md-12 col-lg-4 landing-text-muted">Allow Participants:</div>
      <div className="col-12 mb-2 col-md-12 col-lg-8">
        <input type="checkbox" className="larger-checkbox" checked={allowParticipants}
          onChange={handleAllowParticipants} />&nbsp;
        <label htmlFor="" className="landing-label showmd">Allow participants to join before host</label>
        <label htmlFor="" className="landing-label showxs">Allow participants to join before <br /> host</label>
      </div>
    </>

  );
};
export default Preference;