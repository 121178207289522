import React, { Component } from "react";
import {  withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { updateMeeting, updateTheme } from "../../../common/session/sessionAction";
import _  from "lodash";
import LoadingComp from "../../../common/LoadingComp";
import jaamthemelogo from "../../../assets/jaamthemelogo.svg";
import jaamtext from "../../../assets/jaamtext.svg";
import fun from "../../../assets/fun.svg";
import business from "../../../assets/business.svg";
import StatusHelper from "../../../common/helpers/statusHelper";
import SnackBar from "../../../common/SnackBar";
import ThemeTile from "../../../common/themeTile";
import Sidebar from '../Sidebar';
import LandingHome from './Components/landingHome';

import {
  funImages,
  businessImages,
  funImagesOriginal,
  businessImagesOriginal,
} from "../../../common/api";
import TrialCounter from "../trialCounter";

const funThemeLabel = [
  "Joy",
  "Kid",
  "Chill",
  "Youth",
  "Ride",
  "Family",
  "Child",
  "Surf",
  "Hot",
];
const businessClassicThemeLabel = [
  "Focus",
  "Idea",
  "Meet",
  "Work",
  "Peer",
  "Notes",
  "WFH",
  "Pattern",
  "City"
];

const data_Business_Images = _.zipWith(
  businessImages,
  businessImagesOriginal,
  businessClassicThemeLabel,
  (businessImages, businessImagesOriginal, businessClassicThemeLabel) => ({
    key: businessImages,
    value: businessImagesOriginal,
    label: businessClassicThemeLabel,
  })
);

const data_Fun_Images = _.zipWith(
  funImages,
  funImagesOriginal,
  funThemeLabel,
  (funImages, funImagesOriginal, funThemeLabel) => ({
    key: funImages,
    value: funImagesOriginal,
    label: funThemeLabel,
  })
);


class Landing extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      copySuccess: false,
      passCode: "123#aBcD$@",
      onEdit: false,
      showSnack: false,
      themeName: "fun",
      selImg: funImagesOriginal[8],
      passcodeEnable: props.meetingData.enablePasscode,
    };
    window.scrollTo(0, 0);
  }
  statusHelper = new StatusHelper(this);

  componentWillReceiveProps(nextProps) {
    if (_.isEmpty(nextProps.user)) {
      this.props.history.push("/signin");
    }
  }
  componentDidMount() {
    this.setState({
      selImg: this.props.meetingData.themeImage,
      themeName: this.props.meetingData.theme,
    });

    if (_.isEmpty(this.props.user)) {
      this.props.history.push("/signin");
    }
  }

  selectImage = (selImg) => {
    this.props.dispatch(
      updateMeeting({
        token: this.props.token,
        email: this.props.user.email,
        roomName: this.props.meetingData.meetingId,
        themeName: this.state.themeName,
        selectedTheme: selImg,
      })
    );
    if (this.state.themeName === "fun") {
      this.setState({ selImg });
      this.props.dispatch(updateTheme(this.state.selImg));
    } else {
      this.setState({ selImg });
      this.props.dispatch(updateTheme(this.state.selImg));
    }
  };
  setTheme(themeName) {
    this.setState({ themeName });
  }
  render() {
    let backGroundImage =
      this.state.selImg !== ""
        ? `${window.location.origin}${this.state.selImg}`
        : "";
    let loadingStyle = { minHeight: "50vh", textAlign: "center" };

    return false ? (
      <div style={loadingStyle} className="background">
        <LoadingComp />
      </div> ) : ( 
      <div
        className="background"
        style={
          this.state.selImg !== ""
            ? { backgroundImage: `url(${backGroundImage})` }
            : {}
        }
      >
        {this.state.copySuccess ? (
          <SnackBar
            message={"Copied to Clipboard"}
            snackShow={this.state.copySuccess}
            minwidth="250px"
          ></SnackBar>
        ) : ("")}
        <div className="container container-decoration">
         <TrialCounter/>
          <div className="row mt-5">
            <div className="container">
              <div className="row">
                <div className="col-md-7 offset-lg-1 col-lg-6 offset-xl-2 col-xl-5">
                </div>
                <div className="row"></div>
              </div>
              <div className="row ml-md-n5">
                <div className="col-sm-2 col-12 col-md-2 col-lg-2 col-xl-2">
                  <Sidebar visible={this.state.selectedTabValue}/>
                </div>
                <div className="tab-content col-12 col-md-10 col-lg-10 col-xl-10">
                      <LandingHome/>
                </div>
              </div>
            </div>
          </div>
          <div
              className="modal fade bd-example-modal-lg"
              id="exampleModalCenter"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
          >
            <div
              className="modal-dialog modal-lg modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <img src={jaamthemelogo} classname="" alt="" /*height="10%" width="10%"*/></img>
                  <div className="mt-2 ml-3">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                      <img src={jaamtext}  alt=""></img>
                    </h5>
                    <div className="badge badge-pill badge-gray HelveticaNeue">
                      We know what you like the most! Harmonize your mood with
                      our themes!!
                    </div>
                  </div>

                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span className="fa fa-close"></span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row no-gutters">
                    <div className="col-md-3 col-lg-4">
                      <div
                        className="nav flex-column nav-pills"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <a
                          className={
                            this.state.themeName === "fun"
                              ? "nav-link HelveticaNeue active"
                              : "nav-link HelveticaNeue"
                          }
                          id="fun-tab"
                          data-toggle="pill"
                          href="#fun-tab-image"
                          role="tab"
                          aria-controls="fun-tab-image"
                          aria-selected={
                            this.state.themeName === "fun" ? true : false
                          }
                          onClick={() => this.setTheme("fun")}
                        >
                          <img src={fun} alt=""></img>
                          &nbsp;&nbsp; Fun lovers!
                        </a>
                        <div className="horizontal col-lg-11 offset-lg-n1"></div>

                        <a
                          className={
                            this.state.themeName === "Business"
                              ? "nav-link HelveticaNeue active"
                              : "nav-link HelveticaNeue"
                          }
                          id="v-pills-profile-tab"
                          data-toggle="pill"
                          href="#v-pills-profile"
                          role="tab"
                          aria-controls="v-pills-profile"
                          aria-selected={
                            this.state.themeName === "Business" ? true : false
                          }
                          onClick={() => this.setTheme("Business")}
                        >
                          <img src={business} alt=""></img>
                          &nbsp;&nbsp; Serious, business classic
                        </a>
                      </div>
                    </div>
                    <div className="col-md-9 col-lg-8">
                      <div className="tab-content" id="v-pills-tabContent">
                        <div
                          className={
                            this.state.themeName === "fun"
                              ? "col-12 tab-pane fade show active overflow-auto"
                              : "col-12 tab-pane fade overflow-auto"
                          }
                          style={{
                            height: "50vh",
                          }}
                          id="fun-tab-image"
                          role="tabpanel"
                          aria-labelledby="fun-tab"
                        >                          
                          <ThemeTile
                            dataImages={data_Fun_Images}
                            onSelectImage={this.selectImage}
                            selectedBackGround={this.state.selImg}
                          />
                        </div>
                        <div
                          className={
                            this.state.themeName === "Business"
                              ? "col-12 tab-pane fade show active overflow-auto"
                              : "col-12 tab-pane fade overflow-auto"
                          }
                          style={{
                            height: "50vh",
                          }}
                          id="v-pills-profile"
                          role="tabpanel"
                          aria-labelledby="v-pills-profile-tab"
                        >
                          {" "}
                          <ThemeTile
                            dataImages={data_Business_Images}
                            onSelectImage={this.selectImage}
                            selectedBackGround={this.state.selImg}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row"></div>
          <div className="row">&nbsp;</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.session.user,
  token: state.session.token,
  meetingData: state.session.meetingData,
  isSignedUp: state.session.isSignedUp,
  loading: state.session.loading,
  selectedTheme: state.session.selectedTheme,
});

export default withRouter(connect(mapStateToProps)(Landing));
