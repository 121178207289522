import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'lodash';
import LoadingComp from '../../../../common/LoadingComp';
import { stripeCustomerPortal } from '../../../../common/session/sessionAction';

class RedirectCustomerPortal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerToken: props.user.token,
      priceId: '',   
      customerEmail: props.user.email,   // signed in user
      customerId: props.customerId,          
      showSnack: false,    
      statusData: {},    
    };
  }  

  render() {
    const { invoiceUrl } = this.props;    
    if (invoiceUrl) {
      window.location.href = invoiceUrl;
    }    

    let loadingStyle = {
      minHeight: "50vh",
      textAlign: "center",
    };    

    return (
      <div style={loadingStyle} className="background">
          <LoadingComp />
      </div>
      )
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.session.user,
  token: state.session.token, 
  loading: state.session.loading,
  joinError: state.session.joinError,  
  joinErrorCode: state.session.joinErrorCode,
  customerId: state.session.customerId,
  invoiceUrl: state.session.invoiceUrl,
  sessionId: state.session.sessionId,
});

export default withTranslation()(connect(mapStateToProps)(RedirectCustomerPortal));