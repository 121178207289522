function textTruncate(
  value,
  limit = 13,
  completeWords = false,
  ellipsis = "..."
) {
  if (completeWords) {
    limit = value.substr(0, limit).lastIndexOf(" ");
  }
  return value.length > limit ? value.substr(0, limit) + ellipsis : value;
}

export const numberFormat = (value) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(value);

export const isEmpty = (value) => {
  value = value.trim();
  if (value == null || value == undefined || value.length == 0) {
    return true;
  } else {
    return false;
  }
};

export default {
  trunc: textTruncate,
  isEmpty: isEmpty,
};
