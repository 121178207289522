import React, { Component } from "react";
import Stripecards from "../../../../assets/stripecards.svg";
import Tick from "../../../../assets/tickplan.svg";

class PaymentStaticContent extends Component {
  render() {
    return (
        <div>
            {/* Static data - Stripe Cards Image and the list */}
            <div className="row billing ml-2 ml-md-3">
            <div className="col-11 my-4 billing-horizontal-line"></div>
            <section className="col-10 col-md-10 col-lg-7 ml-4 ml-md-0 ml-lg-0">
                <div className="col-0 ml-0 ml-md-1 ml-lg-2 my-0 my-md-2">                    
                <img src={Stripecards} alt="stripe-cards" className="width_100 stripe-image"/>
                </div>
            </section>
            <div className="col-11 my-4 billing-horizontal-line "></div>
            </div>
            <div className="row ml-3">
            <div className="col-12 ml-n4 mb-4">
                <ul className="billing-list-text ml-0 mb-5">
                <li className="billing-list-text mb-3"><img src={Tick} alt="" className="mr-3 tick-image"/>Upto 50 participants per meeting</li>
                <li className="billing-list-text mb-3"><img src={Tick} alt="" className="mr-3 tick-image"/>Screen &amp; window sharing</li>
                <li className="billing-list-text mb-3"><img src={Tick} alt="" className="mr-3 tick-image"/>No time limits</li>
                <li className="billing-list-text mb-3"><img src={Tick} alt="" className="mr-3 tick-image"/>Unlimited number &amp; length of meetings</li>
                <li className="billing-list-text mb-3"><img src={Tick} alt="" className="mr-3 tick-image"/>Participant list panel with mute all, raise/lower your hand for attention</li>
                <li className="billing-list-text mb-3"><img src={Tick} alt="" className="mr-3 tick-image"/>Invite participants instanteously using your favourite emails</li>
                </ul>
            </div>
            </div>
        </div>
        );
    }
}

export default PaymentStaticContent;