import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import SocialButton from "../common/SocialButton";
import { withTranslation } from "react-i18next";
import { LinkedIn } from "react-linkedin-login-oauth2";
import { login, signup } from "../common/session/sessionAction";
import { connect } from "react-redux";
import _ from "lodash";
import LoadingComp from "../common/LoadingComp";
import { defaultTheme, defaultThemeImage } from "../common/api";
import StatusHelper from "../common/helpers/statusHelper";

class SignIn extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      profile: {},
      statusData: {},
    };

    window.scrollTo(0, 0);
  }
  statusHelper = new StatusHelper(this);
  handleSocialLogin = (user) => {
    var profile = {};
    profile = {
      provider: user._provider,
      email: user._profile.email,
      socialId: user._profile.id,
      firstname: user._profile.firstName,
      lastname: user._profile.lastName,
      profileurl: user._profile.profilePicURL,
    };
    this.setState(
      {
        profile,
      },
      () => {
        this.props.dispatch(login(profile));
      }
    );
  };

  handleSocialLoginFailure = (err) => {
    console.error(err);
  };

  handleSignup() {
    this.props.dispatch(
      signup({
        ...this.state.profile,
        theme: defaultTheme,
        themeImage: defaultThemeImage,
      })
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.isLoggedIn === false &&
      !_.isEqual(this.props.isLoggedIn, nextProps.isLoggedIn) &&
      nextProps.loginMessage === ""
    ) {
      this.props.history.push("/landing");
    }

    if (
      nextProps.signUpMessage &&
      nextProps.signUpMessage !== "" &&
      this.props.signUpMessage !== nextProps.signUpMessage
    ) {
      this.setState({statusData: {
        type: 'error',
        message: nextProps.signUpMessage
      }});
    }

    if (
      nextProps.user &&
      !_.isEmpty(nextProps.user) &&
      !_.isEqual(this.props.user, nextProps.user)
    ) {
      this.props.history.push("/landing");
    }
  }

  render() {
    const { loading, isLoggedIn, t } = this.props;
    return loading || isLoggedIn ? (
      <div
        style={{ minHeight: "50vh", textAlign: "center" }}
        className="background"
      >
        <LoadingComp />
      </div>
    ) : (
      <section className="background">
        <div className="container container-decoration">
          <div className="row content-center">
            <div className="col-lg-8">
              <div className="col-md-12 form-group">
                <h2 className="text-center"> {t("signin.signin")}</h2>
              </div>

              {this.props.loginMessage !== "" &&
              !_.isEmpty(this.state.profile) ? (
                <div>
                  <div className="card">
                    <div
                      style={{
                        flex: "1 1 auto",
                        minHeight: "1px",
                        padding: "1.25rem",
                      }}
                    >
                      <div className="d-flex justify-content-center">
                        <div
                          className="imageDice"
                          style={{
                            height: "60px",
                            width: "60px",
                          }}
                        >
                          <img
                            src={this.state.profile.profileurl}
                            alt="image"
                          ></img>
                        </div>
                      </div>
                      <div className="row">&nbsp;</div>
                      <h6 className="text-center">
                        {t("signin.welcome")} {this.state.profile.firstname}{" "}
                        {this.state.profile.lastname}
                      </h6>
                      <div className="row">&nbsp;</div>
                      <h4 className="text-center">
                        You are one step away from getting started with JAAM
                      </h4>
                      <div className="row">&nbsp;</div>
                      <h6 className="text-center">
                        By Creating a new account, you must agree to our Terms
                        and Service.
                      </h6>
                      <div className="row">&nbsp;</div>
                      <div className="d-flex justify-content-center">
                        <button
                          type="button"
                          className="col-6 col-sm-4 btn btn-purple btn-lg text-center"
                          onClick={() => this.handleSignup()}
                        >
                          {t("signin.signup")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="col-md-12 form-group mt-4 text-center">
                    <SocialButton
                      provider="google"
                      appId="763039710911-b5b2ib9clf3rl5dpv9o6jkhr5bju1kd5.apps.googleusercontent.com"
                      onLoginSuccess={this.handleSocialLogin}
                      onLoginFailure={this.handleSocialLoginFailure}
                    >
                      {t("signin.signinWithGoogle")}
                    </SocialButton>
                  </div>
                  <div className="col-md-12 form-group mt-4 text-center">
                    <SocialButton
                      provider="facebook"
                      appId="866708197220186"
                      onLoginSuccess={this.handleSocialLogin}
                      onLoginFailure={this.handleSocialLoginFailure}
                    >
                      {t("signin.signinWithFacebook")}
                    </SocialButton>
                  </div>
                  {/* <div className="form-group mt-4">
                <LinkedIn
                  clientId="86muxx0qbtyg3q"
                  className="offset-md-2 col-md-8 offset-md-2 btn btn-default btn-lg text-left"
                  onFailure={this.handleSocialLoginFailure}
                  onSuccess={this.handleSocialLogin}
                  redirectUri="http://localhost:3000/linkedin"
                  scope="r_liteprofile,r_emailaddress"
                  state="DCEeFWf45A53sdfKef424"
                >
                  <span>Signin with LinkedIn</span>
                </LinkedIn>
              </div> */}
                  <div className="col-md-12 form-group mt-4">
                    <div className="form-inline offset-md-2">
                      <p>{t("signin.dontHaveAccount")}</p>
                      <Link to="/signup">
                        <p className="mx-1 text-purple">{t("signin.signup")}</p>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-1 col-lg-2">&nbsp;</div>
                </div>
                
              )}
              {Object.keys(this.state.statusData).length > 0 &&
                this.statusHelper.renderStatus(this.state.statusData)}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.session.user,
  token: state.session.token,
  isLoggedIn: state.session.isLoggedIn,
  loginMessage: state.session.loginMessage,
  signUpMessage: state.session.signUpMessage,
  loading: state.session.loading,
});

export default withTranslation()(withRouter(connect(mapStateToProps)(SignIn)));
