import _filter from 'lodash/filter';
import { routerActions } from 'react-router-redux';
import * as types from './sessionActionTypes'

const sessionReducer = (state = [], action) => {    
    switch (action.type) {
        case types.LOGIN.SUCCESS:
            return {
                ...state,
                token: action.payload.data.users.token,
                user: action.payload.data.users,
                meetingData: action.payload.data.meetingData,
                isLoggedIn: false,
                loginMessage: ''
            };
        case types.LOGIN.FAILURE:
            return {
                ...state,
                isLoggedIn: false,
                loginMessage: 'We are sorry, we could not able to log in with that information. Please ensure your mail is verified or check your username or password. If the problem persists, contact support at the link below.'
            };
        case types.LOGIN.REQUEST:
            return {
                ...state,
                isLoggedIn: true,
                loginMessage: ''
            };
            case types.SIGNUP.SUCCESS:               
                return {
                    ...state,
                    token: action.payload.data.users.token,
                    user: action.payload.data.users,
                    meetingData: action.payload.data.meetingData,
                    emailFlag: action.payload.data.emailFlag,
                    isSignedUp: false,
                    signUpMessage: ''
                };
            case types.SIGNUP.FAILURE:
                return {
                    ...state,
                    isSignedUp: false,
                    signUpMessage: action.payload.errorMessage
                };
            case types.SIGNUP.REQUEST:
                return {
                    ...state,
                    isSignedUp: true,
                    signUpMessage: ''
                };
        case types.JOIN.SUCCESS:
            return {
                ...state,
                joinLoading: false,
                enablePC: action.payload.data.enablePasscode,
                hostLock: action.payload.data.hostLock,
                hostActive: action.payload.data.hostActive,
                meetingId: action.payload.data.meetingId
            };
        case types.JOIN.REQUEST:
            return {
                ...state,
                joinLoading: true,                
                joinError: '',
                joinErrorCode: ''
            };
        case types.JOIN.FAILURE:
        return {
            ...state,
            joinLoading: false,
            enablePC: action.payload.data.enablePC,
            meetingId: action.payload.data.meetingId,
            joinError: action.payload.errorMessage,
            joinErrorCode: action.payload.errorCode
        };
        case types.JOINPASSCODE.SUCCESS:
            return {
                ...state,
                joinLoading: false
            };
        case types.JOINPASSCODE.REQUEST:
            return {
                ...state,
                joinLoading: true,                
                joinError: '',
                joinErrorCode: ''
            };
        case types.JOINPASSCODE.FAILURE:
        return {
            ...state,
            joinLoading: false,
            joinError: action.payload.errorMessage,
            joinErrorCode: action.payload.errorCode
        };
        case types.GETUSER.SUCCESS:
            return {
                ...state,
                token: action.payload.data.users.token,
                user: action.payload.data.users,
                meetingData: action.payload.data.meetingData,
                loading: false,
                getUserMessage: ''
            };
        case types.GETUSER.FAILURE:
            return {
                ...state,
                loading: false,
                getUserMessage: 'Try again later.'
            };
        case types.GETUSER.REQUEST:
            return {
                ...state,
                loading: true,
                getUserMessage: ''
            };
            case types.UPDATEMEET.SUCCESS:
                return {
                    ...state,
                    meetingData: action.payload.data,
                    loading: false,
                    message: ''
                };
            case types.UPDATEMEET.FAILURE:
                return {
                    ...state,
                    loading: false,
                    message: 'Try again later.'
                };
            case types.UPDATEMEET.REQUEST:
                return {
                    ...state,
                    loading: true,
                    message: ''
                };
        case types.LOGOUT:
            return {
                ...state,
                token: '',
                user: {}
            };
        case types.CONTACT.REQUEST:
            return{
                ...state,
                contactLoading:true,
                contactStatus:''
            };
        case types.CONTACT.SUCCESS:
            return{
                ...state,
                contactLoading:false,    
                contactStatus:'Your details submitted successfully'
            };
        case types.CONTACT.FAILURE:
            return{
                ...state,
                contactLoading:false,
                contactStatus:'Submission failed. Try again later'
            };
        case types.PARTNERSHIP.REQUEST:
            return{
                ...state,
                partnershipLoading:true,
                partnershipStatus:''
            };
        case types.PARTNERSHIP.SUCCESS:
            return{
                ...state,
                partnershipLoading:false,    
                partnershipStatus:'Your details submitted successfully'
            };
        case types.PARTNERSHIP.FAILURE:
            return{
                ...state,
                partnershipLoading:false,
                partnershipStatus:'Submission failed. Try again later'
            };
    case types.FEEDBACK.REQUEST:
      return {
        ...state,
        feedbackLoading: true,
        feedbackStatus: "",
      };
    case types.FEEDBACK.SUCCESS:
      return {
        ...state,
        feedbackLoading: false,
        feedbackStatus: "Your feedback submitted successfully",
      };
    case types.FEEDBACK.FAILURE:
      return {
        ...state,
        feedbackLoading: false,
        feedbackStatus: "Submission failed. Try again later",
      };
        case 'UPDATE_THEME':
            return{
                ...state,
                selectedTheme: action.payload
            };
    
        // Stripe        
    
        // case create checkout
        case types.CREATECHECKOUT.REQUEST:
            return {
            ...state,
            paymentError: null,
            createcheckoutLoading: true,
            createcheckoutStatus: "",
            };
        case types.CREATECHECKOUT.SUCCESS:
            return {
            ...state,
            paymentError: null,
            sessionId: action.payload.data.sessionId,
            priceId: action.payload.data.priceId,
            createcheckoutLoading: false,
            createcheckoutStatus: "created checkout session in stripe successfully",
            };
        case types.CREATECHECKOUT.FAILURE:            
            return {
            ...state,
            paymentError: action.payload,
            createcheckoutLoading: false,
            createcheckoutStatus: "Checkout session creation failed in stripe. Try again",
            };

        // case Customer Portal
        case types.GETCUSTOMERPORTAL.REQUEST:
            return {
            ...state,
            paymentError: null,
            // getinvoiceLoading: true,
            // getinvoiceStatus: "",
            };
        case types.GETCUSTOMERPORTAL.SUCCESS:
            return {
            ...state,
            paymentError: null,
            customerId: action.payload.data.customerId,
            invoiceUrl: action.payload.data.sessionurl.url,
            // getinvoiceLoading: false,
            // getinvoiceStatus: "Recieved invoice of customer in stripe successfully",
            };
            
        case types.GETCUSTOMERPORTAL.FAILURE:
            return {
            ...state,
            paymentError: action.payload,
            // getinvoiceLoading: false,
            // getinvoiceStatus: "Get Invoice failed in stripe. Try again",
            };

        // case get invoice
        case types.GETINVOICE.REQUEST:
            return {
            ...state,
            paymentError: null,
            getinvoiceLoading: true,
            getinvoiceStatus: "",
            };
        case types.GETINVOICE.SUCCESS:
            return {
            ...state,
            paymentError: null,
            customerId: action.payload.data.customerId,
            invoiceList: action.payload.data.invoiceResponse.data,
            getinvoiceLoading: false,
            getinvoiceStatus: "Recieved invoice of customer in stripe successfully",
            };
            
        case types.GETINVOICE.FAILURE:
            return {
            ...state,
            paymentError: action.payload,
            getinvoiceLoading: false,
            getinvoiceStatus: "Get Invoice failed in stripe. Try again",
            };

        // case get subscriptions
        case types.GETSUBSCRIPTIONS.REQUEST:
            return {
            ...state,
            paymentError: null,
            // getinvoiceLoading: true,
            // getinvoiceStatus: "",
            };
        case types.GETSUBSCRIPTIONS.SUCCESS:
            return {
            ...state,
            paymentError: null,
            subId: action.payload.data.subId,
            subscription: action.payload.data.subscriptionResponse,
            customerInfo: action.payload.data.customerData,
            customerCurrency: action.payload.data.customerCurrency,        
            // getinvoiceLoading: false,
            // getinvoiceStatus: "Recieved invoice of customer in stripe successfully",
            };
            
        case types.GETSUBSCRIPTIONS.FAILURE:
            return {
            ...state,    
            paymentError: action.payload,        
            // getinvoiceLoading: false,
            // getinvoiceStatus: "Get Invoice failed in stripe. Try again",
            };
         // case Get ALL Prices
         case types.GETALLPRICES.REQUEST:
            return {
            ...state,
            paymentError: null,
            getallpricesLoading: true,
            getallpricesStatus: "",
            };
        case types.GETALLPRICES.SUCCESS:          
            return {
            ...state,
            paymentError: null,
            allpricesList: action.payload.data.pricesallData,
            initCurrency: action.payload.data.initialCurrency,            
            getallpricesLoading: false,
            getallpricesStatus: "Get all Prices successfull",
            };
            
        case types.GETALLPRICES.FAILURE:
            return {
            ...state,
            paymentError: action.payload,
            getallpricesLoading: false,
            getallpricesStatus: "Get all Prices failed. Try again",
            stripeError: action.payload.error
            };
        
        // set paymentError value to null
        case types.PAYMENTERROR:
            return {
            ...state,
            paymentError: null
            };
        // case types.LINKEDIN_DATA.REQUEST:
        //     return {
        //         ...state,
        //         linkedinLoading: true,
        //         linkedInData: {}
        //     }
        // case types.LINKEDIN_DATA.SUCCESS:
        //     return {
        //         ...state,
        //         linkedinLoading: false,
        //         linkedInData: action.payload
        //     }
        // case types.LINKEDIN_DATA.FAILURE:
        //     return {
        //         linkedinLoading: false
        //     }
        case types.SHOWPROFILE:
            return {
            ...state,
            showprofile: action.payload
            };
        default:
            return state;
    }
}
export default sessionReducer;
