import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";

class SingleProviderModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
          modalState: true,
        };
      }

      handleShow() {
    //     const { history } = this.props;
        this.setState({ modalState: !this.state.modalState,
         });
    //     history.push('./signin');
      }
    
      render() {
        const {user}=this.props;
        return (
            <div className="container">
              <div className="row content-center">
                  <div className={"modal fade" + (this.state.modalState ? " show d-block" : " d-none")} tabIndex="-1" role="dialog">
                      <div className="modal-dialog modal-dialog-centered" role="document">
                          <div className="modal-content">
                            <div className="modal-body">
                            <div className="text-center">Do you already have an account?</div>
                              <div className="text-center">Looks like an account with this email address already exists. Tap below to login.</div>
                                  <div className ="text-center mt-0 mt-md-4 pr-0 pr-md-4">
                                    <button type="button" className="btn btn-cancel text-center service-provider-dismiss-btn" onClick ={() => this.handleShow()}>Go Back</button>
                                    <button type="button" id="#service-provider-proceed-btn-alignment" className="ml-0 ml-md-2 mt-md-0 btn btn-cancel text-center service-provider-proceed-btn">
                                    <Link
                                      to="/signin"
                                      id="on"
                                      className="text-purple"
                                    >
                                      <span>Sign In</span>
                                    </Link>
                                     </button>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>      
        );
      }
    }

const mapStateToProps = (state, ownProps) => ({
  user: state.session.user,
  emailFlag: state.session.user
});

export default withRouter(connect(mapStateToProps)(SingleProviderModal));