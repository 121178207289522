import React, { Component } from "react";
import { Link , withRouter} from "react-router-dom";
import { connect } from "react-redux";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { api } from "../../../../common/api";

class Meetings extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.handleChecked = this.handleChecked.bind(this);
    this.state = {
      isChecked:false,
    }
  }
  handleChecked () {
    this.setState({isChecked: !this.state.isChecked});
  }
  render() {
    const { isChecked } = this.state;
    const { user, meetingData  } = this.props;
    let recurrencecls;   
      if (isChecked) {
        recurrencecls="showDiv";
      } else {
        recurrencecls="hideDiv";
      }
    return (
        <div className="row">
          <div className="col-12 col-md-12  offset-lg-1 col-lg-11 text-left text-black">
            <div className="card meetings">
                <div className="col-12 my-4 ml-n1 ml-md-3 meetings-tab-title">
                  My Meeting
                </div>
                  <div className="row">
                    <div className="col-12 col-md-9 col-lg-8">
                      <nav className="ml-2 ml-md-4">
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                          <a className="nav-item nav-link GilroyExtrabold meetings-tab-border active" id="nav-schedule-tab" data-toggle="tab" href="#nav-schedule" role="tab" aria-controls="nav-schedule" aria-selected="true">Schedule</a>
                          <a className="nav-item nav-link GilroyExtrabold meetings-tab-border ml-4" id="nav-upcoming-tab" data-toggle="tab" href="#nav-upcoming" role="tab" aria-controls="nav-upcoming" aria-selected="true">Upcoming</a>
                          <a className="nav-item nav-link GilroyExtrabold meetings-tab-border ml-4" id="nav-completed-tab" data-toggle="tab" href="#nav-completed" role="tab" aria-controls="nav-completed" aria-selected="false">Completed</a> 
                        </div>                                                                                        
                      </nav>
                    </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-12 line-adjustment mb-2 meetings-line"></div>
                      <div className="tab-content" id="nav-tabContent">
                      <div className="tab-pane fade show active" id="nav-schedule" role="tabpanel" aria-labelledby="nav-schedule">
                        <div className="row mt-4 ml-1 mr-1">
                          <div className="col-12">
                            <div className="row mt-4">
                              <div className="col-12 col-lg-12 col-md-12">
                                <label className="normal schedule-meetings-main-heading">Topic </label>
                                <input type="text" className="form-control form-control-lg " ></input>
                              </div>
                            </div>
                              <div className="row mt-4">
                                <div className="col-12 col-lg-12 col-md-12">
                                  <label className="normal schedule-meetings-main-heading">Description (Optional)</label>
                                  <textarea  className="form-control form-control-lg " rows="3" ></textarea>
                                </div>
                              </div>
                                <div className="row mt-4">
                                  <div className="col-8 col-md-4">
                                    <label className="normal schedule-meetings-main-heading">Date and Time </label>
                                    <input className="form-control" type="datetime-local" id="birthdaytime" name="birthdaytime" />
                                  </div>
                                </div>
                                  <div className="row mt-4 ">
                                    <div className="col-12 col-lg-12 col-md-12 ml-4">                                          
                                      <input onChange={ this.handleChecked } type="checkbox" id="birthdaytime" name="birthdaytime" className="meetings-custom-checkbox form-check-input"/>
                                      &nbsp;&nbsp;&nbsp;<label className="normal schedule-meetings-main-heading form-check-label mt-1">Recurrence</label>
                                    </div>
                                  </div>
                                    <div className={recurrencecls}>
                                      <div className="ml-n2 ml-md-n3 ml-lg-n2">
                                        <div className="row mt-2 ml-5">                                             
                                          <small className="small schedule-meetings-label">Recurrence Pattern</small> &nbsp; &nbsp;&nbsp;                                                                                                                            
                                          <select
                                            className="form-control-select-sm-trans schedule-meetings-option"                                                   
                                          >
                                            <option selected>Weekly</option> 
                                          </select>                                          
                                        </div>
                                          <div className="row ml-5">                                           
                                            <small className="small mt-1 schedule-meetings-label">Recurrence on Every</small>  &nbsp;&nbsp;&nbsp;                                                                                                                            
                                            <input type="number" min="1" className="form-control meetings-custom-checkbox-half fs_10"></input> <small className="mt-1 ml-1 text-black">Week(s)</small>                                       
                                          </div>
                                            <div className="row mt-2 ml-5">                                           
                                              <input type="checkbox"  className="meetings-custom-checkbox"/>
                                              <label className="small schedule-meetings-label">&nbsp; Sun </label>&nbsp;&nbsp;
                                              <div className="display-only-on-pc-tablet">&nbsp;&nbsp;</div>
                                              <input type="checkbox"  className="meetings-custom-checkbox"/>
                                              <label className="small schedule-meetings-label">&nbsp; Mon </label>&nbsp;&nbsp;
                                              <div className="display-only-on-pc-tablet">&nbsp;&nbsp;</div>
                                              <input type="checkbox"  className="meetings-custom-checkbox"/>
                                              <label className="small schedule-meetings-label">&nbsp; Tue </label>&nbsp;&nbsp;
                                              <div className="display-only-on-pc-tablet">&nbsp;&nbsp;</div>
                                              <input type="checkbox"  className="meetings-custom-checkbox"/>
                                              <label className="small schedule-meetings-label">&nbsp; Wed </label>&nbsp;&nbsp;
                                              <div className="display-only-on-pc-tablet">&nbsp;&nbsp;</div>
                                              <input type="checkbox"  className="meetings-custom-checkbox"/>
                                              <label className="small schedule-meetings-label">&nbsp; Thu </label>&nbsp;&nbsp;
                                              <div className="display-only-on-pc-tablet">&nbsp;&nbsp;</div>
                                              <input type="checkbox"  className="meetings-custom-checkbox"/>
                                              <label className="small schedule-meetings-label">&nbsp; Fri </label>&nbsp;&nbsp;
                                              <div className="display-only-on-pc-tablet">&nbsp;&nbsp;</div>
                                              <input type="checkbox"  className="meetings-custom-checkbox"/>
                                              <label className="small schedule-meetings-label">&nbsp; Sat </label>
                                            </div>  
                                              <div className="row mt-2 ml-5">                                           
                                                <small className="small schedule-meetings-label">Ending</small>                                                                                                                                                                   
                                              </div>
                                                <div className="offset-1">
                                                  <div className="ml-n2 ml-md-0 ml-lg-0">
                                                    <div className="row mt-2 ml-5 ml-md-4 ml-lg-n1">
                                                      <input className="form-check-input" type="radio" name="exampleRadios"/> 
                                                      <small className="small schedule-meetings-label">No End Date</small> &nbsp; &nbsp;&nbsp;                                                                                                                            
                                                      <select
                                                        className="form-control-select-sm-trans schedule-meetings-option"                                                   
                                                      >
                                                        <option selected>05/11/2021</option> 
                                                      </select>                                          
                                                    </div>
                                                      <div className="row ml-5 ml-md-4 ml-lg-n1">   
                                                        <input className="form-check-input mt-2" type="radio" name="exampleRadios"/>                                         
                                                        <small className="small mt-1 schedule-meetings-label">Ending</small>  &nbsp;&nbsp;                                                                                                                           
                                                        <input type="number" min="1" className="form-control meetings-custom-checkbox-half fs_10"></input> <small className="mt-1 ml-1 text-black">Meetings</small>                                       
                                                      </div>
                                                      <div className="row mt-2 ml-5 ml-md-4 ml-lg-n1">   
                                                        <input className="form-check-input" type="radio" name="exampleRadios"/>                                         
                                                        <small className="small schedule-meetings-label">After</small>  &nbsp;&nbsp;                                                                                                                                                                       
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                                <div className="row mt-4 ">
                                                  <div className="col-12 col-md-6 col-lg-5">                                          
                                                    <label className="small ml-1 schedule-meetings-sub-heading">Meeting Id</label>
                                                    <br/>
                                                    <small className="ml-1 ml-md-1 ml-lg-1 schedule-meetings-id">
                                                    {api.WEB_URL}/join/{meetingData.meetingId ? meetingData.meetingId : ""}
                                                    </small>                                          
                                                  </div>
                                                    <div className="col-12 col-md-3 col-lg-3 ml-4 ml-md-n4 ml-lg-n4 mt-4 mt-md-0">                                          
                                                      <input type="checkbox" id="birthdaytime" name="birthdaytime" className="meetings-custom-checkbox form-check-input"/>
                                                      &nbsp;&nbsp;&nbsp;<label className="small schedule-meetings-sub-heading form-check-label mt-1">Passcode</label>
                                                      {/* <br></br><small className="offset-2 schedule-meetings-password">{this.state.passCode}</small> */}
                                                    </div>
                                                  </div>
                                                    <div className="row mt-4 ">
                                                      <div className="col-12 col-lg-12 col-md-12">
                                                        <label className="small schedule-meetings-sub-heading">Attendees</label>
                                                        <input type="text" className="form-control attendees-placeholder form-control-lg schedule-meetings-attendees-text" placeholder="Separate email address with a comma or semicolon" ></input>
                                                      </div>
                                                    </div>
                                                      <div className="row mt-4">
                                                        <div className="col-12 col-lg-5 col-md-5">
                                                          <label className="small schedule-meetings-sub-heading">Meeting Reminder</label>
                                                        </div>
                                                      </div>
                                                        <div className="row ">
                                                          <div className="col-5 col-md-3 col-lg-2">
                                                            <select
                                                              className="form-control-select-sm select-purple schedule-meetings-placeholder"                                                   
                                                            >
                                                              <option selected>15mins</option> 
                                                            </select> 
                                                          </div>
                                                            <div className="col-7 col-lg-7 mt-1 col-md-7">
                                                              <small className="ml-n4 schedule-meetings-gilroy-label"> before meeting starts</small>
                                                            </div>
                                                          </div>
                                                            <div className="row mt-4 ">
                                                              <div className="col-10 col-lg-10 col-md-10 ml-4">                                          
                                                                <input type="checkbox" id="birthdaytime" name="birthdaytime" className="meetings-custom-checkbox form-check-input"/>
                                                                &nbsp;&nbsp;&nbsp;<label className="small schedule-meetings-gilroy-label form-check-label mt-2">Everyone started muted</label>
                                                              </div>
                                                            </div>
                                                              <div className="row mt-2 ">
                                                                <div className="col-10 col-lg-10 col-md-10 ml-4">                                          
                                                                  <input type="checkbox" id="birthdaytime" name="birthdaytime" className="meetings-custom-checkbox form-check-input"/>
                                                                  &nbsp;&nbsp;&nbsp;<label className="small schedule-meetings-gilroy-label form-check-label mt-2">Everyone starts video muted</label>
                                                                </div>
                                                              </div>
                                                                <div className="row mt-4 mb-5">
                                                                  <div className="offset-5 col-5 offset-md-8 col-md-3 offset-lg-9 col-lg-2 span-lg-1">                                               
                                                                    <button className="btn-purple schedule-meeting-btn ml-0 ml-md-0 ml-lg-4">Schedule</button>
                                                                  </div>                                                                                                                                        
                                                       </div>
                                            </div>
                                </div>
                      </div>  
                        <div className="tab-pane fade show" id="nav-upcoming" role="tabpanel" aria-labelledby="nav-upcoming">
                          <div className="row">
                            <div className="col-12 ml-4 text-black">
                              <div className="row my-2">
                                <div className="col-10 col-md-4 col-lg-3">
                                  <select
                                    className="form-control-select-sm  select-purple"                                                                                             
                                  >
                                  <option selected>My Meetings</option> 
                                  </select>
                                </div>
                                <div className="col-10 col-md-5 col-lg-4">
                                  <DateRangePicker
                                  initialSettings={{ startDate: '01/01/2021', endDate: '01/15/2021' }}
                                  >
                                    <input type="text" className="form-control-select-sm  select-purple" />
                                  </DateRangePicker>
                                </div>
                              </div>
                              <div className="row my-3">
                                <div className="col-11 col-md-11 col-lg-11">
                                  <ul className="meetings-unordered-list">
                                    <li className="meetings-ending-line mt-4 meetings-list">
                                      <div className="col-md-12 mb-3">
                                        <div className="row">
                                          <div className="col-2 col-md-1 col-lg-1">
                                            <div
                                            className="landing-img-properties width_height_40"
                                            >
                                              <img src={user.profileurl} alt="image" className="meetings-image"></img>
                                            </div>
                                          </div>
                                            <div className="col-9 col-md-4 col-lg-3">
                                              <div className="row">
                                                <div className="col-12 meetings-timing-text">03:30 PM  -  04:30PM</div>
                                                <div className="col-12 text-black meetings-gray-color">Sun, Feb 7</div>
                                              </div>
                                            </div>
                                              <div className="col-10 offset-2 offset-md-0 offset-lg-1 mt-3 mt-md-0 mt-lg-0 col-md-5 col-lg-4">
                                                <div className="row">
                                                  <div className="col-12 text-black">
                                                    UX Review
                                                  </div>
                                                  <div className="col-12 text-black meetings-gray-color">
                                                    Raghavan Shanmugam
                                                  </div>
                                                </div>
                                              </div>
                                                <div className="offset-3 offset-md-0 offset-lg-0 col-4 span-5 span-md-0 span-lg-0 col-md-2 col-lg-3">
                                                  <div className="row">
                                                  <div className="col-lg-3"></div>
                                                    <button
                                                    type="button"
                                                    className="col-12 col-lg-6 mt-4 mt-md-0 mt-lg-0 ml-0 ml-md-0 ml-lg-n3 btn text-purple meetings-start-btn">
                                                      Start
                                                    </button>
                                                  <div className="col-lg-3"></div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                          <li className="meetings-ending-line mt-4 meetings-list">
                                            <div className="col-md-12 mb-3">
                                              <div className="row">
                                                <div className="col-2 col-md-1 col-lg-1">
                                                <div
                                                className="landing-img-properties width_height_40"
                                                >
                                                  <img src={user.profileurl} alt="image" className="meetings-image"></img>
                                                </div>
                                              </div>
                                                <div className="col-9 col-md-4 col-lg-3">
                                                  <div className="row">
                                                    <div className="col-12 meetings-timing-text">04:00 PM  -  05:30PM</div>
                                                    <div className="col-12 text-black meetings-gray-color">Sun, Jan 31</div>
                                                  </div>
                                                </div>
                                                  <div className="col-10 offset-2 offset-md-0 offset-lg-1 mt-3 mt-md-0 mt-lg-0 col-md-5 col-lg-4">
                                                    <div className="row">
                                                      <div className="col-12 text-black">
                                                        Design Thinking
                                                      </div>
                                                      <div className="col-12 text-black meetings-gray-color">
                                                        Serma Selvaraj
                                                      </div>
                                                    </div>
                                                  </div>
                                                    <div className="offset-3 offset-md-0 offset-lg-0 col-4 span-5 span-md-0 span-lg-0 col-md-2 col-lg-3">
                                                      <div className="row">
                                                      <div className="col-lg-3"></div>
                                                        <button
                                                        type="button"
                                                        className="col-12 col-lg-6 mt-4 mt-md-0 mt-lg-0 ml-0 ml-md-0 ml-lg-n3 btn text-purple meetings-start-btn">
                                                          Start
                                                        </button>
                                                      <div className="col-lg-3"></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="tab-pane fade show" id="nav-completed" role="tabpanel" aria-labelledby="nav-completed">
                                  <div className="row">
                                    <div className="col-12 ml-4 text-black">
                                      <div className="row my-3">
                                        <div className="col-11 col-md-11 col-lg-11">
                                          <ul className="meetings-unordered-list">
                                            <li className="meetings-ending-line mt-4 meetings-list">
                                              <div className="col-md-12 mb-3">
                                                <div className="row">
                                                  <div className="col-2 col-md-1 col-lg-1">
                                                    <div
                                                      className="landing-img-properties width_height_40"
                                                    >
                                                  <img src={user.profileurl} alt="image" className="meetings-image"></img>
                                                </div>
                                              </div>
                                              <div className="col-9 col-md-4 col-lg-3">
                                                <div className="row">
                                                  <div className="col-12 meetings-timing-text">03:30 PM  -  04:30PM</div>
                                                  <div className="col-12 text-black meetings-gray-color">Sun, Feb 7</div>
                                                </div>
                                              </div>
                                                <div className="col-10 offset-2 offset-md-0 offset-lg-1 mt-3 mt-md-0 mt-lg-0 col-md-5 col-lg-4">
                                                  <div className="row">
                                                    <div className="col-12 text-black">
                                                      UX Review
                                                    </div>
                                                    <div className="col-12 text-black meetings-gray-color">
                                                      Raghavan Shanmugam
                                                    </div>
                                                  </div>
                                                </div>
                                                  <div className="offset-3 offset-md-0 offset-lg-0 col-4 span-5 span-md-0 span-lg-0 col-md-2 col-lg-3">
                                                    <div className="row">
                                                    <div className="col-lg-3"></div>
                                                      <button
                                                      type="button"
                                                      className="col-12 col-lg-6 mt-4 mt-md-0 mt-lg-0 ml-0 ml-md-0 ml-lg-n3 btn text-purple meetings-start-btn">
                                                        Start
                                                      </button>
                                                    <div className="col-lg-3"></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                            <li className="meetings-ending-line mt-4 meetings-list">
                                              <div className="col-md-12 mb-3">
                                                <div className="row">
                                                  <div className="col-2 col-md-1 col-lg-1">
                                                    <div
                                                      className="landing-img-properties width_height_40"
                                                    >
                                                      <img src={user.profileurl} alt="image" className="meetings-image"></img>
                                                    </div>
                                                  </div>
                                                  <div className="col-9 col-md-4 col-lg-3">
                                                    <div className="row">
                                                      <div className="col-12 meetings-timing-text">04:00 PM  -  05:30PM</div>
                                                      <div className="col-12 text-black meetings-gray-color">Sun, Jan 31</div>
                                                    </div>
                                                  </div>
                                                  <div className="col-10 offset-2 offset-md-0 offset-lg-1 mt-3 mt-md-0 mt-lg-0 col-md-5 col-lg-4">
                                                    <div className="row">
                                                      <div className="col-12 text-black">
                                                        Design Thinking
                                                      </div>
                                                      <div className="col-12 text-black meetings-gray-color">
                                                        Serma Selvaraj
                                                      </div>
                                                    </div>
                                                  </div>
                                                    <div className="offset-3 offset-md-0 offset-lg-0 col-4 span-5 span-md-0 span-lg-0 col-md-2 col-lg-3">
                                                      <div className="row">
                                                      <div className="col-lg-3"></div>
                                                        <button
                                                        type="button"
                                                        className="col-12 col-lg-6 mt-4 mt-md-0 mt-lg-0 ml-0 ml-md-0 ml-lg-n3 btn text-purple meetings-start-btn">
                                                          Start
                                                        </button>
                                                      <div className="col-lg-3"></div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                          </div>
              </div>
    </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.session.user,
  meetingData: state.session.meetingData,
});

export default withRouter(connect(mapStateToProps)(Meetings));