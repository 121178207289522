import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'lodash';
import LoadingComp from '../../../../common/LoadingComp';
import { loadStripe } from "@stripe/stripe-js";
import { api } from "../../../../common/api";
import { CURRENCY_USD,CURRENCY_INR } from '../../../../common/constants';
//DCDI
let stripePromise;
const stripePromise_us = loadStripe(api.STRIPE_PK_KEY);
const stripePromise_in = loadStripe(api.STRIPE_PKIN_KEY);
let currency;
class RedirectCheckout extends Component {
    constructor(props) {
        super(props);
        //DCDI
    let currency_set=props.currency;
    console.log(currency_set);
    if(currency_set===CURRENCY_USD)
    {
    stripePromise=stripePromise_us;
    }
    else if(currency_set===CURRENCY_INR){
    stripePromise=stripePromise_in;
    }
    else{
       console.log("Check the currency");
    }
    }
    
    redirectCheckout = async () => {  
        const stripe = await stripePromise;
        const sessionID = this.props.sessionId;
        const result = stripe.redirectToCheckout({
                sessionId: sessionID,
            });
            if (result.error) {       
            // console.log('Redirect Error: ', result.error.message);
            }
    }   

    componentDidMount () {
        const script = document.createElement("script");
        script.src = "https://js.stripe.com/v3/";
        script.async = true;
        document.body.appendChild(script);

        if (_.isEmpty(this.props.user)) {
            this.props.history.push("/signin");
        } 
        else {
            this.redirectCheckout();
        }  
    }   
    render() {
        let loadingStyle = {
            minHeight: "50vh",
            textAlign: "center",
          };
        
        const { loading } = this.props;  

        return loading ? (
        <div style={loadingStyle}>
            <LoadingComp />
        </div>
        ) : ""
    };
}

const mapStateToProps = (state, ownProps) => ({
    user: state.session.user,    
    sessionId: state.session.sessionId,    
    loading: state.session.loading,
});

export default withTranslation()(connect(mapStateToProps)(RedirectCheckout));