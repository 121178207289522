import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'lodash';
import { stripeGetSubscriptions, stripeCustomerPortal, stripeGetallPrices } from '../../../../common/session/sessionAction';
import SubscribeCheckout from './subscribeCheckout';
import Trialexpired from "../../../../assets/trialexpired.svg";
import { month_Interval, year_Interval, EXPIRED_VALUE, TRIAL_VALUE } from '../../../../common/constants';
import getSymbolFromCurrency from 'currency-symbol-map';
import BoxPlans from './boxPlans';
import PaymentStaticContent from './paymentStaticContent';

class GetBilling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerToken: props.user.token,
      customerEmail: props.user.email,   // signed in user
      customerId: props.customerId, 
      subscription:'',
      customerInfo:'',
      invoiceUrl: '',
    };
  }

  componentDidMount () {
    this.props.dispatch(stripeGetallPrices()); 

    if (_.isEmpty(this.props.user) && _.isEmpty(this.props.user.token))  {    
      window. location. reload()
    }   

    if (!_.isEmpty(this.props.user) && !_.isEmpty(this.props.user.token))  {  
      this.props.dispatch(stripeGetSubscriptions({ ...this.state }));
    }          
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEmpty(nextProps.customerId) && this.props.customerId !== nextProps.customerId ) {       
      this.setState({ customerId: nextProps.customerId });
    }    
    if (
      nextProps.user.token &&
      !_.isEmpty(nextProps.user.token) &&
      !_.isEqual(this.props.user.token, nextProps.user.token)
    ) {
      this.setState({
        customerToken: nextProps.user.token ? nextProps.user.token : "",
      });
    }
    if (
      nextProps.user &&
      !_.isEmpty(nextProps.user) &&
      !_.isEqual(this.props.user, nextProps.user)
    ) {
      this.setState({
        customerEmail: nextProps.user.email ? nextProps.user.email : "",
      });
    }   
    if (!_.isEmpty(nextProps.subscription) && this.props.subscription !== nextProps.subscription ) {       
        this.setState({ subscription: nextProps.subscription });
      }
    if (!_.isEmpty(nextProps.customerInfo) && this.props.customerInfo !== nextProps.customerInfo ) {       
        this.setState({ customerInfo: nextProps.customerInfo });
      }
  }
 
// Function to convert the timestamp to human readable Date
formatDate = (dateString) => {
  const date = new Date(dateString*1000);
  const dateOptions = { timeZone: 'UTC', month: 'long', day: 'numeric', year: 'numeric' };
  return `${date.toLocaleDateString('en-US', dateOptions)}`  
  // return `${date.toUTCString('default', { month: 'long' })} ${date.getDate()}, ${date.getFullYear()}`
}

// Function to calculate the number of days between 2 timestamps
getDaysDifference = (dateString) => {
  const endAt = new Date(dateString*1000);
  const currentDate = new Date();
  const diffTime = endAt - currentDate;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}

// Dispatch to API stripeCustomerPortal on cancel button or Upgrade Now Click
handleBillingPortalClick = async() => {
  this.props.dispatch(stripeCustomerPortal({ ...this.state }));
}


render() {
    const { user, cusCurrency, allprices } = this.props;    
    
    let userTypeValue = user.userType;
    let myData;
    let currValidate, monthActive = true, annualActive = false;    
    
    // Redirect to the customer portal URL returned from the stripeGetSubscriptions
    const { invoiceUrl } = this.props;    
    if (invoiceUrl) {
      window.location.href = invoiceUrl;
    }
    
    // Check whether the customer has default currency based on previous subscription
    if(user.token) {  
      if(cusCurrency) {
        currValidate = cusCurrency;
      }       
    }

    // Used while the customer cancels the plan
    // Make the customer's currently selected plan's box highlighted
    if(this.state && this.state.subscription && this.state.subscription.plan.interval){
      let pinterval = this.state.subscription.plan.interval;
      switch (pinterval) {
        case month_Interval:   
          monthActive = true ;  
          annualActive = false;
          break;
        case year_Interval:
          monthActive = false;
          annualActive = true;
          break; 
        default:
          break;                  
      }
    }
    const boxes = [
      monthActive,annualActive
    ];

    if(this.state && this.state.customerInfo && this.state.customerInfo.cancelAtPeriodEnd){
      // CANCEL FLOW
      return(
        // RENEWAL FLOW
        <div>
          <div className="row mt-3 ml-0 billing monthlyplan currentplan">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="col-12 billing-plan-title mb-2">
                Your Standard subscription plan has been cancelled. 
              </div>
              <div className="col-12 mb-4 ml-1">
                <span className="billing-benefits-text">Your benefits will continue until </span>
                <span className="billing-date-text">{(this.state && this.state.subscription && this.state.subscription.current_period_end)?
                  (this.state.subscription.current_period_end)?`${this.formatDate(this.state.subscription.current_period_end)}`:''
                  :''}
                </span>
              </div>
              {/* <div className="col-12 mb-4">
                <span className="billing-reactivate-text">To reactivate your {getSymbolFromCurrency(this.props.cusCurrency)}&nbsp;{(this.state.subscription.plan.amount) / 100} 
                  {(this.state.subscription.plan.interval===month_Interval)? " monthly" : " yearly"}
                  &nbsp;subscription&nbsp;
                </span>
                <Link 
                  id="on"
                  className="text-purple"
                  onClick ={() => this.handleBillingPortalClick()}>
                  <span className="text-decoration-underline">Reactivate Now</span>
                </Link>
              </div> */}
              
              {/* Two boxes */}
              <div className="container current-plan ml-n3 ml-md-0 ml-lg-0">
              <div className="row mt-3 ml-n4">           
              { 
                myData = [].concat(allprices && allprices)
                .filter(allprices => allprices.priceCurrency.includes(currValidate))
                .sort((a,b) => a.priceUnitAmount < b.priceUnitAmount ? 1 : -1)
                .map((price, i) => 
                  <div>
                    <div className={"mr-3 mb-3 mb-md-0 mb-lg-0 ml-5 ml-md-4 ml-lg-4 billing-card sub-check box-plan boxLayout" + (boxes[i++] ? 'inactiveplan' : 'active')}>
                      <div className={"displaybox"}>
                      {/* Boxplan Component */}
                      <BoxPlans boxArray = {i} boxPrice = {price} />  
                      </div>
                    </div>  
                  </div>
                )
                }     
                <div className="col-11 col-md-0 col-lg-4 mr-3 mt-0 mt-md-3 mt-lg-3 mb-3 mb-md-n2 mb-lg-0 pr-5 pr-md-0 pr-lg-5 ml-5 ml-md-2 ml-lg-2 ">
                <span>You are allowed to change your subscription plan after &nbsp;
                <span>{(this.state && this.state.subscription && this.state.subscription.current_period_end)?
                    (this.state.subscription.current_period_end)?`${this.formatDate(this.state.subscription.current_period_end)}`:''
                    :''}
                </span>
                </span>
                </div>    
              </div>
            </div> 
            <div className="col-12 mt-1 mt-md-4 mt-lg-4">
                <Link 
                  id="on"
                  className="btn btn-purple buy btn-lg"
                  onClick ={() => this.handleBillingPortalClick()}>
                  <span>Reactivate Now</span>
                </Link>
            </div>
            <div className="col-12 mt-3 mb-n2">Your subscription will be auto-renew on payment</div>
            &nbsp;
            {/* <div className="col-12 mb-2 ml-1">
              <span className="billing-expiration-text">You are allowed to change your subscription plan after &nbsp;
              <span className="billing-date-text">{(this.state && this.state.subscription && this.state.subscription.current_period_end)?
                    (this.state.subscription.current_period_end)?`${this.formatDate(this.state.subscription.current_period_end)}`:''
                    :''}
              </span>
              </span>
            </div> */}

                <PaymentStaticContent />
            </div>
          </div>
        </div>
      );
    }
    else {
      return (
        // If the customer have/ have not subscribed a plan
        <div className="ml-0 ml-md-3">
          <div className="ml-n4 ml-md-n5">
            <div className="row mt-3 ml-0 billing monthlyplan currentplan">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="col-12">                  
                  {(this.state && this.state.subscription && this.state.subscription.plan)? 
                  // User Current Plan                 
                  <div className="container current-plan ml-n3 ml-md-0 ml-lg-0">
                  <div className="row mt-3">
                    <div className="col-12 ml-4 billing-plan-title mb-4">
                      {/* You are currently on a Standard {this.state.subscription.plan.interval}ly plan. */}
                      You are currently on a Standard {(this.state.subscription.plan.interval===month_Interval)? "Monthly" : "Annual"} plan!             
                    </div>
                    <div className="container current-plan ml-n3 ml-md-0 ml-lg-0">
                    <div className="row mt-3 ml-4 ml-md-3">
                    { 
                      myData = [].concat(allprices && allprices)
                      .filter(allprices => allprices.priceCurrency.includes(currValidate))
                      .sort((a,b) => a.priceUnitAmount < b.priceUnitAmount ? 1 : -1)
                      .map((price, i) => 
                        <div className="">                         
                          <div className={"mr-3 mb-3 ml-2 mb-md-0 mb-lg-0 billing-card sub-check box-plan boxLayout" + (boxes[i++] ? 'inactiveplan' : 'active')}>
                            <div className={"displaybox"}>
                            {/* Boxplan Component */}
                            <BoxPlans boxArray = {i} boxPrice = {price} />  
                            </div>
                          </div>  
                          {/* <div className="ml-2 mt-2 mb-2 mb-md-0 mb-lg-0 billing-days-left-text">{this.getDaysDifference(this.state.subscription.current_period_end)} days left</div> */}
                          {/* {(this.state.subscription.plan.interval===year_Interval)? 
                          // <div className={"ml-2 mt-2 mb-2 mb-md-0 mb-lg-0 billing-days-left-text " + (boxes[i++] ? 'd-block' : 'd-none')}>{this.getDaysDifference(this.state.subscription.current_period_end)} days left</div>
                           :
                          // <div className={"ml-2 mt-2 mb-2 mb-md-0 mb-lg-0 billing-days-left-text " + (boxes[i++] ? 'd-none' : 'd-block')}>{this.getDaysDifference(this.state.subscription.current_period_end)} days left</div>
                            ""
                            }
                            {(this.state.subscription.plan.interval===month_Interval)? 
                          // <div className={"ml-2 mt-2 mb-2 mb-md-0 mb-lg-0 billing-days-left-text " + (boxes[i++] ? 'd-block' : 'd-none')}>{this.getDaysDifference(this.state.subscription.current_period_end)} days left</div>
                           :
                          // <div className={"ml-2 mt-2 mb-2 mb-md-0 mb-lg-0 billing-days-left-text " + (boxes[i++] ? 'd-none' : 'd-block')}>{this.getDaysDifference(this.state.subscription.current_period_end)} days left</div>
                            ""
                            } */}
                        </div>
                      )
                    }  
                    <div className="col-12 col-md-0 col-lg-4 mr-3 mt-0 mt-md-3 mt-lg-3 mb-3 mb-md-n2 mb-lg-0 ml-0 ml-md-n2 ml-md-0 ">You are allowed to change from {(this.state.subscription.plan.interval===month_Interval)? " Monthly to Annual plan" : "Annual to Monthly plan"} only after cancel your current subscription plan</div>
                    </div>  
                    </div>
                  </div>
                  </div>     
                  :
                  // If the user has already been in a plan but currently not in any, show them relevant plans based on their already chosen currency
                  (cusCurrency)?
                    <SubscribeCheckout/>
                  :
                  // If there is no subscription for the customer, show them all the plans available
                  //Test whether the user is in trial period or have expired
                  <div>
                    <div className="row current-plan ml-3 ml-md-0 ml-lg-0">
                      {userTypeValue === TRIAL_VALUE &&
                        <div>
                          <div className="col-12 mt-3 ml-n2 ml-md-3">
                            <span className="billing-title">You are currently on a free trial period</span><br/>
                          </div>
                          <div className="col-12 mt-2 ml-n2 ml-md-3">
                            <span className="billing-free-trial-text">40 Minutes time limit on meeting with 50 Participants</span>
                          </div>
                        </div>
                      }
                      {userTypeValue === EXPIRED_VALUE &&
                        <div>
                          <div className="col-12 ml-n2 ml-md-3">
                            <span className="billing-title"><img src={Trialexpired} alt="trialexpired" className="mr-n1 mt-n1"/>  Trial Expired!</span><br/>
                          </div>
                          <div className="col-12 mt-2 ml-n2 ml-md-3">
                            <span className="billing-trial-expired-text">Your trial period for this application has now expired, please choose your plan to upgrade.</span>
                          </div>
                        </div>
                      }
                    </div>
                    <SubscribeCheckout/>
                  </div>       
                }
              </div>
              <div className="ml-0 ml-md-n2">
                { (this.state && this.state.subscription)? 
                    <div className="ml-3 ml-md-5">
                      <div className="col-12 my-3"> 
                      <div className="billing-days-left-text">{this.getDaysDifference(this.state.subscription.current_period_end)} days left</div>
                      </div>
                      <div className="col-12 mt-4 mb-3">
                        {/* <span className="billing-days-left-text">{this.getDaysDifference(this.state.subscription.current_period_end)} days left </span>&nbsp; */}
                        <span>Your subscription will auto renew upon expiration. You are allowed 
                          to <Link 
                        // to="/redirectCustomerPortal"
                          id="on"
                          className="text-purple"
                          // onClick ={() => this.handleBillingPortalClick()}
                          data-toggle="modal" data-target="#ConfirmPaymentCancel"
                          >                   
                          cancel subscription
                        </Link> at anytime during your activation period.</span>
                      </div>
                      {/* Payment Cancel Modal */}
                      <div className="container">
                        <div className="row content-center">
                            <div id="ConfirmPaymentCancel" className="modal fade payment-cancel" tabIndex="-1" role="dialog">
                                <div className="modal-dialog modal-dialog-centered cancel-modal" role="document">
                                    <div className="modal-content">
                                      <div className="modal-body payment-cancel my-3 my-md-0 ml-0 ml-md-4 p-4 p-md-5">
                                        <div className="payment-cancel-message ml-n2 ml-md-0 mb-5">Are you sure you want to cancel your subscription?</div>
                                        <div className="">
                                            <ul className="payment-cancel-instructions my-4 ml-4 ml-md-0 pr-0 pr-md-5 payment-cancel-list">
                                                <li className="payment-cancel-instructions mb-2 pr-4 pr-md-0">After canceling your subscription monthly/annual, your plan remains active until the end of your billing period.</li>
                                                <li className="payment-cancel-instructions mb-2"> To avoid getting charged for another billing cycle, you need to cancel your plan at least one day before your next scheduled billing date.</li>
                                                <li className="payment-cancel-instructions mb-2">You will not get refund at this moment. Kindly visit <a className="text-purple" href="#"> FAQ’s</a> for further details.</li>
                                            </ul>
                                        </div>
                                        <div className ="text-center text-md-right mt-0 mt-md-4 pr-0 pr-md-4">
                                        <button type="button" className="btn btn-cancel text-center payment-cancel-back-btn" data-dismiss="modal">No</button>
                                        <button type="button" id="payment-cancel-proceed-btn-alignment"className="ml-0 ml-md-2 mt-3 mt-md-0 btn btn-cancel text-center payment-cancel-proceed-btn" onClick ={() => this.handleBillingPortalClick()}>Proceed</button>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                      {/* <div className="col-12 mb-4">
                        <Link 
                        // to="/redirectCustomerPortal"
                          id="on"
                          className="text-purple"
                          onClick ={() => this.handleBillingPortalClick()}>                   
                          Cancel Subscription
                        </Link>
                      </div> */}
                      {/* <div className="col-12 monthly-next-payment-text mb-1">Next Payment</div> 
                      <div className="col-12 mb-4 monthly-next-payment-date">
                        {(this.state && this.state.subscription && this.state.subscription.current_period_end)?
                          (this.state.subscription.current_period_end)?`${this.formatDate(this.state.subscription.current_period_end)}`:''
                        :''}
                      </div> */}
                        <PaymentStaticContent />                    
                    </div>      
                      : ''
                  }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  }
} 

const mapStateToProps = (state, ownProps) => ({
  user: state.session.user,
  token: state.session.token, 
  customerId: state.session.customerId,
  subscription: state.session.subscription,
  customerInfo: state.session.customerInfo,
  sessionId: state.session.sessionId,
  cusCurrency: state.session.customerCurrency,
  allprices: state.session.allpricesList,
  invoiceUrl: state.session.invoiceUrl,
});

export default withTranslation()(connect(mapStateToProps)(GetBilling));