import React, { Component } from "react";
import ProductFeature1 from "../assets/productfeature1.png";
import ProductFeature2 from "../assets/productfeature2.svg";
import ProductFeature3 from "../assets/productfeature3.svg";
import ProductFeature4 from "../assets/productfeature4copy.png";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

class ProductFeatures extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
  }
  state = {};
  render() {
    const { t } = this.props;
    return (
      <div>
        <div className="row no-gutters">&nbsp;</div>
        <div className="row no-gutters">&nbsp;</div>
        <section style={{ backgroundColor: "#5D3EA6"/*,minHeight: "55vh"*/ }}>
          <div className="row no-gutters" style={{ height: 21.4 + "vh" }}></div>
          <div className="container">
            <div className="row">
              <div className="col-md-6 mt-md-n4 mt-n4" style={{ color:"#E5CBFF" }}>
                <h3
                  className="text-white font-weight-normal"
                  style={{
                    fontSize: "42px",
                    fontFamily: "Gilroy Extrabold",
                  }}
                >
                  Product Features
                </h3>
                <br/>
                <br/>
                <div
                  className="text-white"
                  style={{
                    fontSize: "24px",
                  }}
                >
                  <span>Unlimited Collaboration with Jaam!</span>
                  <br/>
                  <br/>
                  <span>
                    JAAM is One-Stop-Shop for all your Audio &amp; Video web
                    conference needs.
                  </span>
                  <br></br>
                  <br></br>
                  <br></br>
                  <p>
                    <Link
                      to="/landing"
                      className="col-12 col-sm-5 btn btn-orange btn-lg text-white"
                    >
                      Try for free
                    </Link>
                  </p>
                </div>
              </div>
              <div className="showxs col12"><br/>
              <br/></div>
              <div className="col-md-6 ml-lg-n5 ml-md-n4">
                <img
                  src={ProductFeature1}
                  className="mt-lg-n5 mt-md-5 full-image ml-md-n4 width-height-change-on-pc"
                  width="122%"
                  alt=""
                ></img>
              </div>
              <div className="col-md-12">
                <br></br>
                <br></br>
              </div>
            </div>
          </div>
          <div className="row no-gutters" style={{ height: 8 + "vh" }}></div>
        </section>

        <section style={{ backgroundColor: "#FFEBC4" }}>
          <div className="row no-gutters" style={{ height: 10 + "vh" }}></div>
          <div className="container">
            <div className="row showmd">
            <div className="col-md-6 ml-md-n3 mt-md-3 mt-0">
                <img
                  src={ProductFeature2}
                  // height="120%"
                  width="115%"
                  className="mt-4 ml-md-n5 mt-md-n2"
                  alt=""
                ></img>
              </div>
              <div className="col-md-6 ml-md-3">
                <h3
                  className="font-weight-normal showmd"
                  style={{
                    fontSize: "40px",
                    fontFamily: "Gilroy Extrabold",
                  }}
                >
                  Communicate instantly on Jaam Chat
                </h3>
                <div align="justify"
                >
                  <p>
                    Instant team communication with the Group Chat &amp; 1-1
                    features
                  </p>
                  <p>
                    Group and 1-1 chat allows you to connect instantly with
                    members of your meetings.
                  </p>
                  <p>
                    Users will receive notification alerts ensuring that
                    messages aren’t missed as well as always be able to see
                    which users are currently connecting through private chat.
                  </p>
                  <br></br>
                </div>
              </div>
              
            </div>
            <div className="row showxs">
            
              <div className="col-md-6 ml-3 ml-md-0">
                <h3
                  className="font-weight-normal"
                  style={{
                    fontSize: "40px",
                    fontFamily: "Gilroy Extrabold",
                  }}
                >
                  Communicate instantly on <br/>Jaam Chat
                </h3>
                <div align="justify"
                >
                  <p>
                    Instant team communication with the Group Chat &amp; 1-1
                    features
                  </p>
                  <p>
                    Group and 1-1 chat allows you to connect instantly with
                    members of your meetings.
                  </p>
                  <p>
                    Users will receive notification alerts ensuring that
                    messages aren’t missed as well as always be able to see
                    which users are currently connecting through private chat.
                  </p>
                  <br></br>
                </div>
              </div>
              <div className="col-md-6 mt-md-n5 mt-0">
                <img
                  src={ProductFeature2}
                  height="120%"
                  width="110%"
                  className="mt-4 full-image mt-md-n2"
                  alt=""
                ></img>
              </div>
            </div>
          </div>
          <div className="row no-gutters" style={{ height: 10 + "vh" }}></div>
        </section>

        <section style={{ backgroundColor: "#E2F5F5" }}>
          <div className="row no-gutters" style={{ height: 10 + "vh" }}></div>
          <div className="container">
            <div className="row">
              
              
              <div className="col-md-5 ml-2 ml-md-2">
                <h3
                  className="font-weight-normal showmd mt-md-0 mt-4 mb-3"
                  style={{
                    fontSize: "40px",
                    fontFamily: "Gilroy Extrabold",
                  }}
                >
                  Participants List View
                </h3>
                <h3
                  className="font-weight-normal showxs mt-md-0 mt-4 mb-3"
                  style={{
                    fontSize: "40px",
                    fontFamily: "Gilroy Extrabold",
                  }}
                >
                  Participants <br/>List View
                </h3>
                <div align="justify"
                >
                  <p>
                    Hide and show thumbnails and just select to pin video Host
                    of the meeting always on top of the list, Order by
                    alphabetical order to easily find them from top to bottom
                  </p>
                  <p>
                    View participants status with easy to understand icons for
                    audio mute/unmute, video off/on and raise hand
                  </p>
                  <p>
                    Provided contextual actions like mute all, raise hand and
                    invite participants as part of the panel
                  </p>
                </div>
                
              </div>
              <div className="col-md-1">&nbsp;</div>
              <div className="col-md-6 mt-md-5 ml-n2">
                <img
                  src={ProductFeature3}
                  width="125%"
                  height="100%"
                  className="mt-md-n4 full-image ml-md-n5"
                  alt=""
                ></img>
              </div>
            </div>
          </div>
          <div className="row no-gutters" style={{ height: 10 + "vh" }}></div>
        </section>

        <section className="changebackground" /*style={{ backgroundColor: "#112935" }}*/>
          <div className="container">
          <div className="row no-gutters" style={{ height: 5 + "vh" }}></div>
            <div className="row mb-md-n4">
              <div className="col-lg-8">
                <h3
                  className="font-weight-normal mb-3 text-white"
                  style={{
                    fontSize: "40px",
                    fontFamily: "Gilroy Extrabold",
                  }}
                >
                  YOUR meeting YOUR way..
                </h3>

                  <p align="justify"
                  className="text-white">
                  Choose your theme and keep engage yourself.. when you get
                  bored, no worries! We have lot of exciting visuals to
                  encourage you to get rid of the boring meetings!
                </p>
              </div>
              <div className="col-md-12 ml-md-n1 mt-md-4 ml-n2">
                <img
                  src={ProductFeature4}
                  width="100%"
                  height="100%"
                  className="mt-md-n4 text-center ml-md-n5"
                  alt="pd4"
                ></img>
              </div>
            </div>
          </div>       
        </section>
      </div>
    );
  }
}

export default ProductFeatures;
