import _ from 'lodash';
import queryString from 'query-string';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { api } from "../common/api";

import StatusHelper from '../common/helpers/statusHelper';
import LoadingComp from '../common/LoadingComp';
import { join , joinPasscode } from '../common/session/sessionAction';
import SnackBar from '../common/SnackBar';
import axios from "axios";
let API_URL = api.API_URL;


class Join extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomName: "",
      displayName: props.user.firstname,
      url_passcode: "",
      encryptedPasscode: "",
      passcode: "",
      enablePasscode:"",
      hostLock: "",
      hostActive: "",
      showSnack: false,
      statusData: {},
      urlJoin : false,
    };
  }
  statusHelper = new StatusHelper(this);

  joinMeeting = (e) => {
    e.preventDefault();
    var token = this.props.token;
    var { url_passcode , displayName , urlJoin } = this.state;
    const formIsValid = this.validateForm();
    if(formIsValid){
      if(!url_passcode && displayName ) {
        this.props.dispatch(
          join({
            ...this.state,
            token: token,
          })
        );
      }
        if ( urlJoin ) {
          if((this.state.hostLock === true && this.state.hostActive === 1) || (this.state.hostLock === false)){
            if(this.state.url_passcode === this.state.encryptedPasscode){
                this.props.dispatch(
                  joinPasscode({
                    ...this.state,
                    token: token,
                  })
                );
              }
              else if(this.state.url_passcode !== this.state.encryptedPasscode){
              }
          }else{
            this.props.history.push({
              pathname: '/meetingLock'
            })
          }
        }
    }
  };
  updateState(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.user &&
      !_.isEmpty(nextProps.user) &&
      !_.isEqual(this.props.user, nextProps.user)
    ) {
      this.setState({
        displayName: nextProps.user.firstname ? nextProps.user.firstname : "",
      });
    }

    if (
      nextProps.joinError &&
      nextProps.joinError !== "" &&
      this.props.joinError !== nextProps.joinError
    ) {
      nextProps.joinErrorCode === "ERR_01" ? this.setState({ showSnack: true }) : this.setState({statusData: {
        type: 'error',
        message: nextProps.joinError
      }});
    }
    
    if(nextProps.hostLock!== null && nextProps.hostLock === true && nextProps.hostActive !== 1){
      localStorage.setItem("MeetingID",this.state.roomName);
      localStorage.setItem("DisplayName",this.state.displayName);
      this.props.history.push({
        pathname: '/meetingLock',
        state: { name: this.state.roomName }
      })
    }
    else if (nextProps.enablePC!== null && nextProps.enablePC === true ) {
      this.props.history.push({
        pathname: '/joinPasscode',
        state: { name: this.state.displayName }
      })
    }

  }
  componentDidMount() {
    const { user } = this.props;
    const url = new URLSearchParams(window.location.search);
    const meetingId = this.props.match.params.id ? this.props.match.params.id : '';
    const meetingPasscode= this.props.match.params.pwd ? this.props.match.params.pwd : '';
    let queryStr = queryString.parse(this.props.location.search);
    this.setState({
      roomName: queryStr.meet ? queryStr.meet : "",
    });
    // const { meetingId } = this.props.match.params
    // var decryptedPasscode = encryptor.decrypt(meetingPasscode);
    if(meetingId) {
      this.setState({
        roomName: meetingId ? meetingId : "",
        url_passcode: meetingPasscode ? meetingPasscode: "",
        urlJoin : true,
      });
    }
    let meetingName = meetingId;
    let meetingkey = meetingPasscode;
    let encryptedPasscode =  this.state;
    let passcode = this.state;
    let enablePasscode = this.state;
    if (meetingName !== "" && meetingkey !== "") {
    var encodedURI = window.encodeURI(`${API_URL}/getMeeting`);
      axios
          .post(encodedURI, ({ meetingId: meetingName }))
          .then((response) => {
              encryptedPasscode = response.data.data.meetingData.encryptedPasscode;
              passcode = response.data.data.meetingData.passcode;
              enablePasscode = response.data.data.meetingData.enablePasscode;
              this.setState({
                  meetingData: encryptedPasscode,passcode,
                  encryptedPasscode: encryptedPasscode,
                  passcode : passcode,
                  enablePasscode : enablePasscode
              });
          })
          .catch((error) => {
              console.log("getting meeting details error.");
          });
    }
    let hostLock = this.state;
    let hostActive = this.state;
    if (meetingName !== "") {
      var encodedURI = window.encodeURI(`${API_URL}/getMeeting`);
        axios
            .post(encodedURI, ({ meetingId: meetingName }))
            .then((response) => {
                hostLock = response.data.data.meetingData.hostLock;
                hostActive = response.data.data.meetingData.hostActive;
                this.setState({
                    hostLock : hostLock,
                    hostActive : hostActive
                });
            })
            .catch((error) => {
                console.log("getting meeting details error.");
            });
      }
  }
  

validateForm(){
  var {url_passcode,urlJoin} = this.state;
  let formIsValid = true;
  if(url_passcode !== "" && urlJoin){
    if(this.state.url_passcode !== this.state.encryptedPasscode){
      formIsValid = false;
      this.setState({statusData: {
        type: 'error',
        message: "Please Enter a Valid URL"
      }});
    }
  }
  if(this.state.enablePasscode === false){
    if(url_passcode !=="" && urlJoin){
      formIsValid = false;
      this.setState({statusData: {
        type: 'error',
        message: "Please Enter a Valid URL"
      }});
    }
  }
  if (typeof this.state.displayName !== "undefined") {
    if (!this.state.displayName.match(/^[a-zA-Z ]*$/)) {
      formIsValid = false;
      this.setState({statusData: {
        type: 'error',
        message: "Please Enter a Proper Name"
      }});
    }
    }
    return formIsValid;
  }

  render() {
    const { t } = this.props;
    const { roomName, displayName } = this.state;
    const { loading, userLoading, joinError, joinErrorCode, enablePC } = this.props;
    let loadingStyle = {
      minHeight: "50vh",
      textAlign: "center",
    };
    return loading || userLoading ? (
      <div style={loadingStyle} className="background">
        <LoadingComp />
      </div>
    ) : (
      <section className="background">
        <div className="container container-decoration">
          <div className="row content-center">
            <div className="col-xl-8">
              <div className="row">
                <div className="col-lg-12">
                  <h2 className="text-center">{t("join.title")}</h2>
                </div>
              </div>
              <form onSubmit={this.joinMeeting}>                
                <div className="row mt-4">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                  <input
                      type="text"
                      className="form-control form-control-lg"
                      id="txtMeetingCode"
                      placeholder="Enter the meeting ID"
                      name="roomName"
                      value={roomName}
                      onChange={(e) => this.updateState(e)}
                    ></input>
                    {joinErrorCode === "ERR_02" && (
                      <span className="text-danger error-text">
                        {t("join.validation.meetingid")}
                      </span>
                    )}{" "}
                  </div>
                  <div className="col-md-4 mt-4 mt-md-0">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="displayName"
                      placeholder="Enter your name"
                      name="displayName"
                      value={displayName}
                      maxLength="25"
                      onChange={(e) => this.updateState(e)}
                      // disabled={displayName !== ''}
                    ></input>
                  </div>
                  <div className="col-md-2"></div>
                </div>
                <div className="row mt-4 mb-4 mx-auto">
                  <div className="col-md-2"></div>
                  <button
                    className="col-md-8 btn btn-purple btn-lg"
                    disabled={
                      this.state.displayName === undefined ||
                      this.state.displayName === "" ||
                      this.state.roomName === ""
                    }
                  >
                    {t("join.joinbutton")}
                    
                  </button>
                </div>
                <div className="col-md-2"></div>
              </form>
              {Object.keys(this.state.statusData).length > 0 &&
                this.statusHelper.renderStatus(this.state.statusData)}
            </div>
          </div>
        </div>
        {this.state.showSnack ? (
          <SnackBar
            message={joinError}
            snackShow={true}
            minwidth="600px"
          ></SnackBar>
        ) : (
          ""
        )}{" "}
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.session.user,
  token: state.session.token,
  joinLoading: state.session.joinLoading,
  enablePC: state.session.enablePC,
  hostLock: state.session.hostLock,
  hostActive: state.session.hostActive,
  loading: state.session.loading,
  joinError: state.session.joinError,
  joinErrorCode: state.session.joinErrorCode,
  selectedTheme: state.session.selectedTheme,
});

export default withTranslation()(connect(mapStateToProps)(Join));
