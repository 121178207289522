import React, { Component } from "react";

// statusData is an object which required two keys to function this helper
// statusData.type = "error" || "success"
// statusData.message ="test message"

class StatusHelper extends Component {
  renderStatus(statusData) {
    let classname = "alert alert-";
    classname +=
      statusData["type"].toLowerCase() === "error" ? "danger" : "success";
    return <div className={classname}>{statusData["message"]}</div>;
  }

  // TODO move this method to the respective file.
  validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );
}

export default StatusHelper;
