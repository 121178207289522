import React, { Component } from "react";
import image1 from "../assets/image1.png";
import image2 from "../assets/image2.svg";
import { Link } from "react-router-dom";
import simplified from "../assets/simplified.svg";
import funfilled from "../assets/funfilled.svg";
import security from "../assets/security.svg";
import quote_left from "../assets/quote_left.svg";
import quote_right from "../assets/quote_right.svg";
import swipe_left from "../assets/swipe_left.svg";
import swipe_right from "../assets/swipe_right.svg";
import no_download from "../assets/no_download.svg";
import thumbvideo from "../assets/thumbvideo.png";
import { withTranslation } from "react-i18next";
import VideoContainer from "./videocontainer";
import promo from'../assets/videos/promotional.mp4';
class Home extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    
  }
  state = {};
  componentDidMount(){
    const box = document.querySelector('video');
    let flg=0;
    let options = {
      rootMargin: "0px",
      threshold: [0.25, 0.75]     
    };

    let handlePlay = (entries, observer) => {
      entries.forEach((entry) => {
        if(entry.target.id=='video'){
          entry.target.addEventListener('ended', function(){
            entry.target.load();
            flg=1;
          });
          entry.target.addEventListener('click', function(){
            flg=0; 
          });
          if(entry.isIntersecting&&flg==0){
            entry.target.play();
            
          }
          else{
            entry.target.pause();
            
          }
        }
      });
    };

    let observer = new IntersectionObserver(handlePlay, options);
    observer.observe(box);
  }

  render() {
    const { t } = this.props;
    const width=this.props.width || '100%';
    const height=this.props.height || 'auto';
  
    return (
      <div>
        <div className="row no-gutters">&nbsp;</div>
        <div className="row no-gutters">&nbsp;</div>
        <section>
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-ride="carousel"
          >
            <ol className="carousel-indicators">
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="0"
                className="active"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="1"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="2"
              ></li>
            </ol>
            <div className="carousel-inner">
              <div className="carousel-item active banner banner1">
                <div className="container">
                  <div className="carousel-item-body text-purple">
                    <h1>YOUR meeting YOUR way....</h1>
                    <p>Funfilled, simple and secured!</p>
                    <Link
                      to="/landing"
                      className="col-8 col-sm-5 btn btn-orange btn-lg text-white"
                    >
                      Try for free
                    </Link>
                  </div>
                </div>
              </div>
              <div className="carousel-item banner banner2">
                <div className="container">
                  <div className="carousel-item-body text-B399F1">
                    <h1>Tired of remote meetings?</h1>
                    <p>Let's Jazz it up, buckle-up for a fun ride.</p>
                    <Link
                      to="/landing"
                      className="col-8 col-sm-5 btn btn-orange btn-lg text-white"
                    >
                      Try for free
                    </Link>
                  </div>
                </div>
              </div>
              <div className="carousel-item banner banner3">
                <div className="container">
                  <div className="carousel-item-body text-525260">
                    <h1>Who said remote meetings are</h1>
                    <h1>boring?</h1>
                    <p>Come on in, excitement guaranteed!</p>
                    <Link
                      to="/landing"
                      className="col-8 col-sm-5 btn btn-orange btn-lg text-white"
                    >
                      Try for free
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <img
                src={swipe_left}
                style={{ height: "40px", width: "40px" }}
                alt=""
              ></img>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <img
                src={swipe_right}
                style={{ height: "40px", width: "40px" }}
                alt=""
              ></img>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </section>
        <div className="home_videocontainer" id="homevideo">
        <video id="video" poster={thumbvideo} width={width} height={height} controls muted>
  {/* <source src={promo} type="video/mp4"/> */}
  <source src="https://jaampromo.s3.us-east-2.amazonaws.com/promotional.mp4" type="video/mp4"/>
Your browser does not support the video tag.
</video>
           {/* <VideoContainer width="100%" height="auto" src="https://jaampromo.s3.us-east-2.amazonaws.com/promotional.mp4#t=0.1" />  */}
        </div>
        <section style={{ backgroundColor: "#FCBF49" }}>
          <div className="row no-gutters" style={{ height: 8 + "vh" }}></div>
          <div className="container  showmd">
            <div className="row">
              <div className="col-md-6">
                <img src={image1} height="100%" width="100%" alt=""></img>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-5 text-white ml-3 ml-md-0">
                <h3
                  className="font-weight-normal mb-3"
                  style={{
                    fontSize: "40px",
                    fontFamily: "Gilroy Extrabold",
                  }}
                >
                  HD Video Call Using Jaam Squares
                </h3>
                <div
                  style={{
                    fontSize: "24px",
                  }}
                >
                  <span>Unlimited collaboration.</span>
                  <br></br>
                  <span>Up to 50 participants in a meeting.</span>
                  <br></br>
                  <span>Easy to switch between speaker and gallery view.</span>
                  <br></br>
                  <span>Raise hand feature for attention.</span>
                </div>
              </div>
            </div>
          </div>
          <div className="container showxs">
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-5 text-white ml-3 ml-md-0">
                <h3
                  className="font-weight-normal mb-3"
                  style={{
                    fontSize: "40px",
                    fontFamily: "Gilroy Extrabold",
                  }}
                >
                  HD Video Call Using Jaam Squares
                </h3>
                <div
                  style={{
                    fontSize: "24px",
                  }}
                >
                  <span>Unlimited collaboration.</span>
                  <br></br>
                  <span>Up to 50 participants in a meeting.</span>
                  <br></br>
                  <span>Easy to switch between speaker and gallery view.</span>
                  <br></br>
                  <span>Raise hand feature for attention.</span>
                  <br></br>
                  <br></br>
                </div>
              </div>
              <div className="col-md-6">
                <img src={image1} height="100%" width="100%" alt=""></img>
              </div>
            </div>
          </div>
          <div className="row no-gutters" style={{ height: 5 + "vh" }}></div>
        </section>

        <section style={{ backgroundColor: "#81af2a" }}>
          <div className="row no-gutters" style={{ height: 8 + "vh" }}></div>
          <div className="container">
            <div className="row">
              <div className="col-md-6 text-white ml-3 ml-md-0">
                <h3
                  className="font-weight-normal"
                  style={{
                    fontSize: "40px",
                    fontFamily: "Gilroy Extrabold",
                  }}
                >
                  <img
                    className="mb-2"
                    src={no_download}
                    style={{ height: "50px", width: "40px" }}
                    alt=""
                  ></img>{" "}
                  No Downloads Required
                </h3>
                <div
                  style={{
                    fontSize: "24px",
                  }}
                >
                  <span>
                    Simply log on to our website and start using our meeting.
                  </span>
                  <br></br>
                  <span>
                    Supports majority of browsers including Internet Explorer,
                    Google Chrome, Mozilla Firefox and Safari.
                  </span>
                  <br></br>
                  <span>
                    Log in to the meeting from any devices and countries.
                  </span>
                </div>
              </div>
              <div className="showxs">
                <br></br>
                <br></br>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-5">
                <img src={image2} height="100%" width="100%" alt=""></img>
              </div>
            </div>
          </div>

          <div className="row no-gutters" style={{ height: 5 + "vh" }}></div>
        </section>

        <section className="banner_bg_stars">
          <div className="row no-gutters" style={{ height: 8 + "vh" }}></div>
          <div className="container">
            <div className="row">
              <div className="col-md-4 ml-3 ml-md-0 mr-md-5">
                <p
                  className="text-6D6D74"
                  style={{
                    fontSize: "24px",
                    fontFamily: "Gilroy",
                    fontWeight: 800,
                  }}
                >
                  <img src={simplified} width="30" height="30" alt=""></img>{" "}
                  Simplified
                </p>

                <p>
                  The secret ingredient of any greatest innovation is to keep
                  things simple.
                </p>
                <p>
                  JAAM is One-Stop-Shop for all your Audio &amp;Video web
                  conference needs.
                </p>
                <p>
                  Connect anywhere, anytime, any device and with many offices.
                </p>
              </div>
              <div className="col-md-1 border-left"></div>
              <div className="col-md-3 ml-3 ml-md-n5 mr-md-5">
                <p
                  className="text-6D6D74"
                  style={{
                    fontSize: "24px",
                    fontFamily: "Gilroy",
                    fontWeight: 800,
                  }}
                >
                  <img src={funfilled} width="30" height="30" alt=""></img>{" "}
                  Fun-filled
                </p>

                <p>FUN is just a click-away from you!</p>
                <p>
                  Rest assured to embark a Fantasy ride with <b>Jaam</b> to
                  break the long boring meetings into fun-filled meetings.
                </p>
                <p>
                  Get <b>Engaged, Creative and Funtastic</b> with Jaam's
                  Augmented and Mixed reality solution offerings.
                </p>
              </div>
              <div className="col-md-1 border-left"></div>
              <div className="col-md-3 ml-3 ml-md-n5">
                <p
                  className="text-6D6D74"
                  style={{
                    fontSize: "24px",
                    fontFamily: "Gilroy",
                    fontWeight: 800,
                  }}
                >
                  <img src={security} width="30" height="30" alt=""></img>{" "}
                  Security
                </p>

                <p>Security is not a service it is a Due-Diligence.</p>
                <p>
                  Feel secured and worry-free with Jaam's security protocol
                  commitments and policies.
                </p>
              </div>
            </div>
          </div>
          <div className="row no-gutters" style={{ height: 5 + "vh" }}></div>
        </section>

        <section
          style={{
            background:
              "linear-gradient(180deg, #FFEBC4 0%, rgba(255, 227, 172, 0.2) 100%)",
          }}
        >
          <div
            id="carouselFeedback"
            className="carousel slide"
            data-ride="carousel"
          >
            <ol className="carousel-indicators">
              <li
                data-target="#carouselFeedback"
                data-slide-to="0"
                className="active"
              ></li>
              {/* <li data-target="#carouselFeedback" data-slide-to="1"></li>
              <li data-target="#carouselFeedback" data-slide-to="2"></li> */}
            </ol>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="container">
                  <div className="carousel-item-body-15 text-black">
                    <div className="row">
                      <div className="col-1">
                        <img
                          src={quote_left}
                          className="mt-n5"
                          style={{ height: "50px", width: "50px" }}
                          alt=""
                        ></img>
                      </div>
                      <div className="col-11">
                        <p className="text-justify">
                        Easy to use and you could customize is more than
                         any other collaboration tool
                        </p>
                        <img
                          className="mt-n4 float-right"
                          src={quote_right}
                          style={{ height: "50px", width: "50px" }}
                          alt=""
                        ></img>
                      </div>
                    </div>
                    <div className="row mt-3 mb-2">
                      <div className="col-11 font-italic small">
                        <div className="float-right">
                        Jerry SantaMaria, Executive Project Manager
                          <br></br>
                          Browning Associates
                        </div>
                      </div>
                      <div className="col-2"></div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="carousel-item">
                <div className="container">
                  <div className="carousel-item-body-15 text-black">
                    <div className="row">
                      <div className="col-1">
                        <img
                          src={quote_left}
                          className="mt-n5"
                          style={{ height: "50px", width: "50px" }}
                          alt=""
                        ></img>
                      </div>
                      <div className="col-11">
                        <p className="text-justify">
                          Jaam is a one stop shop for meetings. Doesn't matter
                          if you just want to jump on a quick audio conference
                          call, or a full-fledged video conference with 20
                          offices. Jaam is the single tool to handle all
                          meetings.
                        </p>
                        <p className="text-justify">
                          Jaam is a one stop shop for meetings. Doesn't matter
                          if you just want to jump on a quick audio conference
                          call, or a full-fledged video conference with 20
                          offices. Jaam is the single tool to handle all
                          meetings.
                        </p>
                        <img
                          className="mt-n4 float-right"
                          src={quote_right}
                          style={{ height: "50px", width: "50px" }}
                          alt=""
                        ></img>
                      </div>
                    </div>
                    <div className="row mt-3 mb-2">
                      <div className="col-11 font-italic small">
                        <div className="float-right">
                          Gary Smith, Chief Product Officer
                          <br></br>
                          ABC Corporation
                        </div>
                      </div>
                      <div className="col-2"></div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="carousel-item">
                <div className="container">
                  <div className="carousel-item-body-15 text-black">
                    <div className="row">
                      <div className="col-1">
                        <img
                          src={quote_left}
                          className="mt-n5"
                          style={{ height: "50px", width: "50px" }}
                          alt=""
                        ></img>
                      </div>
                      <div className="col-11">
                        <p className="text-justify">
                          BestReviews spends thousands of hours researching,
                          analyzing, and testing products to recommend the best
                          picks for most consumers. We only make money if you
                          purchase a product through our links, and we never
                          accept free products from manufacturers.
                        </p>
                        <img
                          className="mt-n4 float-right"
                          src={quote_right}
                          style={{ height: "50px", width: "50px" }}
                          alt=""
                        ></img>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-11 font-italic small">
                        <div className="float-right">
                          Lindsay Kolowich, Chief Product Officer
                          <br></br>
                          kolow Corporation
                        </div>
                      </div>
                      <div className="col-2"></div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselFeedback"
              role="button"
              data-slide="prev"
            >
              <img
                src={swipe_left}
                style={{ height: "40px", width: "40px" }}
                alt=""
              ></img>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselFeedback"
              role="button"
              data-slide="next"
            >
              <img
                src={swipe_right}
                style={{ height: "40px", width: "40px" }}
                alt=""
              ></img>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </section>
      </div>
    );
  }
}

export default Home;
