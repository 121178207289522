import React from "react";
import Vector from "../../../../assets/vector.svg";
import Share from "../../../../assets/share.svg";
import Trashbin from "../../../../assets/trash.svg";
//Jaam new imports
import AWS from "aws-sdk";
import { useState, useEffect } from "react";
//Jaam code import
import DownloadFileFromCloud from "./downloadFileFromCloud";
import {api} from "../../../../common/api";
//Jaam code starts
const Recording = () => {
  const [listFiles, setListFiles] = useState([]);
   AWS.config.credentials = new AWS.CognitoIdentityCredentials({
   IdentityPoolId: 'us-east-1:f0f11a44-a990-451e-bf0e-b852c068770b',
   });
  AWS.config.update({         
     region: api.REGION ,
     accessKeyId: api.AWS_KEY,
     secretAccessKey: api.SECRET_KEY
 });
 
  let bucketName="jaamcloudrecording";
  let folderName="myrecordings/";
  var bucket = new AWS.S3({ apiVersion: '2012-10-17', params: { Bucket: bucketName,Prefix:folderName } });
  
  useEffect(() => {
      bucket.listObjects({ Bucket: 'jaamcloudrecording', MaxKeys: '214748', Delimiter: '' }, function (err, data) {
      if (err) { console.log("error" + err.message) }
      else {
        if (data.Contents.length > 0)
          setListFiles((data.Contents));
        else {
          console.log("no recordings");
        }
      }

    });

  }, []);
 
  //renders the body of table

  const renderBody = (files) => {
    if (files.length > 1) {
      return files.map((key, index) => {
        if (index != 0) {
          return <tr className="recordings-table-content"> <td><div>{convertName(key.Key)}</div></td>
            <td className=""><div>{key.LastModified.toString().substring(0, 24)}</div></td>
            <td className=""><div>{convertBytes(key.Size, 3)}</div></td>
            <td className=""><div>{key.Key.split('.').pop()}</div></td>
            <td><a id={convertName(key.Key)} download><img src={Vector} id={"img_" + convertName(key.Key)} height="11" width="10" className="recordings-image" onClick={() => DownloadFileFromCloud(bucketName,folderName,key.Key)}></img></a></td>
          </tr>
        }
      });
    }


    //checks whether recording file is stored in cloud or not

    else {
      if (files.length <= 1) {
        return <p className="text-muted text-center ml-5">No recordings stored in cloud</p>
      }
    }
  }

  //checks whether the data is defined or not


  
  const convertName = (filename) => {
    const value = filename.split('/').pop();
    const recordingFileName = value.split(".");
    return recordingFileName[0].toString();
  }

  //converts the size of file from bytes to KB,MB,GB

  const convertBytes = (bytes, decimals = 2) => {
    if (bytes === 0) {
      return 0.0;
    }
    const kiloByte = 1024;
    const decimal = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const calculate = Math.floor(Math.log(bytes) / Math.log(kiloByte));
    return parseFloat((bytes / Math.pow(kiloByte, calculate)).toFixed(decimal)) + ' ' + sizes[calculate];
  }
  const renderHeader = () => {
    let headerElement = ['Name', 'Date', 'Size', 'format']
    return headerElement.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>
    })
  }

  //Jaam code end
  return (
    <div>
      <div className="row">
        <div className="col-12 col-md-12  offset-lg-1 col-lg-11 text-left text-black">
          <div className="card recordings">
            <div className="col-12 my-4 ml-2 ml-md-3 recordings-tab-title">
              My Recording
            </div>
            <div className="row">
              <div className="col-12 col-md-9 col-lg-9">
                <nav className="ml-4">
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <a className="nav-item nav-link GilroyExtrabold recordings-border active" id="nav-cloud-tab" data-toggle="tab" href="#nav-cloud" role="tab" aria-controls="nav-cloud" aria-selected="true">Cloud</a>
                    <a className="nav-item nav-link GilroyExtrabold recordings-border ml-4" id="nav-local-tab" data-toggle="tab" href="#nav-local" role="tab" aria-controls="nav-local" aria-selected="false">Local</a>
                    <a className="nav-item nav-link GilroyExtrabold recordings-border ml-4" id="nav-settings-tab" data-toggle="tab" href="#nav-settings" role="tab" aria-controls="nav-settings" aria-selected="true">Settings</a>
                  </div>
                </nav>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12 line-adjustment mb-2 recordings-line"></div>
            <div className="tab-content" id="nav-tabContent">
              <div className="tab-pane fade show active" id="nav-cloud" role="tabpanel" aria-labelledby="nav-cloud">
                <div className="row">
                  <div className="col-12 ml-4 ml-md-5 text-black">
                    <div className="row mt-2 ml-n4 ml-md-n2">
                      {/* Jaam code starts */}
                      <div id="total" className="col-3 ml-3 col-md-6 ml-md-n4 col-lg-7 ml-lg-n4 recordings-text">
                        Total: {(listFiles.length > 0 ? listFiles.length - 1 : listFiles.length)} Records
                      </div>
                      <script>
                      </script>
                      <div className="col-5 col-md-3 offset-lg-1 col-lg-2 ml-n3 ml-lg-n2">
                        <div className="progress">
                          <div className="progress-bar" id="progressfile" role="progressbar" style={{ width: (listFiles.length - 1) * 15 }} aria-valuemin="0%" aria-valuemax="100%" label={"50%"} step="10"></div>
                        </div>
                      </div>
                      <div className="col-2 col-md-2 col-lg-2 mt-0 ml-3 recordings-text">
                        {(listFiles.length > 0 ? listFiles.length - 1 : listFiles.length) * 10}%
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-10 table-responsive">
                        <table className="table ml-md-n4 ml-lg-n4">

                          <thead><tr className="recordings-table-heading">{renderHeader()}</tr></thead>
                          <tbody>{renderBody(listFiles)}</tbody>

          {/* Jaam code end */}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab-pane fade show" id="nav-local" role="tabpanel" aria-labelledby="nav-local">
                <div className="row">
                  <div className="col-12 ml-4 ml-md-5 text-black">
                    <div className="row mt-2 ml-n4 ml-md-n4">
                      <div className="col-3 ml-3 col-md-6 ml-md-n2 col-lg-7 ml-lg-n2 recordings-text">
                        Total: 2 Records
                      </div>
                      <div className="col-5 col-md-3 offset-lg-1 col-lg-2 ml-n3 ml-lg-n2">
                        <div className="progress">
                          <div className="progress-bar" role="progressbar" style={{width: "50%"}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                         </div>
                      </div>
                      <div className="col-2 col-md-2 col-lg-2 mt-0 ml-3 recordings-text">
                        50%
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-10 table-responsive">
                        <table className="table ml-md-n4 ml-lg-n4">
                          <thead>
                            <tr className="recordings-table-heading">
                              <th>
                                <div>Topic</div>
                              </th>
                              <th>
                                <div>Security</div>
                              </th>
                              <th>
                                <div>Date</div>
                              </th>
                              <th>
                                <div>Duration</div>
                              </th>
                              <th>
                                <div>Formate</div>
                              </th>
                              <th /><th /><th />
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="recordings-table-content">
                              <td>
                                <div>UX Review Meeting</div>
                              </td>
                              <td></td>
                              <td>
                                <div>05/11/2020</div>
                              </td>
                              <td>
                                <div>01:30:00</div>
                              </td>
                              <td>
                                <div>MP4</div>
                              </td>
                              <td>
                                <img src={Vector} height="11" width="10" className="recordings-image"/></td>
                              <td>
                                <img src={Share} height="11" width="10" className="recordings-image"/></td>
                              <td>
                                <img src={Trashbin} height="11" width="10" className="recordings-image"/>
                              </td>
                            </tr>
                            <tr className="recordings-table-content">
                              <td>
                                <div>UX Review Meeting</div>
                              </td>
                              <td></td>
                              <td>
                                <div>05/11/2020</div>
                              </td>
                              <td>
                                <div>01:30:00</div>
                              </td>
                              <td>
                                <div>MP4</div>
                              </td>
                              <td>   
                              <img src={Vector} height="11" width="10" className="recordings-image"/></td>
                              <td>
                                <img src={Share} height="11" width="10" className="recordings-image"/></td>
                              <td>
                                <img src={Trashbin} height="11" width="10" className="recordings-image"/>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab-pane fade show" id="nav-settings" role="tabpanel" aria-labelledby="nav-settings">
                <div className="col-12 ml-3 mt-4">
                  <div className="row mt-4">
                    <div className="col-12 ml-1">
                      <small className="recordings-settings-text">Recording Type</small>
                    </div>
                    <div className="col-12 col-lg-8 ml-4">
                      <div className="row">
                        <div className="col-12 col-md-3 ml-1">
                          <input  type="radio" name="flexRadioDefault" className="radio-btn form-check-input" id="flexRadioDefault1" />
                          <small className="ml-1 recordings-settings-text">
                            None
                          </small>
                        </div>
                        <div className="col-12 col-md-3 ml-n1 ml-md-n5">
                          <input className="form-check-input" type="radio" name="flexRadioDefault" className="radio-btn form-check-input" id="flexRadioDefault1" />
                          <small className="recordings-settings-text">
                            Local Recording
                          </small>
                        </div>
                        <div className="col-12 col-md-3 ml-n1 ml-md-0">
                          <input className="form-check-input" type="radio" name="flexRadioDefault" className="radio-btn form-check-input" id="flexRadioDefault1" />
                          <small className="recordings-settings-text" >
                            Cloud Recording
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mt-4 ml-1 recordings-settings-text">
                      Recording Disclaimer
                    </div>
                    <div className="col-12 mb-2">
                      <div className="row">
                        <div className="col-10 col-md-10 col-lg-7 ml-1">
                          <small className="recordings-settings-text">Show a customizable disclaimer to participants before a recording starts</small>
                        </div>
                        <div className="col-12 col-md-12 col-lg-3 ml-0 ml-md-0 ml-lg-n4 form-inline">
                          <label className="switch">
                            <input  type="checkbox" checked=""/>
                            <span className="slider round adjustLeft"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mt-4 mb-5">
                      <div className="row">
                        <div className="offset-5 col-5 offset-md-8 col-md-3 offset-lg-9 col-lg-2 span-lg-1">
                          <button className="btn-purple recordings-save-btn ml-0 ml-md-0 ml-lg-4">Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default Recording;

