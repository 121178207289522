import React, { Component} from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enableTab : 'Home',
    }
    window.scrollTo(0, 0);
  }
  componentDidMount () {
    const dropdownName = window.location.pathname;
    if( dropdownName === "/landing") {
      this.setState({
        enableTab : "Home"
      });
    }
      //Jaam code to disable other options
    // } else if( dropdownName === "/landing/meeting") {
    //   this.setState({
    //     enableTab : "Meeting"
    //   });
    // } else if ( dropdownName === "/landing/recording") {
    //   this.setState({
    //     enableTab : "Recordings"
    //   });
    // } 
    if ( dropdownName === "/landing/billing") {
      this.setState({
        enableTab : "Billing"
      });
    } 
    // } else if ( dropdownName === "/landing/setting") {
    //   this.setState({
    //     enableTab : "Settings"
    //   });
  }
  updateTab(item) {
    this.setState({
      enableTab: item,
    });
 }
  render() {
    let homeTab,meetingTab,recordingTab,billingTab,settingTab;
    if( this.state.enableTab === "Home") {
        homeTab = "active"
    }
        //Jaam code to disable other options
    // } else if ( this.state.enableTab === "Meeting") {
    //   meetingTab = "active"
    // }
    // else if ( this.state.enableTab === "Recordings") {
    //   recordingTab = "active"
    // }
    if ( this.state.enableTab === "Billing") {
      billingTab = "active"
    }
    // else if ( this.state.enableTab === "Settings") {
    //   settingTab = "active"
    // }
    return (
          <div className="sidebar-navigation">
          {/* NAVIGATION IN PC & TABLET */}
          <div className="col-12 sidebar-background">
            <div className="col-md-12 showmd">
              <nav className="nav text-center nav-pills flex-column">
                  <Link to="/landing" className={"nav-link sidebar-text ml-n2 mr-n3 text-left mb-2 " + homeTab}>Home</Link>
                  {/* Jaam code to disable other options */}
                  {/* <Link to="/landing/meeting" className={"nav-link sidebar-text ml-n2 mr-n3 text-left mb-2 " + meetingTab}>Meetings</Link> */}
                  {/* Jaam code changed */}
                  {/* <Link to="/landing/recording" className={"nav-link sidebar-text ml-n2 mr-n3 text-left mb-2 " + recordingTab}>Recordings</Link>*/}
                  <Link to="/landing/billing" className={"nav-link sidebar-text ml-n2 mr-n3 text-left mb-2 " + billingTab }>Billing</Link> 
                  {/* Jaam code changed*/}
                  {/* <Link to="/landing/setting" className={"nav-link sidebar-text ml-n2 mr-n3 text-left mb-2 " + settingTab}>Settings</Link>  */}
              </nav>
            </div>
            {/* NAVIGATION IN MOBILE */}
            <div className="mobile-navigation">
            <div className="col-12 input-prepend input-append showxs">
                <div className="btn-group landing-mobile-nav">
                   <button className="btn dropdown-toggle select-main-bg ml-3 mb-4 roboto-large-white" name="recordinput" data-toggle="dropdown">
                          {this.state.enableTab}
                      <span className="caret"></span>
                    </button>
                    <ul className="dropdown-menu nav flex-column">
                      <li><a href="/landing" className="nav-link mr-n3 text-left mb-2" onClick={() =>this.updateTab('Home')}>Home</a></li>
                      {/* //Jaam code to disable other options */}
                      {/* <li><a href="/landing/meeting" className="nav-link mr-n3 text-left mb-2" onClick={() =>this.updateTab('Meeting')}>Meeting</a></li> */}
                     {/* <li><a href="/landing/recording" className="nav-link mr-n3 text-left mb-2" onClick={() =>this.updateTab('Recordings')}>Recordings</a></li> */}
                      <li><a href="/landing/billing" className="nav-link mr-n3 text-left mb-2" onClick={() =>this.updateTab('Billing')}>Billing</a></li>
                      {/*<li><a href="/landing/setting" className="nav-link mr-n3 text-left mb-2" onClick={() =>this.updateTab('Settings')}>Settings</a></li> */}
                    </ul>
                  </div>
              </div>
              </div>
            </div>
          </div>
        );
  }
}

export default (withRouter(Sidebar));