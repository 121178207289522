import _ from 'lodash';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import StatusHelper from '../common/helpers/statusHelper';
import LoadingComp from '../common/LoadingComp';
import SnackBar from '../common/SnackBar';
import { Link } from "react-router-dom";
import { join } from '../common/session/sessionAction';

class MeetingLock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomName: localStorage.getItem("MeetingID"),
      displayName: localStorage.getItem("DisplayName"),
      passcode: "",
      showSnack: false,
      statusData: {},
    };
  }
  statusHelper = new StatusHelper(this);

  componentDidMount(){
    setInterval(function(){ window.location.reload() }, 10000);
    var token = localStorage.getItem("token");
    this.props.dispatch(
      join({
        ...this.state,
        token: token,
      })
    );
  }

  componentWillReceiveProps(nextProps) {    
    if(this.props.hostLock === null && nextProps.hostLock === true && nextProps.hostActive !== 1){
      this.props.history.push({
        pathname: '/meetingLock',
      })
    }
    else if (this.props.hostLock === null && nextProps.hostLock === true && nextProps.hostActive === 1 && nextProps.enablePC === true ) {
      this.props.history.push({
        pathname: '/joinPasscode',
        state: { name: localStorage.getItem("DisplayName")}
      })
    }
  }

  render() {
    const { t } = this.props;
    const { roomName, displayName } = this.state;
    const { loading, userLoading, joinError, joinErrorCode, meetingId } = this.props;
    
    let loadingStyle = {
      minHeight: "50vh",
      textAlign: "center",
    };
    return loading || userLoading ? (
      <div style={loadingStyle} className="background">
        <LoadingComp />
      </div>
    ) : (
      <section className="background">
        <form>
        <div className="container container-decoration">
          <div className="row content-center">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="text-center">The meeting has not started</h2>
                  <h5 className="text-center">This page will refresh in 10 seconds, or <a href="/meetingLock">Reload</a> the page now.</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        </form>
        {this.state.showSnack ? (
          <SnackBar
            message={joinError}
            snackShow={true}
            minwidth="600px"
          ></SnackBar>
        ) : (
          ""
        )}{" "}
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.session.user,
  token: state.session.token,
  joinLoading: state.session.joinLoading,
  meetingId: state.session.meetingId,
  hostLock: state.session.hostLock,
  hostActive: state.session.hostActive,
  enablePC: state.session.enablePC,
  loading: state.session.loading,
  joinError: state.session.joinError,
  joinErrorCode: state.session.joinErrorCode,
});

export default withTranslation()(connect(mapStateToProps)(MeetingLock));
