import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import { Provider } from "react-redux";
import store, { history } from "./store";
import "./i18n";
import NavBar from "./components/navbar";
import Home from "./components/home";
import SignIn from "./components/signin";
import SignUp from "./components/signup";
import Join from "./components/join";
import JoinPasscode from "./components/joinPasscode";
import MeetingLock from "./components/meetingLock";
import Partnership from "./components/partnership";
import ProductFeatures from "./components/productfeatures";
import Contact from "./components/contact";
import Pricing from "./components/pricing";
import SubscribeCheckout from "./components/landing/billing/Components/subscribeCheckout";
import RedirectCheckout from "./components/landing/billing/Components/redirectCheckout";
import RedirectCustomerPortal from "./components/landing/billing/Components/redirectCustomerPortal";
import ShowInvoice from "./components/landing/billing/Components/showInvoice";
import Landing from "./components/landing/home/landing";
import Feedback from "./components/feedback";
import Footer from "./components/footer";
import PageNotFound from "./components/pageNotFound";
import TermsOfService from "./components/termsofservice";
import PrivacyPolicy from "./components/privacypolicy";
import About from "./components/about";
import Template from "./components/template";
import Support from"./components/support";
import Supportpage from"./components/supportpage";
import Cookies from "./components/cookies";
import Services from "./components/services";
import Billing from "./components/landing/billing/bill";
import Meetings from "./components/landing/meeting/meetings";
import Recordings from "./components/landing/recording/recordings";
import Settings from "./components/landing/setting/settings";
import "./assets/scss/style.scss";


function App() {
  return (
    <Provider store={store}>
      <BrowserRouter history={history}>
        <NavBar history={history}></NavBar>
        <Switch>
          <Route path="/" exact component={Home}></Route>
          <Route path="/home" component={Home}></Route>
          <Route path="/join/:id?/:pwd?" component={Join}></Route>
          <Route path="/joinPasscode" component={JoinPasscode}></Route>
          <Route path="/meetingLock" component={MeetingLock}></Route>
          <Route path="/signin" component={SignIn}></Route>
          <Route path="/signup" component={SignUp}></Route>
          <Route path="/pricing" component={Pricing}></Route>
          <Route path="/subscribeCheckout" component={SubscribeCheckout}></Route>
          <Route path="/redirectCheckout" component={RedirectCheckout}></Route>
          <Route path="/redirectCustomerPortal" component={RedirectCustomerPortal}></Route>
          <Route path="/showInvoice" component={ShowInvoice}></Route>
          <Route path="/partnership" component={Partnership}></Route>
          <Route path="/productfeatures" component={ProductFeatures}></Route>
          <Route path="/contact" component={Contact}></Route>
          <Route path="/feedback" component={Feedback}></Route>
          <Route path="/supportpage" component={Supportpage}></Route>
          <Route path="/termsofservice" component={TermsOfService}></Route>
          <Route path="/about" component={About}></Route>
          <Route path="/privacypolicy" component={PrivacyPolicy}></Route>
          <Route path="/linkedin" exact component={LinkedInPopUp}></Route>
          <Route path="/template" exact component={Template}></Route>
          <Route path="/cookies" exact component={Cookies}></Route>
          <Route path="/services" exact component={Services}></Route>
          <Route
                path="/landing"
                render={({ match: { url } }) => (
                  <>
                    <Route path={`${url}/`} component={Landing} exact />
                    {/* //Jaam code to disable other options */}
                    {/* <Route path={`${url}/meeting`} component={Meetings} />
                    <Route path={`${url}/recording`} component={Recordings} />*/}
                    <Route path={`${url}/billing`} component={Billing} />
                    {/* <Route path={`${url}/setting`} component={Settings} />  */}
                    <Route path={`${url}/meeting`} component={Landing} />
                    <Route path={`${url}/recording`} component={Landing} />
                    {/* <Route path={`${url}/billing`} component={Landing} /> */}
                    <Route path={`${url}/setting`} component={Landing} />
                  </>
                )}
          />  
          <Route path="*" component={PageNotFound} />
        </Switch>
        <Footer></Footer>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
