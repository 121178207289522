import React, { Component } from "react";
// import loadingGif from '../assets/Spinner-1s-200px.gif'
import loadingGif from '../assets/Dual Ball-1s-200px.gif'


const styles = {
    divImage: {
        position:'relative',
    },
   image: {
       marginTop: '150px'
    }
}

class LoadingComp extends Component {
    render() {
       return (
        <div style={styles.divImage}>
            <img
                style={styles.image}
                src={loadingGif}
            />
        </div>
       )
    }
}

export default LoadingComp;


