import React, { Component } from "react";

class Faq extends Component {
  state = {};
  render() {
    return (
     
              <div className="row content-center">
                <div className="col-lg-12">
                  <br></br>
                  <h2 className="faq_header">FAQ</h2>
                    <span>Why cannot i login to my account?</span><br></br>
                    <span>How do I access my account information?</span><br></br>
                    <span>How to use a theme?</span>                
              </div>
            </div>
          
    );
  }
}

export default Faq;
