import { getStorage } from './localStorage';

let initialSession = {
    authToken: '',
    user: {},
    isLoggedIn: false,
    isSignedUp: false,
    signUpMessage: '',
    loginMessage: '',
    linkedInData: {},
    linkedinLoading: false,
    joinLoading: false,
    enablePC: null,
    hostLock: null,
    meetingId: '',
    meetingData: {},
    joinError: '',
    joinErrorCode: '',
    selectedTheme: '',
    customerId: '', // Stripe CustomerID
    sessionId: '', // Stripe Checkout SessionID
    priceId: '', // Stripe Checkout PriceId
    invoiceUrl: '', // Stripe Customer Portal URL for invoice
    invoiceList: '', //Stripe invoice list for current customer
    allpricesList: [], // Stripe- Get Prices from DB 
    getallpricesLoading: false,   
    initCurrency: '', // Stripe- Set initCurrency
    selectedCurrency:'',
    getpricesLoading: false,
    paymentError: null
};


const initialState = {
    session: initialSession
}

export default initialState;