import React, { Component } from "react";

class Template extends Component {
  state = {};
  render() {
    return (
      <section className="background">
        <div className="container container-decoration">
          <div className="row content-center">
            <div className="col-xl-8">
              <div className="row">
                <div className="col-lg-12">
                  <h2 className="text-center">Template Page...</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Template;
