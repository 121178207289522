import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

class paymentErrors extends Component {
  render() {
    const { errInfo } = this.props;
    
    return (
      <div>
        <div className="row">         
          <div className="col-10 col-md-10 col-lg-11 mt-4 mb-0 payment-error-message ml-4">
            <span className="">{errInfo.message}</span>                  
            </div>
         </div>
      </div>
      )
    }
}

export default withRouter(paymentErrors);  