import React, { Component } from "react";
import { Link } from "react-router-dom";
import monitor from "../assets/monitor.svg";
import { api } from "../common/api";
import likeicon from "../assets/likeicon.svg";
import push from "../assets/push.svg";
import band from "../assets/band.svg";
import calendar from "../assets/calendar.svg";
import cloud from "../assets/cloud.svg";
import group from "../assets/group.svg";
import party from "../assets/party.svg";
import webcam from "../assets/webcam.svg";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'lodash';
import { stripeGetallPrices, stripeGetSubscriptions } from "../common/session/sessionAction";
import getSymbolFromCurrency from 'currency-symbol-map';

var cc = require('currency-codes');


class Pricing extends Component {
  constructor(props) {
    super(props);  
    this.state = {      
      customerEmail: props.user.email,   // signed in user  
      customerToken: props.user.token, 
      selectedCurrency: '', // select option    
      // allprices: '',
      showYearly:false,
    };
    window.scrollTo(0, 0);
  }

  componentDidMount(){
    this.props.dispatch(stripeGetallPrices()); 
 
    if (!_.isEmpty(this.props.user) && !_.isEmpty(this.props.user.token))  {    
      this.props.dispatch(stripeGetSubscriptions({ ...this.state }));   
    }   
  }

  componentWillReceiveProps(nextProps) {     
    if (
      nextProps.user.token &&  !_.isEmpty(nextProps.user.token) && !_.isEqual(this.props.user.token, nextProps.user.token)
    ) {
      this.setState({
        customerToken: nextProps.user.token ? nextProps.user.token : "",
      });
    }

    if (
      nextProps.user &&  !_.isEmpty(nextProps.user) && !_.isEqual(this.props.user, nextProps.user)
    ) {
      this.setState({
        customerEmail: nextProps.user.email ? nextProps.user.email : "",
      });
    } 

    if (!_.isEmpty(nextProps.allprices) && this.props.allprices !== nextProps.allprices ) {       
      this.setState({ allprices: nextProps.allprices });
    }
  }

  handleChange =  (event) => {  
    this.setState({ selectedCurrency: event.target.value
     });
  };
  
  render() {
    const { allprices, initCurrency, cusCurrency, user } = this.props;   
    const { selectedCurrency , showYearly} = this.state;

    let sortPrices;
    const uniqueTags = [];
    if (allprices) {     
      allprices.map(item => {                  
        var findItem = uniqueTags.find(x => x.priceCurrency === item.priceCurrency);
        if (!findItem)
         uniqueTags.push(item);
        });     
    }    
    
    let currValidate;
    let selectoption = false;

      // For all Signedin/ Non-signedin users with or without subscriptions display both currencies
    // if(user.token) {
    //   if(cusCurrency) {
    //     currValidate = cusCurrency;
    //   } else {    
    //     selectoption = true;  
    //     if(selectedCurrency){
    //       currValidate = selectedCurrency;
    //     }else {
    //       currValidate = initCurrency;
    //     }      
    //   }
    // } else
    // {
      selectoption = true;
      if(selectedCurrency){
        currValidate = selectedCurrency;
      }else {
        currValidate = initCurrency;
      }
    // }


   let toggleFilter; 
   const showYear=showYearly ? "showComponent" : "hidecomponent" ;

   const toggleButton = (id) => {
    var e = document.getElementById(id);
    var f= document.getElementById("month");
    if(e.style.display == 'block'){
       e.style.display = 'none';
       f.style.display = 'block';
      }
    else{
       e.style.display = 'block';
       f.style.display = 'none';
      }
   }

    return (
      <section className="background">
        <div className="pricing">
        <div className="container container-decoration">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h1 className="pricing-heading">Pricing and Plan</h1>
              <h4 className="pricing-sub-heading"><span>Pay just what you want,</span><span id="new-line-mobile">it is simple!</span></h4>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="pricing-card container">

                <div className="row no-gutters">
                <div className="col-sm-5 col-md-5" id="pricing-gradient">

                <div className="row no-gutters mb-2 mt-3">
                  <div className="col-md-12 mb-1 ml-4 ml-md-0 ml-lg-0 text-center">
                    <span className="pricing-purple-text">
                      Standard
                    </span>
                  </div>         
                  </div>

                  <div className="row no-gutters">
                  <div className="col-md-12 ml-4 ml-md-0 ml-lg-0 text-center">
                    <span className="pricing-text">
                      Great for small and mid<span id="new-line-mobile">size businesses</span>
                    </span>
                    <div className="horizontal my-3 col-lg-12"></div>
                  </div>
                </div>

                <div className="row">                 
                <div className="col-6">  </div>
                <div className="col-6 ml-0 ml-md-n3 ml-lg-0">  
                {(selectoption)?
                  <form id="formcurrency">           
                  <div className="row">              
                    <div className="column">                    
                    <select
                    name="currency"
                    value={currValidate}
                    label="Currency" 
                    onChange={this.handleChange}   
                    > 
                    {
                      uniqueTags && uniqueTags.map((allprices, i) =>                          
                      <option key={allprices.priceId} value={allprices.priceCurrency}>
                        {cc.code(allprices.priceCurrency).currency} {getSymbolFromCurrency(allprices.priceCurrency)}
                      </option>
                    )
                    }
                    </select>
                    </div>
                  </div>
                  </form>
                  :
                  ''
                }
                {/* {
                  sortPrices = [].concat(allprices && allprices.filter(allprices => allprices.priceCurrency.includes(currValidate))
                  .sort((a, b) => a.priceUnitAmount/100 > b.priceUnitAmount/100 ? 1 : -1)
                  .map((allprices, i) => 
                  // allprices && allprices.filter(allprices => allprices.priceCurrency.includes(currValidate)).map(allprices =>            
                    <li key={allprices.priceId}>{allprices.priceName}</li>    
                  )
                  )
                }          */}
                {/* <span className="ml-4 ml-lg-0">Beta Version</span> */}
                </div>
                </div>
             
                <br></br>

                {/* Toggle CSS Start */}
                <div className="row no-gutters mt-n1">
                  <div className="col-md-12 text-center">
                    <button type="button" onClick={() => toggleButton("annual")} className="btn btn-toggle" data-toggle="button" aria-pressed="false" autocomplete="off">
                    <div className="handle"></div>
                    </button>
                    <span className="pricing-discount ml-0 ml-md-2 ml-lg-2">
                    &nbsp;&nbsp;Save 10%
                    </span>
                  </div>
                </div>

                <div className="row no-gutters d-flex" >
                <div id="month" className="col-12 text-center" >
                  <div className="text-center">
                  <span
                    className="text-purple pricing-display text-center font-weight-bolder"
                  >                 
                  {
                    toggleFilter = [].concat(allprices && allprices
                    .filter(allprices => allprices.priceCurrency.includes(currValidate) && allprices.priceInterval == 'month')
                    .map((allprices, i) =>             
                      <span key={allprices.priceId}>
                        {getSymbolFromCurrency(allprices.priceCurrency)}{allprices.priceUnitAmount/100}
                      </span>    
                    ))                                
                  }
                  </span>
                  </div>
                </div>
                </div>

                <div className="row no-gutters">
                  <div className="col-12 text-center">
                  <div className={showYear} id="annual" onClick={() => this.hideComponent("showYearly")}>
                  <div className="text-center">
                  <span
                      className="text-purple pricing-display font-weight-bolder"
                    >
                   
                    {
                    toggleFilter = [].concat(allprices && allprices
                    .filter(allprices => allprices.priceCurrency.includes(currValidate) && allprices.priceInterval == 'year')
                    .map((allprices, i) =>             
                      <span key={allprices.priceId}>
                        {getSymbolFromCurrency(allprices.priceCurrency)}{allprices.priceUnitAmount/100}
                      </span>    
                    ))                                
                    }
                    
                  </span>
                  </div>
                  </div>
                  </div>
                </div>
                {/* Toggle CSS End */}

                
                <div className="row no-gutters mt-3">
                  <div className="col-3 col-md-2"></div>
                  <div className="col-12 col-md-8 ml-0 ml-sm-3 ml-lg-0 text-center">
                  <Link
                      to="/landing/billing"
                      id="on"
                      className="btn btn-purple btn-lg"
                    >
                      <span>Buy now</span>
                    </Link>
                 </div>
                 <div className="col-3 col-md-2"></div>
                </div>

                {/* <div className="row mt-4 showmd">
                  <div className="col-12 col-md-12 col-lg-12 betaversion">
                  Please <Link
                      to="/contact"
                      id="on"
                      className="text-purple fontbold"
                    >
                      <span>reach out</span>
                    </Link> for unlimited access!
                  </div>
                </div> */}

                {/* <div className="row mt-4 showxs">
                  <div className="col-12 ml-5 col-lg-12 betaversion">
                  Please <Link
                      to="/contact"
                      id="on"
                      className="text-purple fontbold"
                    >
                      <span>reach out</span>
                    </Link> for <span id="new-line-mobile"></span>unlimited access!
                  </div>
                </div> */}

                <div className="row no-gutters ml-lg-n4 ml-md-0 mt-4">
                  <div className="col-md-12 text-center pricing-list">
                    <ul className="text-center">
                      <li className="ml-md-n1 ml-lg-n4 ml-2 mb-2"><img src={monitor} className="mr-3" alt="HD"/>HD Video &amp; Audio Conferencing</li>
                      <li className="ml-md-4 ml-lg-n5 ml-n3 mb-2"><img src={likeicon} className="mr-2 mr-md-2" alt="like"/>Recommended for 1-50 users</li>
                    </ul>
                  </div>
                  </div>

                </div>

                <div className="col-md-7">

                <div className="row no-gutters mt-4 mt-md-5 mt-lg-5 text-center">
                <div className="pricing-purple-text col-lg-12 ml-lg-n3 ml-md-5 mt-md-5 mt-lg-5 ml-n2 mb-3">
                  Enjoy our fun &amp; music themes! Have fun!!
                </div>
                
                <div className="col-lg-6 col-sm-12">
                
                <div className="pricing-list pricing-text list-group">
                    <ul id="pricing-list-alignment">
                        <li className="list-group-item ml-n2 ml-md-0 mb-2"><img src={push} alt="push" className="mr-3 ml-md-n1"/><span className="ml-n1">Push-to-talk mode</span></li>
                        <li className="list-group-item ml-n2 ml-lg-0 ml-sm-0 mb-2"><img src={band} alt="wifi" className="mr-2 ml-md-n1" id="tabcal"/>&nbsp;Bandwidth controls</li>
                        <li className="list-group-item mb-2 ml-n2 ml-md-2"><img src={party} alt="part" className="mr-2"/>Fun &amp; Music themes</li>
                        {/* <li className="list-group-item ml-n2 ml-md-3 mb-2 pricing-list-text"><img src={calendar} alt="cale"/>&nbsp;&nbsp;Calendar integrations</li>
                        <li className="list-group-item ml-n2 ml-md-n3 mb-2"><img src={cloud} alt="clou" className="mr-3 ml-md-n2 mri"/><span className="ml-n1">Cloud recordings</span></li> */}
                    </ul>
                </div>
                
                </div>
                <div className="col-lg-6 col-sm-12 mt-sm-n1 mt-lg-0">
               <div className="pricing-list pricing-text list-group">
                    <ul id="pricing-list-alignment">
                        {/* <li className="list-group-item mb-2 ml-n2 ml-md-2"><img src={party} alt="part" className="mr-2"/>Fun &amp; Music themes</li> */}
                        <li className="list-group-item mb-2 ml-n2"><img src={group}  alt="grou" className="mr-2 ml-md-n2"/>Group chat window</li>
                        <li className="list-group-item mb-2 ml-lg-0 ml-sm-0 ml-n2"><img src={webcam} alt="web" className="mr-2 mr-md-3" id="webcamm"/>No webcam streams</li>
                    </ul>
                </div>
                </div>
                </div>
                </div>

                </div>

                <div className="row no-gutters mt-2">
                  <div className="pricing-horizontal-line my-3 col-lg-12"></div>
                </div>
                <div className="row no-gutters ml-lg-2 ml-3 mt-4">
                  <div className="col-lg-12 ml-lg-4">
                    <h4 className="text-purple">Features:</h4>
                  </div>
                </div>

                <div className="row no-gutters mt-4 ml-xl-0 ml-lg-5 ml-0">
                  <div className="col-12 col-md-5 col-lg-5 ml-md-2 col-xl-5">
                    <ul>
                      <li className="list-item">
                        Upto 50 participants per meeting
                      </li>
                      <li className="list-item mt-3">
                        Screen &amp; window sharing
                      </li>
                      <li className="list-item mt-3">No time limits</li>
                      <li className="list-item mt-3">
                        Unlimited number &amp; length of meetings
                      </li>
                      <li className="list-item mt-3">
                        Participant list panel with mute all,<span id="content1">raise/ lower your
                        hand for attention</span>
                      </li>
                      <li className="list-item mt-3 pr-0 pr-md-0 pr-lg-5">
                        Invite participants instanteously using your favourite emails (Native, Outlook, Gmail &amp; Yahoo)
                      </li>
                    </ul>
                  </div>
                
                  <div className="pricing-vertical-line d-none d-md-none d-lg-block ml-n5"></div>
                
                  <div className="col-12 col-md-6 col-lg-7 col-xl-7">
                    <ul>
                      <li className="list-item">
                        Auto-view the active speaker or click on any attendee to
                        see their video quickly 
                      </li>
                      <li className="list-item mt-3">
                        Private chat through individual window
                      </li>
                      <li className="list-item mt-3">
                        Lock a room with a password
                      </li>
                      <li className="list-item mt-3">
                        Raise/Lower your hand for attention
                      </li>
                      <li className="list-item mt-3">Talk-time statistics</li>
                      {/* <li className="list-item mt-3">Meeting live streaming</li> */}
                    </ul>
                  </div>
                
                </div>

                <div className="row">&nbsp;</div>
                <div className="row">&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="row no-gutters d-flex pricing-info-bg">
          <div className="container">
            <div className="col-md-12 mt-4 mb-4">
              <div className="row no-gutters">
              <div className=" col-lg-2">&nbsp;</div>
                <div className="col-md-5 col-lg-4 mt-lg-3 mt-2 text-center">
                  <span className="pricing-info-text">
                    Looking for even greater flexibility?
                  </span>
                </div>
                <div className="col-md-2 col-lg-2">&nbsp;</div>
                <div className="col-md-5 col-lg-4 mt-2 text-md-left text-center">
                  <Link
                    to="/contact"
                    className="btn btn-purple btn-lg col-8 col-md-8"
                  >
                    <span className="contact-sales-btn">
                      Contact Sales
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid bg-white">
        <div className="row bg-white">&nbsp;</div>
        <div className="container bg-white">
          <div className="row">&nbsp;</div>
          <div className="row">
            <div className="col-lg-12">
              <div className="row no-gutters">
              <div className="col-sm-12 text-left">
                <span className="common">Frequently Asked <span id="content2">Questions (FAQ)</span></span>
              </div></div>

              <div className="row">&nbsp;</div>

              <div className="row no-gutters mt-1">
                <div className="col-lg-12">
                  <div className="accordion" id="accordionExample">
                  <div className="fullaccordion">
                    <div
                      role="button"
                      className="collapse-header mb-n2"
                      data-toggle="collapse"
                      data-target="#collapse1"
                      aria-expanded="true"
                      aria-controls="collapse1"
                    >
                      <a> What does the Jaam meet <span id="new-line-mobile"></span> account include?</a>
                    </div>
                    <div
                      id="collapse1"
                      className="collapse show accordionanswer"
                      aria-labelledby="heading1"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 col-lg-10 col-md-10 pt-3 mt-0" id="opaci">
                      <div>
                      <ul>  
                      <li>FREE unlimited high-definition video and audio conferencing for up to 50 participants for 30 days.</li>
                      <li>Online meetings with screen sharing.</li>
                      <li>Top-notch security, and more…</li>
                      </ul>
                      </div>
                      <br/>
                      </div>
                    </div>
                    </div>

                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse2"
                      aria-expanded="true"
                      aria-controls="collapse2"
                    >
                      <a>How do I sign up for Jaam?</a>
                    </div>
                    <div
                      id="collapse2"
                      className="collapse accordionanswer pb-3"
                      aria-labelledby="heading2"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 col-md-10 pt-3 pb-1 mt-0" id="opaci">
                      <div>  
                      To sign up for your free JAAM account, visit 
                      <Link
                      to="/signup"
                      id="on"
                      className="text-purple fontbold"
                    >
                      &nbsp;{`${api.WEB_URL}/signup`}  
                    </Link> and enter your 
                      valid Google/Facebook email address and password. You will receive an email notification.
                      </div>
                      </div>
                    </div>
                    </div>
                    
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse3"
                      aria-expanded="true"
                      aria-controls="collapse3"
                    >
                      <a>Why can't I sign up for a Jaam account?</a>
                    </div>
                    <div
                      id="collapse3"
                      className="collapse accordionanswer"
                      aria-labelledby="heading3"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 col-md-10 pt-3 mt-0" id="opaci">
                      <div>
                      <ul>  
                      <li>If you already have a Gmail/Facebook account with the email address, then you can’t sign up with the same Email. Try with an alternative email id.</li>
                      <li>Even If your alternative Email ID doesn’t work try incognito mode (or) another browser (or) contact our Jaam support team at <a href="mailto: support@jaam.co">support@jaam.co</a></li>
                      </ul>
                      </div>
                      <div>&nbsp;</div>
                      </div>
                    </div>
                    </div>
                    
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse4"
                      aria-expanded="true"
                      aria-controls="collapse4"
                    >
                      <a> How do I access my account information?</a>
                    </div>
                    <div
                      id="collapse4"
                      className="collapse accordionanswer"
                      aria-labelledby="heading4"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 col-md-10 pt-3 mt-0" id="opaci">
                      <div>  
                      To access your account information, click on ‘My Account’ on the Jaam home page.
                      </div>
                      <div>&nbsp;</div>
                      </div>
                    </div>
                    </div>
                    
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse5"
                      aria-expanded="true"
                      aria-controls="collapse5"
                    >
                      <a>How do I join a Jaam meeting?</a>
                    </div>
                    <div
                      id="collapse5"
                      className="collapse accordionanswer"
                      aria-labelledby="heading5"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 col-md-10 pt-3 mt-0" id="opaci">
                      <div>  
                      You can join a meeting by clicking the meeting URL  or go to  
                      <Link
                      to="/join"
                      id="on"
                      className="text-purple fontbold"
                      >
                       &nbsp;{`${api.WEB_URL}/join`} 
                      </Link> and enter the meeting ID and your 
                      name and click on ‘Join’, you may be asked to enter a 
                      passcode only if required.
                      </div>
                      <div>&nbsp;</div>
                      </div>
                      </div>
                    </div>
                    
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse6"
                      aria-expanded="true"
                      aria-controls="collapse6"
                    >
                      <a> Is the Jaam account needed to <span id="new-line-mobile"></span>Join a meeting?</a>
                    </div>
                    <div
                      id="collapse6"
                      className="collapse accordionanswer"
                      aria-labelledby="heading6"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 col-md-10 pt-3" id="opaci">
                      <div>
                      <ul>  
                      <li>No, just to join a meeting as a participant you don’t need to create an account.</li>
                      <li>A Jaam account is only required if you host your own meeting and send invitations to participants. 
                      Having a Jaam account allows you to start your own meetings.</li>
                      </ul>
                      </div>
                      <div>&nbsp;</div>
                      </div>
                      </div>
                    </div>
                    
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse7"
                      aria-expanded="true"
                      aria-controls="collapse7"
                    >
                      <a> Do I have to download the Jaam <span id="new-line-mobile"></span>App to join the meeting?</a>
                    </div>
                    <div
                      id="collapse7"
                      className="collapse accordionanswer"
                      aria-labelledby="heading7"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 pt-3 col-md-10" id="opaci">
                      <div>  
                      No downloads are required, Jaam allows hosts or participants to join online meetings 
                      with their web browsers instead of downloading a meeting app.
                      </div>
                      <div>&nbsp;</div>
                      </div>
                      </div>
                    </div>
                    
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse8"
                      aria-expanded="true"
                      aria-controls="collapse8"
                    >
                      <a>How many participants can join <span id="new-line-mobile"></span>the online meeting?</a>
                    </div>
                    <div
                      id="collapse8"
                      className="collapse accordionanswer"
                      aria-labelledby="heading8"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 pt-3 col-md-10" id="opaci">
                      <div>  
                      A maximum of 50 participants can join a meeting at a time.
                      </div>
                      <div>&nbsp;</div>
                      </div>
                    </div>
                    </div>
                    
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse9"
                      aria-expanded="true"
                      aria-controls="collapse9"
                    >
                      <a>How do I invite others to join my meeting?</a>
                    </div>
                    <div
                      id="collapse9"
                      className="collapse accordionanswer"
                      aria-labelledby="heading9"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 pt-3 col-md-10" id="opaci">
                      <div>  
                      There are several ways to invite participants to the meeting.
                      <br />
                      <br />
                      1. Landing Page -
                      <ul>
                      <li>Click on ‘Copy Invite’ Link or Copy the Invitation if you want to send your instant meeting information to the participants.</li>
                      <li>Copy Invite link: Copy the meeting join link.</li>
                      <li>Copy Invitation link: Copy the invitation info along with a join link.</li> 
                      <li>Paste the URL or invitation and send it to the participants via email or chat.</li>
                      </ul>
                      2. Email -
                      <ul>
                      <li>Sign in to the JAAM Account.</li>
                      <li>Start or join a meeting.</li>
                      </ul> 
                      Find the invite icon on the top left corner to copy (or) open a participants panel from the menu and find the invite icon at the bottom of the panel.
                      </div>
                      <div>&nbsp;</div>
                      </div>
                      </div>
                    </div>
                    
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse10"
                      aria-expanded="true"
                      aria-controls="collapse10"
                    >
                      <a>Is there a limit to using the same account?</a>
                    </div>
                    <div
                      id="collapse10"
                      className="collapse accordionanswer"
                      aria-labelledby="heading10"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 col-md-10 pt-3" id="opaci">
                      <div>  
                      You can use the same account as many times as you like to Join the Jaam meeting. You have only 30 days of free trial to host a meeting using your trial account.
                      </div>
                      <div>&nbsp;</div>
                      </div>
                    </div>
                    </div>
                    
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion col-lg-12 mt-2" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse11"
                      aria-expanded="true"
                      aria-controls="collapse11"
                    >
                      <a>How do I eliminate static or echo <span id="new-line-mobile"></span>at a meeting?</a>
                    </div>
                    <div
                      id="collapse11"
                      className="collapse accordionanswer"
                      aria-labelledby="heading11"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 pt-3 col-md-10" id="opaci">
                      <ul>
                        <li>Echo can be caused by many things, such as a participant 
                      connected to the meeting audio on multiple devices or two
                      participants joined in from the same local</li>
                        <li>A participant has both the computer and telephone audio 
                      active, Participants with computer or telephones speakers that
                      are too close to each other,</li>
                        <li>Echo cancellation has failed (device or performance issue)</li>
                        <li>A bad microphone.</li>
                      </ul>
                      </div>
                      <div>&nbsp;</div>
                      </div>
                    </div>
                    
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse12"
                      aria-expanded="true"
                      aria-controls="collapse12"
                    >
                      <a>Why my audio isn’t working?</a>
                    </div>
                    <div
                      id="collapse12"
                      className="collapse accordionanswer"
                      aria-labelledby="heading12"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 pt-3 col-md-10" id="opaci">
                      <div>  
                      <ul>
                        <li>Ensure your audio is turned on,</li>
                        <li>Check the speaker icon from your computer is turned off, tap it to turn on.</li>
                        <li>Try using headphones or speaker,</li>
                        <li>Visit your browser settings and check the audio permission is enableb,</li>
                        <li>Visit your device's Manager and update/enable the audio driver.</li>
                      </ul>
                      </div>
                      <div>&nbsp;</div>
                      </div>
                      </div>
                    </div>
                    
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse13"
                      aria-expanded="true"
                      aria-controls="collapse13"
                    >
                      <a>Why my camera isn't working?</a>
                    </div>
                    <div
                      id="collapse13"
                      className="collapse accordionanswer"
                      aria-labelledby="heading13"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 col-md-10 pt-3" id="opaci">
                      <div>  
                      <ul>
                      <li>Ensure your camera is turned on</li>
                      <li>Visit your device's manager and update/enable the camera driver</li>
                      <li> If your camera is not showing up within the Jaam application, make sure that any other application that utilizes your camera or not.</li>
                      <li>Visit your browser settings and check for camera permission.</li>
                      </ul>
                      </div>
                      <div>&nbsp;</div>
                      </div>
                      </div>
                    </div>
                    
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse14"
                      aria-expanded="true"
                      aria-controls="collapse14"
                    >
                      <a>How can I tell who is at the <span id="new-line-mobile"></span>meeting / How can I view the Participant list in the meeting?</a>
                    </div>
                    <div
                      id="collapse14"
                      className="collapse accordionanswer"
                      aria-labelledby="heading14"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 pt-3 col-md-10" id="opaci">
                      <div>  
                      Gallery View will list all the participants, else click on the participant’s icon
                      on the menu to know the list of participants in the meeting.
                      </div>
                      <div>&nbsp;</div>
                      </div>
                      </div>
                    </div>
                    
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse15"
                      aria-expanded="true"
                      aria-controls="collapse15"
                    >
                      <a>How can I change my passcode?</a>
                    </div>
                    <div
                      id="collapse15"
                      className="collapse accordionanswer"
                      aria-labelledby="heading15"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 col-md-10 pt-3" id="opaci">
                      <div>  
                      You can change the passcode by clicking on edit in ‘My Account’. You can have numeric, alpha or any combination of alpha-numeric.
                      </div>
                      <div>&nbsp;</div>
                      </div>
                      </div>
                    </div>
                    
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse16"
                      aria-expanded="true"
                      aria-controls="collapse16"
                    >
                      <a> What are the roles in the JAAM account?</a>
                    </div>
                    <div
                      id="collapse16"
                      className="collapse accordionanswer"
                      aria-labelledby="heading16"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 pt-3 col-md-10" id="opaci">
                      <div>  
                      There are 3 roles in the Jaam accounts:
                      <ul>
                        <li>Host: Has all the rights to start a meeting, add/remove participants, including role management.</li>
                        <li>Co-Host: -Can add, remove, or edit users in the meetings.</li>
                        <li>Participants: Have no administrative rights.</li>
                      </ul>
                      </div>
                      <div>&nbsp;</div>
                      </div>
                      </div>
                    </div>
                    
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse17"
                      aria-expanded="true"
                      aria-controls="collapse17"
                    >
                      <a>How can the host assign a <span id="new-line-mobile"></span>co-host?</a>
                    </div>
                    <div
                      id="collapse17"
                      className="collapse accordionanswer"
                      aria-labelledby="heading17"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 col-md-10 pt-3" id="opaci">
                      To make a participant as a host, click on the “participants’” icon on the 
                      conference page. In the participants’ window, hover over the name of the 
                      participant you want to make co-host and click ‘More’. Click on the ‘Assign 
                      Co-host’ option, Click ‘Yes’ to confirm, and make the participant co-host.
                      </div>
                      <div>&nbsp;</div>
                      </div>
                    </div>
                    
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse18"
                      aria-expanded="true"
                      aria-controls="collapse18"
                    >
                      <a>Can someone else start my <span id="new-line-mobile"></span>meeting?</a>
                    </div>
                    <div
                      id="collapse18"
                      className="collapse accordionanswer"
                      aria-labelledby="heading18"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 pt-3 col-md-10" id="opaci">
                      <div>  
                      On the landing page, if you enable the “Allow Participants to join 
                      the meeting before the Host” option then the participants can join 
                      the meeting before the host start the meeting or without the host.
                      <br />
                      Note: When the user disables the “Allow Participants to join the 
                      meeting before the Host”  in the landing page, the participants 
                      have to wait until the host starts the meeting.
                      </div>
                      <div>&nbsp;</div>
                      </div>
                      </div>
                    </div>
                    
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse19"
                      aria-expanded="true"
                      aria-controls="collapse19"
                    >
                      <a> How do I share my screen? </a>
                    </div>
                    <div
                      id="collapse19"
                      className="collapse accordionanswer"
                      aria-labelledby="heading19"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 pt-3 col-md-10" id="opaci">
                      <div>
                      To share your screen with the participants:
                      Click the ‘Share Screen’ icon on the menu. A popup window 
                      will be shown. Select any one of these screen share options: 
                      Share your entire desktop, specific application windows, or 
                      share your browser tab and then click on the  ‘Share audio’ 
                      to screen your screen along with audio and press the “Share” 
                      button to start the screen share.
                      </div>
                      <div>&nbsp;</div>
                      </div>
                      </div>
                    </div>
                    
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse20"
                      aria-expanded="true"
                      aria-controls="collapse20"
                    >
                    <a>How do I change my theme?</a>
                    </div>
                    <div
                      id="collapse20"
                      className="collapse accordionanswer"
                      aria-labelledby="heading20"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 col-md-10 pt-3" id="opaci">
                      <div>  
                      You can change the theme by clicking on ‘My Account’. You can select the themes from the new popup window.
                      </div>
                      <div>&nbsp;</div>
                      </div>
                      </div>
                    </div>
                    
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse21"
                      aria-expanded="true"
                      aria-controls="collapse21"
                    >
                      <a> How to enable a Lobby room?</a>
                    </div>
                    <div
                      id="collapse21"
                      className="collapse accordionanswer"
                      aria-labelledby="heading21"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 col-lg-10 col-md-10 pt-3" id="opaci">
                      On the conference page, click on ‘more’ options, from the list, 
                      select the ‘security option’, a popup will be displayed. After 
                      you enable a meeting lobby, participants who have not yet joined 
                      the meeting must ask to wait until the host accepts the request.
                      </div>
                      <div>&nbsp;</div>
                      </div>
                    </div>
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse22"
                      aria-expanded="true"
                      aria-controls="collapse22"
                    >
                      <a>Is there a limit on the length of a Meeting?</a>
                    </div>
                    <div
                      id="collapse22"
                      className="collapse accordionanswer"
                      aria-labelledby="heading22"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 col-md-10 pt-3" id="opaci">
                        <ul>
                          <li>There is no time limit in the length of the meeting for the paid Jaam users.</li>
                          <li>In the free trial, the user has a time limit of 40-mins.</li>
                        </ul>
                      </div>
                      <div>&nbsp;</div>
                      </div>
                    </div>
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse23"
                      aria-expanded="true"
                      aria-controls="collapse23"
                    >
                      <a>Are my meetings kept private?</a>
                    </div>
                    <div
                      id="collapse23"
                      className="collapse accordionanswer"
                      aria-labelledby="heading23"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 pt-3" id="opaci">
                      Yes. We don’t listen or record any meetings.
                      </div>
                      <div>&nbsp;</div>
                      </div>
                    </div>
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse24"
                      aria-expanded="true"
                      aria-controls="collapse24"
                    >
                      <a>How to secure your meetings with <span id="new-line-mobile"></span>the lobby room?</a>
                    </div>
                    <div
                      id="collapse24"
                      className="collapse accordionanswer"
                      aria-labelledby="heading24"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 col-md-10 pt-3" id="opaci">
                      When someone enters the meeting, they will see a screen 
                      indicating they are in the waiting room and the host 
                      will see a message indicating someone is in the waiting 
                      room. Click ‘Admit’ to allow the participant to join 
                      immediately or click on ‘Remove’.
                      </div>
                      <div>&nbsp;</div>
                      </div>
                    </div>
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse25"
                      aria-expanded="true"
                      aria-controls="collapse25"
                    >
                      <a>Can the co-host admit from the waiting room?</a>
                    </div>
                    <div
                      id="collapse25"
                      className="collapse accordionanswer"
                      aria-labelledby="heading25"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 col-md-10 pt-3" id="opaci">
                      When participants are in the Waiting Room, hosts and co-hosts <b>can admit</b> or <b>remove</b> them on an individual basis.
                      </div>
                      <div>&nbsp;</div>
                      </div>
                    </div>
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse26"
                      aria-expanded="true"
                      aria-controls="collapse26"
                    >
                      <a>Can a host leave a meeting <span id="new-line-mobile"></span>without ending it?</a>
                    </div>
                    <div
                      id="collapse26"
                      className="collapse accordionanswer"
                      aria-labelledby="heading26"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 col-md-10 pt-3" id="opaci">
                      Yes! Before leaving the meeting, if you want you can assign 
                      another host to take over the meeting. When you click on the 
                      ‘leave meeting’, another pop-up window will appear to assign
                      a host to continue the meeting. You can assign another host
                      from the list of participants and then click on the ‘Assign
                      and Leave’ button to leave the meeting successfully without
                      ending it.
                      <br />
                      Note: If you want to end the meeting for all click on the 
                      ‘End Meeting’ button
                      </div>
                      <div>&nbsp;</div>
                      </div>
                    </div>
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse27"
                      aria-expanded="true"
                      aria-controls="collapse27"
                    >
                      <a> What devices are compatible <span id="new-line-mobile"></span>with online meetings?</a>
                    </div>
                    <div
                      id="collapse27"
                      className="collapse accordionanswer"
                      aria-labelledby="heading27"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 col-md-10 pt-3" id="opaci">
                      Online meetings work on PCs, Macs®, Chromebooks™, iOS, and Android™ phones and tablets with Chrome and Safari browsers. No downloads are required.
                      </div>
                      <div>&nbsp;</div>
                      </div>
                    </div>
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse28"
                      aria-expanded="true"
                      aria-controls="collapse28"
                    >
                      <a>Which operating systems are supported with Jaam?</a>
                    </div>
                    <div
                      id="collapse28"
                      className="collapse accordionanswer"
                      aria-labelledby="heading28"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 col-md-10 pt-3" id="opaci">
                      <ul>
                      <li>Windows® 7</li>
                      <li>Mac® OS X 10.9</li>
                      <li>iOS 9.0 (screen sharing requires v11.0)</li>
                      <li>Android 4.0.1 (screen sharing requires v5.0)</li>
                      <li>Ubuntu® 14.04 64-bit, other distributives may be also supported but the result is not guaranteed
                      <ul>
                        <li>Preferred display server: X.Org</li>
                        <li>Desktop environment: Unity, Gnome</li>
                      </ul>
                      </li>
                      <li>
                        Bandwidth: 100Kb/s (HD Audio), 400Kb/s (screen
                        sharing), 1.1Mb/s (video)
                      </li>
                      <li>
                      Desktop: Chrome™ 57, Firefox® 60, Safari® 10.1 (Mac only), Internet Explorer® 11(Windows only), Edge 15 (Windows only)
                      </li>
                      <li>
                      Mobile: Chrome™ 57, Firefox® Android 60 and iOS 20, Safari® iOS 10
                      </li>
                      </ul>  
                      </div>
                      <div>&nbsp;</div>
                      </div>
                    </div>
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse29"
                      aria-expanded="true"
                      aria-controls="collapse29"
                    >
                      <a>How much does JAAM cost?</a>
                    </div>
                    <div
                      id="collapse29"
                      className="collapse accordionanswer"
                      aria-labelledby="heading29"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 col-md-10 pt-3" id="opaci">
                      A basic trial Jaam license is free. Please check out the 
                      available Jaam plans on the pricing page 
                      (<Link
                      to="/pricing"
                      id="on"
                      className="text-purple fontbold"
                    >
                      &nbsp;{`${api.WEB_URL}/pricing`}
                    </Link>).
                      </div>
                      <div>&nbsp;</div>
                      </div>
                    </div>
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse30"
                      aria-expanded="true"
                      aria-controls="collapse30"
                    >
                      <a>How do I make a payment?</a>
                    </div>
                    <div
                      id="collapse30"
                      className="collapse accordionanswer"
                      aria-labelledby="heading30"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 col-md-10 pt-3" id="opaci">
                      <ol>
                      <li>
                      On the landing page, click on Upgrade Now or on the Pricing page, choose the currency and plan and click ‘Buy Now’.
                      <br/>
                      Note: When you select currency (INR/Dollar) in your first payment, you cannot change the currency for future payment. 
                      </li>
                      <li>
                      On the Billing page, Select your currency and plan and then click ‘Pay Now ‘.
                      </li>
                      <li>
                      On the payment page, Enter your Account information, Billing address.
                      </li>
                      <li>
                      Click on the ‘Subscribe’ button. Your payment is successful.
                      </li>
                      <li>
                      Enjoy the Unlimited fun-filled Jaam experience!.
                      </li>
                      </ol>  
                      </div>
                      <div>&nbsp;</div>
                      </div>
                    </div>
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse31"
                      aria-expanded="true"
                      aria-controls="collapse31"
                    >
                      <a>Where do I find my payment<span id="new-line-mobile"></span>/invoice history?</a>
                    </div>
                    <div
                      id="collapse31"
                      className="collapse accordionanswer"
                      aria-labelledby="heading31"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 col-md-10 pt-3" id="opaci">
                      You can find the invoice history for your JAAM account on 
                      the Billing page. On the billing page Click on the ‘Invoice 
                      history’ tab, you can view your paid invoices. You can also 
                      download the receipt/invoice for your future reference. 
                      </div>
                      <div>&nbsp;</div>
                      </div>
                    </div>
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse32"
                      aria-expanded="true"
                      aria-controls="collapse32"
                    >
                      <a>How do I change from ‘Monthly to Annual’ or ‘Annual to Monthly’ Payments?</a>
                    </div>
                    <div
                      id="collapse32"
                      className="collapse accordionanswer"
                      aria-labelledby="heading32"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 col-md-10 pt-3" id="opaci">
                      If the user wants to change the plan, he/she needs to cancel the plan and wait until the end of the next billing cycle to update the new plan.
                      </div>
                      <div>&nbsp;</div>
                      </div>
                    </div>
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse33"
                      aria-expanded="true"
                      aria-controls="collapse33"
                    >
                      <a>How do I cancel my Jaam account?</a>
                    </div>
                    <div
                      id="collapse33"
                      className="collapse accordionanswer"
                      aria-labelledby="heading33"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 col-md-10 pt-3" id="opaci">
                      You can cancel your subscription through the Billing Page at any time before the next billing cycle. If you do not cancel your subscription, it will automatically be renewed. You can make other changes to your subscriptions, including changing your billing period and adjusting the number of licenses.
                      <br/>
                      Note: After canceling your subscription, your plan remains active until the end of your billing period.
                      </div>
                      <div>&nbsp;</div>
                      </div>
                    </div>
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse34"
                      aria-expanded="true"
                      aria-controls="collapse34"
                    >
                      <a>What is the Refund process for <span id="new-line-mobile"></span>a paid plan?</a>
                    </div>
                    <div
                      id="collapse34"
                      className="collapse accordionanswer"
                      aria-labelledby="heading34"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 col-md-10 pt-3" id="opaci">
                      No refund for the monthly plans. Annual plan subscribers are allowed to cancel the plan and can request a refund via our support team at <a href = "mailto: support@jaam.co">support@jaam.co</a> . 
                      <br />
                      Note: To be eligible for a refund, an account must not have any usage since the last time the account was billed.
                      </div>
                      <div>&nbsp;</div>
                      </div>
                    </div>
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse35"
                      aria-expanded="true"
                      aria-controls="collapse35"
                    >
                      <a>What payment methods can I use?</a>
                    </div>
                    <div
                      id="collapse35"
                      className="collapse accordionanswer"
                      aria-labelledby="heading35"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 col-md-10 pt-3" id="opaci">
                      Jaam accepts all credit cards, debit card payments. Jaam saves the default payment for your upcoming payments. We make sure that your payment information will be kept safe and secure.
                      <br />
                      Note: If you are a user from India, Jaam will not accept the international card (AMEX) payment.
                      </div>
                      <div>&nbsp;</div>
                      </div>
                    </div>
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse36"
                      aria-expanded="true"
                      aria-controls="collapse36"
                    >
                      <a>How can I change the payment <span id="new-line-mobile"></span>details in Jaam?</a>
                    </div>
                    <div
                      id="collapse36"
                      className="collapse accordionanswer"
                      aria-labelledby="heading36"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 col-md-10 pt-3" id="opaci">
                      On the payment page, you can add or change your default payment.
                      </div>
                      <div>&nbsp;</div>
                      </div>
                    </div>
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse37"
                      aria-expanded="true"
                      aria-controls="collapse37"
                    >
                      <a>What happens when a payment is declined?</a>
                    </div>
                    <div
                      id="collapse37"
                      className="collapse accordionanswer"
                      aria-labelledby="heading37"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 col-md-10 pt-3" id="opaci">
                      When the payment fails but the amount gets deducted from the 
                      bank account, it usually takes up to 5 - 7  business days to 
                      add money back to your account. Please wait for your bank to 
                      complete the reversal. Refer to the bank account statement to 
                      verify if your transaction has been reversed.
                      </div>
                      <div>&nbsp;</div>
                      </div>
                    </div>
                    <div className="fullaccordion">
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div
                      role="button"
                      className="collapse-header mb-n2 collapsed"
                      data-toggle="collapse"
                      data-target="#collapse38"
                      aria-expanded="true"
                      aria-controls="collapse38"
                    >
                      <a>Why did my payment fail?</a>
                    </div>
                    <div
                      id="collapse38"
                      className="collapse accordionanswer"
                      aria-labelledby="heading38"
                      data-parent="#accordionExample"
                    >
                      <div className="text-525260 col-md-10 pt-3" id="opaci">
                      There are several reasons why a payment can be denied. 
                      <ul>
                        <li>The debit card has Insufficient funds or has reached its limit.</li>
                        <li>The credit or debit card information is incorrect.</li>
                        <li>CVV mismatch.</li>
                        <li>The credit or debit card  is expired.</li>
                        <li>Your card has been previously reported as stolen.</li>
                        <li>Your purchase triggered fraud protection.</li>
                        <li>Your country is not supported by Rebtel payment providers.</li>
                        <li>Your payment attempts have been refused by our payment provider.</li>
                      </ul>
                      </div>
                      <div>&nbsp;</div>
                      </div>
                    </div>
                    <div className="row horizontal-accordion mt-2 col-lg-12" id="opac"></div>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.session.user,
  token: state.session.token,
  allprices: state.session.allpricesList,
  initCurrency: state.session.initCurrency,
  cusCurrency: state.session.customerCurrency
});

export default withTranslation()(connect(mapStateToProps)(Pricing));