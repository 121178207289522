import AWS from "aws-sdk";
import { api } from "../../../../common/api";
const DownloadFileFromCloud = (bucketName, folderName, fileReference) => {

    AWS.config.update({
        region: api.REGION,
        accessKeyId: api.AWS_KEY,
        secretAccessKey: api.SECRET_KEY
    });
    let slash = '/';
    let folder = folderName.split('/');
    folder = folder[0];
    var bucket = new AWS.S3({ apiVersion: '2012-10-17', params: { Bucket: bucketName + slash + folder } });
    var fileName = fileReference.split('/').pop().toString();
    var value = fileReference.split('/').pop();
    value = value.split(".");
    const fileId = value[0].toString();
    var url = bucket.getSignedUrl('getObject', { Key: fileName }); {
        return document.getElementById(fileId).href = url;
    }
}
export default DownloadFileFromCloud;