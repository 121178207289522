// Jaam New File 
import React, { useState } from "react";
import { Slider } from "@material-ui/core";
import { withStyles, makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
  horizontal: {
    width: 600,
    margin: '0 20px 0 20px',
    height: 50
  }
})

const CustomSlider = withStyles({
  root: {
    height: 30
  },
  thumb: {
    height: 16,
    width: 10,
    backgroundColor: '#908C9A',
    border: '1px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover': {
      boxShadow: '0px 0px 0px 8px rgba(84, 199, 97, 0.16)'
    },
    '&$active': {
      boxShadow: '0px 0px 0px 12px rgba(84, 199, 97, 0.16)'
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)'
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 8,
    borderRadius: 4,
    color: '#3f51b5'
  }
})(Slider)

function valueText(value) {
  return `${value}°C`;
}
function getValueLabelFormat(value) {
  return bandwidthList.findIndex((mark) => mark.value === value) + 1;
}

function getLabelForValue(value) {
  var labelIndex = getValueLabelFormat(value) - 1;
  return bandwidthList[labelIndex].label;
}

const bandwidthList = [
  {
    value: 25,
    label: 'Low Bandwidth',
  },
  {
    value: 50,
    label: 'Low Definition',
  },
  {
    value: 75,
    label: 'Medium Definition',
  },
  {
    value: 100,
    label: 'High Definition',
  },
];

function RangeSlider() {
  const defaultSliderValue = 100
  const classes = useStyles()
  const [val, setVal] = useState(defaultSliderValue)

  const handleChange = (e, data) => {
    setVal(data);
  }

  const buttonCallback = () => {
    sessionStorage.setItem('BandWidthRange', val);
    sessionStorage.setItem('BandwidthCategory', getLabelForValue(val));
    
  }

  return (
    <>
      <div className="col-12">
        <div className="row mt-3">
          <div className="col-10 col-md-12 col-lg-12">
            <div className={classes.horizontal}>
              <CustomSlider
                defaultValue={defaultSliderValue}
                valueLabelFormat={getValueLabelFormat}
                getAriaValueText={valueText}
                aria-labelledby="discrete-slider-restrict"
                step={25}
                valueLabelDisplay="off"
                marks={bandwidthList}
                value={val}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 col-lg-6 showmd">&nbsp;</div>
            <div className="col-6 showxs">&nbsp;</div>
            <div className="col-10 col-md-12 col-lg-12">
              <button
                type="button"
                className="btn btn-purple landing-btn-sm-purple float-right"
                onClick={buttonCallback}
                >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default RangeSlider;