import React, { Component } from "react";

class Cookies extends Component {
    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
      }

  render() {
    return (
      <section className="bg-white">
        <div className="container container-decoration">
          <div className="row text-justify">
           <div className="col-12">
            <br></br>
            <br></br>
            <br></br>
            <h4 className="text-center mb-3">JAAM COMMUNICATIONS LLC.</h4>
            <u>
            <h4 className="text-center Gilroy mb-3">COOKIE POLICY</h4>
            </u>
            <br></br>
            <p>Please read this cookie policy (“cookie policy”, "policy") carefully before using www.jaam.co 
            &nbsp;website (“website”, "service") operated by Jaam communications LLC.,("us", 'we", "our").</p>
            &nbsp;
            <div>
            <p className="GilroyLight h4">Cookie Policy</p>
                <p>Jaam communications LLC., cookie policy ("Policy") describes what cookies are
                and how and they're being used by the jaam.co website ("Website" or "Service")
                and any of its related products and services (collectively, "Services"). This 
                Policy is a legally binding agreement between you ("User", "you" or "your") and
                this Website operator ("Operator", "we", "us" or "our"). You should read this Policy 
                so you can understand the types of cookies we use, the information we collect using 
                cookies and how that information is used. It also describes the choices available to
                you regarding accepting or declining the use of cookies. For further information on
                how we use, store and keep your personal data secure, see our privacy policy.</p>   
            </div>
            &nbsp;
            <div>
                <p className="GilroyLight h4">1. What are cookies?</p>
                    <p>Cookies are small pieces of data stored in text files that
                        are saved on your computer or other devices when websites 
                        are loaded in a browser. They are widely used to remember
                        you and your preferences, either for a single visit 
                        (through a "session cookie") or for multiple repeat 
                        visits (using a "persistent cookie").</p>
                    <p>Session cookies are temporary cookies that are used during
                       the course of your visit to the Website, and they expire when
                        you close the web browser</p>
                    <p>Persistent cookies are used to remember your preferences within 
                        our Website and remain on your desktop or mobile device even 
                        after you close your browser or restart your computer. They 
                        ensure a consistent and efficient experience for you while 
                        visiting the Website and Services</p>
                    <p>Cookies may be set by the Website ("first-party cookies"), or
                        by third parties, such as those who serve content or provide
                        advertising or analytics services on the Website ("third party cookies"). 
                        These third parties can recognize you when you visit our website
                        and also when you visit certain other websites. You may learn more
                        about cookies and how they work here.</p>
            </div>
            &nbsp;
            <div>
                <p className="GilroyLight h4">2. How JAAM communications LLC., uses cookies?</p>
                <p className="GilroyLight h4">(i) Necessary cookies</p>
                <p>Necessary cookies allow us to offer you the best possible experience when
                   accessing and navigating through our website and using its features. For 
                   example, these cookies let us recognize that you have created an account 
                   and have logged into that account</p>
                <p className="GilroyLight h4">(ii) Functionality cookies</p>
                <p>Functionality cookies let us operate the site in accordance with the choices
                   you make. For example, we will recognize your username and remember how you
                    customized the site during future visits</p>
                <p className="GilroyLight h4">(iii) Analytical cookies</p>
                <p>These cookies enable us and third-party services to collect aggregated data 
                   for statistical purposes on how our visitors use the website. These cookies
                   do not contain personal information such as names and email addresses and are
                   used to help us improve your user experience of the website</p>
            </div>
            &nbsp;
            <div>
                <p className="GilroyLight h4">3. What are your cookie options?</p>
                <p>If you don't like the idea of cookies or certain types of cookies, you can 
                    change your browser's settings to delete cookies that have already been set 
                    and to not accept new cookies.</p>
            </div>
            &nbsp;
            <div>
                <p className="GilroyLight h4">Changes and amendments</p>
                    <p>We reserve the right to modify this Policy or its terms relating to the Website
                       and Services at any time, effective upon posting of an updated version of this
                       Policy on the Website. When we do, we will post a notification on the main page
                       of the Website. Continued use of the Website and Services after any such changes
                       shall constitute your consent to such changes.</p>
            </div>
            &nbsp;
            <div>
                <p className="GilroyLight h4">Acceptance of this policy</p>
                <p>You acknowledge that you have read this Policy and agree to all its terms and conditions.
                   By accessing and using the Website and Services you agree to be bound by this Policy. </p>
                <p>If you'd like to delete cookies or instruct your web browser to delete or refuse cookies,
                   please visit the help pages of your web browser.</p>
                <p>Please note, however, that if you delete cookies or refuse to accept them, you might not
                   be able to use all of the features we offer, you may not be able to store your preferences,
                   and some of our pages might not display properly.</p>
            </div>
            &nbsp;
            <div>
                <p className="GilroyLight h4">Contacting Us</p>
                <p>If you would like to contact us to understand more about this Policy or wish to contact us
                   concerning any matter relating to our use of cookies, you may send an email to 
                   <a href="mailto:support@jaam.co"> support@jaam.co </a>
                   or visit our website www.jaam.co</p>
            </div>
            &nbsp;
            <p>This document was last updated on March 3, 2021</p>
            <br/>
            <br/>
            <br/>
            </div> 
          </div>
        </div>
      </section>
    );
  }
}

export default Cookies;
