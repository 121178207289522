import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import React, { Component } from "react";
//Jaam New Imports
import Webcam from "react-webcam";
import Preference from "./Preference";
import RangeSlider from "./VideoQuality";
import EditName from "./EditName";

//Jaam code starts
const user = "";
class Setting extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
  //Jaam code starts
    this.state = {
      user: user.firstname,
      nameEditButton: true,
      videoInputDevList: [],
      audioInputDevList: [],
      audioOutputDevList: [],
      selectedAudioOutput: null,
      selectedAudioInput: null,
      selectedVideoInput: null
    };

    this.audioOutputRef = React.createRef();
    this.audioInputRef = React.createRef();
    this.videoInputRef = React.createRef();
  }

  componentDidMount() {
    navigator.mediaDevices.enumerateDevices().then(result => {
      this.setState({
        audioOutputDevList: this.filterAudioVideoDevices(result, "audiooutput")
      });
      this.setState({
        audioInputDevList: this.filterAudioVideoDevices(result, "audioinput")
      });
      this.setState({
        videoInputDevList: this.filterAudioVideoDevices(result, "videoinput")
      });
    }, function (error) {
      console.log("Error occurred: " + error);
    });
  }

  filterAudioVideoDevices(outputDevices, stringToCheck) {
    var audioVideoDevices = outputDevices.filter(({ kind }) => kind === stringToCheck);
    var filteredDevices = audioVideoDevices.filter(avDevice => (/\d/.test(avDevice['deviceId'])));
    return filteredDevices;
  }

  playAudio() {
    const audioEl = document.getElementsByClassName("audio-element")[0]
    audioEl.play()
  }
// Jaam code end
  render() {
// Jaam code starts
    this.nameEditButton = React.createRef();
    const { user } = this.props;
    return (
      <div className="row landing-settings">
        <div className="col-md-1 col-lg-1"></div>
        <div className="col-12 col-md-10 col-lg-10 text-left text-black showmd">
          <div className="card">
            <div className="row mt-4 ml-3 devicesettings ">
              Device Settings
            </div>
            <div className="row">
              <div className="col-12 mt-4 col-md-6 col-lg-6 video-container">
                {/* Jaam code starts */}
                <Webcam width={300} height={181} audio={false} />
                <div className="ml-5 ml-md-4 ml-lg-5 mt-1 mb-3 mb-md-0 mb-lg-0 " />
              </div>

              <div className="col-12 col-md-6 mt-md-4 mt-lg-4 col-lg-6">
                <div className="row">
                  <div className="col-10 mt-3  col-md-12 col-lg-10">
                    {/* Jaam code starts */}
                    <select
                      ref={this.videoInputRef}
                      defaultValue={this.state.videoInputDevList[0] ? this.state.videoInputDevList[0] : null}
                      onChange={(e) => {
                        this.setState({ selectedVideoInput: e.target.value ? e.target.value : null });
                      }}
                      onClick={(e) => {
                        this.setState({ selectedVideoInput: e.target.value ? e.target.value : null });
                      }}
                      className="form-control-select-sm select-purple schedule-meetings-placeholder">
                      {this.state.videoInputDevList.map((device, key) => (
                        <option key={key}>{device.label}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-10 mt-3 col-md-12 col-lg-10">

                    {/* Jaam code starts */}
                    <select
                      ref={this.audioInputRef}
                      defaultValue={this.state.audioInputDevList[0] ? this.state.audioInputDevList[0] : null}
                      onChange={(e) => {
                        this.setState({ selectedAudioInput: e.target.value });
                      }}
                      onClick={(e) => {
                        this.setState({ selectedAudioInput: e.target.value });
                      }}
                      className="form-control-select-sm select-purple schedule-meetings-placeholder"
                    >
                      {this.state.audioInputDevList.map((device, key) => (
                        <option key={key}>{device.label}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-10 mt-3 col-md-12 col-lg-10">
                    {/* Jaam code starts */}
                    <select
                      ref={this.audioOutputRef}
                      defaultValue={this.state.audioOutputDevList[0] ? this.state.audioOutputDevList[0] : null}
                      onChange={(e) => {
                        this.setState({ selectedAudioOutput: e.target.value });
                      }}
                      onClick={(e) => {
                        this.setState({ selectedAudioOutput: e.target.value });
                      }}
                      className="form-control-select-sm select-purple schedule-meetings-placeholder"
                    >
                      {this.state.audioOutputDevList.map((device, key) => (
                        <option key={key}>{device.label}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg-4">&nbsp;</div>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-12">
                <div className="row">
                  <div className="col-10 col-md-6 col-lg-6 mt-3">
                    <div className="audio-input-preview">

                      <div className="audio-input-preview-level">
                        {/*  <Linear/> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-4 ml-1">
                    {/* Jaam code starts */}
                    <small><a onClick={this.playAudio} href="#" className="GilroyExtrabold text-purple">Play a Test Sound</a></small>
                    <audio className="audio-element">
                      <source src="https://assets.coderrocketfuel.com/pomodoro-times-up.mp3"></source>
                    </audio>
                  </div>
                  <div className="col-md-1 col-lg-1 showmd">&nbsp;</div>
                </div>
                <div className="row mt-2 mt-md-4 mt-lg-4">
                  <div className="col-12 col-md-6 col-lg-6">&nbsp;</div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="row">
                      <div className="col-md-2 col-lg-2 showmd">&nbsp;</div>
                      <div className="col-2 showxs"></div>
                      <div className="col-4 col-md-3 col-lg-4">
                        <button
                          type="button"
                          // Jaam code starts
                          className="btn no-class text-purple cancel-btn"
                          onClick={() => {
                            var audioOut = sessionStorage.getItem("selectedAudioOutputDevice");
                            this.audioOutputRef.current.value = audioOut ? audioOut : this.state.audioOutputDevList[0]
                            var audioIn = sessionStorage.getItem("selectedAudioInputDevice");
                            this.audioInputRef.current.value = audioIn ? audioIn : this.state.audioInputDevList[0]
                            var videoIn = sessionStorage.getItem("selectedVideoInputDevice");
                            this.videoInputRef.current.value = videoIn ? videoIn : this.state.videoInputDevList[0]
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="col-3 col-md-3 col-lg-3">
                        <button
                          type="button"
                          // Jaam code starts
                          className="btn btn-purple landing-btn-sm-purple"
                          onClick={() => {
                            sessionStorage.setItem("selectedAudioOutputDevice", this.state.selectedAudioOutput ? this.state.selectedAudioOutput : (this.audioOutputRef.current.value ? this.audioOutputRef.current.value : null));
                            sessionStorage.setItem("selectedAudioInputDevice", this.state.selectedAudioInput ? this.state.selectedAudioInput : (this.audioInputRef.current.value ? this.audioInputRef.current.value : null));
                            sessionStorage.setItem("selectedVideoInputDevice", this.state.selectedVideoInput ? this.state.selectedVideoInput : (this.videoInputRef.current.value ? this.videoInputRef.current.value : null));
                          }}
                        >
                          Apply
                        </button>
                      </div>
                      <div className="col-3 showxs"></div>
                      <div className="col-md-2 col-lg-2 showmd">&nbsp;</div>
                    </div>
                  </div>
                </div>
                <div className="col-10 col-md-11 col-lg-11 my-2 landing-bottom-line"></div>
                <div className="row mt-4 ml-3">
                  <div className="col-12 mt-2 upcomingmeetings">
                    Bandwidth Settings
                  </div>
                  <div className="col-12 mt-3 landing-label">
                    Video Quality
                  </div>
                  <div className="col-12">
                    <div className="row mt-3">
                      {/* Jaam code starts */}
                      <div className="col-10 col-md-10 col-lg-10">
                        <RangeSlider />
                      </div>
                      <div className="col-md-6 col-lg-6 showmd">&nbsp;</div>
                      <div className="col-6 showxs">&nbsp;</div>
                      <div className="col-2 showxs">&nbsp;</div>
                      <div className="col-md-2 col-lg-2 showmd"></div>
                    </div>
                  </div>
                </div>
                <div className="col-10 col-md-11 col-lg-11 my-2 landing-bottom-line"></div>
                <div className="row mt-3 ml-3">
                  <div className="col-12 upcomingmeetings">
                    Display Name
                  </div>
                  <div className="col-lg-12">
                    <div className="row mt-4">
                      <div className="col-md-4 col-lg-4 col-4 mt-2">
                        <div className="landing-text-muted" >
                          Set Your Display Name :
                        </div>
                      </div>
                      <div className="col-md-7 col-lg-7 col-7 form-inline">
                        <EditName />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-10 col-md-11 col-lg-11 my-2 landing-bottom-line"></div>
            <div className="row mt-3 ml-2 mb-2 ml-md-3 ml-lg-4">
              <div className="col-12 upcomingmeetings">
                Preferences
              </div>
              <div className="col-12 col-md-10 col-lg-10">
                <div className="row mt-4">
                  <Preference />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-10 col-lg-10 text-left text-black showxs">
          <div className="col-12 mt-4 ml-2 upcomingmeetings ">
            Device Settings
          </div>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-6">
            </div>
            <div className="offset-1 col-10 offset-1 mt-3">
              <small><a href="#" className="GilroyExtrabold text-purple">Play a Test Sound</a></small>
            </div>
            <div className="offset-1 col-10 offset-1 mb-4">
              <div className="audio-input-preview">
                <div className="audio-input-preview-level"></div>
              </div>
            </div>
            <div className="col-12 ml-n2">
              <div className="row">
                <div className="offset-1 col-10 offset-1 mb-3">
                  <select
                    className="form-control-select-sm select-purple"
                  >
                    <option defaultValue>FaceTime HD Camera (05a...</option>
                  </select>
                </div>
                <div className="offset-1 col-10 offset-1 mb-3">
                  <select
                    className="form-control-select-sm select-purple"
                  >
                    <option defaultValue>Internal Microphone (built...</option>
                  </select>
                </div>
                <div className="offset-1 col-10 offset-1 mb-3">
                  <select
                    className="form-control-select-sm select-purple"
                  >
                    <option defaultValue>Internal Speakers (Built-in)</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12 ml-2">
              <div className="card col-11">
                <div className="row mt-4">
                  <div className="col-12 mt-4 upcomingmeetings">
                    Bandwidth Settings
                  </div>
                  <div className="col-12 mt-2 landing-label">
                    Video Quality
                  </div>
                  <div className="col-12">
                    <div className="row my-3">
                      <div className="col-12">

                      </div>
                      <div className="col-6 showxs">&nbsp;</div>
                      <div className="col-4 col-md-4 col-lg-4">
                        <button
                          type="button"
                          className="btn btn-purple landing-btn-sm-purple">
                          Done
                        </button>
                      </div>
                      <div className="col-2">&nbsp;</div>
                    </div>
                  </div>
                </div>
                <div className="row my-4">
                  <div className="col-12 upcomingmeetings">
                    Display Name
                  </div>
                  <div className="col-lg-12">
                    {user.firstname ? (
                      <div className="row my-2">
                        <div className="col-12 mt-2 mb-2">
                          <div className="landing-text-muted " >
                            Set Your Display Name :
                          </div>
                        </div>
                        <div className="col-12 ">
                          {this.state.onEdit ? (
                            <div className="md-form mt-0">
                              <input
                                type="text"
                                className="form-control"
                                style={{
                                  width: "100px",
                                }}
                                value={user.firstname}
                                onChange={(e) =>
                                  this.setState({ user: e.target.value })
                                }
                              />
                            </div>
                          ) : (
                            <div className="col-6 ml-n3 landing-label">{user.firstname}</div>
                          )}
                          <div className="col-2"></div>
                          <label
                            className="col-4 mt-2 ml-3 landing-small-purple"
                            onClick={() => this.onPasscodeChange()}
                            style={{ cursor: "pointer" }}
                          >
                            {this.state.onEdit ? "Save" : "Edit"}
                          </label>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12 upcomingmeetings">
                    Preferences
                  </div>
                  <div className="col-12 col-md-10 col-lg-10">
                    <div className="row">
                      <div className="col-12 mt-2 mb-2 landing-text-muted">Audio Mute:</div>
                      <div className="col-12 mb-2">
                        <input type="checkbox" className="larger-checkbox" />&nbsp;
                        <label htmlFor="" className="landing-label">Everyone started muted</label>
                      </div>
                      <div className="col-12 mb-2 landing-text-muted">Video Mute:</div>
                      <div className="col-12 mb-2">
                        <input type="checkbox" className="larger-checkbox" />&nbsp;
                        <label htmlFor="" className="landing-label">Everyone starts video muted</label>
                      </div>
                      <div className="col-12 mb-2 landing-text-muted">Allow Participants:</div>
                      <div className="col-12 mb-2">
                        <input type="checkbox" className="larger-checkbox" />&nbsp;
                        <label htmlFor="" className="landing-label showxs">Allow participants to join before <br /> host</label>
                      </div>
                      <div className="col-12">
                        <div className="row mt-n2 mb-4">
                          <div className="offset-1 col-10 offset-1 order-2">
                            <button
                              type="button"
                              className="btn no-class text-purple landing-btn-sm-mob">
                              Cancel
                            </button>
                          </div>
                          <div className="offset-1 col-10 offset-1 mb-2 order-1">
                            <button
                              type="button"
                              className="btn btn-purple landing-btn-sm-purple-mob">
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-1 col-lg-1"></div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.session.user,
});

export default withRouter(connect(mapStateToProps)(Setting));