import React, { Component} from "react";
import ShowInvoice from './showInvoice';
import GetBilling from './getBilling';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PaymentErrors from "./paymentErrors";
import { payErrorClear } from "../../../../common/session/sessionAction";

class billing extends Component {
    constructor(props) {
        super(props);
        this.state = { 

        }
    }

    handleError() {
        this.props.dispatch(payErrorClear());
    }

    render() { 
        const { payError } = this.props;
        return ( 
            <div className="row">
                <div className=" col-12 col-md-12 offset-lg-1 col-lg-11 col-xl-11 billing text-left text-black">
                    <div className="card">
                        <div className="col-12 mt-4 ml-3 billing-tab-title">
                            Billing
                        </div>       
                        <div className="row">
                            <div className="col-12">
                                <nav className="ml-4">
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <a className="nav-item nav-link GilroyExtrabold biling-border-bottom active" id="nav-billing-tab" data-toggle="tab" href="#nav-billing" role="tab" aria-controls="nav-billing" aria-selected="false" onClick={() => this.handleError()}>Current Plan</a>
                                        <a className="nav-item nav-link GilroyExtrabold biling-border-bottom ml-4" id="nav-invoicehistory-tab" data-toggle="tab" href="#nav-invoicehistory" role="tab" aria-controls="nav-invoicehistory" aria-selected="false" onClick={() => this.handleError()}>Invoice History</a> 
                                    </div>                                                                                        
                                </nav>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12 line-adjustment mb-2 billing-bottom-line"></div>
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-billing" role="tabpanel" aria-labelledby="nav-billing-tab">
                                <div className="row">
                                    <div className="col-12 ml-0 text-black">
                                        {/* Payment Error Component */}
                                        { payError && <PaymentErrors errInfo ={payError} /> }
                                        <GetBilling/>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade show" id="nav-invoicehistory" role="tabpanel" aria-labelledby="nav-invoicehistory-tab">
                                <div className="row">
                                    <div className="col-11 ml-4 mt-4 mb-5 text-black">
                                        <ShowInvoice/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    payError: state.session.paymentError
});

export default withRouter(connect(mapStateToProps)(billing));  