import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { api } from "../../../../common/api";
import { connect } from "react-redux";
import RecVideo from "../../../../assets/recordedvideo.svg";
import SnackBar from "../../../../common/SnackBar";
import { updateMeeting } from "../../../../common/session/sessionAction";
import StatusHelper from '../../../../common/helpers/statusHelper';


class landingHome extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      showSnack: false,
      copySuccess: false,
      passCode: "123#aBcD$@",
      passCodeCopy: "123#aBcD$@",
      passcodeEnable: props.meetingData.enablePasscode,
      hostLock: props.meetingData.hostLock,
      statusData:{},
      passCodeBtn:true,
      cancelBtn:"hidden"
    }
    this.passCodeBtn = React.createRef();
  }
  statusHelper = new StatusHelper(this);
  componentDidMount( ){
    this.setState({
      passCode: this.props.meetingData.passcode,
      passCodeCopy: this.props.meetingData.passcode,
    })  
  }
  copyCodeToClipboard(str) {
    this.setState({ showSnack: true });
    var inp = document.createElement("textarea");
    document.body.appendChild(inp);
    inp.value = str;
    inp.select();
    document.execCommand("copy");
    inp.remove();
    this.setState({ copySuccess: true, showSnack: false });
    setTimeout(() => {
      this.setState({ copySuccess: false });
    }, 4000);
  }

  handlePasscodeChange(e){
    this.setState({ passCode: e.target.value });
    if(e.target.value==='')
    {
      this.setState({statusData: {
      type: 'error',
      message: "Passcode cannot be empty"
      }});
      this.setState({passCodeBtn:false});
    }
    else if(e.target.value.length<3)
    {
      this.setState({statusData: {
      type: 'error',
      message: "Passcode must contain atleast 3 characters"
      }});
      this.setState({passCodeBtn:false});
    }
    else if(e.target.value.length>10)
    {
      this.setState({statusData: {
      type: 'error',
      message: "Passcode must not have more than 10 characters"
      }});
      this.setState({passCodeBtn:false});
    }
    else
    {
      this.setState({statusData: {
      type: 'success',
      message: ""
      }});
      this.setState({passCodeBtn:true});
  }
  }

  onPasscodeChange() {
    this.setState({ onEdit: !this.state.onEdit }, () => {
      if (!this.state.onEdit){
        this.props.dispatch(
          updateMeeting({
            passcode: this.state.passCode,
            token: this.props.token,
            email: this.props.user.email,
            roomName: this.props.meetingData.meetingId,
          })
        );
        this.setState({passCodeCopy: this.state.passCode});
      }
    }); 
  }

  handleCancel(){
    this.setState({onEdit:false});
    const passCodeCopy= this.state.passCodeCopy;
    this.setState({passCode:passCodeCopy});
    this.setState({statusData: {
      type: 'success',
      message: ""
      }});
      this.setState({passCodeBtn:true});
  }
  
  handlePasswordEnable(value) {
    this.setState({ passcodeEnable: value });
    this.props.dispatch(
      updateMeeting({
        token: this.props.token,
        email: this.props.user.email,
        enablePasscode: value,
        roomName: this.props.meetingData.meetingId,
      })
    );
    if(!value){
      this.handleCancel();
    }
  }

  handleHostLock(value) {
    this.setState({ hostLock: value });
    this.props.dispatch(
      updateMeeting({
        token: this.props.token,
        email: this.props.user.email,
        hostLock: value,
        roomName: this.props.meetingData.meetingId,
      })
    );
  }

  render() { 
    const { user, meetingData, token } = this.props;
    const EXPIRED_VALUE = "expired";
    const profile_url = `${user.profileurl ? user.profileurl : '' }`;
    // console.log('URL landing',profile_url);

    let custStyle = this.state.passcodeEnable
      ? "slider round"
      : "slider round adjustLeft";

      let disableStart;
      let userTypeValue = user.userType;
      if(userTypeValue === EXPIRED_VALUE) {
        disableStart = true; 
      } else {
        disableStart = false;
      }
    return ( 
      <div className="home">
        {this.state.copySuccess ? (
          <SnackBar
            message={"Copied to Clipboard"}
            snackShow={this.state.copySuccess}
            minwidth="250px"
          ></SnackBar>
        ) : (
          ""
        )}
        <div className="row">
          <div className="col-md-12 col-12 text-left text-black">
            <div className="col-md-12 offset-lg-1 col-lg-8">
              <div className="card br_50">
                <div className="row mt_4">
                  <div className="col-1"></div>
                  <div className="col-10 form-inline my-2 home-theme">Would you like to explore our themes? &nbsp; &nbsp; &nbsp; &nbsp;
                    <a  type="button"
                        className="GilroyExtrabold text-purple"
                        data-toggle="modal"
                        data-target="#exampleModalCenter">
                          Check out now!
                    </a>
                  </div>
                  <div className="col-1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt__20">
          <div className="col-lg-1">&nbsp;</div>
          <div className="col-md-12 text-black col-lg-7">
            <div className="card border_0">
              <div className="my-3">
                <div className="row">
                  <div className="offset-md-1 col-md-2 offset-1 col-2">
                    <div className="landing-img-properties width_height_60">
                      <img src={profile_url} alt="image" className="home-image"></img>
                    </div>
                  </div>
                  <div className="offset-1 col-8 offset-md-0 col-md-8">
                    <div className="card-text home-user-name">
                      <span>
                        {user.firstname} {user.lastname}
                      </span>
                    </div>
                    <div className="card-text home-user-email">
                      <span className="text-muted">{user.email}</span>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="offset-1 col-6">
                    <div className="home-meeting-id">Your Personal Meeting ID:</div>
                  </div>
                  <div className="col-5">
                    <div className="home-meeting-id">
                      {meetingData.meetingId ? meetingData.meetingId : ""}
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="offset-1 col-11">
                    <div className="home-share-attendees"> Share this link with your attendees. </div>
                  </div>
                </div>
                <div className="row">
                  <div className="offset-1 col-6 mt-2">
                    <div className="home-share-attendees">
                      {api.WEB_URL}/join/{meetingData.meetingId ? meetingData.meetingId : ""}
                    </div>
                  </div>
                  <div className="col-5 form-inline mt-1">
                    <span 
                          className="xsmall copy-to-clipboard-text navbar-brand text-purple ml-md-1"
                          role="button"
                          onClick={() =>
                            this.copyCodeToClipboard(`
${api.WEB_URL}/join/${meetingData.meetingId}${this.state.passcodeEnable ? '/' : ''}${this.state.passcodeEnable? (meetingData.encryptedPasscode): ''}`)
                          }>
                          <i className="fa fa-clone"></i> Copy URL
                      </span>
                  </div>
                </div>
                <div>&nbsp;</div>
                <div className="row mt-2">
                  <div className="offset-1 col-6">
                    <div className="home-require-pass wrap"> Require a password for Personal Meeting ID (PMI) </div>
                  </div>
                  <div className="col-5 form-inline">
                    <label className="switch">
                      <input  type="checkbox"
                              onChange={() =>  this.handlePasswordEnable( !this.state.passcodeEnable ) }
                              checked={this.state.passcodeEnable}/>
                      <span className={custStyle}></span>
                    </label>
                  </div>
                </div>
                {this.state.passcodeEnable ? (
                  <div className="row mt-2">
                    <div className="offset-1 col-5">
                      <div className="home-passcode mt-1"> Passcode (Optional) </div>
                    </div>
                    <div className="offset-1 col-5 form-inline">
                      {this.state.onEdit ? (
                      <div className="md-form mt-0">
                        <input  type="text"
                                className="form-control w_100"
                                maxlength="10"
                                value={this.state.passCode}
                                // onChange={(e) =>
                                //   this.setState({ passCode: e.target.value })
                                // }
                                onChange={(e)=>this.handlePasscodeChange(e)}
                          />
                      </div>) : (
                        <div className="home-passcode">{this.state.passCode}</div>
                      )}
                            <div className="small password-alert">
                            {Object.keys(this.state.statusData).length > 0 &&
                              this.statusHelper.renderStatus(this.state.statusData)}
                            </div>
                            <button name="passCodeBtn"
                              id="passCodeBtn"
                              className="btn btn-sm btn-default home-save-edit-btn mt-1 mt-md-0 mt-lg-0 ml-n2 ml-md-0 ml-lg-0 text-purple"
                              hidden={this.state.onEdit ? "" : "hidden"}
                              onClick={()=>this.handleCancel()}
                            >Cancel
                            </button> 
                            <button 
                              name="passCodeBtn"
                              id="passCodeBtn"
                              ref={this.passCodeButton}
                              className="btn btn-sm btn-default home-save-edit-btn mt-1 mt-md-0 mt-lg-0 ml-1 ml-md-0 ml-lg-0 text-purple"
                              disabled={!this.state.passCodeBtn}
                              onClick={() => this.onPasscodeChange()}>
                              {this.state.onEdit ? "Save" : "Edit"}
                            </button>

                    </div>
                  </div> ) : null
                }
                <div className="row mt-4">
                  <div className="offset-7 col-5 form-inline">
                    <span 
                          className="copy-to-clipboard-text text-purple "
                          role="button"
                          onClick={() =>
                            this.copyCodeToClipboard(`
${user.firstname} ${user.lastname} is inviting you to a scheduled Jaam meeting.
${this.state.passcodeEnable ? `
Just click to join without entering passcode:
${api.WEB_URL}/join/${meetingData.meetingId}${this.state.passcodeEnable ? '/' : ''}${this.state.passcodeEnable? (meetingData.encryptedPasscode)

  : ''}` : ''}
${this.state.passcodeEnable ? `
Or join Jaam Meeting using the below URL` : 'Join Jaam Meeting'}

${api.WEB_URL}/join/${meetingData.meetingId}

Meeting ID: ${meetingData.meetingId}                        
${this.state.passcodeEnable? `
Passcode: ${meetingData.passcode} `:``}`)
                          }>
                            <i className="fa fa-clone"></i> Copy invite details
                    </span>
                  </div>
                  </div>
                  <div className="row mt-2">
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 text-black col-lg-4">
            <div className="card-circle ml-lg-n5">
              <div className="mt-5">&nbsp;</div>
                <div className="row">
                  <div className="offset-1 col-10 d-flex justify-content-center">
                    <span className="home-start-meeting-text"> Start a meeting </span>
                  </div>
                </div>
                <div className="row">
                  <div className="offset-1 col-10 d-flex justify-content-center">
                    <button type="button"
                            className="col-6 btn btn-purple btn-sm"
                            disabled={disableStart}
                            onClick={() => {
                              window.location.href = `${api.APP_URL}/?m=${meetingData.meetingId ? meetingData.meetingId : "" }&t=${token ? token : ""}&q=${user.firstname ? user.firstname : "jaamUser"}`
                              window.location.replace(`${api.APP_URL}/?m=${meetingData.meetingId ? meetingData.meetingId : "" }&t=${token ? token : ""}&q=${user.firstname ? user.firstname : "jaamUser"}`)
                              return;
                             }                            
                            } > Start
                    </button>
                  </div>
                </div>
                <div className="row border-top border-secondary my-3 offset-1 col-10"></div>
                <div className="offset-1 col-10 home-meeting-question"> Did you get meeting invite to join a meeting? &nbsp;&nbsp;
                  <Link to="/join" className="GilroyExtrabold text-purple text-decoration-none home-join-now-text">
                    Join now!
                  </Link>
                </div>
            </div>
            <div className="mt-5 d-none">
              <div className="row mt-2 mx-auto">
                <h3>Start a meeting</h3>
              </div>
              <div className="row mt-2 mx-auto">
                <button type="button"
                        className="col-6 btn btn-purple btn-sm"
                        onClick={() =>
                          window.open(
                            `${api.APP_URL}/?m=${
                              meetingData.meetingId ? meetingData.meetingId : ""
                            }&t=${token ? token : ""}&q=${
                              user.firstname ? user.firstname : "jaamUser"
                            }`,
                            "_blank"
                          )
                          }>
                      Start
                </button>
              </div>
              <div className="row border-top border-secondary my-3 col-12"></div>
                <div className="row mt-2">
                  <div className="col-md-12"> Did you get meeting invite to join a meeting?&nbsp;&nbsp;
                    <Link to="/join" className="GilroyExtrabold text-purple text-decoration-none">
                      Join now!
                    </Link>
                  </div>
                </div>
            </div>
          </div>
          <div className="col-lg-1">&nbsp;</div>
        </div>
        {/* settings option */}
        <div className="row mt-4 home">
          <div className="col-lg-1">&nbsp;</div>
            <div className="col-12 col-md-12 col-lg-10 col-xl-10">
              <div className="card">
                  <ul className="home-unordered-list">
                    <li className="home-ending-line mt-4 ml-3 home-list">
                      <div className="col-md-12 mb-3">
                        <div className="row">
                          <div className="col-2 col-md-1 col-lg-1">
                            {/* <div  className="landing-img-properties width_height_40">
                              <img src={user.profileurl} alt="image" className="home-image"></img>
                            </div> */}
                          </div>
                          <div className="offset-2 offset-md-0 offset-lg-0 col-10 mt-3 mt-md-0 mt-lg-0 col-md-5 col-lg-6">
                            <div className="row">
                              <div className="col-12 text-black"> Allow Participants to join the meeting before the Host</div>
                            </div>
                          </div>
                          <div className="offset-3 offset-md-0 offset-lg-0 col-4 span-5 col-md-2 col-lg-3">
                            <div className="row">
                              <div className="col-lg-3 mt-1"></div>
                              <div className="col-5 form-inline">
                                <label className="switch">
                                  <input  type="checkbox"
                                          onChange={() =>  this.handleHostLock( !this.state.hostLock ) }
                                          checked={!this.state.hostLock}/>
                                  <span className={custStyle}></span>
                                </label>
                              </div>
                              {/* <div className="col-lg-3"></div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  {/* <div className="col-12 mt-2 ml-3 home-tab-title">
                </div>     */}
              </div>      
            </div>
            {/* <div className="col-lg-2">&nbsp;</div> */}
          </div>
          {/* //Jaam code to disable meeting options */}
        {/* Meetings */}
        {/* <div className="row mt-4 home">
          <div className="col-lg-1">&nbsp;</div>
            <div className="col-12 col-md-12 col-lg-10 col-xl-10">
              <div className="card">
                <div className="col-md-12 mt-4 ml-3 home-tab-title"> Upcoming meetings </div>
                  <ul className="home-unordered-list">
                    <li className="home-ending-line mt-4 ml-3 home-list">
                      <div className="col-md-12 mb-3">
                        <div className="row">
                          <div className="col-2 col-md-1 col-lg-1">
                            <div  className="landing-img-properties width_height_40">
                              <img src={user.profileurl} alt="image" className="home-image"></img>
                            </div>
                          </div>
                          <div className="col-9 col-md-4 col-lg-4">
                            <div className="row">
                              <div className="col-12 home-timing-text">03:30 PM  -  04:30PM</div>
                              <div className="col-12 text-black home-gray-color">Sun, Feb 7</div>
                            </div>
                          </div>
                          <div className="offset-2 offset-md-0 offset-lg-0 col-10 mt-3 mt-md-0 mt-lg-0 col-md-5 col-lg-4">
                            <div className="row">
                              <div className="col-12 text-black"> UX Review </div>
                              <div className="col-12 text-black home-gray-color">Raghavan Shanmugam </div>
                            </div>
                          </div>
                          <div className="offset-3 offset-md-0 offset-lg-0 col-4 span-5 col-md-2 col-lg-3">
                            <div className="row">
                              <div className="col-lg-3 mt-1"></div>
                              <button type="button"
                                      className="col-12 col-lg-6 ml-n2 ml-md-0 ml-lg-0 mt-2 mt-md-0 mt-lg-0 btn text-purple home-start-btn">
                                    Start
                              </button>
                              <div className="col-lg-3"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="home-ending-line mt-4 ml-3 home-list" >
                      <div className="col-md-12 mb-3">
                        <div className="row">
                          <div className="col-2 col-md-1 col-lg-1">
                            <div className="landing-img-properties width_height_40">
                                <img src={user.profileurl} alt="image" className="home-image"></img>
                            </div>
                          </div>
                          <div className="col-9 col-md-4 col-lg-4">
                            <div className="row">
                              <div className="col-12 home-timing-text">04:00 PM  -  05:30PM</div>
                              <div className="col-12 text-black home-gray-color">Sun, Jan 31</div>
                            </div>
                          </div>
                          <div className="offset-2 offset-md-0 offset-lg-0 col-10 mt-3 mt-md-0 mt-lg-0 col-md-5 col-lg-4">
                            <div className="row">
                              <div className="col-12 text-black"> Design Thinking </div>
                              <div className="col-12 text-black home-gray-color"> Serma Selvaraj </div>
                            </div>
                          </div>
                          <div className="offset-3 offset-md-0 offset-lg-0 col-4 span-5 col-md-2 col-lg-3">
                            <div className="row">
                              <div className="col-lg-3 mt-1"></div>
                              <button type="button"
                                      className="col-12 col-lg-6 ml-n2 ml-md-0 ml-lg-0 mt-2 mt-md-0 mt-lg-0 btn no-class text-purple home-start-btn">
                                    Start
                              </button>
                              <div className="col-lg-3"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="col-12 mt-2 ml-3 home-tab-title">
                    Recorded Meetings&nbsp;&nbsp;&nbsp;<a href="#" className="text-purple font-size_18">more...</a>
                  </div>    
                  <div className="col-12 mt-4 ml-3 mb-3">
                    <div className="row">
                      <div className="col-md-3 col-12">
                        <img src={RecVideo} alt="image" className="home-image"></img>  
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="row ml-lg-n5">
                          <div className="col-12 home-timing-text">UX Review Meeting</div>
                          <div className="col-12 home-recorded-by-text">Recorded By : Raghavan...</div>
                          <div className="col-9 col-md-12 home-timing-text">Started : Yesterday  8:00 AM &nbsp; Ended : 10:00 AM</div>
                        </div>
                      </div>
                      <div className="col-md-3">&nbsp;</div>
                    </div>
                  </div>                                                                                          
              </div>      
            </div>
            <div className="col-lg-2">&nbsp;</div>
          </div> */}
       </div>
     );
  }
}
 
const mapStateToProps = (state, ownProps) => ({
  user: state.session.user,
  token: state.session.token,
  meetingData: state.session.meetingData,
});

export default withRouter(connect(mapStateToProps)(landingHome));