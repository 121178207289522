import React, { Component } from "react";

const classSelectedBG = "";
class ThemeTile extends Component {
  render() {
    const {
      dataImages,
      onSelectImage,
      keyProperty,
      valueProperty,
      labelProperty,
      selectedBackGround,
    } = this.props;
    const abc = 0;
    return (
      <div className="row mt-3 gutters">
        {dataImages.map((img, index) => {
          return (
            <div
              key={img[keyProperty]}
              className="col-6 col-md-4 col-xl-4 mb-5 dice-container"
            >
              <div
                className={
                  "imageDice-theme " +
                  (img[valueProperty] === selectedBackGround
                    ? "selectedDice"
                    : "")
                }
                onClick={() => onSelectImage(img[valueProperty])}
              >
                <img src={img[keyProperty]} alt=""></img>
              </div>
              <div className="circle-theme">
                <span className="theme-label">{img[labelProperty]}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

ThemeTile.defaultProps = {
  keyProperty: "key",
  valueProperty: "value",
  labelProperty: "label",
};
export default ThemeTile;
