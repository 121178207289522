import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import SocialButton from "../common/SocialButton";
import { LinkedIn } from "react-linkedin-login-oauth2";
import { signup,signOut,setShowProfile } from "../common/session/sessionAction";
import _ from "lodash";
import LoadingComp from "../common/LoadingComp";
import { defaultTheme, defaultThemeImage } from "../common/api";
import { withTranslation } from "react-i18next";
// import {signUpEmail} from "../services/signupService";
import SingleProviderModal from "./singleProviderModal";
import ExistingEmailModal from "./existingEmailModal";
class SignUp extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
  }

  handleSocialLogin = (user) => {
    var profile = {};

    profile = {
      firstname: user._profile.firstName,
      lastname: user._profile.lastName,
      profileurl: user._profile.profilePicURL,
      provider: user._provider,
      email: user._profile.email,
      socialId: user._profile.id,
    };

    // //email trigger
    // (async()=>{
    //     await signUpEmail(profile);
    //     const statusData = {
    //       type: "success",
    //     };
    //   })();
    // //email end
      this.props.dispatch(
        signup({
          ...profile,
          theme: defaultTheme,
          themeImage: defaultThemeImage,
        })
      );
  };

 signOut() {
  this.props.dispatch(setShowProfile(false));
  this.props.dispatch(signOut());
}

  componentWillReceiveProps(nextProps) {
    // console.log(this.props.emailFlag,"from cwrp");
    if(this.props.emailFlag != nextProps.emailFlag){
      // console.log(nextProps.emailFlag,"nextprops");
      if((nextProps.emailFlag == 1) || (nextProps.emailFlag == 2)) {
        this.signOut();
      }else{
        this.props.history.push("/landing");
      }
    } else if (
      nextProps.isSignedUp === false &&
      this.props.isSignedUp !== nextProps.isSignedUp &&
      nextProps.signUpMessage === ""
    ) {
      this.props.history.push("/landing");
    } else if (
      nextProps.user &&
      !_.isEmpty(nextProps.user) &&
      !_.isEqual(this.props.user, nextProps.user)
    ) {
      this.props.history.push("/landing");
    }
  }

  handleSocialLoginFailure = (err) => {
    console.error(err);
  };
  state = {};
  render() {
    const { loading, isSignedUp, t, emailFlag } = this.props;
    return loading || isSignedUp ? (
      <div
        style={{ minHeight: "50vh", textAlign: "center" }}
        className="background"
      >
        <LoadingComp />
      </div>
    ) : (
      <section className="background">
        <div className="container container-decoration">
          <div className="row content-center">
          { (emailFlag == 2) ?
              <div>
                <SingleProviderModal/>
              </div>
              :
              ""
              }
              { (emailFlag == 1) ?
              <div>
                <ExistingEmailModal/>
              </div>
              :
              ""
              }
            <div className="col-md-10 col-lg-8">
              <div className="col-md-12 form-group">
                <h2 className="text-center">{t("signup.tryForFree")}</h2>
              </div>

              <div className="col-md-12 form-group mt-4 text-center">
                <SocialButton
                  provider="google"
                  appId="763039710911-b5b2ib9clf3rl5dpv9o6jkhr5bju1kd5.apps.googleusercontent.com"
                  onLoginSuccess={this.handleSocialLogin}
                  onLoginFailure={this.handleSocialLoginFailure}
                >
                  {t("signup.signupWithGoogle")}
                </SocialButton>
              </div>
              <div className="col-md-12 form-group mt-4 text-center">
                <SocialButton
                  provider="facebook"
                  appId="866708197220186"
                  onLoginSuccess={this.handleSocialLogin}
                  onLoginFailure={this.handleSocialLoginFailure}
                >
                  {t("signup.signupWithFacebook")}
                </SocialButton>
              </div>
              {/* <div className="form-group mt-4">
              <LinkedIn
                clientId="86muxx0qbtyg3q"
                className="offset-md-2 col-md-8 offset-md-2 btn btn-default btn-lg text-left"
                onFailure={this.handleSocialLoginFailure}
                onSuccess={this.handleSocialLogin}
                redirectUri="http://localhost:3000/linkedin"
                scope="r_liteprofile,r_emailaddress"
                state="DCEeFWf45A53sdfKef424"
              >
                <text>Signup with LinkedIn</text>
              </LinkedIn>
            </div> */}
              <div className="col-md-12 form-group mt-4">
                <div className="form-inline offset-md-2">
                  <p>{t("signup.termsText")}</p>
                  <Link to="/termsofservice" target="_blank">
                    <p className="mx-1 text-purple">
                      {t("signup.termsLinkText")}
                    </p>
                  </Link>
                </div>
              </div>
              <div className="col-md-12 form-group mt-n2">
                <div className="form-inline offset-md-2">
                  <p> {t("signup.alreadyHaveAccount")}</p>
                  <Link to="/signin">
                    <p className="mx-1 text-purple"> {t("signup.signin")}</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {this.props.signUpMessage && 
          <div className="row">         
          <div className="col-10 col-md-10 col-lg-11 mt-4 mb-4 payment-error-message">
            <span>{this.props.signUpMessage}</span>                  
            </div>
          </div>
          }

        </div>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.session.user,
  token: state.session.token,
  isSignedUp: state.session.isSignedUp,
  loading: state.session.loading,
  signUpMessage: state.session.signUpMessage,
  emailFlag: state.session.emailFlag
});

export default withTranslation()(withRouter(connect(mapStateToProps)(SignUp)));
