import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'lodash';
import LoadingComp from '../../../../common/LoadingComp';
import { stripeGetInvoice } from '../../../../common/session/sessionAction';
import getSymbolFromCurrency from 'currency-symbol-map';
import {invoice_status} from '../../../../common/constants';

class ShowInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerToken: props.user.token,
      priceId: '',   
      customerEmail: props.user.email,   // signed in user
      customerId: props.customerId,          
      showSnack: false,    
      statusData: {},  
      invoiceList: props.invoiceList,
    };
  }  

  componentDidMount () {
    if (!_.isEmpty(this.props.user) && !_.isEmpty(this.props.user.token))  {     
      this.props.dispatch(stripeGetInvoice({ ...this.state }));
    }          
  }

  componentWillReceiveProps(nextProps) { 
    if (!_.isEmpty(nextProps.customerId) && this.props.customerId !== nextProps.customerId ) {       
      this.setState({ customerId: nextProps.customerId });
    }    
    if (
      nextProps.user.token &&
      !_.isEmpty(nextProps.user.token) &&
      !_.isEqual(this.props.user.token, nextProps.user.token)
    ) {
      this.setState({
        customerToken: nextProps.user.token ? nextProps.user.token : "",
      });
    }
    if (
      nextProps.user &&
      !_.isEmpty(nextProps.user) &&
      !_.isEqual(this.props.user, nextProps.user)
    ) {
      this.setState({
        customerEmail: nextProps.user.email ? nextProps.user.email : "",
      });
    }   
    if (
      nextProps.joinError &&
      nextProps.joinError !== "" &&
      this.props.joinError !== nextProps.joinError
    ) {
      nextProps.joinErrorCode === "ERR_01" ? this.setState({ showSnack: true }) : this.setState({statusData: {
        type: 'error',
        message: nextProps.joinError
      }});
    } 
    if (!_.isEmpty(nextProps.invoiceList) && this.props.invoiceList !== nextProps.invoiceList ) {       
        this.setState({ invoiceList: nextProps.invoiceList });
    }
  }

  formatDate = (dateString) => {
    const date = new Date(dateString*1000);
    return date.toUTCString("en-GB");
  }

  render() {
    const { loading } = this.props;    
    let loadingStyle = {
      minHeight: "50vh",
      textAlign: "center",
    };
    return  loading ? (
      <div style={loadingStyle} className="background">
        <LoadingComp />
      </div>
      ) : (
       <div className="hscroll">
       <table className="table invoice-table">
                  <thead>
                    <tr className="Invoice-table-heading">
                      <th><div> Invoice Number </div></th>
                      <th><div> Payment Date </div></th>
                      <th><div> Status </div></th>
                      <th><div> Amount </div></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="Invoice-table-content">
                    {
                      (this.state && this.state.invoiceList && this.state.invoiceList.length > 0) ? (this.state.invoiceList.map(invoice => 
                        // (
                          {
                            return invoice.status === invoice_status ?
                        <tr key={invoice.id}>
                          <td>
                            <div>{invoice.number}</div>
                          </td>
                          <td>
                            <div>{this.formatDate(invoice.created)}</div>
                          </td>
                          <td>
                            <div className="Invoice-status">{invoice.status}</div>
                          </td>
                          <td>
                            <div>{getSymbolFromCurrency(invoice.currency)}
                            &nbsp;{Number(invoice.amount_paid/100)}</div>
                          </td>
                          <td>
                            <a href={invoice.hosted_invoice_url}>
                              <span className="text-purple">Download Receipt</span>
                            </a>
                          </td>
                        </tr>
                        :
                        ''
                      }
                    // )
                      )) 
                      : 
                      <div className="my-5 d-flex justify-content-center">No Items</div>
                    }
                  </tbody>
                </table>
                </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.session.user,
  token: state.session.token, 
  loading: state.session.loading,
  customerId: state.session.customerId,
  invoiceUrl: state.session.invoiceUrl,
  invoiceList: state.session.invoiceList,
  sessionId: state.session.sessionId,
});

export default withTranslation()(connect(mapStateToProps)(ShowInvoice));