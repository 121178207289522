import React from "react";

const TextAreaElem = ({ name, label, error, ...rest }) => {
  return (
    <div>
      <label htmlFor={name}>{label}:</label>
      <textarea {...rest} name={name} />
      {error && <div className="errormessage">{error}</div>}
    </div>
  );
};

export default TextAreaElem;
